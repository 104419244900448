import { useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import appDrawerStyles from "./AppDrawer.styles";
import CloseButton from "../../../global/components/CloseButton/CloseButton";
import AppFooter from "../AppFooter/AppFooter";
import HomeRunLogo from "../../../assets/images/logos/logo_dark_en.svg";
import AgencyLogo from "../../../assets/images/logos/agency_en.svg";
import HomeRunLogoFr from "../../../assets/images/logos/logo_dark_fr.svg";
import AgencyLogoFr from "../../../assets/images/logos/agency_fr.svg";
import urls from "../../../global/constants/UrlConstants";
import strings from "../../../global/constants/StringConstants";
import { selectLocale } from "../../../redux/authSlice";
import { useAppSelector } from "../../../utils/hooks";
import { useLocation } from "react-router-dom";
import history from "../../../utils/history";

interface CustomProps {
  setMenuMobileVisible?: Function;
  isActive?: boolean;
  listItems: JSX.Element;
  subListItems?: JSX.Element;
}

const AppDrawer = (props: CustomProps) => {
  const classes = appDrawerStyles;
  const theme = useTheme();
  const location = useLocation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const locale = useAppSelector(selectLocale);

  const getLogo = () => {
    return (
      <Box my={4} style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={
            location.pathname === "/"
              ? locale === strings.EN
                ? AgencyLogo
                : AgencyLogoFr
              : locale === strings.EN
              ? HomeRunLogo
              : HomeRunLogoFr
          }
          alt="HomeRun"
          style={{
            height: isDesktop ? "60px" : "50px",
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={() => history.push(urls.homeViewPath)}
        />
      </Box>
    );
  };

  const getCloseButton = () => {
    return (
      <CloseButton
        onClick={() => props.setMenuMobileVisible && props.setMenuMobileVisible(false)}
      />
    );
  };

  const getAppDrawer = () => {
    return (
      <Box sx={classes.drawer}>
        <Box style={{ width: "100%" }}>
          {!isDesktop && getCloseButton()}
          {!isDesktop && getLogo()}
          {props.listItems}
          {props.subListItems}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {getAppDrawer()}
      <AppFooter />
    </>
  );
};

export default AppDrawer;
