import urls from "../../global/constants/UrlConstants";
import { getCallParams, getNoAuthCallParams, makeCall } from "../../utils/service";

export const retrieveCategories = async (locale: String) => {
  try {
    const url = urls.CATEGORY_LIST + "/" + locale.toUpperCase();
    const callParams = getNoAuthCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const retrieveMeasures = async (category: string, locale: string) => {
  try {
    const url = urls.CATEGORY_LIST + "/" + locale + "/category/" + decodeURI(category);
    const callParams = getNoAuthCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const retrievePeerGroups = async () => {
  try {
    const url = urls.PEER_GROUP_LIST;
    const callParams = getNoAuthCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const retrieveAfterLoginPeerGroups = async () => {
  try {
    const url = urls.AUTH_PEER_GROUP_LIST;
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const retrieveMeasureDetails = async (
  locale: string,
  measureId: string,
  peerGroupId: number,
  category: string,
) => {
  try {
    const url =
      urls.MEASURE_DETAILS +
      "/" +
      locale +
      "/measure/" +
      measureId +
      "/peer/" +
      peerGroupId +
      "?category=" +
      category;
    const callParams = getNoAuthCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const retrieveAfterLoginMeasureDetails = async (
  locale: string,
  measureId: string,
  peerGroupId: number,
  category: string,
) => {
  try {
    const url =
      urls.AUTH_MEASURE_DETAILS +
      "/" +
      locale +
      "/measure/" +
      measureId +
      "/peer/" +
      peerGroupId +
      "?category=" +
      category;
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
