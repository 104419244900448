import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalLayout from "../../Pages/Global/GlobalLayout";
import CustomInput from "../../../../global/components/CustomInput/CustomInput";
import { homeInputFields } from "./HomeTypesAndValidation";
import homeStyles from "./Home.styles";
import PreviewHomeComponent from "../../../Template/Home/Home";
import strings from "../../../../global/constants/StringConstants";
import { addImage, deleteImage, getAllRoutes } from "../../AdminService";
import { retrieveImage } from "../../../PageRenderService";
import { imageRenderer } from "../../../../utils/service";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { isTruthy, openErrorNotification } from "../../../../helpers/methods";
import notifiers from "../../../../global/constants/NotificationConstants";
import { v4 as uuidv4 } from "uuid";
import CustomLoader from "../../../../global/components/CustomLoader/CustomLoader";

interface CustomProps {
  content: any;
  updatePageContent: Function;
  templateId: string;
  routesList: string[];
}

const Home = (props: CustomProps) => {
  const classes = homeStyles;
  const [formData, setFormData] = useState(homeInputFields(props.content));
  const [selectedFile, setSelectedFile] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.updatePageContent(formData);
  }, [formData]);

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        button: {
          ...formData.banner.button,
          hyperlink: "",
          isExternal: event.target.checked,
        },
      },
    });
  };

  const handleImageAltTextOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const localImageState = formData.banner.images;
    localImageState[index].altText = event.target.value;
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        images: localImageState,
      },
    });
  };

  const handleAboutUsButtonLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      about: {
        ...formData.about,
        button: {
          ...formData.about.button,
          hyperlink: "",
          isExternal: event.target.checked,
        },
      },
    });
  };

  const handleReadMoreLinksChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedCards: any = formData.cards;

    updatedCards[index]["readMoreLink"]["hyperlink"] = "";
    updatedCards[index]["readMoreLink"]["isExternal"] = event.target.checked;
    setFormData({
      ...formData,
      cards: updatedCards,
    });
  };

  const handleOnchange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleIntervalChange = (event: any) => {
    if (event.target.value <= 0) {
      return openErrorNotification("Interval can't be zero or in negative.");
    }
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        carousel_metadata: {
          ...formData.banner.carousel_metadata,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  const handleOnChangeOnAboutSection = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      about: {
        ...formData.about,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleCardsOnChange = (event: React.ChangeEvent<any>, index: number) => {
    const updatedCards: any = formData.cards;
    updatedCards[index][event.target.name] = event.target.value;
    setFormData({
      ...formData,
      cards: updatedCards,
    });
  };

  const handleReadMoreLinksOnChange = (event: React.ChangeEvent<any>, index: number) => {
    const updatedCards: any = formData.cards;
    updatedCards[index]["readMoreLink"][event.target.name] = event.target.value;
    setFormData({
      ...formData,
      cards: updatedCards,
    });
  };

  const handleBannerButtonsChange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        button: {
          ...formData.banner.button,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  const handleAboutButtonsChange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      about: {
        ...formData.about,
        button: {
          ...formData.about.button,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  const handleChangeDescription = (event: React.ChangeEvent<any>, index: number) => {
    const localDescriptionState = formData.banner.description;
    localDescriptionState[index] = event.target.value;
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        description: localDescriptionState,
      },
    });
  };

  const handleChangeDescriptionInAbout = (event: React.ChangeEvent<any>, index: number) => {
    const localDescriptionState = formData.about.description;
    localDescriptionState[index] = event.target.value;
    setFormData({
      ...formData,
      about: {
        ...formData.about,
        description: localDescriptionState,
      },
    });
  };

  const handleOnDropZoneFile = async (e: any) => {
    try {
      setLoading(true);
      const localImageState = formData.banner.images;
      const data = new FormData();
      const file = e.target.files[0];
      data.append("file", file);
      const response = await addImage(data, props.templateId);
      localImageState.push({ title: response.filename, altText: "" });
      setFormData({
        ...formData,
        banner: {
          ...formData.banner,
          images: localImageState,
        },
      });
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOnIconsUpload = async (e: any, index: number) => {
    try {
      setLoading(true);
      const updatedCards: any = formData.cards;
      const data = new FormData();
      const file = e.target.files[0];
      data.append("file", file);
      const response = await addImage(data, props.templateId);
      updatedCards[index]["icon"] = response.filename;
      setFormData({
        ...formData,
        cards: updatedCards,
      });
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const addNewRow = () => {
    const localDescriptionState = formData.banner.description;
    localDescriptionState.push("");
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        description: localDescriptionState,
      },
    });
  };

  const addNewPara = () => {
    const localDescriptionState = formData.about.description;
    localDescriptionState.push("");
    setFormData({
      ...formData,
      about: {
        ...formData.about,
        description: localDescriptionState,
      },
    });
  };

  const addNewCard = () => {
    const newCard = formData.cards;
    newCard.push({
      title: "",
      icon: "",
      altText: "",
      description: "",
      readMoreLink: {
        isExternal: false,
        hyperlink: "",
      },
    });
    setFormData({
      ...formData,
      cards: newCard,
    });
  };

  const handleDelete = async (templateId: string, image: string) => {
    try {
      const deletedImage = await deleteImage(templateId, image);
      const localImages = formData.banner.images;
      const updatedImages = localImages.filter((v, i) => v.title !== image);
      setFormData({
        ...formData,
        banner: {
          ...formData.banner,
          images: updatedImages,
        },
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const handleDeleteDesc = async (text: string) => {
    try {
      const local = formData.banner.description;
      const updated = local.filter((v, i) => v !== text);
      setFormData({
        ...formData,
        banner: {
          ...formData.banner,
          description: updated,
        },
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const handleDeletePara = async (text: string) => {
    try {
      const local = formData.about.description;
      const updated = local.filter((v, i) => v !== text);
      setFormData({
        ...formData,
        about: {
          ...formData.about,
          description: updated,
        },
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const getRoutesDropDown = () => {
    return (
      <Select
        value={formData?.banner.button.hyperlink}
        name="hyperlink"
        id="hyperlink"
        onChange={(event: any) =>
          setFormData({
            ...formData,
            banner: {
              ...formData.banner,
              button: {
                ...formData.banner.button,
                [event.target.name]: event.target.value,
              },
            },
          })
        }
        sx={classes.dropDown}
      >
        {props.routesList.map((item) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    );
  };

  const getAboutUsLinkDropDown = () => {
    return (
      <Select
        value={formData?.about.button.hyperlink}
        name="hyperlink"
        id="hyperlink"
        onChange={(event: any) =>
          setFormData({
            ...formData,
            about: {
              ...formData.about,
              button: {
                ...formData.about.button,
                [event.target.name]: event.target.value,
              },
            },
          })
        }
        sx={classes.dropDown}
      >
        {props.routesList.map((item) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    );
  };

  const getReadMoreRoutesDropDown = (value: string, index: number) => {
    return (
      <Select
        value={value}
        name="hyperlink"
        id="hyperlink"
        onChange={(event: any) => handleReadMoreLinksOnChange(event, index)}
        sx={classes.dropDown}
      >
        {props.routesList.map((item) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    );
  };

  const getSection1 = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Title", true, formData.banner.title)}
          <CustomInput
            placeHolder="Title"
            type="text"
            name="title"
            id="title"
            value={formData.banner.title}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {formData.banner.description.map((ele, index: number) => {
            return (
              <>
                {getInputHeader(index > 0 ? "" : "Description", true, ele)}
                <CustomInput
                  placeHolder="Description"
                  type="text"
                  name="description"
                  id="description"
                  value={ele}
                  onChange={(event: any) => handleChangeDescription(event, index)}
                  onKeyPress={handleKeypress}
                  // error={!isTruthy(ele) && "Atleast 3 description lines required"}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteForeverIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={() => handleDeleteDesc(ele)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            );
          })}
          <Box sx={classes.addLinks} onClick={() => addNewRow()}>
            + Add
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={6} lg={12}>
          {getInputHeader("Button Label", false, formData.banner.button.label)}
          <CustomInput
            placeHolder="Button Text"
            type="text"
            name="label"
            id="label"
            value={formData.banner.button.label}
            onChange={handleBannerButtonsChange}
            onKeyPress={handleKeypress}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={6} lg={12}>
          <Stack direction="row" justifyContent="space-between">
            <Box sx={classes.label}>
              <Typography sx={classes.labelText}>Button Link</Typography>
              <Typography sx={classes.star}>*</Typography>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.banner.button.isExternal}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="External Link"
            />
          </Stack>
          {formData.banner.button.isExternal ? (
            <CustomInput
              placeHolder="/link"
              type="text"
              name="hyperlink"
              id="hyperlink"
              value={formData.banner.button.hyperlink}
              onChange={handleBannerButtonsChange}
              onKeyPress={handleKeypress}
            />
          ) : (
            getRoutesDropDown()
          )}
        </Grid>
        <Grid item xs={6} sm={6} md={6} xl={6} lg={6}>
          <Box sx={classes.label}>
            <Typography sx={classes.labelText}>Carousel Images</Typography>
          </Box>
          <CustomInput
            type="file"
            name="file"
            value={selectedFile}
            onChange={handleOnDropZoneFile}
            InputProps={{
              accept: strings.acceptedFiles,
              dataMaxSize: 3000000,
            }}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6} xl={6} lg={6}>
          <Box sx={classes.label}>
            <Typography sx={classes.labelText}>Carousel Timing (in seconds)</Typography>
          </Box>
          <CustomInput
            placeHolder="Carousel Interval"
            name="interval"
            id="interval"
            type="number"
            value={formData.banner.carousel_metadata.interval.toString()}
            onChange={handleIntervalChange}
            // error={!isTruthy(formData.banner.title) && "Title cannot be empty"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          <Box sx={classes.label}>
            <Typography sx={classes.labelText}>Image List</Typography>
          </Box>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            {formData?.banner?.images?.map((image: { title: string; altText: string }, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  display={"flex"}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <img
                    src={imageRenderer(props.templateId, image.title)}
                    width={50}
                    height={50}
                    alt={image.altText}
                    style={{ marginRight: "10px" }}
                  />
                  <CustomInput
                    placeHolder="Alt Text"
                    type="text"
                    name="altText"
                    id="altText"
                    value={image.altText}
                    onChange={(e: any) => handleImageAltTextOnChange(e, index)}
                    onKeyPress={handleKeypress}
                  />
                  <DeleteForeverIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDelete(props.templateId, image.title)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getInputHeader = (title: string, required: boolean, value: any) => {
    return (
      <Box
        sx={classes.label}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        borderColor="red"
      >
        <Box style={{ display: "flex" }}>
          {isTruthy(title) && (
            <>
              <Typography sx={classes.labelText}>{title}</Typography>
              {required && <Typography sx={classes.star}>*</Typography>}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getSection2 = () => {
    return (
      <Grid container spacing={1} display="flex" flexDirection="column">
        <Typography sx={classes.labelText}>Cards</Typography>
        {formData?.cards.map((card, index) => {
          return (
            <>
              <Grid
                container
                spacing={1}
                sx={{ borderBottom: "1px solid #E7E7F9", padding: 2 }}
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  {getInputHeader("Title", true, card.title)}
                  <CustomInput
                    placeHolder="Title"
                    type="text"
                    name="title"
                    id="title"
                    value={card.title}
                    onChange={(event: any) => handleCardsOnChange(event, index)}
                    onKeyPress={handleKeypress}
                    // error={!isTruthy(card.title) && "Title cannot be empty"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={8} lg={8}>
                  {getInputHeader("Icon", true, card.icon)}
                  <CustomInput
                    type="file"
                    name="file"
                    onChange={(e: any) => handleOnIconsUpload(e, index)}
                    InputProps={{
                      accept: strings.acceptedFiles,
                      dataMaxSize: 3000000,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                  {getInputHeader("Icon Preview", true, card.icon)}
                  {card.icon && (
                    <img
                      src={imageRenderer(props.templateId, card.icon)}
                      width={50}
                      height={50}
                      alt={card.altText}
                      style={{
                        marginRight: "10px",
                        backgroundColor: "rgba(255, 255, 255, 0.4)",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  {getInputHeader("Alternative Text", true, card.altText ?? "")}
                  <CustomInput
                    placeHolder="Text"
                    type="text"
                    name="altText"
                    id="altText"
                    value={card.altText}
                    onChange={(event: any) => handleCardsOnChange(event, index)}
                    onKeyPress={handleKeypress}
                    // error={!isTruthy(card.title) && "Title cannot be empty"}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} xl={12} lg={12}>
                  {getInputHeader("Description", true, card.description)}
                  <CustomInput
                    placeHolder="Description"
                    type="text"
                    name="description"
                    id="description"
                    value={card.description}
                    onChange={(event: any) => handleCardsOnChange(event, index)}
                    onKeyPress={handleKeypress}
                    // error={!isTruthy(card.description) && "Description cannot be empty"}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} xl={12} lg={12}>
                  <Stack direction="row" justifyContent="space-between">
                    {getInputHeader("Read More Link", true, card.readMoreLink.hyperlink)}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={card.readMoreLink.isExternal}
                          onChange={(event) => handleReadMoreLinksChange(event, index)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="External Link"
                    />
                  </Stack>
                  {card?.readMoreLink?.isExternal ? (
                    <CustomInput
                      placeHolder="/link"
                      type="text"
                      name="hyperlink"
                      id="hyperlink"
                      value={card.readMoreLink.hyperlink}
                      onChange={(event: any) => handleReadMoreLinksOnChange(event, index)}
                      onKeyPress={handleKeypress}
                      // error={!isTruthy(card.readMoreLink) && "Add text to button"}
                    />
                  ) : (
                    getReadMoreRoutesDropDown(card.readMoreLink.hyperlink, index)
                  )}
                </Grid>
              </Grid>
            </>
          );
        })}
        {formData.cards.length < 4 && (
          <Box sx={classes.addLinks} onClick={() => addNewCard()}>
            + Add
          </Box>
        )}
      </Grid>
    );
  };
  const getSection3 = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Title", true, formData.about.title)}
          <CustomInput
            placeHolder="Title"
            type="text"
            name="title"
            id="title"
            value={formData.about.title}
            onChange={handleOnChangeOnAboutSection}
            onKeyPress={handleKeypress}
            // error={!isTruthy(formData.about.title) && "Title cannot be empty"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader(
            "Alternative Text For About Us Graphic",
            true,
            formData.about?.altTextForImage,
          )}
          <CustomInput
            placeHolder="Alternative Text"
            type="text"
            name="altTextForImage"
            id="altTextForImage"
            value={formData.about?.altTextForImage}
            onChange={handleOnChangeOnAboutSection}
            onKeyPress={handleKeypress}
            // error={!isTruthy(formData.about.title) && "Title cannot be empty"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {formData.about.description.map((ele, index: number) => {
            return (
              <>
                {getInputHeader(index > 0 ? "" : "Description", true, ele)}
                <CustomInput
                  placeHolder="Description"
                  type="text"
                  name="description"
                  id="description"
                  value={ele}
                  onChange={(event: any) => handleChangeDescriptionInAbout(event, index)}
                  onKeyPress={handleKeypress}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteForeverIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={() => handleDeletePara(ele)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            );
          })}
          <Box sx={classes.addLinks} onClick={() => addNewPara()}>
            + Add
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
          {getInputHeader("Button Label", true, formData.about.button.label)}
          <CustomInput
            placeHolder="Button Text"
            type="text"
            name="label"
            id="label"
            value={formData.about.button.label}
            onChange={handleAboutButtonsChange}
            onKeyPress={handleKeypress}
            // error={!isTruthy(formData.about.button.label) && "Add text to button"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={6} lg={12}>
          <Stack direction="row" justifyContent="space-between">
            <Box sx={classes.label}>
              <Typography sx={classes.labelText}>Button Link</Typography>
              <Typography sx={classes.star}>*</Typography>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.about.button.isExternal}
                  onChange={handleAboutUsButtonLinkChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="External Link"
            />
          </Stack>
          {formData?.about.button.isExternal ? (
            <CustomInput
              placeHolder="/link"
              type="text"
              name="hyperlink"
              id="hyperlink"
              value={formData.about.button.hyperlink}
              onChange={handleAboutButtonsChange}
              onKeyPress={handleKeypress}
              // error={
              //   !isTruthy(formData.about.button.hyperlink) && "Please provide link to the button"
              // }
            />
          ) : (
            getAboutUsLinkDropDown()
          )}
        </Grid>
      </Grid>
    );
  };

  const getPreviewScreen = () => {
    return (
      <Box>
        <PreviewHomeComponent content={formData} templateId={props.templateId} />
      </Box>
    );
  };

  const sections = [
    { section: getSection1(), id: uuidv4(), name: "Banner", isDeletable: false },
    { section: getSection2(), id: uuidv4(), name: "Cards", isDeletable: false },
    { section: getSection3(), id: uuidv4(), name: "About Us", isDeletable: false },
  ];

  return (
    <Box>
      <GlobalLayout section={sections} pageTitle={"Home"} previewElement={getPreviewScreen()} />
      <CustomLoader isLoading={loading} />
    </Box>
  );
};

export default Home;
