import { CSSProperties } from "react";
import { theme } from "../../utils/styles";
import { createMakeStyles } from "tss-react";
import bgImage from "../../assets/images/Admin/bg.png";

const BenchmarkingStyles = {
  stepWrapper: {
    background: "#FFF3F2",
    py: 3,
  } as CSSProperties,
  mobileStepperStyles: {
    position: "absolute",
    width: "110%",
    margin: "0 auto",
    left: "50%",
    background: "transparent",
    top: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: "translateY(-42%) translateX(-50%)",
    },
  },
  bgBox: {
    [theme.breakpoints.up("md")]: {
      backgroundImage: "url(" + bgImage + ")",
      backgroundRepeat: "no-repeat",
    },
  },
  activeScreenWrapper: {
    position: "relative",
    pt: 4,
  },
  activeScreen: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    alignItems: "center",
  },
  stepperController: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": { backgroundColor: theme.palette.primary.main },
    "&:disabled": { border: "1px solid #C1C1C1" },
  },
};

function useTheme() {
  return {
    root: {
      maxWidth: 400,
      flexGrow: 1,
    },
    dot: {
      backgroundColor: "#008000",
    },
    dotActive: {
      backgroundColor: "#3f51b5",
    },
  };
}

export const { useStyles: dotStyles } = createMakeStyles({ useTheme });

export default BenchmarkingStyles;
