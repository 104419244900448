import strings from "../../global/constants/StringConstants";
import { getFormattedNumbers } from "../../helpers/methods";
import { LoginFields } from "../../models/interface";
import { selectAuthenticated, selectLocale } from "../../redux/authSlice";
import { useAppSelector } from "../../utils/hooks";
import { staticContent } from "../../utils/locale";

export const loginForm = () => {
  return {
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  } as LoginFields;
};

export const loginValidation = (loginUserData: any, text: any) => {
  let errors = loginUserData;
  let isValid = true;
  const email = loginUserData.email.value;
  const password = loginUserData.password.value;
  if (!email) {
    errors.email.error = text.general.email;
    isValid = false;
  }
  if (!password) {
    errors.password.error = text.general.password;
    isValid = false;
  }
  return { isValid, errors };
};
