import { loginPageContentData } from "../../../../models/interface";

export const loginInputFields = (prevState?: loginPageContentData) => {
  return {
    welcomeText: prevState?.welcomeText ?? {
      orangeText: "",
      blackText: "",
    },
    subTitle: prevState?.subTitle ?? "",
    description: prevState?.description ?? "",
  } as loginPageContentData;
};
