import { Box } from "@mui/material";
import CustomDialog from "../../../../../global/components/CustomDialog/CustomDialog";
import AppButton from "../../../../../global/components/AppButton/AppButton";
import PreviewModalStyles from "./PreviewModal.styles";

interface customProps {
  setOpenModal: Function;
  openModal: boolean;
  elementToPreview: JSX.Element;
}

const PreviewModal = (props: customProps) => {
  const classes = PreviewModalStyles;

  const handleCloseModel = () => {
    props.setOpenModal(false);
  };

  const dialogTitleContent = () => {
    return (
      <>
        <Box sx={classes.dialogTitleWrapper}>
          <Box sx={classes.blackHeadings}>Preview</Box>
        </Box>
      </>
    );
  };

  const dialogBody = () => <Box sx={classes.description}>{props.elementToPreview}</Box>;

  const dialogContent = () => {
    return (
      <>
        <Box sx={classes.dialogFooter}>
          <AppButton label="Cancel" onClick={() => handleCloseModel()} />
        </Box>
      </>
    );
  };

  return (
    <CustomDialog
      isDialogOpen={props.openModal}
      closable
      closeButtonVisibility
      handleDialogClose={handleCloseModel}
      dialogTitleContent={dialogTitleContent()}
      dialogBodyContent={dialogBody()}
      dialogFooterContent={dialogContent()}
      fullScreen
    />
  );
};

export default PreviewModal;
