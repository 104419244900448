import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalLayout from "../../Pages/Global/GlobalLayout";
import { v4 as uuidv4 } from "uuid";
import { loginInputFields } from "./LoginValidationAndTypes";
import CustomInput from "../../../../global/components/CustomInput/CustomInput";
import { isTruthy } from "../../../../helpers/methods";
import LoginStyles from "./Login.styles";
import LoginPreview from "./LoginPreview";

interface CustomProps {
  content: any;
  updatePageContent: Function;
  templateId: string;
}

const Login = (props: CustomProps) => {
  const classes = LoginStyles;
  const [formData, setFormData] = useState(loginInputFields(props.content));

  useEffect(() => {
    props.updatePageContent(formData);
  }, [formData]);

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      welcomeText: {
        ...formData.welcomeText,
        [event.target.id]: event.target.value,
      },
    });
  };

  const handleOnChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const getInputHeader = (title: string, required: boolean, value: any) => {
    return (
      <Box
        sx={classes.label}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        borderColor="red"
      >
        <Box style={{ display: "flex" }}>
          {isTruthy(title) && (
            <>
              <Typography sx={classes.labelText}>{title}</Typography>
              {required && <Typography sx={classes.star}>*</Typography>}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getSection = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Title", true, formData.welcomeText.orangeText)}
          <CustomInput
            placeHolder="Orange Text in Title"
            type="text"
            name="title"
            id="orangeText"
            value={formData.welcomeText.orangeText}
            onChange={handleOnChange}
            onKeyPress={handleKeypress}
            // error={!isTruthy(formData.banner.title) && "Title cannot be empty"}
          />
          <CustomInput
            placeHolder="Black Text in Title"
            type="text"
            name="title"
            id="blackText"
            value={formData.welcomeText.blackText}
            onChange={handleOnChange}
            onKeyPress={handleKeypress}
            // error={!isTruthy(formData.banner.title) && "Title cannot be empty"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Sub Title", true, formData.subTitle)}
          <CustomInput
            placeHolder="Subtitle"
            type="text"
            name="subTitle"
            id="subTitle"
            value={formData.subTitle}
            onChange={handleOnChangeText}
            onKeyPress={handleKeypress}
            // error={!isTruthy(formData.banner.title) && "Title cannot be empty"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Description", true, formData.description)}
          <CustomInput
            placeHolder="Description"
            type="text"
            name="description"
            id="description"
            value={formData.description}
            onChange={handleOnChangeText}
            onKeyPress={handleKeypress}
            // error={!isTruthy(formData.banner.title) && "Title cannot be empty"}
          />
        </Grid>
      </Grid>
    );
  };
  const sections = [
    { section: getSection(), id: uuidv4(), name: "Page Content", isDeletable: false },
  ];

  const getPreviewScreen = () => {
    return (
      <Box width={"100%"}>
        <LoginPreview content={formData} templateId={props.templateId} />
      </Box>
    );
  };

  return (
    <Box>
      <GlobalLayout section={sections} pageTitle={"Login"} previewElement={getPreviewScreen()} />
    </Box>
  );
};

export default Login;
