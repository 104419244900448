import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../utils/store";
import strings from "../global/constants/StringConstants";

export const RESET_STORE = "RESET_STORE";

export interface AuthState {
  authenticated: boolean;
  accessToken: string;
  userId: string;
  loading: boolean;
  role: string;
  locale: string;
  coopName: string;
  city: string;
  province: string;
}

const initialState: AuthState = {
  authenticated: false,
  accessToken: "",
  userId: "",
  loading: false,
  role: "",
  locale: strings.EN,
  coopName: "",
  city: "",
  province: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (state, action) => {
      state.authenticated = action.payload.authenticated;
      state.accessToken = action.payload.accessToken;
      state.userId = action.payload.userId;
      state.role = action.payload.role;
      state.coopName = action.payload.coopName;
      state.city = action.payload.city;
      state.province = action.payload.province;
    },
    // updateTokens: (
    //   state,
    //   action: PayloadAction<{
    //     userId: string;
    //     refreshToken: string;
    //   }>,
    // ) => {
    //   state.userId = action.payload.userId;
    //   state.refreshToken = action.payload.refreshToken;
    // },
    addLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    logoutAction: (state) => {
      state.authenticated = false;
      state.accessToken = "";
      state.userId = "";
      state.role = "";
      state.coopName = "";
      state.province = "Ontario";
      state.city = "Ottawa";
    },
  },
});

export const { loginAction, addLoading, updateLocale, logoutAction } = authSlice.actions;

export const selectAuthenticated = (state: RootState) => state.auth.authenticated;
export const currentUserRole = (state: RootState) => state.auth.role;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectUserId = (state: RootState) => state.auth.userId;
export const selectRole = (state: RootState) => state.auth.role;
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectLocale = (state: RootState) => state.auth.locale;
export const selectedCoopName = (state: RootState) => state.auth.coopName;
export const selectCity = (state: RootState) => state.auth.city;
export const selectedProvince = (state: RootState) => state.auth.province;
export default authSlice.reducer;
