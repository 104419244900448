import { Box, Grid, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { theme } from "../../../../utils/styles";
import GlobalLayoutStyles from "./GlobalLayout.styles";
import { SectionMetaDataType } from "../../../../models/interface";
import { DeleteForeverOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import AppButton from "../../../../global/components/AppButton/AppButton";
import PreviewModal from "./PreviewModal/PreviewModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DraggableList from "../../../../global/components/Draggable/Draggable";
import { isTruthy, reorder } from "../../../../helpers/methods";
import { DropResult } from "react-beautiful-dnd";
import { cloneDeep } from "lodash";

interface CustomProps {
  pageTitle: string;
  section: any;
  previewElement: JSX.Element;
  handleDelete?: Function;
  customComponent?: JSX.Element;
  handleReOrder?: Function;
  handleEditSection?: Function;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const getDraggableItems = (items: any) => {
  return items.reduce((acc: any, sec: any) => {
    if (sec.isDeletable) {
      acc.push({
        id: sec.id,
        text: sec.name,
      });
    }
    return acc;
  }, []);
};

const GlobalLayout = (props: CustomProps) => {
  const classes = GlobalLayoutStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [draggable, setDraggable] = useState<boolean>(false);
  const [items, setItems] = React.useState(props.section);
  const [reOrdered, setReOrdered] = React.useState<{ id: string; text: string }[]>([]);
  const reOrderedSectionsExists = isTruthy(getDraggableItems(items));

  useEffect(() => {
    setItems(props.section);
  }, [props.section]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems: { id: string; text: string }[] = reorder(
      reOrdered,
      source.index,
      destination.index,
    );
    setReOrdered(newItems);
  };

  const getAccordion = () => {
    return (
      <Box>
        {draggable ? (
          <DraggableList items={reOrdered} onDragEnd={onDragEnd} />
        ) : (
          items.map((sec: any, index: number) => {
            return (
              <Accordion
                expanded={expanded === "panel" + index}
                onChange={handleChange("panel" + index)}
              >
                <AccordionSummary aria-controls="panel-content" id="panel-header">
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <Typography>{sec.name}</Typography>
                    {sec.isDeletable &&
                      (sec.isEditable ? (
                        <Box display="flex">
                          <Box
                            onClick={() =>
                              props.handleEditSection?.({
                                id: sec.id,
                                name: sec.name,
                              })
                            }
                          >
                            <EditIcon />
                          </Box>
                          <Box onClick={() => props.handleDelete?.(sec.id, sec.custom, sec.key)}>
                            <DeleteForeverOutlined />
                          </Box>
                        </Box>
                      ) : (
                        <Box display="flex">
                          <Box onClick={() => props.handleDelete?.(sec.id, sec.custom, sec.key)}>
                            <DeleteForeverOutlined />
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>{sec.section}</AccordionDetails>
              </Accordion>
            );
          })
        )}
      </Box>
    );
  };

  const getPreview = () => {
    return (
      <Box sx={classes.header}>
        <Box
          sx={classes.screen}
          maxHeight={isDesktop ? "800px" : "500px"}
          height={isDesktop ? "auto" : "250px"}
          display="flex"
        >
          {props.previewElement}
        </Box>
      </Box>
    );
  };

  const getCustomDialog = () => {
    return (
      <PreviewModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        elementToPreview={props.previewElement}
      />
    );
  };

  const getInfoTooltip = () => {
    return (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: theme.palette.common.white,
              color: "rgba(0, 0, 0, 0.87)",
              boxShadow: "0px 10px 50px 0px #0000000D",
              p: 2,
              cursor: "pointer",
            },
          },
        }}
        title={
          <>
            <Typography variant="body1" gutterBottom sx={classes.tooltipDesc}>
              To make the text bold, italics, or add a hyperlink, wrap the text in the format.
            </Typography>
            <Typography sx={classes.tooltipDesc}>
              Text: make it **bold** <br />
              Result: make it <b>bold</b>
            </Typography>
            <Typography sx={classes.tooltipDesc}>
              Text: make it __italic__ <br /> Result: make it <i>italic</i>
            </Typography>
            <Typography sx={classes.tooltipDesc}>
              Text: Add a link [Homerun](baseurl)
              <br /> Result: Add a link{" "}
              <a href="http://homerun.coop" style={{ textDecoration: "underline" }}>
                Homerun
              </a>
            </Typography>
          </>
        }
        placement="bottom"
      >
        <Box sx={{ cursor: "pointer" }}>
          <InfoOutlinedIcon fontSize="small" />
        </Box>
      </Tooltip>
    );
  };

  const handleReOrderSave = () => {
    const disabledReOrderedSections = items.filter((section: any) => !section.isDeletable);
    const local = cloneDeep(items);
    reOrdered.forEach((section: any) => {
      const indexToPush = local.findIndex((sec: any) => sec.id === section.id);
      if (indexToPush > -1) {
        disabledReOrderedSections.push(items[indexToPush]);
      }
    });
    setItems(disabledReOrderedSections);
    props.handleReOrder!(reOrdered);
    setDraggable(false);
  };

  const getButtons = () => {
    return (
      <Box>
        {draggable ? (
          <Stack direction="row" spacing={1}>
            <AppButton label="Save" onClick={() => handleReOrderSave()} />
            <AppButton label="Cancel" onClick={() => setDraggable(false)} />
          </Stack>
        ) : (
          reOrderedSectionsExists && (
            <AppButton
              label="Sort"
              onClick={() => {
                setReOrdered(getDraggableItems(items));
                setDraggable(true);
              }}
            />
          )
        )}
      </Box>
    );
  };

  const getStructure = () => {
    return (
      <Box m={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="h6">{props.pageTitle}</Typography>
              <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                {getButtons()}
                {getInfoTooltip()}
              </Stack>
            </Stack>

            {getAccordion()}
            {!draggable && <Box sx={{ mt: 2 }}>{props.customComponent}</Box>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
            <Box display={"flex"} alignItems="center" justifyContent="flex-end" mb={1}>
              <Typography>Not sure how the page will look?</Typography>
              <Typography onClick={() => setOpenModal(true)} sx={classes.addLinks}>
                View in fullscreen
              </Typography>
            </Box>

            {getPreview()}
          </Grid>
        </Grid>
        {getCustomDialog()}
      </Box>
    );
  };
  return getStructure();
};

export default GlobalLayout;
