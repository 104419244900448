import urls from "../../global/constants/UrlConstants";
import { getNoAuthCallParams, makeCall } from "../../utils/service";

export const contact = async (body: any, locale: string) => {
  try {
    const url = `${urls.contactApiCallEndpoint}/${locale}`;
    const callParams = getNoAuthCallParams("POST", body);
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
