import { BenchmarkingContent } from "../../../../models/interface";

export const benchmarkInputFields = (prevState?: BenchmarkingContent) => {
  return {
    content: prevState?.content ?? {
      beforeLogin: [
        {
          title: "",
          desc: [""],
          image: "",
          imageAlt: "",
        },
      ],
      afterLogin: [
        {
          title: "",
          desc: [""],
          image: "",
          imageAlt: "",
        },
      ],
      descriptionIssame: true,
    },
  };
};
