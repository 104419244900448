import { Box, Stack } from "@mui/system";
import GlobalLayout from "../Global/GlobalLayout";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { InputFields } from "./MeasureTypesAndValidations";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../../helpers/methods";
import notifiers from "../../../../global/constants/NotificationConstants";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import MeasuresStyles from "./MeasureEditForm.styles";
import CustomInput from "../../../../global/components/CustomInput/CustomInput";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { getAllRoutes, getMeasure, saveMeasureByLocale } from "../../AdminService";
import { useLocation } from "react-router";
import CustomLoader from "../../../../global/components/CustomLoader/CustomLoader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Measures from "../../../MeasuresInternalPage/Measures";
import AppButton from "../../../../global/components/AppButton/AppButton";
import MeasuresPreview from "./MeasurePreview";
import _ from "lodash";
import ConfirmSaveModal from "./ConfirmSaveModal";
import history from "../../../../utils/history";

const MeasuresEditForm = () => {
  const classes = MeasuresStyles;
  const [formData, setFormData] = useState(InputFields());
  const [measureData, setMeasureData] = useState(InputFields());
  const [locale, setLocale] = useState<string>("en");
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmSave, setConfirmSave] = useState<{ visible: boolean; redirect: string }>({
    visible: false,
    redirect: "en",
  });
  const [practicesRoutes, setPracticesRoute] = useState<string[]>([]);
  const urlParams = useLocation().search;
  const measureId = new URLSearchParams(urlParams).get("id");

  useEffect(() => {
    getMeasureDetails();
    getListOfRoutes();
  }, [locale]);

  const getMeasureDetails = async () => {
    try {
      const details = await getMeasure(measureId!, locale.toUpperCase());
      const parsedData = JSON.parse(details?.content ?? "");
      const parsed = {
        ...details,
        content: {
          graphTitle: parsedData.graphTitle ?? "",
          graphDescription: parsedData.graphDescription ?? [{ title: "", description: [""] }],
          notApplicableDescription: parsedData.notApplicableDescription ?? [""],
        },
      };
      setFormData(parsed);
      setMeasureData({ ...parsed });
      setLoading(true);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const saveMeasure = async () => {
    try {
      await saveMeasureByLocale(
        {
          id: measureId,
          rowId: formData.rowId,
          name: formData.name,
          category: formData.category,
          content: JSON.stringify(formData.content),
          goodPractices: formData.goodPractices,
        },
        locale.toUpperCase(),
      );
      setLoading(true);
      openSuccessNotification("Measure data has been updated successfully.");
      setConfirmSave({ ...confirmSave, visible: false });
      if (locale === confirmSave.redirect) {
        await getMeasureDetails();
      } else {
        setLocale(confirmSave.redirect);
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const getListOfRoutes = async () => {
    try {
      setLoading(true);
      const routes = await getAllRoutes(locale);
      setPracticesRoute(routes);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeypress = (e: { key: string; preventDefault: () => void }) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (formModified()) {
      return setConfirmSave({
        visible: true,
        redirect: newValue,
      });
    }
    setLocale(newValue);
  };

  const formModified = () => {
    const currentReadGoodPractices = _.cloneDeep(formData.goodPractices);
    const currentMeasureInformation = _.cloneDeep(formData.content);
    return !(
      _.isEqual(currentMeasureInformation, measureData.content) &&
      _.isEqual(currentReadGoodPractices, measureData.goodPractices)
    );
  };

  const addNewRelatedPractices = () => {
    const newSection = formData.goodPractices;
    newSection.push({
      link: "",
      label: "",
      isExternal: false,
    });
    setFormData({
      ...formData,
      goodPractices: newSection,
    });
  };

  const addGraphTitle = (event: any) => {
    setFormData({
      ...formData,
      content: {
        ...formData.content,
        graphTitle: event.target.value,
      },
    });
  };

  const addNew = (index: number) => {
    const localDescriptionState = formData.content;
    localDescriptionState.graphDescription.push({ title: "", description: [""] });
    setFormData({
      ...formData,
      content: localDescriptionState,
    });
  };

  const addNewDescription = (index: number) => {
    const localDescription = formData.content;
    localDescription.graphDescription[index].description.push("");
    setFormData({
      ...formData,
      content: localDescription,
    });
  };

  const addNewNADescription = () => {
    const localDescription = formData.content;
    localDescription.notApplicableDescription.push("");
    setFormData({
      ...formData,
      content: localDescription,
    });
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<any>,
    index: number,
    nestedIndex: number,
  ) => {
    const localDescriptionState = formData.content;
    localDescriptionState.graphDescription[index].description[nestedIndex] = event.target.value;
    setFormData({
      ...formData,
      content: localDescriptionState,
    });
  };

  const handleChangeTitle = (event: React.ChangeEvent<any>, index: number) => {
    const localDescriptionState = formData.content;
    localDescriptionState.graphDescription[index].title = event.target.value;
    setFormData({
      ...formData,
      content: localDescriptionState,
    });
  };

  const handleNADescription = (event: React.ChangeEvent<any>, index: number) => {
    const localDescriptionState = formData.content;
    localDescriptionState.notApplicableDescription[index] = event.target.value;
    setFormData({
      ...formData,
      content: localDescriptionState,
    });
  };

  const handleTitleChangeForRelatedGoodPractices = (
    event: React.ChangeEvent<any>,
    index: number,
  ) => {
    const updated = formData.goodPractices;
    updated[index].label = event.target.value;
    setFormData({
      ...formData,
      goodPractices: updated,
    });
  };

  const deletePractice = (index: number) => {
    try {
      const local = formData.goodPractices;
      const updated = local.filter((v, i) => i !== index);
      setFormData({
        ...formData,
        goodPractices: updated,
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const handleReadMoreLinksChangeForPractices = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updated: any = formData.goodPractices;
    updated[index].link = "";
    updated[index].isExternal = event.target.checked;
    setFormData({
      ...formData,
      goodPractices: updated,
    });
  };

  const handleLinkSelectionForPractices = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updated: any = formData.goodPractices;
    updated[index].link = event.target.value;
    setFormData({
      ...formData,
      goodPractices: updated,
    });
  };

  const handleDelete = async (index: number) => {
    try {
      const local = formData.content;
      const updated = local.graphDescription.filter((v, i) => i !== index);
      setFormData({
        ...formData,
        content: {
          ...formData.content,
          graphDescription: updated,
        },
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const handleDeleteDescription = async (index: number, nestedIndex: number) => {
    try {
      const local = formData.content;
      let updated = local.graphDescription[index].description.filter((v, i) => i !== nestedIndex);
      local.graphDescription[index].description = updated;
      setFormData({
        ...formData,
        content: local,
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const handleDeleteNADescription = async (nestedIndex: number) => {
    try {
      const local = formData.content;
      let updated = local.notApplicableDescription.filter((v, i) => i !== nestedIndex);
      setFormData({
        ...formData,
        content: {
          ...formData.content,
          notApplicableDescription: updated,
        },
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const getInputHeader = (title: string, required: boolean, value: any) => {
    return (
      <Box
        sx={classes.label}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        borderColor="red"
      >
        <Box style={{ display: "flex" }}>
          {isTruthy(title) && (
            <>
              <Typography sx={classes.labelText}>{title}</Typography>
              {required && <Typography sx={classes.star}>*</Typography>}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getInformation = () => {
    return (
      <Grid container spacing={1} display="flex" flexDirection="column">
        {getInputHeader("Graph Title", true, "")}
        <CustomInput
          placeHolder="Graph Title"
          type="text"
          name="graphTitle"
          id="graphTitle"
          value={formData.content.graphTitle}
          onChange={(event: any) => addGraphTitle(event)}
          onKeyPress={handleKeypress}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {formData &&
            formData.content?.graphDescription?.map((desc, index) => {
              return (
                <>
                  {index > 0 && (
                    <Box
                      onClick={() => handleDelete(index)}
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        cursor: "pointer",
                        width: "100%",
                      }}
                    >
                      <DeleteOutlineIcon />
                    </Box>
                  )}
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    {getInputHeader("Title", true, "")}
                    <CustomInput
                      placeHolder="Title"
                      type="text"
                      name="title"
                      id="title"
                      value={desc.title}
                      onChange={(event: any) => handleChangeTitle(event, index)}
                      onKeyPress={handleKeypress}
                    />
                  </Grid>
                  {getInputHeader("Description", true, "")}
                  {desc.description.map((text, nestedIndex) => {
                    return (
                      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <CustomInput
                          placeHolder="Description"
                          type="text"
                          name="description"
                          id="description"
                          value={text}
                          onChange={(event: any) =>
                            handleChangeDescription(event, index, nestedIndex)
                          }
                          onKeyPress={handleKeypress}
                          InputProps={{
                            endAdornment: nestedIndex > 0 && (
                              <InputAdornment position="end">
                                <DeleteForeverIcon
                                  sx={{ cursor: "pointer", marginLeft: "8px" }}
                                  onClick={() => handleDeleteDescription(index, nestedIndex)}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    );
                  })}
                  <Box sx={classes.addLinks} onClick={() => addNewDescription(index)}>
                    + Add
                  </Box>
                  <Box sx={classes.addLinks} onClick={() => addNew(index)}>
                    + Add New Section
                  </Box>
                </>
              );
            })}
        </Box>
        {getInputHeader("Not Applicable Description", true, "")}
        {formData &&
          formData?.content.notApplicableDescription?.map((text, nestedIndex) => {
            return (
              <CustomInput
                placeHolder="Not Applicable Description"
                type="text"
                name="notApplicableDescription"
                id="notApplicableDescription"
                value={text}
                onChange={(event: any) => handleNADescription(event, nestedIndex)}
                onKeyPress={handleKeypress}
                InputProps={{
                  endAdornment: nestedIndex > 0 && (
                    <InputAdornment position="end">
                      <DeleteForeverIcon
                        sx={{ cursor: "pointer", marginLeft: "8px" }}
                        onClick={() => handleDeleteNADescription(nestedIndex)}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            );
          })}
        <Box sx={classes.addLinks} onClick={() => addNewNADescription()}>
          + Add
        </Box>
      </Grid>
    );
  };

  const getInternalLinksDropdownForPractices = (value: string, index: number) => {
    return (
      <Select
        value={value}
        name="hyperlink"
        id="hyperlink"
        onChange={(event: any) => handleLinkSelectionForPractices(event, index)}
        sx={classes.dropDown}
      >
        {practicesRoutes.map((item) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    );
  };

  const getRelatedPractices = () => {
    return (
      <Grid container spacing={1}>
        {formData.goodPractices?.map((practice, index) => {
          return (
            <>
              {index > 0 && (
                <Box
                  onClick={() => deletePractice(index)}
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DeleteOutlineIcon />
                </Box>
              )}
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                {getInputHeader("Label", true, practice.label)}
                <CustomInput
                  placeHolder="Label"
                  type="text"
                  name="label"
                  id="label"
                  value={practice.label}
                  onChange={(event: any) => handleTitleChangeForRelatedGoodPractices(event, index)}
                  onKeyPress={handleKeypress}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                sx={{ borderBottom: "1px solid #E7E7F9", padding: "10px 0" }}
              >
                <Stack direction="row" justifyContent="space-between">
                  {getInputHeader("Practices Link", true, practice.link)}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={practice.isExternal}
                        onChange={(event) => handleReadMoreLinksChangeForPractices(event, index)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="External Link"
                  />
                </Stack>
                {practice.isExternal ? (
                  <CustomInput
                    placeHolder="/link"
                    type="text"
                    name="hyperlink"
                    id="hyperlink"
                    value={practice.link}
                    onChange={(event: any) => handleLinkSelectionForPractices(event, index)}
                    onKeyPress={handleKeypress}
                    // error={!isTruthy(card.readMoreLink) && "Add text to button"}
                  />
                ) : (
                  getInternalLinksDropdownForPractices(practice.link, index)
                )}
              </Grid>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewRelatedPractices()}>
          + Add
        </Box>
      </Grid>
    );
  };

  const sectionData = [
    {
      section: getInformation(),
      id: uuidv4(),
      name: "Measures Information",
      isDeletable: false,
    },
    {
      section: getRelatedPractices(),
      id: uuidv4(),
      name: "Related Good Practices",
      isDeletable: false,
    },
  ];

  const getPreviewScreen = () => {
    return (
      <Box>
        <MeasuresPreview content={formData} />
      </Box>
    );
  };

  return (
    <Box>
      <Box sx={{ ml: 4, mt: 4 }}>
        <Tabs
          value={locale}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="en" label="English" />
          <Tab value="fr" label="French" />
        </Tabs>
      </Box>
      <GlobalLayout
        section={sectionData}
        pageTitle={"About Measures"}
        previewElement={getPreviewScreen()}
      />
      <Stack direction="row" spacing={2} m={4}>
        <AppButton label="Back" onClick={() => history.goBack()} />
        <AppButton label={"Save"} onClick={() => saveMeasure()} />
      </Stack>
      <CustomLoader isLoading={loading} />
      <ConfirmSaveModal
        isDialogOpen={confirmSave.visible}
        handleDialogClose={() => {
          setLocale(confirmSave.redirect);
          setConfirmSave({ ...confirmSave, visible: false });
        }}
        save={saveMeasure}
      />
    </Box>
  );
};

export default MeasuresEditForm;
