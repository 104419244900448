import { Button } from "@mui/material";
import React, { CSSProperties } from "react";

interface ButtonInputs {
  label: string | JSX.Element | undefined;
  variant?: "contained" | "text" | "outlined";
  color?:
    | "primary"
    | "inherit"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  styles?: any;
  withRadius?: boolean;
  type?: "button" | "submit" | undefined;
  onClick?: Function;
}

const AppButton = (props: ButtonInputs) => {
  const { variant = "contained", color = "primary", type = "button" } = props;
  const curvestyles: any = props.withRadius
    ? {
        borderRadius: "100px",
        "&:hover": {
          backgroundColor: "#423F6F",
          color: "#fff",
        },
      }
    : undefined;

  return (
    <Button
      variant={variant}
      color={color}
      sx={{ px: 4, py: 0.5, ...props.styles, ...curvestyles }}
      type={type}
      onClick={(event: any) => props.onClick?.(event)}
    >
      {props.label}
    </Button>
  );
};

export default AppButton;
