import { isTruthy } from "../../../helpers/methods";

export const initAddEditPeerGroup = (editState: any) => {
  if (!isTruthy(editState)) {
    return {
      selections: {
        units: new Set(["0", "36", "81"]),
        type: new Set(["WU", "El", "TH", "Single"]),
        mgmt: new Set(["MgmtCo", "Staff", "Bookkeeper", "Volunteers", "Other"]),
      },
      locations: {
        locationcity: new Set(),
        locationprovince: new Set(),
      },
      selectedLocation: "locationprovince",
      name: "",
    };
  }

  const { selectedLocation, selections, locations, name } = editState;

  return {
    selections,
    locations,
    selectedLocation,
    name,
  };
};
