import {
  descriptionFont,
  getRelativeFontSize,
  mediumFontTitle,
  regularFont,
  theme,
} from "../../../../utils/styles";

const GlobalLayoutStyles = {
  blackHeadings: {
    ...mediumFontTitle,
    fontSize: getRelativeFontSize(20),
    fontweight: "bold",
  },
  screen: {
    overflow: "auto",
    scrollbarWidth: "thin",
    "::-webkit-scrollbar": {
      display: "none",
    },
    border: "1px solid #f7f7f8",
  },
  addLinks: {
    ml: 1,
    display: "inline-block",
    ...theme.typography.h6,
    color: theme.palette.secondary.dark,
    fontSize: getRelativeFontSize(4),
    cursor: "pointer",
  },
  description: {
    ...descriptionFont,
    color: "#3E3E3F",
    fontSize: getRelativeFontSize(4),
    mb: 2,
  },
  header: {
    boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.05)",
    borderRadius: "20px",
  },
  pageBox: {
    padding: 2,
    border: "1px solid #F2F2F2",
    boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.05)",
  },
  tooltipDesc: {
    ...theme.typography.body1,
    fontSize: getRelativeFontSize(),
  },
};

export default GlobalLayoutStyles;
