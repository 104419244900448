import { Box, Button, Typography, useMediaQuery, Grid, Stack, Divider } from "@mui/material";
import { Container } from "@mui/system";
import { centerItemFlex, theme } from "../../../utils/styles";
import leftbg from "../../../assets/images/GoodPractices/orangebg.png";
import rightbg from "../../../assets/images/GoodPractices/greenbg.png";
import customSectionStyles from "./CustomSection.styles";
import { PracticesData } from "../../../models/interface";
import youtube from "../../../assets/icons/youtube.svg";
import { imageRenderer } from "../../../utils/service";
import strings from "../../constants/StringConstants";
import { useState } from "react";
import CustomDialog from "../CustomDialog/CustomDialog";
import AppButton from "../AppButton/AppButton";
import { isTruthy } from "../../../helpers/methods";
import history from "../../../utils/history";
import { selectLocale, selectAuthenticated } from "../../../redux/authSlice";
import { useAppSelector } from "../../../utils/hooks";
import { staticContent } from "../../../utils/locale";

interface CustomSectionProps {
  data: PracticesData[];
  templateId: string;
}

export const CustomSection = (props: CustomSectionProps) => {
  const classes = customSectionStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [openModal, setOpenModal] = useState<{ isOpen: boolean; id: string }>({
    isOpen: false,
    id: "",
  });
  const locale = useAppSelector(selectLocale);
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const contentText = staticContent(locale, isAuthenticated);

  const handleCloseModel = () => {
    setOpenModal({ isOpen: false, id: "" });
  };

  const getBgImage = (index: number) => {
    if (index % 2 === 0) {
      return leftbg;
    }
    return rightbg;
  };

  const getYoutubeThumbnail = (link: any) => {
    if (isTruthy(link)) {
      const id = link[1];
      const thumbnail = `https://img.youtube.com/vi/coop.agency:homerun-service:jar:1.0/maxresdefault.jpg`;
      return { thumbnail, videoId: id };
    }
    return { thumbnail: "", videoId: "" };
  };

  const dialogBody = () => (
    <Box>
      <iframe
        width="100%"
        style={{ maxHeight: "900px" }}
        height={isDesktop ? "750px" : "auto"}
        src={`https://www.youtube.com/embed/${openModal.id}`}
        title="YouTube video player"
        allowFullScreen
      ></iframe>
    </Box>
  );

  const getCustomDialog = () => {
    return (
      <CustomDialog
        isDialogOpen={openModal.isOpen}
        closable
        closeButtonVisibility
        handleDialogClose={handleCloseModel}
        dialogHeaderContent={<></>}
        dialogBodyContent={dialogBody()}
        height="80%"
        width="70%"
      />
    );
  };

  return (
    <>
      {props.data.map((practice, index: number) => {
        return (
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection={index % 2 == 0 ? "row" : "row-reverse"}
            sx={{ mb: 3 }}
            id={practice.title.toLowerCase().replaceAll(" ", "-")}
          >
            <Grid item xs={12} lg={4} md={12} sm={12}>
              <Box
                sx={{
                  backgroundImage: "url(" + getBgImage(index) + ")",
                  padding: 2,
                  mb: 4,
                  alignItems: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  display: "flex",
                  justifyContent: "center",
                  [theme.breakpoints.down("lg")]: {
                    ...centerItemFlex,
                  },
                }}
              >
                <Box sx={classes.blueColor}>
                  <Stack direction="column" justifyContent="center" alignItems="center">
                    <Box sx={classes.iconBox}>
                      <img
                        src={imageRenderer(props.templateId, practice.practiceIcon)}
                        style={{
                          width: isDesktop ? "auto" : "100%",
                          height: isDesktop ? "80px" : "100%",
                        }}
                        alt={practice.practiceIconAltText}
                      />
                    </Box>
                    <Typography sx={classes.whiteHeading}>{practice.title}</Typography>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={8} md={12} sm={12} mt={3}>
              <Container
                maxWidth="md"
                sx={{
                  [theme.breakpoints.down("lg")]: {
                    marginRight: "auto !important",
                  },
                }}
              >
                {index === 0 && isDesktop && (
                  <Typography sx={classes.orangeHeadingText}>
                    {contentText.goodPractices.focusAreaText}
                  </Typography>
                )}
                {practice.practices.map((item) => {
                  let thumbnail = "";
                  let videoId = "";
                  if (item.isExternal) {
                    const response = getYoutubeThumbnail(
                      item?.redirectUrl?.match(strings.youtubeVideoIdRegex),
                    );
                    thumbnail = response.thumbnail;
                    videoId = response.videoId;
                  }
                  return (
                    <Box mt={{ xs: 6, sm: 6, md: 6, lg: 0, xl: 0 }} mb={2}>
                      <Stack
                        direction={isDesktop ? "row" : "column"}
                        sx={{ my: 2 }}
                        alignItems="center"
                        spacing={2}
                        justifyContent={isDesktop ? "flex-start" : "center"}
                      >
                        <img
                          src={imageRenderer(props.templateId, item.icon)}
                          alt={item.altText}
                          style={{
                            width: isDesktop ? "auto" : "100px",
                            height: isDesktop ? "80px" : "100px",
                          }}
                        />
                        {item.isExternal && isTruthy(videoId) ? (
                          <Box
                            sx={{
                              cursor: "pointer",
                              position: "relative",
                              display: "inline-block",
                            }}
                            onClick={() => setOpenModal({ isOpen: true, id: videoId })}
                          >
                            <Stack
                              direction={isDesktop ? "row" : "column"}
                              alignItems="center"
                              spacing={2}
                              justifyContent={isDesktop ? "flex-start" : "center"}
                            >
                              <Typography variant="h2" sx={classes.whyUsText}>
                                {item.title}
                              </Typography>

                              <Box
                                sx={{
                                  cursor: "pointer",
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <img
                                  style={{
                                    marginLeft: "16px",
                                    height: "100px",
                                    width: "auto",
                                    borderRadius: "10px",
                                    background: "rgba(0, 0, 0, 0.5)",
                                  }}
                                  alt="Youtube ThumbNail"
                                  src={thumbnail}
                                />
                                <img
                                  src={youtube}
                                  style={{
                                    position: "absolute",
                                    top: "50%", // Adjust this value to vertically center the icon
                                    left: "50%", // Adjust this value to horizontally center the icon
                                    transform: "translate(-50%, -50%)", // Center the icon precisely
                                    width: "40px", // Adjust the size of the icon
                                    height: "auto",
                                  }}
                                  alt="YouTube Icon"
                                />
                              </Box>
                            </Stack>
                          </Box>
                        ) : isTruthy(item.redirectUrl) ? (
                          <Typography
                            variant="h2"
                            sx={classes.whyUsText}
                            onClick={() => history.push(item.redirectUrl)}
                          >
                            {item.title}
                          </Typography>
                        ) : (
                          <Typography variant="h2" sx={classes.whyUsTextWithoutHover}>
                            {item.title}
                          </Typography>
                        )}
                      </Stack>
                      <Divider />
                    </Box>
                  );
                })}
              </Container>
            </Grid>
          </Grid>
        );
      })}
      {getCustomDialog()}
    </>
  );
};
