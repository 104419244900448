import React, { useEffect, useState } from "react";
import { Box, Container, Tooltip, Typography, useMediaQuery } from "@mui/material";
import LoginStyles from "./Login.styles";
import sideImage from "../../assets/images/login-view-building.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppButton from "../../global/components/AppButton/AppButton";
import { descriptionFont, theme } from "../../utils/styles";
import AppTextField from "../../global/components/AppTextField/AppTextField";
import HomeRunLogo from "../../assets/images/logos/logo-light-en.svg";
import HomeRunLogoFr from "../../assets/images/logos/logo-light-fr.svg";
import { LoginFields } from "../../models/interface";
import { loginForm, loginValidation } from "./LoginTypesAndValidation";
import {
  getBodyHeight,
  isTruthy,
  markdownText,
  openErrorNotification,
} from "../../helpers/methods";
import { fetchPageContent, login } from "./LoginPageService";
import { loginAction, selectAuthenticated, selectLocale } from "../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import urls from "../../global/constants/UrlConstants";
import notifiers from "../../global/constants/NotificationConstants";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import strings from "../../global/constants/StringConstants";
import { loginInputFields } from "../Admin/templates/Login/LoginValidationAndTypes";
import { useTitle } from "../../utils/useTitle";
import AppFooter from "../Shared/AppFooter/AppFooter";
import parse from "html-react-parser";
import history from "../../utils/history";
import { staticContent } from "../../utils/locale";

const classes = LoginStyles;

interface CustomProps {
  adminLogin: boolean;
}

const Login = (props: CustomProps) => {
  useTitle("Login");
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [formFields, setFormFields] = useState<LoginFields>(loginForm());
  const [content, setContent] = useState(loginInputFields());
  const [isLoading, setIsLoading] = useState(false);
  const locale = useAppSelector(selectLocale);
  const authenticated = useAppSelector(selectAuthenticated);
  const text = staticContent(locale, authenticated);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    try {
      const response = await fetchPageContent(locale);
      setContent(JSON.parse(response.data ?? {}));
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    }
  };

  const handleLogin = async () => {
    if (handleValidation()) {
      try {
        setIsLoading(true);
        const email = formFields.email.value.toLowerCase();
        const password = formFields.password.value;
        const user = await login(email, password, props.adminLogin, locale);
        dispatch(
          loginAction({
            email,
            authenticated: true,
            accessToken: user.authToken,
            userName: user.userName,
            role: props.adminLogin ? "cms" : "coop",
            coopName: props.adminLogin ? "" : user.shortName,
            city: props.adminLogin ? "" : user.city,
            province: props.adminLogin ? "" : user.province,
          }),
        );
        history.push(props.adminLogin ? urls.adminPath : urls.homeViewPath);
      } catch (error: any) {
        openErrorNotification(
          isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };
  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };

  const handleValidation = () => {
    const { isValid, errors } = loginValidation(formFields, text);
    setFormFields({ ...errors });
    return isValid;
  };

  const getLoginCard = () => {
    return (
      <Box sx={classes.backgroundBox}>
        <Container maxWidth="xl">
          <Box sx={[classes.wrapper, { height: { lg: `calc(85vh - ${getBodyHeight()}px)` } }]}>
            <Box sx={classes.cardWrapper}>
              <Box sx={classes.loginCard}>
                <Tooltip title={text.general.goBack} onClick={() => history.goBack()}>
                  <ArrowBackIcon sx={{ display: "inline" }} />
                </Tooltip>
                <Box>
                  <Typography sx={classes.title} variant="h5" fontWeight={700} display="inline">
                    <Typography
                      sx={classes.title}
                      variant="h5"
                      fontWeight={700}
                      color="secondary"
                      display="inline"
                    >
                      {content.welcomeText.orangeText}
                    </Typography>{" "}
                    {parse(markdownText(content.welcomeText.blackText))}
                  </Typography>
                  <Typography fontSize={18} fontWeight={500} variant="subtitle1">
                    {content.subTitle}
                  </Typography>
                </Box>
                <Typography sx={classes.subHeading} variant="h5" fontWeight={700}>
                  {locale === "en" ? "Log In" : "Ouvrir une session"}
                </Typography>
                <Box display="flex" gap={2} flexDirection={"column"}>
                  <AppTextField
                    fieldLabel={locale === "en" ? `Username` : `Nom d’utilisateur`}
                    placeholder={locale === "en" ? "Your Username" : "Votre nom d'utilisateur"}
                    onKeyPress={handleKeypress}
                    onChange={handleOnChangeInputField}
                    fieldId="email"
                    fieldName="email"
                    required
                    error={!isTruthy(formFields.email.value) && formFields.email.error}
                  />
                  <AppTextField
                    fieldLabel={locale === "en" ? `Password` : `Mot de passe`}
                    placeholder={locale === "en" ? "Your Password" : "Votre mot de passe"}
                    onKeyPress={handleKeypress}
                    onChange={handleOnChangeInputField}
                    fieldId="password"
                    fieldName="password"
                    type="password"
                    required
                    error={!isTruthy(formFields.password.value) && formFields.password.error}
                  />
                  <Box>
                    <Typography
                      my={1}
                      fontSize={16}
                      sx={{ ...descriptionFont }}
                      variant="subtitle1"
                    >
                      {parse(markdownText(content.description))}
                    </Typography>
                  </Box>
                </Box>
                <AppButton
                  label={locale === "en" ? "Log In" : "Ouvrir une session"}
                  withRadius
                  styles={{
                    alignSelf: "center",
                    fontSize: 14,
                    paddingBottom: `8px`,
                    paddingTop: `8px`,
                    fontWeight: 400,
                    width: "auto",
                  }}
                  onClick={handleLogin}
                />
              </Box>
              {/* <Typography sx={{ mt: 4, textAlign: "center", fontSize: 14 }}>
                © 2023 HomeRun. All Rights Reserved
              </Typography> */}
            </Box>
          </Box>
        </Container>
      </Box>
    );
  };

  return (
    <>
      <Box sx={classes.header}>
        <Container maxWidth="xl">
          <img
            src={locale === strings.EN ? HomeRunLogo : HomeRunLogoFr}
            alt="Homerun"
            height="80px"
            style={{ cursor: "pointer" }}
            onClick={() => history.push(urls.homeViewPath)}
          />
        </Container>
      </Box>
      {getLoginCard()}
      <AppFooter />
      {isDesktop && (
        <img
          src={sideImage}
          style={{
            position: "fixed",
            right: "-15%",
            top: 0,
            width: "70%",
            overflow: "hidden",
            objectFit: "fill",
            zIndex: 5,
            height: `calc(100vh - ${getBodyHeight()}px)`,
          }}
          alt=""
        />
      )}
      <CustomLoader isLoading={isLoading} />
    </>
  );
};

export default Login;
