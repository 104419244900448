import {
  centerItemFlex,
  descriptionFont,
  getRelativeFontSize,
  headingFont,
  mediumFontTitle,
  regularFont,
  theme,
} from "../../../utils/styles";

const customSectionStyles = {
  centerAlignedBox: {
    ...regularFont,
    ...centerItemFlex,
  },
  orangeHeadingText: {
    ...mediumFontTitle,
    color: theme.palette.secondary.main,
    fontSize: getRelativeFontSize(20),
    fontWeight: "bold",
  },
  stack: {
    padding: 10,
  },
  blueColor: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    padding: 4,
    width: "250px",
    height: "250px",
    marginBottom: -10,
  },
  whyUsText: {
    ...headingFont,
    color: "#3E3E3F",
    fontSize: getRelativeFontSize(10),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(5),
      textAlign: "center",
    },
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.secondary.light,
    },
  },
  whyUsTextWithoutHover: {
    ...headingFont,
    color: "#3E3E3F",
    fontSize: getRelativeFontSize(10),
    [theme.breakpoints.down("sm")]: {
      fontSize: getRelativeFontSize(5),
      textAlign: "center",
    },
  },
  plansText: {
    ...regularFont,
    fontWeight: 600,
    width: "auto",
    marginRight: 0.5,
    color: "#ADC804",
    lineHeight: 3,
  },
  freeTrialText: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    svg: {
      height: "18px",
      width: "18px",
      color: "#ADC804",
    },
  },
  ButtonMain: {
    textDecoration: " none",
    "&.MuiTypography-root-MuiLink-root": {
      textDecoration: " none",
    },
  },
  Button: {
    ...regularFont,
    fontSize: getRelativeFontSize(2),
    backgroundColor: "#1B1028",
    textTransform: "unset",
    color: "#FFFFFF",
    height: "auto",
    width: "auto",
    borderRadius: "12px",
    boxShadow: "none !important",
    padding: "8px 30px",
    marginRight: 3,
    marginTop: 3,
    "&:hover": {
      backgroundColor: "#1B1028",
      color: "#F5F5F5",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      marginBottom: 2,
    },
  },
  iconBox: {
    backgroundColor: "#7B799A",
    padding: 1,
    borderRadius: "20px",
  },
  descriptionText: {
    textAlign: "justify",
    fontSize: getRelativeFontSize(6),
    ...regularFont,
    color: "#666666",
    [theme.breakpoints.down("sm")]: {
      padding: 3,
    },
    "&.MuiTypography-root": {
      padding: 0,
    },
  },
  whiteHeading: {
    ...descriptionFont,
    fontWeight: "bolder",
    fontSize: getRelativeFontSize(8),
    color: "#FFFFFF",
  },
} as const;

export default customSectionStyles;
