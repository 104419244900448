import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { PureComponent } from "react";
import {
  ComposedChart,
  LabelList,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
  Legend,
  Label,
} from "recharts";
import { theme } from "../../../utils/styles";
import { isTruthy } from "../../../helpers/methods";

interface CustomProps {
  data: any;
  min: number;
  max: number;
  locale: string;
  isAuthenticated: boolean;
  subTitle: string;
}

const getLegend = (locale: string, isAuthenticated: boolean) => {
  if (locale === "FR") {
    return {
      coop: "Votre coopérative",
      median: "La médiane",
    };
  }
  return {
    coop: "Your Co-op",
    median: "The Median",
  };
};

const getLabel = (locale: string, isAuthenticated: boolean): { [key: string]: string } => {
  if (locale === "FR" && !isAuthenticated) {
    return {
      value: "La valeur",
      median: "La médiane",
    };
  }
  if (locale === "FR") {
    return {
      value: "Votre coopérative",
      median: "La médiane",
    };
  }
  if (locale === "EN" && !isAuthenticated) {
    return {
      value: "The Value",
      median: "The Median",
    };
  }
  return {
    value: "Your Co-op",
    median: "The Median",
  };
};

export default class ScoreGraph extends PureComponent<CustomProps> {
  GetHouseIcon = (props: any) => {
    const { x, y, value } = props;
    return [-1, 0, 1, 2].map((house, index: number) => {
      return (
        <svg
          x={x + house * 20}
          y={y - 5}
          width="20"
          height="16"
          viewBox="0 0 20 20"
          fill={index + 1 <= props ? "#42406F" : "none"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9211 1.08264L10.9211 1.08266L19.305 7.60358C19.3051 7.60361 19.3051 7.60364 19.3051 7.60366C19.6811 7.89637 19.4746 8.49999 18.9971 8.49999H18.0001H17.5001V8.99999V17C17.5001 17.3978 17.3421 17.7793 17.0608 18.0606C16.7795 18.342 16.3979 18.5 16.0001 18.5H12.5001V13C12.5001 12.3369 12.2367 11.7011 11.7679 11.2322C11.299 10.7634 10.6631 10.5 10.0001 10.5C9.33706 10.5 8.70118 10.7634 8.23234 11.2322C7.7635 11.7011 7.5001 12.3369 7.5001 13V18.5H4.0001C3.60228 18.5 3.22075 18.342 2.93944 18.0606C2.65814 17.7793 2.5001 17.3978 2.5001 17V8.99999V8.49999H2.0001H1.0031C0.527211 8.49999 0.317726 7.89758 0.695498 7.60234C0.695586 7.60227 0.695675 7.6022 0.695763 7.60213L9.07905 1.08269L9.0791 1.08264C9.34242 0.877811 9.6665 0.766602 10.0001 0.766602C10.3337 0.766602 10.6578 0.877811 10.9211 1.08264Z"
            stroke="#42406F"
          />
        </svg>
      );
    });
  };

  // CustomLabelList = (props: any) => {
  //   const { x, y, value } = props;
  //   const labelText = `${value} houses`;

  //   // Style for the background rectangle
  //   const rectStyle = {
  //     fill: "#FFF",
  //     rx: 5, // Rounded corners
  //     ry: 5, // Rounded corners
  //     boxShadow: "0px 1px 16px 0px #00000024",
  //     borderRadius: "8px",
  //   };

  //   // Style for the label text
  //   const textStyle = {
  //     fill: "#575FF0",
  //     fontSize: "12px",
  //   };

  //   const textWidth = labelText.length * 7; // Approximate text width

  //   return (
  //     <g>
  //       <rect
  //         x={x - textWidth / 2 - 5}
  //         y={y - 15}
  //         width={textWidth + 10}
  //         height={30}
  //         style={rectStyle}
  //       />

  //       {this.GetHouseIcon(props)}
  //     </g>
  //   );
  // };

  customizedGroupTick = (props: any) => {
    const { index, x, y, payload } = props;

    return (
      <g>
        <text
          x={x - 40}
          y={y}
          fontSize={"14px"}
          style={{ fontFamily: "system-ui", color: "#3E3E3F" }}
        >
          {`(${payload.value ?? 0} coops)`}
        </text>
      </g>
    );
  };

  CustomTooltip = (props: any) => {
    const labels = getLabel(this.props.locale, this.props.isAuthenticated);
    const yourScore =
      this.props.locale === "EN"
        ? this.props.isAuthenticated
          ? "Your Score"
          : "The score"
        : "Ton score";
    if (props.active && props.payload && props.payload.length) {
      return (
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0px 1px 16px 0px #00000024",
            padding: 1,
            alignItems: "center",
          }}
        >
          {props.payload.map((pld: any) => (
            <Typography
              sx={{
                color: pld.dataKey === "value" ? "#42406F" : "#13B9A5",
                fontSize: "16px",
              }}
            >
              <span style={{ color: pld.dataKey === "value" ? "#42406F" : "#13B9A5" }}>
                {labels[pld.dataKey]}:{" "}
              </span>
              {pld.dataKey === "value" ? pld.payload.displayValue : pld.payload.displayMedian}
            </Typography>
          ))}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: "#42406F", fontSize: "16px" }}>{yourScore}: </Typography>
            <Box>{this.GetHouseIcon(props.payload[0].payload.score ?? 0)}</Box>
          </Box>
        </Box>
      );
    }

    return null;
  };

  generateIntervals(start: number, difference: number, count: number) {
    const series = [];
    let currentNumber = start;

    for (let i = 0; i < count; i++) {
      series.push(Math.round(currentNumber * 100) / 100);
      currentNumber += difference;
    }

    return series;
  }

  roundup(value: number) {
    const sign = Math.sign(value);
    const abs = Math.abs(value);
    if (abs > 0 && abs < 1) {
      return 1 * sign;
    }
    if (abs > 1 && abs < 10) {
      return 10 * sign;
    }
    const power = Math.ceil(abs).toString().length - 1;
    const roundTo = Math.pow(10, power);
    const roundedAbsValue = Math.ceil(abs / roundTo) * roundTo;
    return roundedAbsValue * sign;
  }

  verifyRoundedMinMax(min: number, max: number) {
    const minLength = Math.abs(min).toString().length;
    const maxLength = Math.abs(max).toString().length;
    const minSign = Math.sign(min);
    const maxSign = Math.sign(max);
    if (minLength !== maxLength || min !== 0 || max !== 0) {
      if (minLength > maxLength) {
        return {
          roundedMinVerified: min,
          roundedMaxVerified: min * minSign,
        };
      } else {
        return {
          roundedMinVerified: max * minSign,
          roundedMaxVerified: max,
        };
      }
    }
    return {
      roundedMinVerified: min,
      roundedMaxVerified: max,
    };
  }

  render() {
    const values = (this.props?.data ?? []).map((member: any) => member.value);
    const medianValues = (this.props?.data ?? []).map((member: any) => member.median);
    const minValue = Math.min(...values, ...medianValues);
    const maxValue = Math.max(...values, ...medianValues);
    const min = minValue < 0 ? minValue : 0;
    const max = maxValue > 0 ? maxValue : 0;
    const roundedMin = this.roundup(min);
    const roundedMax = this.roundup(max);

    const { roundedMinVerified, roundedMaxVerified } = this.verifyRoundedMinMax(
      roundedMin,
      roundedMax,
    );

    const range = roundedMaxVerified - roundedMinVerified;

    const tickInterval = range / 10;

    let intervals: number[];
    if (roundedMinVerified === 0 && tickInterval === 0) {
      intervals = [];
    } else {
      intervals = this.generateIntervals(roundedMinVerified, tickInterval, 11);
    }

    const customTickFormatter = (tick: any, index: number) => {
      if (this.props.locale.toLocaleLowerCase() === "fr") {
        return tick.toString().replace(".", ",");
      }
      return tick; // Return the original tick value
    };

    const customLegend = (property: any) => {
      return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: "#42406F",
              marginRight: "5px",
            }}
          ></div>
          <span style={{ marginRight: "10px", fontFamily: "AvenirLTStd-Light" }}>
            {getLegend(this.props.locale, this.props.isAuthenticated).coop}
          </span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "20px",
                height: "2px",
                backgroundColor: "#13B9A5",
                marginRight: "5px",
              }}
            ></div>
          </div>
          <span style={{ fontFamily: "AvenirLTStd-Light" }}>
            {getLegend(this.props.locale, this.props.isAuthenticated).median}
          </span>
        </div>
      );
    };

    return (
      <ResponsiveContainer width="99%" aspect={[theme.breakpoints.up("md")] ? 1.5 : 1}>
        <ComposedChart
          width={500}
          height={400}
          data={this.props.data}
          margin={{
            top: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" vertical={false} />
          <XAxis
            xAxisId="0"
            dataKey="year"
            tickLine={false}
            tick={{ stroke: "#3E3E3F", fontWeight: "normal" }}
            style={{ fontFamily: "system-ui" }}
          />
          <XAxis
            xAxisId="1"
            tick={this.customizedGroupTick}
            tickLine={false}
            dataKey="peerCount"
            axisLine={false}
          />

          <YAxis
            tickLine={false}
            allowDecimals={true}
            dataKey="median"
            ticks={intervals}
            tickCount={11}
            type="number"
            tickFormatter={customTickFormatter}
            style={{ fontFamily: "system-ui", fontSize: "16px" }}
          >
            <Label
              style={{
                textAnchor: "middle",
                fontSize: "14px",
                fill: "black",
                fontFamily: "AvenirLTStd-Light",
              }}
              position="left"
              angle={270}
              value={this.props.subTitle ?? ""}
            />
          </YAxis>
          <Tooltip content={this.CustomTooltip} cursor={{ fill: "#FFF" }} />
          <Bar
            dataKey="value"
            barSize={25}
            fill="#42406F"
            background={{ fill: "transparent" }}
          ></Bar>
          <Legend content={customLegend} />
          <Line type="monotone" dataKey="median" stroke="#13B9A5" />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
