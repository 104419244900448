import { Box } from "@mui/material";
import closeButtonStyles from "./CloseButton.styles";
import ClearIcon from "@mui/icons-material/Clear";
import CustomIcon from "../CustomIcon/CustomIcon";

interface CustomProps {
  onClick: Function;
  customClasses?: string;
}

const CloseButton = (props: CustomProps) => {
  const { classes } = closeButtonStyles();
  const appliedClass = props.customClasses ? props.customClasses : classes.closeBtn;

  const getCloseButton = () => {
    return (
      <Box className={appliedClass} onClick={() => props.onClick()}>
        <CustomIcon icon={<ClearIcon fontSize="large" htmlColor="#828282" />} />
      </Box>
    );
  };

  return getCloseButton();
};

export default CloseButton;
