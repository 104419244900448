import { theme, getRelativeFontSize, centerItemFlex } from "../../../../utils/styles";

const addPageStyles = {
  imageBox: {
    border: "1px solid #F2F2F2",
    boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      transition: "transform 0.3s ease",
      transform: "scale(1.1)",
    },
  },
  addLinks: {
    mt: 1,
    display: "inline-block",
    ...theme.typography.h6,
    color: theme.palette.secondary.dark,
    fontSize: getRelativeFontSize(),
    cursor: "pointer",
  },
  regularFonts: {
    ...theme.typography.body1,
    ...centerItemFlex,
    color: theme.palette.secondary.main,
  },
  mediumFonts: {
    ...theme.typography.h6,
    color: theme.palette.primary.main,
  },
  selectedTemplate: {
    border: "1px solid" + theme.palette.secondary.main,
    borderRadius: "10px",
  },
  dropDown: {
    height: "48px",
    width: "100%",
    borderRadius: "12px",
    marginTop: "9px",
  },
} as const;

export default addPageStyles;
