import governance from "../assets/images/Dashboard/governance.png";
import socialInclusion from "../assets/images/Dashboard/socailinclusion.png";
import financialRatios from "../assets/images/Dashboard/financialratios.png";
import assetmgmt from "../assets/images/Dashboard/assetmgmt.png";
import env from "../assets/images/Dashboard/environmental.png";
import strings from "../global/constants/StringConstants";

export const MoreDetailsData = {
  title: "What are Good Practices?",
  description: [
    "Good practices are the things a co‑op does to get good results. Visit the Good Practices section of this website to read fellow co‑ops' stories about methods or approaches that have worked well for them.The real-life advice and resources will help you find ways to boost your co‑op's housing game!",
    "Good practices are the things a co‑op does that get good results. Because most co‑ops deal with common issues, often the methods that work for one can be used by others. Learning good practices from one another is bound to result in better operations, happier members and a greater sense of pride. This is the thinking behind the Good Practices section of this website.",
    "Below, you will find a collection of success stories shared by co‑ops from across Canada. Each story looks at how a particular housing co‑op has solved a problem, or improved their operations by applying an idea or technique that worked. We hope that other co‑ops will learn from these examples and adopt the ideas of their peers in order to improve their co‑op’s operations.",
  ],
};

export const dashboardData = {
  governance: {
    icon: governance,
    title: "Managing the Property",
    borderColor: "#FFD5D1",
    backgroundColor: "#FFF8F8",
    measure: [],
  },
  "social-inclusion": {
    icon: socialInclusion,
    title: "Managing the Expenses",
    borderColor: "#13B9A54D",
    backgroundColor: "#F2F9F8",
    measure: [],
  },
  "financial-viability": {
    icon: financialRatios,
    title: "Financial Ratios",
    borderColor: "#42406F4D",
    backgroundColor: "#F8F8FF",
    measure: [
      // {
      //   title: "Liquidity Ratio",
      //   score: 1,
      //   link: "/",
      // },
      // {
      //   title: "Net-Income Ratio",
      //   score: 2,
      //   link: "/",
      // },
    ],
  },
  "asset-management": {
    icon: assetmgmt,
    title: "Managing the Revenue",
    borderColor: "#0F85754D",
    backgroundColor: "#FAFFFE",
    measure: [],
  },
  "environmental-sustainability": {
    icon: env,
    title: "Managing the Revenue",
    borderColor: "#F3776C",
    backgroundColor: "#FFE7E4",
    measure: [],
  },
} as {
  [key: string]: {
    icon: string;
    title: string;
    borderColor: string;
    backgroundColor: string;
    measure: {
      title: string;
      score: number;
      link: string;
    }[];
  };
};

export const dashboardMeasuresStylesData = {
  [strings.EN]: {
    "asset-management": {
      icon: assetmgmt,
      title: "Asset Management",
      displayTitle: "Asset Management",
      borderColor: "#0F8575",
      backgroundColor: "#CBF0EB",
    },
    "environmental-sustainability": {
      icon: env,
      title: "Environmental Sustainability",
      displayTitle: "Environmental Sustainability",
      borderColor: "#F3776C",
      backgroundColor: "#FFE7E4",
    },
    "financial-viability": {
      icon: financialRatios,
      title: "Financial Viability",
      displayTitle: "Financial Viability",
      borderColor: "#42406F",
      backgroundColor: "#E4E2FF",
    },
    governance: {
      icon: governance,
      title: "Governance",
      displayTitle: "Governance",
      borderColor: "#F3776C",
      backgroundColor: "#FFE7E4",
    },
    "social-inclusion": {
      icon: socialInclusion,
      title: "Social Inclusion",
      displayTitle: "Social Inclusion",
      borderColor: "#13B9A5",
      backgroundColor: "#C0F3ED",
    },
  },
  [strings.FR]: {
    "asset-management": {
      icon: assetmgmt,
      title: "Asset Management",
      displayTitle: "Gestion immobilière",
      borderColor: "#0F8575",
      backgroundColor: "#CBF0EB",
    },
    "environmental-sustainability": {
      icon: env,
      title: "Environmental Sustainability",
      displayTitle: "Durabilité environnementale",
      borderColor: "#F3776C",
      backgroundColor: "#FFE7E4",
    },
    "financial-viability": {
      icon: financialRatios,
      title: "Financial Viability",
      displayTitle: "Viabilité financière",
      borderColor: "#42406F",
      backgroundColor: "#E4E2FF",
    },
    governance: {
      icon: governance,
      title: "Governance",
      displayTitle: "Gouvernance",
      borderColor: "#F3776C",
      backgroundColor: "#FFE7E4",
    },
    "social-inclusion": {
      icon: socialInclusion,
      displayTitle: "Inclusion sociale",
      title: "Social Inclusion",
      borderColor: "#13B9A5",
      backgroundColor: "#C0F3ED",
    },
  },
} as {
  [key: string]: {
    [key: string]: {
      icon: string;
      title: string;
      displayTitle: string;
      borderColor: string;
      backgroundColor: string;
    };
  };
};
