import { configureStore, ThunkAction, Action, combineReducers, AnyAction } from "@reduxjs/toolkit";
import authReducer, { RESET_STORE } from "../redux/authSlice";
import persistReducer from "../redux/persistSlice";

const loadState = () => {
  try {
    const loadedState = localStorage.getItem("state");
    if (loadedState === null) return undefined;
    return JSON.parse(loadedState);
  } catch (error: any) {
    return undefined;
  }
};

const saveState = (state: RootState) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem("state", serializedState);
};

const persistedState = loadState();

const appReducer = combineReducers({
  auth: authReducer,
  persist: persistReducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveState({ auth: store.getState().auth, persist: store.getState().persist });
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
