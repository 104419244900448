import { Box } from "@mui/system";
import { v4 as uuidv4 } from "uuid";
import TableComponent from "./TableComponent/TableComponent";
import { Section } from "../../../../../models/interface";
import TextComponent from "./TextComponent/TextComponent";
import ImageComponent from "./ImageComponent/ImageComponent";

export const getTableMetaDataStructure = () => {
  return {
    title: "",
    header: [{ name: "", field: uuidv4(), align: "" }],
    rows: [],
  };
};

export const getImageMetaDataStructure = () => {
  return {
    image: "",
    altText: "",
  };
};

export const getTextMetaDataStructure = () => {
  return {
    title: "",
    des: [
      {
        type: "",
        content: "",
      },
    ],
  };
};

export const getJSXAndFormFields = (type: string) => {
  switch (type) {
    case "text":
      return {
        formFields: {
          type: "text",
          metadata: getTextMetaDataStructure(),
        },
      };
    case "table": {
      return {
        formFields: {
          type: "table",
          metadata: getTableMetaDataStructure(),
        },
      };
    }
    case "image": {
      return {
        formFields: {
          type: "image",
          metadata: getImageMetaDataStructure(),
        },
      };
    }
    default: {
      return {
        formFields: {
          type: "text",
          metadata: getTextMetaDataStructure(),
        },
      };
    }
  }
};
