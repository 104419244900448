import { useState } from "react";
import {
  Box,
  Container,
  IconButton,
  MobileStepper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BenchmarkingStyles, { dotStyles } from "./Benchmarking.styles";
import BenchmarkingSteps from "./Components/BenchmarkingSteps";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import howitowrksImg from "../../assets/images/Benchmarking/howitworks.png";
import dashboardImg from "../../assets/images/Benchmarking/dashboard.png";
import loginImg from "../../assets/images/Benchmarking/login.png";
import dashboardResultImg from "../../assets/images/Benchmarking/dashboardresults.png";
import peergrpImg from "../../assets/images/Benchmarking/peergroup.png";
import chosenpeergrpImg from "../../assets/images/Benchmarking/chosenpeergroup.png";
import exploreResultsImg from "../../assets/images/Benchmarking/exploreresults.png";
import AppButton from "../../global/components/AppButton/AppButton";
import urls from "../../global/constants/UrlConstants";
import { selectAuthenticated, selectLocale, selectRole } from "../../redux/authSlice";
import { useAppSelector } from "../../utils/hooks";
import parse from "html-react-parser";
import { markdownText } from "../../helpers/methods";
import { theme } from "../../utils/styles";
import { BenchmarkingContent } from "../../models/interface";
import { imageRenderer } from "../../utils/service";
import history from "../../utils/history";

export const steps = (isAuthenticated: boolean) => [
  {
    title: "How it works",
    description: [
      "Through benchmarking, you can track your progress year over year, compare expenses against your peers, and establish a reputation for strong performance that attracts new applicants and reduces turnover.",
      "Click the arrow to learn how you can benchmark your co-op using HomeRun.",
    ],
    image: howitowrksImg,
    stepLabel: "How It Works",
  },
  {
    title: "Sample Dashboard",
    description: ["Look at the sample dashboard to get an idea of what to expect."],
    image: dashboardImg,
    stepLabel: "Sample Dashboard",
  },
  {
    title: "Log In",
    description: ["Sign in to benchmark your co-op against others in your peer group."],
    image: loginImg,
    stepLabel: "Log in",
  },
  {
    title: "Check Your Results",
    description: [
      "Explore your co-op's scores on each benchmarked measure. Look for home runs and areas for improvement. ",
    ],
    image: dashboardResultImg,
    stepLabel: "Check Your Results",
  },
  {
    title: "Choose Peer Groups",
    description: [
      "Create comparison peer groups based on factors like co-op size, building type, management type, province, or city.",
    ],
    image: peergrpImg,
    stepLabel: "Choose Peer Groups",
  },
  {
    title: "Save Peer Groups",
    description: [
      "Customize the names of your chosen peer groups. They will be available on your dashboard when you log in again. You can create as many groups as you want.",
    ],
    image: chosenpeergrpImg,
    stepLabel: "Save Peer Groups",
  },
  {
    title: "Explore Results",
    description: [
      "Dive deeper into each measure for more detailed results. Discover relevant resources and success stories on each measure's page.",
      isAuthenticated ? "Visit your " : "Ready to get started?",
      <Typography
        onClick={() =>
          history.push(isAuthenticated ? urls.sampleDashboardViewPath : urls.loginViewPath)
        }
        sx={{ textDecoration: "underline", color: "#42406F", fontWeight: "bold" }}
      >
        {isAuthenticated ? "Dashboard" : "Log In"}
      </Typography>,
      "to begin.",
    ],
    image: exploreResultsImg,
    stepLabel: "Explore Results",
  },
];

interface CustomProps {
  content: BenchmarkingContent;
  templateId: string;
}

const Benchmarking = (props: CustomProps) => {
  const classes = BenchmarkingStyles;
  const authenticated = useAppSelector(selectAuthenticated);
  const userRole = useAppSelector(selectRole);
  const isAuthenticated = authenticated && userRole === "coop";
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { cx, css } = dotStyles();
  const content = props.content;
  const benchmarkingSteps = steps(isAuthenticated);
  const locale = useAppSelector(selectLocale);
  const [activeStep, setActiveStep] = useState<number>(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={classes.bgBox}>
      <Box sx={classes.stepWrapper}>
        <Container maxWidth="xl">
          <BenchmarkingSteps
            activeStep={activeStep}
            data={isAuthenticated ? content.content.afterLogin : content.content.beforeLogin}
          />
        </Container>
      </Box>
      <Container sx={classes.activeScreenWrapper} maxWidth="md">
        <Box sx={classes.activeScreen}>
          <Typography variant="h4" textAlign={"center"}>
            {isAuthenticated
              ? content.content.afterLogin[activeStep].title
              : content.content.beforeLogin[activeStep]?.title}
          </Typography>
          <Container maxWidth="lg" sx={{ textAlign: "center" }}>
            {!isAuthenticated
              ? content.content?.beforeLogin[activeStep]?.desc?.map((text) => {
                  return (
                    <Typography sx={{ marginRight: "4px" }} gutterBottom>
                      {parse(markdownText(text))}
                    </Typography>
                  );
                })
              : content.content?.descriptionIssame
              ? content.content?.beforeLogin[activeStep]?.desc?.map((text) => {
                  return (
                    <Typography sx={{ marginRight: "4px" }} gutterBottom>
                      {parse(markdownText(text))}
                    </Typography>
                  );
                })
              : content.content?.afterLogin[activeStep]?.desc?.map((text) => {
                  return (
                    <Typography sx={{ marginRight: "4px" }} gutterBottom>
                      {parse(markdownText(text))}
                    </Typography>
                  );
                })}

            <Box sx={{ mt: 2, justifyContent: "center", display: "flex" }}>
              {isAuthenticated ? (
                <img
                  src={imageRenderer(
                    props.templateId,
                    content.content?.afterLogin[activeStep]?.image,
                  )}
                  alt={content.content?.afterLogin[activeStep]?.imageAlt}
                  width={isDesktop ? "auto" : "100%"}
                  height={isDesktop ? "500px" : "auto"}
                />
              ) : (
                <img
                  src={imageRenderer(
                    props.templateId,
                    content.content.beforeLogin[activeStep]?.image,
                  )}
                  alt={content.content.beforeLogin[activeStep]?.imageAlt}
                  width={isDesktop ? "auto" : "100%"}
                  height={isDesktop ? "500px" : "auto"}
                />
              )}
            </Box>
          </Container>
        </Box>
        <MobileStepper
          variant="dots"
          steps={
            isAuthenticated ? content.content.afterLogin.length : content.content.beforeLogin.length
          }
          position="static"
          activeStep={activeStep}
          classes={{
            dot: cx(css({ display: "none" })), // this will overrides default cx related to dot elements
          }}
          sx={classes.mobileStepperStyles}
          nextButton={
            activeStep ===
            (isAuthenticated
              ? content.content.afterLogin.length
              : content.content.beforeLogin.length) -
              1 ? (
              !isAuthenticated ? (
                <AppButton
                  withRadius
                  label={locale === "en" ? "Log In" : "Ouvrir une session"}
                  onClick={() => history.push(urls.loginViewPath)}
                />
              ) : (
                <AppButton
                  withRadius
                  label={locale === "en" ? "Dashboard" : "Tableau de bord"}
                  onClick={() => history.push(urls.sampleDashboardViewPath)}
                />
              )
            ) : (
              <IconButton
                sx={classes.stepperController}
                onClick={handleNext}
                disabled={activeStep === benchmarkingSteps.length - 1}
              >
                <ArrowForwardIcon />
              </IconButton>
            )
          }
          backButton={
            <IconButton
              sx={classes.stepperController}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <ArrowBackIcon />
            </IconButton>
          }
        />
      </Container>
    </Box>
  );
};

export default Benchmarking;
