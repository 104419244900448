import React, { useEffect, useState } from "react";
import { benchmarkInputFields } from "./BenchmarkingTypesAndValidations";
import benchmarkingStyles from "./Benchmarking.styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import CustomLoader from "../../../../global/components/CustomLoader/CustomLoader";
import GlobalLayout from "../../Pages/Global/GlobalLayout";
import PreviewBenchmarking from "../../../Benchmarking/Benchmarking";
import { v4 as uuidv4 } from "uuid";
import { isTruthy, openErrorNotification } from "../../../../helpers/methods";
import CustomInput from "../../../../global/components/CustomInput/CustomInput";
import notifiers from "../../../../global/constants/NotificationConstants";
import { addImage } from "../../AdminService";
import strings from "../../../../global/constants/StringConstants";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { cloneDeep } from "lodash";
import { imageRenderer } from "../../../../utils/service";

interface CustomProps {
  content: any;
  updatePageContent: Function;
  templateId: string;
}

const Benchmarking = (props: CustomProps) => {
  const classes = benchmarkingStyles;
  const [formData, setFormData] = useState(benchmarkInputFields(props.content));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.updatePageContent(formData);
  }, [formData]);

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleTitleOnchange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = formData.content;
    localState[forWhat][index].title = event.target.value;
    setFormData({
      ...formData,
      content: localState,
    });
  };

  const handleImageAltTextonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = formData.content;
    localState[forWhat][index].imageAlt = event.target.value;
    setFormData({
      ...formData,
      content: localState,
    });
  };

  const handleDesconChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
    descIndex: number,
  ) => {
    const localState = cloneDeep(formData.content);
    localState[forWhat][index].desc[descIndex] = event.target.value;
    setFormData({
      ...formData,
      content: localState,
    });
  };

  const handleDeleteDesc = (
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
    descIndex: number,
  ) => {
    const localState = cloneDeep(formData.content);
    const updated = localState[forWhat][index].desc.filter((v, i) => i !== descIndex);
    localState[forWhat][index].desc = updated;
    setFormData({
      ...formData,
      content: localState,
    });
  };

  const handleAfterLOginChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localContent = formData.content;
    localContent.descriptionIssame = event.target.checked;
    if (event.target.checked) {
      localContent[forWhat] = cloneDeep(localContent[forWhat]);
    }
    setFormData({
      ...formData,
      content: localContent,
    });
  };

  const handleOnDropZoneFile = async (
    e: any,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    try {
      const localImageState = formData.content;
      const data = new FormData();
      const file = e.target.files[0];
      data.append("file", file);
      const response = await addImage(data, props.templateId);
      localImageState[forWhat][index].image = response.filename;
      setFormData({
        ...formData,
        content: localImageState,
      });
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    }
  };

  const addNewRow = (forWhat: "beforeLogin" | "afterLogin") => {
    const localDescriptionState = formData.content;
    localDescriptionState[forWhat].push({
      title: "",
      desc: [""],
      image: "",
      imageAlt: "",
    });
    setFormData({
      ...formData,
      content: localDescriptionState,
    });
  };

  const addNewDesc = (index: number, forWhat: "beforeLogin" | "afterLogin") => {
    const localDescriptionState = formData.content;
    localDescriptionState[forWhat][index].desc.push("");
    setFormData({
      ...formData,
      content: localDescriptionState,
    });
  };

  const deleteInfo = (index: number, forWhat: "beforeLogin" | "afterLogin") => {
    const local = formData.content[forWhat];
    const updated = local.filter((v, i) => i !== index);
    setFormData({
      ...formData,
      content: {
        ...formData.content,
        [forWhat]: updated,
      },
    });
  };

  const getInputHeader = (title: string, required: boolean, value: any) => {
    return (
      <Box
        sx={classes.label}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        borderColor="red"
      >
        <Box style={{ display: "flex" }}>
          {isTruthy(title) && (
            <>
              <Typography sx={classes.labelText}>{title}</Typography>
              {required && <Typography sx={classes.star}>*</Typography>}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getSection = () => {
    return (
      <Grid container spacing={1}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.content.descriptionIssame}
              onChange={(event) => handleAfterLOginChange(event, "beforeLogin")}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Same for After Login"
        />
        {formData.content.beforeLogin.map((content, index) => {
          return (
            <>
              {index > 0 && (
                <Box
                  onClick={() => deleteInfo(index, "beforeLogin")}
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DeleteOutlineIcon />
                </Box>
              )}
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                {getInputHeader("Title", true, content.title)}
                <CustomInput
                  placeHolder="Title"
                  type="text"
                  name="title"
                  id="title"
                  value={content.title}
                  onChange={(e: any) => handleTitleOnchange(e, index, "beforeLogin")}
                  onKeyPress={handleKeypress}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                <Box sx={classes.label}>
                  <Typography sx={classes.labelText}>Image</Typography>
                </Box>
                <CustomInput
                  type="file"
                  name="file"
                  value={""}
                  onChange={(e: any) => handleOnDropZoneFile(e, index, "beforeLogin")}
                  InputProps={{
                    accept: strings.acceptedFiles,
                    dataMaxSize: 3000000,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={2} lg={2}>
                <Box sx={classes.label}>
                  <Typography sx={classes.labelText}> Preview</Typography>
                </Box>
                {isTruthy(content?.image) && (
                  <img
                    src={imageRenderer(props.templateId, content.image)}
                    width={50}
                    height={50}
                    alt={content.imageAlt}
                    style={{ marginTop: "5px" }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
                {getInputHeader("Alternative Text", true, content.imageAlt ?? "")}
                <CustomInput
                  placeHolder="Text"
                  type="text"
                  name="altText"
                  id="altText"
                  value={content.imageAlt}
                  onChange={(event: any) => handleImageAltTextonChange(event, index, "beforeLogin")}
                  onKeyPress={handleKeypress}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {getInputHeader("Description", true, "")}
              </Grid>
              {content.desc?.map((text, descIndex) => {
                return (
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <CustomInput
                      placeHolder="Description"
                      type="text"
                      name="description"
                      id="description"
                      value={text}
                      onChange={(event: any) =>
                        handleDesconChange(event, index, "beforeLogin", descIndex)
                      }
                      onKeyPress={handleKeypress}
                      InputProps={{
                        endAdornment: descIndex > 0 && (
                          <InputAdornment position="end">
                            <DeleteOutlineIcon
                              sx={{ cursor: "pointer", marginLeft: "8px" }}
                              onClick={(event: any) =>
                                handleDeleteDesc(index, "beforeLogin", descIndex)
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                );
              })}
              <Box sx={classes.addLinks} onClick={() => addNewDesc(index, "beforeLogin")}>
                + Add Description
              </Box>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewRow("beforeLogin")}>
          + Add New Section
        </Box>
        {!formData.content.descriptionIssame && (
          <>
            {formData.content.afterLogin.map((content, index: number) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    {getInputHeader("Title", true, content.title)}
                    <CustomInput
                      placeHolder="Title"
                      type="text"
                      name="title"
                      id="title"
                      value={content.title}
                      onChange={(e: any) => handleTitleOnchange(e, index, "afterLogin")}
                      onKeyPress={handleKeypress}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Box sx={classes.label}>
                      <Typography sx={classes.labelText}>Image</Typography>
                    </Box>
                    <CustomInput
                      type="file"
                      name="file"
                      value={""}
                      onChange={(e: any) => handleOnDropZoneFile(e, index, "afterLogin")}
                      InputProps={{
                        accept: strings.acceptedFiles,
                        dataMaxSize: 3000000,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={2} lg={2}>
                    <Box sx={classes.label}>
                      <Typography sx={classes.labelText}> Preview</Typography>
                    </Box>
                    {isTruthy(content?.image) && (
                      <img
                        src={imageRenderer(props.templateId, content.image)}
                        width={50}
                        height={50}
                        alt={content.imageAlt}
                        style={{ marginTop: "5px" }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
                    {getInputHeader("Alternative Text", true, content.imageAlt ?? "")}
                    <CustomInput
                      placeHolder="Text"
                      type="text"
                      name="altText"
                      id="altText"
                      value={content.imageAlt}
                      onChange={(event: any) =>
                        handleImageAltTextonChange(event, index, "afterLogin")
                      }
                      onKeyPress={handleKeypress}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {getInputHeader("Description", true, "")}
                  </Grid>
                  {content.desc?.map((text, descIndex) => {
                    return (
                      <>
                        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                          <CustomInput
                            placeHolder="Description"
                            type="text"
                            name="description"
                            id="description"
                            value={text}
                            onChange={(event: any) =>
                              handleDesconChange(event, index, "afterLogin", descIndex)
                            }
                            onKeyPress={handleKeypress}
                            InputProps={{
                              endAdornment: descIndex > 0 && (
                                <InputAdornment position="end">
                                  <DeleteOutlineIcon
                                    sx={{ cursor: "pointer", marginLeft: "8px" }}
                                    onClick={(event: any) =>
                                      handleDeleteDesc(index, "afterLogin", descIndex)
                                    }
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </>
                    );
                  })}
                  <Box sx={classes.addLinks} onClick={() => addNewDesc(index, "afterLogin")}>
                    + Add Description
                  </Box>
                </>
              );
            })}
            <Box sx={classes.addLinks} onClick={() => addNewRow("afterLogin")}>
              + Add New Section
            </Box>
          </>
        )}
      </Grid>
    );
  };

  const sections = [
    { section: getSection(), id: uuidv4(), name: "Information", isDeletable: false },
  ];

  const getPreviewScreen = () => {
    return (
      <Box>
        <PreviewBenchmarking content={formData} templateId={props.templateId} />
      </Box>
    );
  };

  return (
    <Box>
      <GlobalLayout
        section={sections}
        pageTitle={"Benchmarking"}
        previewElement={getPreviewScreen()}
      />
      <CustomLoader isLoading={loading} />
    </Box>
  );
};

export default Benchmarking;
