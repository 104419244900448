import { Divider, Typography, Box, Stack, useMediaQuery } from "@mui/material";
import appFooterStyles from "./AppFooter.styles";
import TwitterIcon from "../../../assets/images/footer/twitter.png";
import FacebookSharpIcon from "../../../assets/images/footer/fb.png";
import LinkedInIcon from "../../../assets/images/footer/linkedin.png";
import urls from "../../../global/constants/UrlConstants";
import { theme } from "../../../utils/styles";
import { staticContent } from "../../../utils/locale";
import { useAppSelector } from "../../../utils/hooks";
import { selectAuthenticated, selectLocale, selectRole } from "../../../redux/authSlice";

const AppFooter = () => {
  const classes = appFooterStyles;
  const locale = useAppSelector(selectLocale);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const userRole = useAppSelector(selectRole);
  const authenticated = useAppSelector(selectAuthenticated);
  const isAuthenticated = authenticated && userRole === "coop";
  const content = staticContent(locale, isAuthenticated);

  const redirectToNewTab = (url: string) => {
    return window.open(url, "_blank");
  };

  return (
    <Box textAlign="center" sx={classes.wrapper} id={"Footer"}>
      <Stack
        direction={isDesktop ? "row" : "column"}
        justifyContent="center"
        alignItems="center"
        divider={
          isDesktop && (
            <Divider orientation="vertical" sx={{ height: "20px", backgroundColor: "white" }} />
          )
        }
        spacing={1}
      >
        <Typography fontSize={18} sx={{ py: 1 }}>
          {content.footer.copyright}
        </Typography>
        <Typography
          fontSize={18}
          sx={{ py: 1, color: "#fff", cursor: "pointer" }}
          onClick={() =>
            redirectToNewTab(
              locale === "en" ? urls.agency_website_url : urls.agency_fr__website_url,
            )
          }
        >
          {locale === "en" ? "www.agency.coop" : "www.agence.coop"}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
        <Box sx={classes.socialMediaBoxes} onClick={() => redirectToNewTab(urls.twitter)}>
          <img src={TwitterIcon} height="15px" />
        </Box>
        <Box sx={classes.socialMediaBoxes} onClick={() => redirectToNewTab(urls.facebook)}>
          <img src={FacebookSharpIcon} height="15px" />
        </Box>
        <Box sx={classes.socialMediaBoxes} onClick={() => redirectToNewTab(urls.linkedIn)}>
          <img src={LinkedInIcon} height="15px" />
        </Box>
      </Stack>
    </Box>
  );
};

export default AppFooter;
