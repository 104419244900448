import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalLayout from "../../Pages/Global/GlobalLayout";
import CustomInput from "../../../../global/components/CustomInput/CustomInput";
import { addImage, deleteImage, getAllRoutes } from "../../AdminService";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { isTruthy, openErrorNotification } from "../../../../helpers/methods";
import notifiers from "../../../../global/constants/NotificationConstants";
import { InputFields } from "./GoodPracticesTypesAndValidations";
import goodPracticesStyles from "./GoodPractices.styles";
import PreviewGoodPractices from "../../../Goodpractices/Goodpractices";
import strings from "../../../../global/constants/StringConstants";
import { PracticesData } from "../../../../models/interface";
import { v4 as uuidv4 } from "uuid";
import { imageRenderer } from "../../../../utils/service";
import DeleteModal from "../../Pages/Global/DeleteModal/DeleteModal";
import { cloneDeep } from "lodash";

interface CustomProps {
  content: any;
  updatePageContent: Function;
  templateId: string;
  routesList: string[];
}

const GoodPractices = (props: CustomProps) => {
  const classes = goodPracticesStyles;
  const [formData, setFormData] = useState(InputFields(props.content));
  const [openModal, setOpenModal] = useState<{ state: boolean; index: number }>({
    state: false,
    index: -1,
  });

  useEffect(() => {
    props.updatePageContent(formData);
  }, [formData]);

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleOnchange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        button: {
          ...formData.banner.button,
          hyperlink: "",
          isExternal: event.target.checked,
        },
      },
    });
  };

  const handleBannerButtonsChange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        button: {
          ...formData.banner.button,
          [event.target.name]: event.target.value,
        },
      },
    });
  };

  const addNewRow = () => {
    const localDescriptionState = formData.banner.description;
    localDescriptionState.push("");
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        description: localDescriptionState,
      },
    });
  };

  const handleChangeDescription = (event: React.ChangeEvent<any>, index: number) => {
    const localDescriptionState = formData.banner.description;
    localDescriptionState[index] = event.target.value;
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        description: localDescriptionState,
      },
    });
  };

  const handleDeleteDesc = async (text: string) => {
    try {
      const local = formData.banner.description;
      const updated = local.filter((v, i) => v !== text);
      setFormData({
        ...formData,
        banner: {
          ...formData.banner,
          description: updated,
        },
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const handlePracticesOnChange = (
    event: React.ChangeEvent<any>,
    index: number,
    nestedIndex?: number,
  ) => {
    const updatedPractices: any = formData.practicesList;
    if (nestedIndex !== undefined) {
      updatedPractices[index].practices[nestedIndex][event.target.name] = event.target.value;
    } else {
      updatedPractices[index][event.target.name] = event.target.value;
    }
    setFormData({
      ...formData,
      practicesList: updatedPractices,
    });
  };

  const handleOnDropZoneFile = async (e: any, index: number) => {
    try {
      const localImageState = formData.practicesList;
      const data = new FormData();
      const file = e.target.files[0];
      data.append("file", file);
      const response = await addImage(data, props.templateId);
      localImageState[index].practiceIcon = response.filename;
      setFormData({
        ...formData,
        practicesList: localImageState,
      });
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    }
  };

  const handleOnDropZonePracticesFile = async (e: any, index: number, nestedIndex: number) => {
    try {
      const localImageState = formData.practicesList;
      const data = new FormData();
      const file = e.target.files[0];
      data.append("file", file);
      const response = await addImage(data, props.templateId);
      localImageState[index].practices[nestedIndex].icon = response.filename;
      setFormData({
        ...formData,
        practicesList: localImageState,
      });
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    }
  };

  const addNewPractice = (index: number) => {
    const newPractice = formData.practicesList;
    newPractice[index].practices.push({
      redirectUrl: "",
      icon: "",
      altText: "",
      title: "",
      isExternal: false,
    });
    setFormData({
      ...formData,
      practicesList: newPractice,
    });
  };

  const addNewSection = () => {
    const newSection: PracticesData[] = formData.practicesList;
    newSection.push({
      practiceIcon: "",
      practiceIconAltText: "",
      title: "",
      practices: [
        {
          redirectUrl: "",
          icon: "",
          altText: "",
          title: "",
          isExternal: false,
        },
      ],
    });
    setFormData({
      ...formData,
      practicesList: newSection,
    });
  };

  const handleReadMoreLinksChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    nestedIndex: number,
  ) => {
    const updated: any = formData.practicesList;
    updated[index].practices[nestedIndex].redirectUrl = "";
    updated[index].practices[nestedIndex].isExternal = event.target.checked;
    setFormData({
      ...formData,
      practicesList: updated,
    });
  };

  const handleLinkSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    nestedIndex: number,
  ) => {
    const updated: any = formData.practicesList;
    updated[index].practices[nestedIndex].redirectUrl = event.target.value;
    setFormData({
      ...formData,
      practicesList: updated,
    });
  };

  const handleDelete = (index: number) => {
    try {
      const local = formData.practicesList;
      const updated = local.filter((v, i) => i !== index);
      setFormData({
        ...formData,
        practicesList: updated,
      });
      setOpenModal({ state: false, index: -1 });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const deletePractice = (index: number, nestedIndex: number) => {
    try {
      const localPracticesList = cloneDeep(formData.practicesList);
      localPracticesList[index] = {
        ...localPracticesList[index],
        practices: localPracticesList[index].practices.filter((v, i) => i !== nestedIndex),
      };
      setFormData({
        ...formData,
        practicesList: localPracticesList,
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const getRoutesDropDown = () => {
    return (
      <Select
        value={formData?.banner.button.hyperlink}
        name="hyperlink"
        id="hyperlink"
        onChange={(event: any) =>
          setFormData({
            ...formData,
            banner: {
              ...formData.banner,
              button: {
                ...formData.banner.button,
                [event.target.name]: event.target.value,
              },
            },
          })
        }
        sx={classes.dropDown}
      >
        {props.routesList.map((item) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    );
  };

  const getReadMoreRoutesDropDown = (value: string, index: number, nestedIndex: number) => {
    return (
      <Select
        value={value}
        name="hyperlink"
        id="hyperlink"
        onChange={(event: any) => handleLinkSelection(event, index, nestedIndex)}
        sx={classes.dropDown}
      >
        {props.routesList.map((item) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    );
  };

  const getSection1 = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Title", true, formData.banner.title)}
          <CustomInput
            placeHolder="Title"
            type="text"
            name="title"
            id="title"
            value={formData.banner.title}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Subtitle", true, formData.banner.subtitle)}
          <CustomInput
            placeHolder="Subtitle"
            type="text"
            name="subtitle"
            id="subtitle"
            value={formData.banner.subtitle}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader(
            "Alternative Text For Banner Graphic",
            true,
            formData.banner?.altTextForImage,
          )}
          <CustomInput
            placeHolder="Alternative text"
            type="text"
            name="altTextForImage"
            id="altTextForImage"
            value={formData.banner?.altTextForImage}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {formData.banner.description.map((ele, index: number) => {
            return (
              <>
                {getInputHeader(index > 0 ? "" : "Description", true, ele)}
                <CustomInput
                  placeHolder="Description"
                  type="text"
                  name="description"
                  id="description"
                  value={ele}
                  onChange={(event: any) => handleChangeDescription(event, index)}
                  onKeyPress={handleKeypress}
                  // error={!isTruthy(ele) && "Atleast 3 description lines required"}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteForeverIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={() => handleDeleteDesc(ele)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            );
          })}
          <Box sx={classes.addLinks} onClick={() => addNewRow()}>
            + Add
          </Box>
        </Grid>
      </Grid>
    );
  };

  const customDialog = () => {
    return (
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal.state}
        handleConfirmDelete={() => handleDelete(openModal.index)}
        heading="Delete Good Practice"
        description="Are you sure want to delete this practices?"
      />
    );
  };

  const getSection2 = () => {
    return (
      <Grid container spacing={1}>
        {formData.practicesList?.map((practice, index) => {
          return (
            <Grid container spacing={1} sx={{ borderBottom: "1px solid #E7E7F9", padding: 2 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                width="100%"
                sx={classes.labelText}
                style={{ cursor: "pointer" }}
                onClick={() => setOpenModal({ state: true, index: index })}
              >
                Delete whole practice
                <DeleteForeverIcon />
              </Stack>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                {getInputHeader("Practice Title", true, practice.title)}
                <CustomInput
                  placeHolder="Title"
                  type="text"
                  name="title"
                  id="title"
                  value={practice.title}
                  onChange={(event: any) => handlePracticesOnChange(event, index)}
                  onKeyPress={handleKeypress}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                <Box sx={classes.label}>
                  <Typography sx={classes.labelText}>Practice Image</Typography>
                </Box>
                <CustomInput
                  type="file"
                  name="file"
                  value={""}
                  onChange={(e: any) => handleOnDropZoneFile(e, index)}
                  InputProps={{
                    accept: strings.acceptedFiles,
                    dataMaxSize: 3000000,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={2} lg={2}>
                <Box sx={classes.label}>
                  <Typography sx={classes.labelText}> Preview</Typography>
                </Box>
                {isTruthy(practice.practiceIcon) && (
                  <img
                    src={imageRenderer(props.templateId, practice.practiceIcon)}
                    width={50}
                    height={50}
                    alt={practice.practiceIconAltText}
                    style={{ marginTop: "5px" }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
                <Box sx={classes.label}>
                  <Typography sx={classes.labelText}>Practice Image Alternate Text</Typography>
                </Box>
                <CustomInput
                  placeHolder="Text"
                  type="text"
                  name="practiceIconAltText"
                  id="practiceIconAltText"
                  value={practice.practiceIconAltText}
                  onChange={(event: any) => handlePracticesOnChange(event, index)}
                  onKeyPress={handleKeypress}
                />
              </Grid>
              <Box mt={4}>
                <Typography sx={classes.labelText} style={{ fontWeight: "bold" }}>
                  Practices
                </Typography>
              </Box>
              <Grid container spacing={1} alignItems="center">
                {practice.practices.map((item, nestedIndex) => {
                  return (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        xl={12}
                        lg={12}
                        sx={{ borderTop: "1px solid #E7E7F9", mt: 2 }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          sx={{ cursor: "pointer" }}
                          onClick={() => deletePractice(index, nestedIndex)}
                        >
                          <DeleteForeverIcon />
                        </Stack>
                        {getInputHeader("Name", true, item.title)}
                        <CustomInput
                          placeHolder="Name"
                          type="text"
                          name="title"
                          id="title"
                          value={item.title}
                          onChange={(event: any) =>
                            handlePracticesOnChange(event, index, nestedIndex)
                          }
                          onKeyPress={handleKeypress}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                        <Stack direction="row" justifyContent="space-between">
                          {getInputHeader("Practices Link", true, item.redirectUrl)}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.isExternal}
                                onChange={(event) =>
                                  handleReadMoreLinksChange(event, index, nestedIndex)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="External Link"
                          />
                        </Stack>
                        {item.isExternal ? (
                          <CustomInput
                            placeHolder="/link"
                            type="text"
                            name="hyperlink"
                            id="hyperlink"
                            value={item.redirectUrl}
                            onChange={(event: any) =>
                              handleLinkSelection(event, index, nestedIndex)
                            }
                            onKeyPress={handleKeypress}
                            // error={!isTruthy(card.readMoreLink) && "Add text to button"}
                          />
                        ) : (
                          getReadMoreRoutesDropDown(item.redirectUrl, index, nestedIndex)
                        )}
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                        <Box sx={classes.label}>
                          <Typography sx={classes.labelText}>Practice Image</Typography>
                        </Box>
                        <CustomInput
                          type="file"
                          name="file"
                          value={""}
                          onChange={(e: any) =>
                            handleOnDropZonePracticesFile(e, index, nestedIndex)
                          }
                          InputProps={{
                            accept: strings.acceptedFiles,
                            dataMaxSize: 3000000,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={2} lg={2}>
                        <Box sx={classes.label}>
                          <Typography sx={classes.labelText}> Preview</Typography>
                        </Box>
                        {isTruthy(item.icon) && (
                          <img
                            src={imageRenderer(props.templateId, item.icon)}
                            width={50}
                            height={50}
                            alt={item.altText}
                            style={{ marginTop: "5px" }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
                        {getInputHeader("Alternative Text", true, item.altText ?? "")}
                        <CustomInput
                          placeHolder="Text"
                          type="text"
                          name="altText"
                          id="altText"
                          value={item.altText}
                          onChange={(event: any) =>
                            handlePracticesOnChange(event, index, nestedIndex)
                          }
                          onKeyPress={handleKeypress}
                        />
                      </Grid>
                    </>
                  );
                })}
                <Box sx={classes.addLinks} onClick={() => addNewPractice(index)}>
                  + Add New Practice
                </Box>
              </Grid>
            </Grid>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewSection()}>
          + Add
        </Box>
      </Grid>
    );
  };

  const getInputHeader = (title: string, required: boolean, value: any) => {
    const valueLen = isTruthy(value.length) ? value.length : 0;
    return (
      <Box
        sx={classes.label}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        borderColor="red"
      >
        <Box style={{ display: "flex" }}>
          {isTruthy(title) && (
            <>
              <Typography sx={classes.labelText}>{title}</Typography>
              {required && <Typography sx={classes.star}>*</Typography>}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getPreviewScreen = () => {
    return (
      <Box>
        <PreviewGoodPractices content={formData} templateId={props.templateId} />
      </Box>
    );
  };

  const sections = [
    { section: getSection1(), id: uuidv4(), name: "Banner", isDeletable: false },
    { section: getSection2(), id: uuidv4(), name: "Practices", isDeletable: false },
  ];

  return (
    <Box>
      <GlobalLayout
        section={sections}
        pageTitle={"Good Practices"}
        previewElement={getPreviewScreen()}
      />
      {customDialog()}
    </Box>
  );
};

export default GoodPractices;
