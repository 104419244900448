import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface CustomProps {
  item: any;
  index: number;
  isDragDisabled?: boolean;
}

const DraggableListItem = (props: CustomProps) => {
  return (
    <Draggable
      draggableId={props.item.id}
      index={props.index}
      isDragDisabled={props.isDragDisabled ? props.isDragDisabled : false}
    >
      {(provided: any, snapshot: any) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging}
        >
          <ListItemIcon>
            <DragIndicatorIcon />
          </ListItemIcon>
          <ListItemText primary={props.item.text} />
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
