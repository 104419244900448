import { StringConstants } from "./StringConstants";

class UrlConstants extends StringConstants {
  PROD = true;

  url_prod = "https://www.homerun.coop";
  url_dev = "http://localhost:6050";
  //url_dev = "http://uat.homerun.coop";

  url = this.PROD ? this.url_prod : this.url_dev;

  homerun = "homerun";
  cms = "cms";
  public = "public";
  // View Paths
  homeViewPath = "/";
  loginViewPath = "/login";
  forgotPasswordViewPath = "/forgot-password";
  changePasswordViewPath = "/user/public/pwd/reset";
  dashboardViewPath = "/dashboard";
  tradesPath = "/trades";
  tradeAddPath = "/trades/add";
  tradeUpdatePath = "/trades/update";
  adminPath = "/admin";
  editPage = "/admin/edit-page";
  addPage = "/admin/page";
  newPage = "/admin/new-page";
  editMeasuresPage = "/admin/edit/measure";
  reportsViewPath = `/reports`;
  sampleDashboardViewPath = "/dashboard";
  goodPracticesViewPath = "/good-practices";
  measuresViewPath = "/measures";

  // Base Folders
  auth = "auth";

  // Auth
  LOGIN = `${this.url}/${this.homerun}/coop/public/login`;
  ADMINLOGIN = `${this.url}/${this.homerun}/public/cms/login`;
  LOGINPAGECONTENT = `${this.url}/${this.homerun}/${this.public}/${this.cms}/page/get/login/locale`;

  //Admin
  ADMIN_HOME = "/admin/home";
  ADMIN_PAGE_RENDERER = "/admin/pagerenderer";

  // DYNAMIC ROUTES
  DYNAMIC_ROUTES = `${this.url}/${this.homerun}/${this.public}/${this.cms}/page/get/all`;
  ADMIN_DYNAMIC_ROUTES = `${this.url}/${this.homerun}/${this.public}/${this.cms}/page/get/pages`;
  ADMIN_DYNAMIC_ROUTES_PAGE_COUNT = `${this.url}/${this.homerun}/${this.public}/${this.cms}/page/get/pages/count`;
  ADMIN_DYNAMIC_ROUTES_PAGE_SEARCH_COUNT = `${this.url}/${this.homerun}/${this.cms}/page/search`;
  listOfRoutes = `${this.url}/${this.homerun}/${this.cms}/page/routes`;
  measureList = `${this.url}/${this.homerun}/${this.cms}/measure/get/pages`;

  //cms api calls
  getPageDetailsById = `${this.url}/${this.homerun}/${this.public}/${this.cms}/page/get`;
  updatePage = `${this.url}/${this.homerun}/${this.cms}/page/update`;
  addPageAPI = `${this.url}/${this.homerun}/${this.cms}/page/add`;
  deletePage = `${this.url}/${this.homerun}/${this.cms}/page/delete`;
  searchPage = `${this.url}/${this.homerun}/${this.cms}/page/search`;
  addImage = `${this.url}/${this.homerun}/${this.cms}/page/image/upload`;
  deleteImage = `${this.url}/${this.homerun}/${this.cms}/page/image/delete`;
  getImage = `${this.url}/${this.homerun}/${this.public}/${this.cms}/page/get/image`;
  updatePageStatus = `${this.url}/${this.homerun}/${this.cms}/page/route`;
  getMeasuresRoutes = `${this.url}/${this.homerun}/${this.public}/agency/measureRoutes/lang`;
  getMeasureDetails = `${this.url}/${this.homerun}/${this.cms}/measure/get/id`;
  measureCount = `${this.url}/${this.homerun}/${this.cms}/measure/count`;
  updateMeasure = `${this.url}/${this.homerun}/${this.cms}/measure/update/locale`;
  updateMeasurePageStatus = `${this.url}/${this.homerun}/${this.cms}/measure/update/id`;
  searchMeasurePage = `${this.url}/${this.homerun}/${this.cms}/measure/search`;
  searchMeasureCount = `${this.url}/${this.homerun}/${this.cms}/measure/search/count`;

  //peer-group-apis
  getPeerGroupCount = `${this.url}/${this.homerun}/agency/peer-group-count`;
  addPeeGroup = `${this.url}/${this.homerun}/agency/peerGroupDef/add`;
  updatePeeGroup = `${this.url}/${this.homerun}/agency/peerGroupDef/update`;
  deletePeerGroup = `${this.url}/${this.homerun}/agency/peerGroupDef`;
  getAllPeerGroups = `${this.url}/${this.homerun}/agency/peerGroupDef/all/page`;
  getPeerGroupsTotalCount = `${this.url}/${this.homerun}/agency/peerGroupDef/count`;
  //validate routes
  validateRoutes = `${this.url}/${this.homerun}/${this.cms}/page/validate/route`;

  // Dashboard
  DASHBOARD_MEASURES = `${this.url}/${this.homerun}/${this.public}/agency/benchmark/lang`;
  AUTH_DASHBOARD_MEASURES = `${this.url}/${this.homerun}/coop/benchmark/lang`;
  MEASURE_DETAILS = `${this.url}/${this.homerun}/${this.public}/agency/measure/lang`;
  AUTH_MEASURE_DETAILS = `${this.url}/${this.homerun}/coop/measure/lang`;
  PEER_GROUP_LIST = `${this.url}/${this.homerun}/${this.public}/agency/peerGroups`;
  AUTH_PEER_GROUP_LIST = `${this.url}/${this.homerun}/coop/peerGroups`;
  CATEGORY_LIST = `${this.url}/${this.homerun}/${this.public}/agency/measureCategories/lang`;
  getGoodPracticesData = `${this.url}/${this.homerun}/${this.public}/${this.cms}/page/get/good-practices/locale`;

  benchMarkingViewPath = "/benchmarking";
  practicesViewPath = "/practices";
  contactUsViewPath = "/contact-us";
  practicesDetails = "/gp_details";
  listofpeergrpsViewPath = "/peer-groups";
  addPeerGroupsViewPath = "/add-peer-group";
  editPeerGroupsViewPath = "/edit-peer-group";
  homeRunApiCallEndpoint = this.PROD
    ? "https://services.agency.coop"
    : "https://api-uat.agency.coop";
  agency_website_url = "https://www.agency.coop/";
  agency_fr__website_url = "https://www.agence.coop/node";

  contactApiCallEndpoint =
    this.url + "/" + this.homerun + "/" + this.public + "/agency/contact/lang";

  //social media links
  facebook = "https://www.facebook.com/AgencyCoopHousing/";
  twitter = "https://twitter.com/AgencyCoop";
  linkedIn =
    "https://www.linkedin.com/company/the-agency-for-co-operative-housing/?originalSubdomain=ca";

  BYPASS_TIMEOUT = [this.addPeeGroup, this.updatePeeGroup];
}
let urls = new UrlConstants();
export default urls;
