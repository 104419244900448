import {
  useMediaQuery,
  Typography,
  List,
  ListItem,
  Grid,
  Tooltip,
  MenuItem,
  Select,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { useState, useEffect } from "react";
import AppButton from "../../../../global/components/AppButton/AppButton";
import CustomLoader from "../../../../global/components/CustomLoader/CustomLoader";
import notifiers from "../../../../global/constants/NotificationConstants";
import urls from "../../../../global/constants/UrlConstants";
import { isTruthy, openErrorNotification, markdownText } from "../../../../helpers/methods";
import { MeasuresInternalPageData } from "../../../../models/interface";
import { selectLocale, selectAuthenticated, selectRole } from "../../../../redux/authSlice";
import { selectedPeerGroup, savePeerGroup } from "../../../../redux/persistSlice";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { theme } from "../../../../utils/styles";
import {
  retrieveAfterLoginMeasureDetails,
  retrieveMeasureDetails,
  retrieveCategories,
  retrieveAfterLoginPeerGroups,
  retrievePeerGroups,
  retrieveMeasures,
} from "../../../MeasuresInternalPage/MeasureService";
import MeasuresStyles from "../../../MeasuresInternalPage/Measures.styles";
import ScoreGraph from "../../../MeasuresInternalPage/components/ScoreGraph";
import parse from "html-react-parser";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useLocation } from "react-router";
import history from "../../../../utils/history";
import { staticContent } from "../../../../utils/locale";

interface CustomProps {
  content: MeasuresInternalPageData;
}

const MeasuresPreview = (props: CustomProps) => {
  const classes = MeasuresStyles;
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const locale = useAppSelector(selectLocale).toUpperCase();
  const authenticated = useAppSelector(selectAuthenticated);
  const peerGroupPersisted = useAppSelector(selectedPeerGroup);
  const userRole = useAppSelector(selectRole);
  const isAuthenticated = authenticated && userRole === "coop";
  const urlParams = useLocation().search;
  const contentText = staticContent(locale, authenticated);
  const category = new URLSearchParams(urlParams).get("category");
  const measureId = new URLSearchParams(urlParams).get("measureId");
  const peerGroupId = new URLSearchParams(urlParams).get("peerGroupId");
  const [categories, setCategories] = useState([]);
  const [measures, setMeasures] = useState<string[]>([]);
  const [peerGroups, setPeerGroups] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<{ id: string; name: string }>({
    id: "",
    name: "",
  });
  const [selectedMeasure, setSelectedMeasure] = useState<{ id: string; name: string }>({
    id: "",
    name: "",
  });
  const [peerGroupSelected, setPeerGroupSelected] = useState<{ id: number; name: string }>(
    peerGroupPersisted,
  );
  const [pageData, setPageData] = useState(props.content);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [initLoaded, setInitLoaded] = useState(false);

  useEffect(() => {
    setPageData(props.content);
  }, [props.content]);

  //   useEffect(() => {
  //     pageInit();
  //   }, []);

  //   useEffect(() => {
  //     if (initLoaded && isTruthy(selectedCategory.id)) {
  //       fetchMeasures(selectedCategory!);
  //     }
  //   }, [selectedCategory]);

  //   useEffect(() => {
  //     if (
  //       initLoaded &&
  //       isTruthy(selectedCategory.id) &&
  //       isTruthy(selectedMeasure.id) &&
  //       isTruthy(peerGroupSelected.id)
  //     ) {
  //       fetchPageData(selectedMeasure, peerGroupSelected);
  //     } else {
  //       // navigate(-1);
  //     }
  //   }, [selectedMeasure, peerGroupSelected]);

  const fetchPageData = async (
    selectedMeasure: { id: string; name: string },
    selectedPeerGroupId: { id: number; name: string },
  ) => {
    try {
      setLoading(true);
      const data = isAuthenticated
        ? await retrieveAfterLoginMeasureDetails(
            locale,
            selectedMeasure.id,
            selectedPeerGroupId.id,
            selectedCategory.name,
          )
        : await retrieveMeasureDetails(
            locale,
            selectedMeasure.id,
            selectedPeerGroupId.id,
            selectedCategory.name,
          );
      setPageData(data);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const pageInit = async () => {
    try {
      setLoading(true);
      const [cat, groups, pageData] = await Promise.all([
        retrieveCategories(locale),
        isAuthenticated ? retrieveAfterLoginPeerGroups() : retrievePeerGroups(),
        isAuthenticated
          ? retrieveAfterLoginMeasureDetails(locale, measureId!, parseInt(peerGroupId!), category!)
          : retrieveMeasureDetails(locale, measureId!, parseInt(peerGroupId!), category!),
      ]);
      const categoryIndex = cat.findIndex(
        (c: { id: string; name: string }) => c.name === decodeURI(category!),
      );
      setCategories(cat);
      setSelectedCategory(cat[categoryIndex]);
      const apiMeasure = await retrieveMeasures(cat[categoryIndex].id, locale);
      setMeasures(apiMeasure);
      const measureIndex = apiMeasure.findIndex(
        (mes: any) => mes.id === parseInt(measureId ?? "0"),
      );
      setSelectedMeasure({
        id: apiMeasure[measureIndex].id,
        name: apiMeasure[measureIndex].name,
      });
      setPeerGroups(groups);
      const selectedGroup = groups.findIndex(
        (group: any) => group.id === parseInt(peerGroupId ?? "0"),
      );
      setPeerGroupSelected({ id: groups[selectedGroup].id, name: groups[selectedGroup].name });
      dispatch(savePeerGroup({ id: groups[selectedGroup].id, name: groups[selectedGroup].name }));
      setPageData(pageData);
      setInitLoaded(true);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchMeasures = async (selectedCategory: { id: string; name: string }) => {
    try {
      setLoading(true);
      const measure = await retrieveMeasures(selectedCategory.id, locale);
      setMeasures(measure);
      if (isTruthy(measure)) {
        setSelectedMeasure({
          id: measure[0].id,
          name: measure[0].name,
        });
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const getCategoryDropDown = () => {
    return (
      <Select
        value={JSON.stringify(selectedCategory)}
        name="hyperlink"
        id="hyperlink"
        sx={classes.categoryDropdown}
        onChange={(event: any) => {
          setSelectedCategory(JSON.parse(event.target.value));
        }}
      >
        {categories.map((category: { id: string; name: string }) => (
          <MenuItem value={JSON.stringify(category)}>{category.name}</MenuItem>
        ))}
      </Select>
    );
  };

  const getMeasuresDropDown = () => {
    return (
      <Select
        value={JSON.stringify(selectedMeasure)}
        name="hyperlink"
        id="hyperlink"
        sx={classes.titleDropdown}
        onChange={(event: any) => setSelectedMeasure(JSON.parse(event.target.value))}
      >
        {measures.map((measure: any) => (
          <MenuItem value={JSON.stringify({ id: measure.id, name: measure.name })}>
            {measure.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const getPeerGroupsDropDown = () => {
    return (
      <Select
        value={JSON.stringify(peerGroupSelected)}
        name="hyperlink"
        id="hyperlink"
        sx={classes.peerGroupDropdown}
        onChange={(event: any) => {
          const selected = JSON.parse(event.target.value);
          setPeerGroupSelected(selected);
          dispatch(savePeerGroup(selected));
        }}
      >
        {peerGroups.map((group: any) => (
          <MenuItem value={JSON.stringify({ id: group.id, name: group.name })}>
            {group.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const getRelatedPractices = () => {
    return (
      <Box>
        <Typography sx={classes.blackHeadings} mb={3}>
          {locale.toLowerCase() === "en" ? "Related Good Practices" : "bonnes pratiques associées"}
        </Typography>
        {pageData?.goodPractices?.map((practice: any) => {
          return (
            <List
              dense
              component={"ul"}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                "&.MuiList-root": { padding: 0 },
                fontWeight: "bold",
                color: "#4E7AEC",
              }}
              onClick={() => window.open(practice.link, "_blank")}
            >
              <FiberManualRecordIcon
                fontSize="small"
                sx={{ width: 10, paddingTop: "10px" }}
                color="primary"
              />

              <ListItem sx={{ m: 0, p: 0, pl: 1 }}>
                <Typography variant="subtitle1" sx={classes.bullets}>
                  {practice.label}
                </Typography>
              </ListItem>
            </List>
          );
        })}
      </Box>
    );
  };

  const getDrawerData = () => {
    return <Box p={2}>{getRelatedPractices()}</Box>;
  };

  const getBanner = () => {
    return (
      <Grid
        container
        p={2}
        display="flex"
        justifyContent="space-evenly"
        sx={{ borderBottom: "1px dotted #00000040" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography sx={classes.blackHeadings}>
            {parse(markdownText(`Benchmark Report for XYZ Co-op`))}
          </Typography>
          <Typography sx={classes.description}>
            {parse(
              markdownText(
                `The benchmark report for this measure lets you compare our sample co-op to other co-ops in predefined peer groups. These results are for an Ontario co-op with suburban units. ${
                  !isAuthenticated
                    ? "[Log in](/login) now to see the actual results for your own co-op."
                    : ""
                }`,
              ),
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getGraph = () => {
    return (
      <>
        <Box
          sx={{
            height: "auto",
            width: "auto",
            border: "1px solid #E6E6E6",
            borderRadius: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        ></Box>
      </>
    );
  };

  const getGraphSection = () => {
    return (
      <Grid container p={2} display="flex" justifyContent="space-evenly" spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          {pageData?.content.graphDescription.map((desc, index: number) => {
            return (
              <>
                <Typography sx={classes.blackHeadings}>
                  {parse(markdownText(desc.title ?? ""))}
                </Typography>
                {index === 0 &&
                  pageData?.content?.notApplicableDescription?.map((text: string) => {
                    return (
                      <Typography sx={classes.notScoreDescription} gutterBottom>
                        {parse(markdownText(text ?? ""))}
                      </Typography>
                    );
                  })}
                {desc.description?.map((text: string) => {
                  return (
                    <Typography sx={classes.description} gutterBottom>
                      {parse(markdownText(text ?? ""))}
                    </Typography>
                  );
                })}
              </>
            );
          })}

          {!isAuthenticated && (
            <AppButton
              onClick={() => history.push(urls.loginViewPath)}
              label={"Log In"}
              withRadius
              styles={classes.btnwithnoradius}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography>{pageData.content.graphTitle}</Typography>
          {/* {getGraph()} */}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      {/* {getHeader()} */}
      <Grid container display="flex" flexDirection={isDesktop ? "row" : "column-reverse"}>
        <Grid item lg={2} xl={2} md={4} sx={{ backgroundColor: "#FFF3F2" }}>
          {getDrawerData()}
        </Grid>
        <Grid item lg={10} xl={10} md={8} p={2}>
          {getBanner()}
          {getGraphSection()}
        </Grid>
      </Grid>
      <CustomLoader isLoading={isLoading} />
    </Box>
  );
};

export default MeasuresPreview;
