import { homePageContentData } from "../../../../models/interface";

export const homeInputFields = (prevState?: homePageContentData) => {
  return {
    banner: {
      title: prevState?.banner?.title ?? "",
      description: prevState?.banner?.description ?? [""],
      button: {
        label: prevState?.banner?.button.label ?? "",
        hyperlink: prevState?.banner?.button.hyperlink ?? "",
        isExternal: prevState?.banner?.button.hyperlink ?? false,
      },
      images: prevState?.banner?.images ?? [],
      carousel_metadata: {
        interval: prevState?.banner?.carousel_metadata?.interval ?? 3,
      },
    },
    cards: prevState?.cards ?? [],
    about: {
      title: prevState?.about?.title ?? "",
      altTextForImage: prevState?.about?.altTextForImage ?? "",
      description: prevState?.about?.description ?? [""],
      button: {
        label: prevState?.about?.button.label ?? "",
        hyperlink: prevState?.about?.button.hyperlink ?? "",
        isExternal: prevState?.about?.button.hyperlink ?? false,
      },
    },
  } as homePageContentData;
};
