import { Box } from "@mui/system";
import AppFooter from "../AppFooter/AppFooter";
import AppHeader from "../AppHeader/AppHeader";
import { useState } from "react";
import LayoutStyles from "./Layout.styles";
interface CustomProps {
  children?: any;
}

const Layout = (props: CustomProps) => {
  const classes = LayoutStyles;
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <AppHeader toggleDrawer={toggleDrawer} />
      <Box>
        <Box sx={classes.main}>{props.children}</Box>
        <AppFooter />
      </Box>
    </>
  );
};

export default Layout;
