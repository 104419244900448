import {
  Box,
  Button,
  Container,
  Typography,
  Divider,
  useMediaQuery,
  Stack,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import SampleDashboardStyles from "./SampleDashboard.styles";
import { theme } from "../../utils/styles";
import { dashboardMeasuresStylesData } from "../MockData";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  selectAuthenticated,
  selectLocale,
  selectRole,
  selectedCoopName,
} from "../../redux/authSlice";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import urls from "../../global/constants/UrlConstants";
import { getContentBasedOnAuthState, markdownText } from "../../helpers/methods";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { isTruthy, openErrorNotification } from "../../helpers/methods";
import notifiers from "../../global/constants/NotificationConstants";
import { dashboardMeasures, dashboardMeasuresAfterLogin } from "./SampleDashboardService";
import CustomTabs from "./components/CustomTabs";
import {
  retrieveAfterLoginPeerGroups,
  retrievePeerGroups,
} from "../MeasuresInternalPage/MeasureService";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import { savePeerGroup, selectedPeerGroup } from "../../redux/persistSlice";
import { SampleDashboardType } from "../../models/interface";
import history from "../../utils/history";
import { staticContent } from "../../utils/locale";

interface CustomProps {
  content: SampleDashboardType;
  templateId: string;
}

const getInitialState = (locale: string) => {
  const localeMeasures = dashboardMeasuresStylesData[locale];
  return Object.keys(localeMeasures).map((measure: string) => {
    const local = localeMeasures[measure];
    return {
      icon: local.icon,
      title: local.title,
      displayTitle: local.displayTitle,
      borderColor: local.borderColor,
      backgroundColor: local.backgroundColor,
      measure: [] as {
        title: string;
        score: number;
        measureId: string;
        peerGroupId: string;
        scoreNotAvailable: boolean;
      }[],
    };
  });
};

const SampleDashboard = (props: CustomProps) => {
  const classes = SampleDashboardStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const authenticated = useAppSelector(selectAuthenticated);
  const coopName = useAppSelector(selectedCoopName);
  const peerGroupPersisted = useAppSelector(selectedPeerGroup);
  const userRole = useAppSelector(selectRole);
  const locale = useAppSelector(selectLocale);
  const isAuthenticated = authenticated && userRole === "coop";
  const [peerGroups, setPeerGroups] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const contentText = staticContent(locale, isAuthenticated);
  const [measures, setMeasures] = useState<
    {
      icon: string;
      title: string;
      displayTitle: string;
      borderColor: string;
      backgroundColor: string;
      measure: {
        title: string;
        score: number;
        measureId: string;
        peerGroupId: string;
        scoreNotAvailable: boolean;
      }[];
    }[]
  >(getInitialState(locale));

  useEffect(() => {
    pageInit();
  }, []);

  useEffect(() => {
    getDashboardMeasures();
  }, [peerGroupPersisted]);

  const getDashboardMeasures = async () => {
    try {
      setLoading(true);
      const response = isAuthenticated
        ? await dashboardMeasuresAfterLogin(locale, peerGroupPersisted.id)
        : await dashboardMeasures(locale, peerGroupPersisted.id);
      const convertedState = convertResponseToState(response);
      setMeasures(convertedState);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const pageInit = async () => {
    try {
      setLoading(true);
      const groups: any = isAuthenticated
        ? await retrieveAfterLoginPeerGroups()
        : await retrievePeerGroups();
      setPeerGroups(groups);
      // const selectedGroup = groups.findIndex((group: any) => group.id === parseInt("1"));
      // dispatch(savePeerGroup({ id: groups[selectedGroup].id, name: groups[selectedGroup].name }));
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const convertResponseToState = (response: any) => {
    const localeMeasures = dashboardMeasuresStylesData[locale];
    return Object.keys(localeMeasures).map((measure: string) => {
      const local = localeMeasures[measure];
      const responseMeasureData = response[local.title];
      return {
        icon: local.icon,
        title: local.title,
        displayTitle: local.displayTitle,
        borderColor: local.borderColor,
        backgroundColor: local.backgroundColor,
        measure: responseMeasureData?.map((mes: any) => {
          return {
            title: mes.measure,
            score: mes.score,
            measureId: mes.measureId,
            peerGroupId: mes.peerGroupId,
            scoreNotAvailable: mes.scoreNotAvailable,
          };
        }),
      };
    });
  };

  const getHouseIcon = (fill?: boolean) => {
    return (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 19"
        fill={fill ? "#F3776C" : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9211 1.08264L10.9211 1.08266L19.305 7.60358C19.3051 7.60361 19.3051 7.60364 19.3051 7.60366C19.6811 7.89637 19.4746 8.49999 18.9971 8.49999H18.0001H17.5001V8.99999V17C17.5001 17.3978 17.3421 17.7793 17.0608 18.0606C16.7795 18.342 16.3979 18.5 16.0001 18.5H12.5001V13C12.5001 12.3369 12.2367 11.7011 11.7679 11.2322C11.299 10.7634 10.6631 10.5 10.0001 10.5C9.33706 10.5 8.70118 10.7634 8.23234 11.2322C7.7635 11.7011 7.5001 12.3369 7.5001 13V18.5H4.0001C3.60228 18.5 3.22075 18.342 2.93944 18.0606C2.65814 17.7793 2.5001 17.3978 2.5001 17V8.99999V8.49999H2.0001H1.0031C0.527211 8.49999 0.317726 7.89758 0.695498 7.60234C0.695586 7.60227 0.695675 7.6022 0.695763 7.60213L9.07905 1.08269L9.0791 1.08264C9.34242 0.877811 9.6665 0.766602 10.0001 0.766602C10.3337 0.766602 10.6578 0.877811 10.9211 1.08264Z"
          stroke="#F3776C"
        />
      </svg>
    );
  };

  const showHousesWithFill = (numberOfFilledHouses: number, numberOfHousesToShow: number) => {
    return (
      <Stack direction="row">
        {Array(numberOfHousesToShow)
          .fill(1)
          .map((score, index: number) => getHouseIcon(index + 1 <= numberOfFilledHouses))}
      </Stack>
    );
  };

  const getScoreSystemTable = () => {
    const scoreRating = getContentBasedOnAuthState(
      isAuthenticated,
      props.content.scoreSection,
      "scoreScale",
    );
    return (
      <Box>
        {scoreRating.map((row: any) => {
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                borderBottom: "1px solid #E8E8E8",
                display: "flex",
                alignItems: "center",
                mb: 0.5,
                pb: 0.5,
              }}
            >
              {showHousesWithFill(row.score, row.score)}
              <Typography variant="subtitle2" sx={classes.bodyContent}>
                {row.title}
              </Typography>
            </Stack>
          );
        })}
      </Box>
    );
  };

  const getPeerGroupsDropDown = () => {
    return (
      <Select
        value={JSON.stringify(peerGroupPersisted)}
        name="hyperlink"
        id="hyperlink"
        sx={classes.peerGroupDropdown}
        onChange={(event: any) => {
          const selected = JSON.parse(event.target.value);
          dispatch(savePeerGroup(selected));
        }}
        MenuProps={{
          disableScrollLock: true,
        }}
        inputProps={{ "aria-label": "Without label" }}
      >
        {peerGroups.map((group: any, index: number) => (
          <MenuItem value={JSON.stringify({ id: group.id, name: group.name })}>
            {locale === "en"
              ? group.name
              : contentText.sampleDashboard.comparison.peerGroups[index] ?? group.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const getDetailsSection = () => {
    return (
      <Box sx={classes.pageBody}>
        <Box sx={classes.bodyContent}>
          <Box display="flex" width={{ xs: "100%", md: "45%" }} flexDirection="column">
            <Typography variant="h5" fontSize={24}>
              {getContentBasedOnAuthState(isAuthenticated, props.content.scoreSection, "title")}
            </Typography>
            {getContentBasedOnAuthState(
              isAuthenticated,
              props.content.scoreSection,
              "subtitle",
            ).map((text: string) => {
              return (
                <Typography fontSize={18} gutterBottom>
                  {text}
                </Typography>
              );
            })}
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                <Stack direction="column">
                  <ul style={{ fontStyle: "normal" }}>
                    {getContentBasedOnAuthState(
                      isAuthenticated,
                      props.content.scoreSection,
                      "points",
                    ).map((data: string) => {
                      return (
                        <li style={{ marginBottom: 5 }}>
                          <Typography fontSize={18}>{data}</Typography>
                        </li>
                      );
                    })}
                  </ul>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <Box>{getScoreSystemTable()}</Box>
              </Grid>
            </Grid>
            {getContentBasedOnAuthState(
              isAuthenticated,
              props.content.scoreSection,
              "description",
            ).map((text: string) => {
              return <Typography fontSize={18}>{text}</Typography>;
            })}
          </Box>
          {!isMobile && <Divider flexItem orientation="vertical" />}
          <Box
            display="flex"
            width={{ xs: "100%", md: "45%" }}
            flexDirection="column"
            gap={1}
            alignItems="flex-start"
          >
            <Typography variant="h5" fontSize={24}>
              {getContentBasedOnAuthState(isAuthenticated, props.content.note, "title")}
            </Typography>
            {getContentBasedOnAuthState(isAuthenticated, props.content.note, "description").map(
              (text: string) => {
                return <Typography fontSize={18}>{parse(markdownText(text))}</Typography>;
              },
            )}
            <Box
              component="ul"
              display={"flex"}
              gap={isDesktop ? 4 : 1}
              flexDirection={isDesktop ? "row" : "column"}
            >
              {getContentBasedOnAuthState(
                isAuthenticated,
                props.content.note,
                "horizontalList",
              ).map((data: string) => {
                return (
                  <li>
                    <Typography fontSize={18}>{data}</Typography>
                  </li>
                );
              })}
            </Box>
            <Typography fontSize={18}>
              {getContentBasedOnAuthState(isAuthenticated, props.content.note, "helper")}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const getHeader = () => {
    return (
      <Box sx={classes.headWrapper}>
        <Container maxWidth="xl" sx={classes.flexHead}>
          <Typography variant="h5" gutterBottom>
            {isAuthenticated ? coopName : contentText.sampleDashboard.heading}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{contentText.sampleDashboard.comparison.comparedToText}</Typography>
            {getPeerGroupsDropDown()}
            {isAuthenticated ? (
              <>
                <Button
                  sx={classes.button}
                  onClick={() => history.push(urls.listofpeergrpsViewPath)}
                >
                  <FormatListBulletedIcon sx={{ mr: { xl: 1 } }} />
                  {locale === "en" ? "Peer Groups" : "Groupe de paires"}
                </Button>
                <Button
                  sx={classes.addpeergrp}
                  onClick={() => history.push(urls.addPeerGroupsViewPath)}
                >
                  {locale === "en" ? "Add Peer Group" : "Ajouter un groupe de paires"}
                </Button>
              </>
            ) : (
              <Typography>
                {locale === "en"
                  ? "You haven't logged in yet"
                  : "Vous n’avez pas encore ouvert de session"}
              </Typography>
            )}
          </Stack>
        </Container>
      </Box>
    );
  };

  return (
    <Box>
      {getHeader()}
      <Container maxWidth="xl" sx={classes.pageBodyWrapper}>
        <Box sx={{ pt: isDesktop ? "100px" : "150px" }}>
          <Container maxWidth="lg">
            <Box mb={2} mt={{ sm: 2, xs: 2 }} textAlign="center">
              {getContentBasedOnAuthState(
                isAuthenticated,
                props.content.dashboardDescription,
                "description",
              ).map((text: string) => {
                return <Typography sx={classes.hightlightedText}>{text}</Typography>;
              })}
            </Box>
          </Container>
          <CustomTabs data={measures} />
        </Box>
        {getDetailsSection()}
      </Container>
      <CustomLoader isLoading={loading} />
    </Box>
  );
};

export default SampleDashboard;
