import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import InputBase from "@mui/material/InputBase";
import Radio, { RadioProps } from "@mui/material/Radio";
import { styled } from "@mui/system";
import { List } from "@mui/material";
import Switch from "@mui/material/Switch";
import { CSSProperties } from "@mui/material/styles/createTypography";
import { createTheme } from "@mui/material/styles";
const borderRadius = "30px";

const primaryColor = "#C20C85";
const primaryBackgroundColor = "#1A0224";
const borderColor = "rgba(0, 0, 0, 0.12)";
const borderStyle = "1px solid " + borderColor;
const infoTextColor = "#888888";
const disabledBackgroundColor = "#888888";
const defaultFontSize = 14;
const primaryColorBlack = "#202730";
const defaultBoxShadow = "0 0 0 0.2rem rgb(0 123 255 / 25%)";
const drawerWidth = 300;
const paragraphColor = "#858585";

const lightGreyBg = "#F6F7F9";

const mainContainer: CSSProperties = {
  margin: "20px",
};

const headingFont: CSSProperties = {
  fontFamily: "Domine-Regular",
  fontWeight: 700,
  fontSize: "4.375rem",
  letterSpacing: "0.005em",
  lineHeight: 1.15,
};
const mediumFont: CSSProperties = {
  fontFamily: "Domine-Regular",
  fontWeight: 700,
};

const mediumFontTitle: CSSProperties = {
  fontFamily: "Source Serif Pro",
  fontWeight: 600,
};

const regularFont: CSSProperties = {
  fontFamily: "SourceSans3-Regular",
  fontWeight: 400,
};

const interFont: CSSProperties = {
  fontFamily: "SourceSans3-Regular",
  fontWeight: 600,
};

const descriptionFont: CSSProperties = {
  fontFamily: "`AvenirLTStd-Light",
  fontWeight: 400,
};

const avenirMediumFont: CSSProperties = {
  fontFamily: "`AvenirLTStd-Medium",
  fontWeight: 400,
};

const theme = createTheme({
  typography: {
    subtitle1: {
      fontFamily: `AvenirLTStd-Light`,
      textTransform: "unset",
      fontSize: 16 + 2,
    },
    body1: {
      fontFamily: `AvenirLTStd-Light`,
      textTransform: "unset",
      fontSize: 16 + 2,
    },
    button: {
      fontFamily: `AvenirLTStd-Medium`,
      textTransform: "unset",
      fontSize: 16 + 2,
    },
    h4: {
      ...mediumFontTitle,
      textTransform: "unset",
      fontSize: 34 + 2,
    },
    h5: {
      fontFamily: `Source Serif Pro`,
      textTransform: "unset",
      fontSize: 22 + 2,
    },
    h6: {
      fontFamily: `AvenirLTStd-Medium`,
      textTransform: "unset",
      fontSize: 18 + 2,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#423F6F",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#E7E7FF",
      main: "#423F6F",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#F3776C",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  spacing: 10,
});

const getRelativeFontSize = (value: number = 0) => {
  let size = defaultFontSize + value;
  return size + "px";
};

const customButtonStyle: CSSProperties = {
  borderRadius: "15px",
  border: "none",
  fontSize: getRelativeFontSize(),
  textAlign: "center",
  backgroundColor: "#EFA185",
  padding: "10px 15px",
  boxShadow: " 4px 4px 30px rgba(0, 0, 0, 0.03)",
  color: "black",
  cursor: "pointer",
  textTransform: "none",
};

const customTextFieldStyle: CSSProperties = {
  borderRadius: borderRadius,
  position: "relative",
  border: "none",
  fontSize: getRelativeFontSize(2),
  backgroundColor: primaryBackgroundColor,
  padding: "10px 15px",
  boxShadow: "none",
  width: "100%",
};

const headingText: CSSProperties = {
  display: "inline-block",
  fontSize: getRelativeFontSize(8),
};

const centerItemFlex: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const spaceBetweenItemFlex: CSSProperties = {
  display: "flex",
  alignItems: "start",
  justifyContent: "space-between",
};

const centerItemAbsolute: CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const StyledList = styled(List)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "red",
  },
});

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  backgroundColor: theme.palette.common.white,
  border: "1px solid #ced4da",
  borderRadius: borderRadius,
  padding: "0 5px",
  position: "relative",
  fontSize: getRelativeFontSize(),
  width: "100%",
  input: {
    padding: "10px 12px",
  },
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
  switchBase: {
    color: "grey",
    "&$checked": {
      color: primaryColor,
      "& + $track": {
        backgroundColor: primaryColor,
      },
    },
    "&$checked + $track": {
      color: primaryColor,
    },
  },
  track: { backgroundColor: "grey" },
  checked: {},
}));

const CustomCheckbox = styled((props: CheckboxProps) => <Checkbox color="default" {...props} />)(
  ({ theme }) => ({
    root: {
      color: primaryColor,
      "&$checked": {
        color: primaryColor,
      },
    },
    checked: {},
  }),
);

const CustomRadio = styled((props: RadioProps) => <Radio color="default" {...props} />)(
  ({ theme }) => ({
    root: {
      color: primaryColor,
      "&$checked": {
        color: primaryColor,
      },
    },
    checked: {},
  }),
);

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(4),
  },
  backgroundColor: theme.palette.common.white,
  borderRadius: "15px",
  padding: "5px 5px",
  width: "300px",
  input: {
    position: "relative",
    fontSize: "inherit",
    width: "100%",
    padding: "10px 12px",
  },
}));

export {
  borderRadius,
  primaryColor,
  disabledBackgroundColor,
  primaryBackgroundColor,
  borderColor,
  borderStyle,
  infoTextColor,
  defaultBoxShadow,
  customButtonStyle,
  customTextFieldStyle,
  headingText,
  centerItemFlex,
  spaceBetweenItemFlex,
  centerItemAbsolute,
  CustomInput,
  CustomSwitch,
  CustomCheckbox,
  CustomRadio,
  headingFont,
  descriptionFont,
  regularFont,
  mediumFont,
  mediumFontTitle,
  getRelativeFontSize,
  theme,
  StyledList,
  mainContainer,
  drawerWidth,
  primaryColorBlack,
  paragraphColor,
  lightGreyBg,
};
