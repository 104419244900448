import coopsizeImg from "../../../assets/images/Dashboard/coopsize.png";
import building from "../../../assets/images/Dashboard/building.png";
import management from "../../../assets/images/Dashboard/management.png";
import { store } from "../../../utils/store";
import { staticContent } from "../../../utils/locale";

export const PeerGroupData = () => {
  const locale = store.getState().auth.locale;
  const isAuthenticated = store.getState().auth.authenticated;
  const contentText = staticContent(locale, isAuthenticated);
  return [
    {
      icon: coopsizeImg,
      title: contentText.peerGroupForm.coopSize.size.heading,
      stateType: "units",
      values: [
        { label: contentText.peerGroupForm.coopSize.size.values[0], value: "0" },
        { label: contentText.peerGroupForm.coopSize.size.values[1], value: "36" },
        { label: contentText.peerGroupForm.coopSize.size.values[2], value: "81" },
      ],
    },
    {
      icon: building,
      title: contentText.peerGroupForm.coopSize.buildingType.heading,
      stateType: "type",
      values: [
        { label: contentText.peerGroupForm.coopSize.buildingType.values[0], value: "WU" },
        { label: contentText.peerGroupForm.coopSize.buildingType.values[1], value: "El" },
        { label: contentText.peerGroupForm.coopSize.buildingType.values[2], value: "TH" },
        { label: contentText.peerGroupForm.coopSize.buildingType.values[3], value: "Single" },
      ],
    },
    {
      icon: management,
      title: contentText.peerGroupForm.coopSize.mgmtType.heading,
      stateType: "mgmt",
      values: [
        { label: contentText.peerGroupForm.coopSize.mgmtType.values[0], value: "MgmtCo" },
        { label: contentText.peerGroupForm.coopSize.mgmtType.values[1], value: "Staff" },
        { label: contentText.peerGroupForm.coopSize.mgmtType.values[2], value: "Bookkeeper" },
        { label: contentText.peerGroupForm.coopSize.mgmtType.values[3], value: "Volunteers" },
        { label: contentText.peerGroupForm.coopSize.mgmtType.values[4], value: "Other" },
      ],
    },
  ];
};

export const Province = () => {
  const locale = store.getState().auth.locale;
  const isAuthenticated = store.getState().auth.authenticated;
  const contentText = staticContent(locale, isAuthenticated);
  return {
    name: contentText.peerGroupForm.location.heading,
    stateType: "locationprovince",
    values: [
      { label: contentText.peerGroupForm.location.province.values[0], value: "BC" },
      { label: contentText.peerGroupForm.location.province.values[1], value: "AB" },
      { label: contentText.peerGroupForm.location.province.values[2], value: "ON" },
      { label: contentText.peerGroupForm.location.province.values[3], value: "PE" },
    ],
    validationText: contentText.peerGroupForm.location.province.validationText,
  };
};

export const City = () => {
  const locale = store.getState().auth.locale;
  const isAuthenticated = store.getState().auth.authenticated;
  const contentText = staticContent(locale, isAuthenticated);
  return {
    name: contentText.peerGroupForm.location.city.heading,
    stateType: "locationcity",
    values: contentText.peerGroupForm.location.city.values,
    validationText: contentText.peerGroupForm.location.city.validationText,
  };
};

export const predefinedData = () => {
  const locale = store.getState().auth.locale;
  const isAuthenticated = store.getState().auth.authenticated;
  const city = store.getState().auth.city;
  const province = store.getState().auth.province;
  const contentText = staticContent(locale, isAuthenticated);
  return [
    {
      heading: contentText.peerGroup.predefined.values.all,
      characteristics: [
        {
          label: contentText.peerGroupForm.coopSize.size.heading,
          value: contentText.peerGroup.filters.units,
        },
        {
          label: contentText.peerGroupForm.coopSize.buildingType.heading,
          value: contentText.peerGroup.filters.type,
        },
        {
          label: contentText.peerGroupForm.coopSize.mgmtType.heading,
          value: contentText.peerGroup.filters.mgmt,
        },
        {
          label: contentText.peerGroupForm.location.heading,
          value: contentText.peerGroup.filters.location,
        },
      ],
      comment:
        locale === "en"
          ? "Used as the default peer group for a measure when no other peer group has been selected."
          : "Sert de groupe de pairs par défaut aux fins de mesure lorsqu’aucun autre groupe de pairs n’a été sélectionné.",
    },
    {
      heading: contentText.peerGroup.predefined.values.province,
      characteristics: [
        {
          label: contentText.peerGroupForm.coopSize.size.heading,
          value: contentText.peerGroup.filters.units,
        },
        {
          label: contentText.peerGroupForm.coopSize.buildingType.heading,
          value: contentText.peerGroup.filters.type,
        },
        {
          label: contentText.peerGroupForm.coopSize.mgmtType.heading,
          value: contentText.peerGroup.filters.mgmt,
        },
        {
          label: contentText.peerGroupForm.location.heading,
          value: province,
        },
      ],
    },
    {
      heading: contentText.peerGroup.predefined.values.city,
      characteristics: [
        {
          label: contentText.peerGroupForm.coopSize.size.heading,
          value: contentText.peerGroup.filters.units,
        },
        {
          label: contentText.peerGroupForm.coopSize.buildingType.heading,
          value: contentText.peerGroup.filters.type,
        },
        {
          label: contentText.peerGroupForm.coopSize.mgmtType.heading,
          value: contentText.peerGroup.filters.mgmt,
        },
        {
          label: contentText.peerGroupForm.location.heading,
          value: city,
        },
      ],
    },
  ];
};
