import { Box } from "@mui/system";
import LayoutStyles from "./AdminPanelLayout.styles";
import { AppBar, Container, Stack, Toolbar, Typography, useMediaQuery } from "@mui/material";
import AppButton from "../../../global/components/AppButton/AppButton";
import urls from "../../../global/constants/UrlConstants";
import { theme } from "../../../utils/styles";
import HomeRunLogo from "../../../assets/images/logos/logo_dark_en.svg";
import HomeRunLogoFr from "../../../assets/images/logos/logo_dark_fr.svg";
import { logoutAction, selectLocale } from "../../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import strings from "../../../global/constants/StringConstants";
import history from "../../../utils/history";

interface CustomProps {
  children?: any;
}

const AdminPanelLayout = (props: CustomProps) => {
  const classes = LayoutStyles;
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const locale = useAppSelector(selectLocale);

  const handleLogout = () => {
    dispatch(logoutAction());
    history.push(urls.homeViewPath);
  };

  const getAppHeader = () => {
    return (
      <>
        <AppBar position="sticky" sx={classes.appBar}>
          <Toolbar sx={classes.menuToolbar}>
            <Container maxWidth="xl">
              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <img
                  src={locale === strings.EN ? HomeRunLogo : HomeRunLogoFr}
                  alt="HomeRun"
                  style={{
                    height: isDesktop ? "80px" : "30px",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => history.push(urls.homeViewPath)}
                />
                <AppButton
                  label={<Typography fontWeight={700}>Logout</Typography>}
                  withRadius
                  variant="outlined"
                  styles={{ textTransform: "capitalize" }}
                  onClick={() => handleLogout()}
                />
              </Stack>
            </Container>
          </Toolbar>
        </AppBar>
      </>
    );
  };

  return (
    <>
      {getAppHeader()}
      <Box sx={classes.main}>{props.children}</Box>
    </>
  );
};

export default AdminPanelLayout;
