import { centerItemFlex, getRelativeFontSize, theme } from "../../../utils/styles";

const LayoutStyles = {
  main: {
    [theme.breakpoints.up("md")]: {},
  },
  appBar: {
    boxShadow: "0 0 2px #ccc",
    backgroundColor: "white",
    color: "black",
    wordSpacing: 1,
  },
  menuToolbar: {
    backgroundColor: "#FFFFFF",
    color: "#000",
    ...centerItemFlex,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up("md")]: {
      // height: "112px",
      padding: 1,
    },

    [theme.breakpoints.up("sm")]: {
      // height: "60px",
    },
    [theme.breakpoints.down("md")]: {
      // height: "30px",
      padding: 1.5,
    },
  },
  navMenuMain: {
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    [theme.breakpoints.down("md")]: {
      ...centerItemFlex,
      flexDirection: "column",
    },
  },
  selected: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      borderBottom: 0,
    },
  },
  navBtn: {
    width: "auto",
    color: "#212121",
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      mb: 2,
      "&:hover": { borderBottom: 0 },
    },
  },
  Button: {
    fontSize: getRelativeFontSize(1),
    backgroundColor: "#1B1028",
    textTransform: "unset",
    color: "#fff",
    height: "45px",
    width: "auto",
    borderRadius: "12px",
    letterSpacing: "1px",
    boxShadow: "none !important",
    "&:hover": {
      backgroundColor: "#1B1028",
      color: "#F5F5F5",
    },
    [theme.breakpoints.down("sm")]: {
      height: "50px",
    },
  },
  menuMobile: {
    width: "30vw",
  },
} as const;

export default LayoutStyles;
