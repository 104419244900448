import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui-compat";
// @mui/styles
const closeButtonStyles = makeStyles()((theme: Theme) => ({
  closeBtn: {
    position: "fixed",
    top: "10px",
    right: "10px",
    borderRadius: "50%",
    padding: "3px",
    color: "white",
  },
}));

export default closeButtonStyles;
