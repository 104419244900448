import urls from "../../global/constants/UrlConstants";
import { getNoAuthCallParams, makeCall } from "../../utils/service";

export async function login(email: string, password: string, adminLogin: boolean, locale: string) {
  var body = {
    userName: email,
    pwd: password,
  };
  try {
    const url = adminLogin ? `${urls.ADMINLOGIN}/lang/${locale}` : `${urls.LOGIN}/lang/${locale}`;
    const callParams = getNoAuthCallParams("POST", body);
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function fetchPageContent(locale: string) {
  try {
    const url = urls.LOGINPAGECONTENT + "/" + locale;
    const callParams = getNoAuthCallParams("GET");
    const response: any = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}
