import * as React from "react";
import DraggableListItem from "./DraggableListItem";
import { DragDropContext, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import { Box } from "@mui/material";
import { StrictModeDroppable } from "./StrictModeDroppable";

interface CustomProps {
  items: any;
  onDragEnd: OnDragEndResponder;
  isDragDisabled?: boolean;
}

const DraggableList = (props: CustomProps) => {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <StrictModeDroppable droppableId="droppable-list">
        {(provided: any) => {
          return (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {props.items.map((item: any, index: number) => (
                <DraggableListItem item={item} index={index} key={item.id} isDragDisabled={false} />
              ))}
              {provided.placeholder}
            </Box>
          );
        }}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

export default DraggableList;
