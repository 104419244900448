export class StringConstants {
  PROD = false;

  //contentTypes
  applicationJSON = { "Content-Type": "application/json" };
  multipartForm = {};

  notification = "notification";
  error = "error";
  success = "success";
  successF = "Création réussie";
  warning = "warning";
  info = "info";
  autoHideDuration = 600 * 1000; //in milliseconds

  //date
  am = "AM";
  pm = "PM";

  //locales
  EN = "en";
  FR = "fr";
  //images accepted
  jpeg = ".jpeg";
  jpg = ".jpg";
  png = ".png";

  acceptedFiles = ".jpeg,.jpg,.png,.mp4";
  alignments = ["left", "center", "right"];

  SEARCH_TIME_OUT = 350;

  LOGIN = "login";
  REGISTER = "register";
  FORGOTPASSWORD = "forgot-password";
  CHANGE_PASSWORD = "user";
  BENCHMARKING = "Benchmarking";
  CONTACT = "Contact Us";
  SAMPLE_DASHBOARD = "Sample Dashboard";
  LIST_OF_PEER_GROUPS = "List of Peer Groups";
  ADD_PEER_GROUP = "Add Peer Group";
  EDIT_PEER_GROUP = "Edit Peer Group";
  GOOD_PRACTICES = "Good Practices";
  ADMIN_PANEL = "Admin Panel";
  ADMIN_PANEL_HOME = "Admin home";
  ADMIN_PAGE_RENDER = "Admin Panel Page Renderer";
  ADMIN_ADD_PAGE = "Add Page";
  ADMIN_ADD_NEW_PAGE = "Add new Page";
  EDIT_MEASURES_PAGE = "Edit Measures Info";

  //Email validation Regex
  regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,100}$/;
  youtubeVideoIdRegex =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  vowelRegex = "^[aieouAIEOU].*";

  // labels
  submit = "Submit";
  delete = "Delete";
  edit = "Edit";
  cancel = "Cancel";
  add = "Add";
  TARGET_HIT = "TARGET_HIT";
  TARGET_HIT_LABEL = "Target Hit";
  SL_HIT = "SL_HIT";
  SL_HIT_LABEL = "SL Hit";
  LONG = "LONG";
  SHORT = "SHORT";

  //PAGES

  HOME = "Home";
  DASHBOARD = "Dashboard";
  TRADE = "trade";
  ESH = "esh";
  TRADES = "trades";
  ADMIN = "Admin";
  REPORTS = "Reports";

  POINT_TO_REVENUE = 50;

  // Errors & Dates
  INVALID_DATE = "Invalid date";
  startDate = "startDate";
  endDate = "endDate";
  fromDate = "fromDate";
  toDate = "toDate";

  Description = "description";
  Paragraph = "paragraph";
  Bullet = "bullet";
  bulledtedPara = "bullets";

  benchmarking = "What is Benchmarking?";
  goodPractice = "What are Good Practices?";
  dashboard = "What is Dashboard?";

  FILE_LIMIT = 5; // 5MB
}

let strings = new StringConstants();
export default strings;
