import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import AppButton from "../../../../../global/components/AppButton/AppButton";
import CustomDialog from "../../../../../global/components/CustomDialog/CustomDialog";
import SectionModalStyles from "./SectionModal.styles";
import CustomInput from "../../../../../global/components/CustomInput/CustomInput";
import { useEffect, useState } from "react";
import { CustomRadio } from "../../../../../utils/styles";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const types = ["text", "table", "image"];

interface CustomProps {
  setOpenModal: Function;
  openModal: boolean;
  handleAddSection: Function;
  handleEditSection: Function;
  editMode: boolean;
  section: {
    id: string;
    name: string;
    columns: number;
    content: any[];
  };
}

const SectionModal = (props: CustomProps) => {
  const classes = SectionModalStyles;
  const [sectionMetaData, setSectionMetaData] = useState<{
    columns: number;
    id: string;
    name: string;
    content: { type: string }[];
  }>({
    name: props.editMode ? props.section.name : "",
    id: props.editMode ? props.section.id : uuidv4(),
    columns: props.editMode ? props.section.columns : 1,
    content: props.editMode
      ? props.section.content
      : [
          {
            type: "",
          },
        ],
  });

  useEffect(() => {
    setSectionMetaData({
      ...sectionMetaData,
      name: props.section.name,
      id: props.section.id,
      columns: props.section.columns,
      content: props.section.content,
    });
  }, [props.editMode]);

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleCloseModel = () => {
    setSectionMetaData({
      columns: 1,
      name: "",
      id: "",
      content: [
        {
          type: "",
        },
      ],
    });
    props.setOpenModal({
      visible: false,
      mode: "add",
      section: {
        id: "",
        name: "",
        columns: 1,
        content: [
          {
            type: "",
          },
        ],
      },
    });
  };

  const handleAddSection = () => {
    props.handleAddSection(sectionMetaData);
    setSectionMetaData({
      columns: 1,
      name: "",
      id: "",
      content: [
        {
          type: "",
        },
      ],
    });
    props.setOpenModal({
      visible: false,
      mode: "add",
      section: {
        id: "",
        name: "",
        columns: 1,
        content: [
          {
            type: "",
          },
        ],
      },
    });
  };

  const handleEditSection = () => {
    props.handleEditSection(sectionMetaData);
    props.setOpenModal({
      visible: false,
      mode: "add",
      section: {
        id: "",
        name: "",
        columns: 1,
        content: [
          {
            type: "",
          },
        ],
      },
    });
  };

  const dialogTitleContent = () => {
    return (
      <Box sx={classes.dialogTitleWrapper}>
        <Box sx={classes.blackHeadings}>{`${props.editMode ? "Edit" : "Add"} Section`}</Box>
      </Box>
    );
  };

  const handleColumnChange = (event: any) => {
    const local = sectionMetaData;
    if (event.target.value === "2") {
      local.content.push({
        type: "",
      });
    } else {
      local.content.splice(1, 1);
      local.content[0] = {
        type: "",
      };
    }
    setSectionMetaData({
      ...sectionMetaData,
      columns: parseInt(event.target.value),
      content: local.content,
    });
  };

  const getDropdown = (value: string, index: number) => {
    return (
      <Select
        value={value}
        name="type"
        id="type"
        onChange={(event: any) => {
          const localType = sectionMetaData.content;
          localType[index].type = event.target.value;
          setSectionMetaData({
            ...sectionMetaData,
            content: localType,
          });
        }}
        sx={{ width: "100%", height: "50px" }}
      >
        {types.map((item) => {
          return (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const handleDeleteContentType = (index: number) => {
    const local = cloneDeep(sectionMetaData);
    local.content.splice(index, 1);
    local.columns = 1;
    setSectionMetaData(local);
  };

  const dialogBody = () => (
    <Box sx={classes.description}>
      <Typography gutterBottom>Fill in details to customize your section</Typography>
      <Box mb={2}>
        <Typography sx={classes.blackHeadings}>Section Name</Typography>
        <CustomInput
          placeHolder="Section Name"
          type="text"
          name="name"
          id="name"
          value={sectionMetaData.name}
          onChange={(event: any) =>
            setSectionMetaData({
              ...sectionMetaData,
              [event.target.name]: event.target.value,
            })
          }
          onKeyPress={handleKeypress}
        />
      </Box>
      <>
        <Box>
          <Typography sx={classes.blackHeadings}>Select the number of column(s)?</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <CustomRadio
              value={1}
              size="small"
              onChange={handleColumnChange}
              checked={sectionMetaData.columns === 1}
              disabled={props.editMode}
            />
            <Typography>One column</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <CustomRadio
              value={2}
              size="small"
              onChange={handleColumnChange}
              checked={sectionMetaData.columns === 2}
              disabled={props.editMode}
            />
            <Typography>Two columns</Typography>
          </Box>
        </Box>
        {sectionMetaData.content.map((content, index) => {
          return (
            <Box>
              <Typography sx={classes.blackHeadings}>{`Type for column ${index + 1}`}</Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                {getDropdown(content.type, index)}
                {props.editMode && (
                  <DeleteForeverIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDeleteContentType(index)}
                  />
                )}
              </Stack>
            </Box>
          );
        })}
        {props.editMode && sectionMetaData.content.length === 1 && (
          <Typography sx={classes.addLinks} onClick={() => handleAddColumns()}>
            + Add New Column
          </Typography>
        )}
      </>
    </Box>
  );

  const handleAddColumns = () => {
    const local = cloneDeep(sectionMetaData);
    local.content.push({
      type: "",
    });
    local.columns = 2;
    setSectionMetaData(local);
  };

  const dialogContent = () => {
    return (
      <Box sx={classes.dialogFooter}>
        <AppButton label="Cancel" onClick={() => handleCloseModel()} />
        <AppButton
          label={props.editMode ? "Edit" : "Add"}
          onClick={() => (props.editMode ? handleEditSection() : handleAddSection())}
        />
      </Box>
    );
  };

  return (
    <CustomDialog
      isDialogOpen={props.openModal}
      closable
      closeButtonVisibility
      handleDialogClose={handleCloseModel}
      dialogTitleContent={dialogTitleContent()}
      dialogBodyContent={dialogBody()}
      dialogFooterContent={dialogContent()}
    />
  );
};

export default SectionModal;
