import {
  descriptionFont,
  getRelativeFontSize,
  mediumFontTitle,
  theme,
} from "../../../../utils/styles";
import bg from "../../../../assets/images/Admin/bg.png";

const AdminDashboardStyles = {
  blackHeadings: {
    ...mediumFontTitle,
    fontSize: getRelativeFontSize(20),
    fontweight: "bold",
  },
  pagesHeading: {
    ...theme.typography.body1,
    "&:hover": {
      color: theme.palette.secondary.light,
      cursor: "pointer",
    },
  },
  activeList: {
    color: theme.palette.secondary.light,
    fontWeight: "bold",
  },
  description: {
    ...descriptionFont,
    color: "#3E3E3F",
    fontSize: getRelativeFontSize(4),
    mb: 2,
  },
  pageBtn: {
    display: "flex",
    justifyContent: "end",
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: `${theme.palette.primary.main} ! important`,
    },
  },
  header: {
    mt: 2,
    mb: 2,
    // [theme.breakpoints.up("xl")]: {
    //   background: "url(" + bg + ")",
    // },
  },
  searchInput: {
    borderRadius: "50%",
  },
  pageBox: {
    padding: 2,
    border: "1px solid #F2F2F2",
    boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.05)",
  },
  headerBox: {
    ...theme.typography.button,
    backgroundColor: "#FFF3F2",
    color: "#F3776C",
    padding: 2,
  },
};

export default AdminDashboardStyles;
