import {
  descriptionFont,
  getRelativeFontSize,
  mediumFontTitle,
  theme,
} from "../../../../../utils/styles";
import bg from "../../../../assets/images/Admin/bg.png";

const DeleteModalStyles = {
  blackHeadings: {
    ...mediumFontTitle,
    fontSize: getRelativeFontSize(20),
    fontweight: "bold",
  },
  description: {
    ...theme.typography.body1,
    color: "#3E3E3F",
    fontSize: getRelativeFontSize(4),
    mb: 2,
  },
  pageBox: {
    padding: 2,
    border: "1px solid #F2F2F2",
    boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.05)",
  },
  headerBox: {
    ...theme.typography.h2,
    backgroundColor: "#FFF3F2",
    color: "#F3776C",
    padding: 2,
  },
  dialogTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "10px",
    textAlign: "center",
  },
  dialogFooter: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "10px",
    "& button": {
      width: "120px",
    },
  },
};

export default DeleteModalStyles;
