import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomLoader from "../../../../global/components/CustomLoader/CustomLoader";
import GlobalLayout from "../../Pages/Global/GlobalLayout";
import { v4 as uuidv4 } from "uuid";
import { InputFields } from "./SampleDashboardTypesAndValidations";
import PreviewSampleDashboard from "../../../SampleDashboard/SampleDashboard";
import CustomInput from "../../../../global/components/CustomInput/CustomInput";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { cloneDeep } from "lodash";
import { isTruthy } from "../../../../helpers/methods";
import sampleDashboardStyles from "./SampleDashboard.styles";

interface CustomProps {
  content: any;
  updatePageContent: Function;
  templateId: string;
}

const SampleDashboard = (props: CustomProps) => {
  const classes = sampleDashboardStyles;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(InputFields(props.content));

  useEffect(() => {
    props.updatePageContent(formData);
  }, [formData]);

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  // section 1 handler methods
  const handleDesconChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.dashboardDescription);
    localState[forWhat].description[index] = event.target.value;
    setFormData({
      ...formData,
      dashboardDescription: localState,
    });
  };

  const handleDeleteDesc = (index: number, forWhat: "beforeLogin" | "afterLogin") => {
    const localState = cloneDeep(formData.dashboardDescription);
    const updated = localState[forWhat].description.filter((v, i) => i !== index);
    localState[forWhat].description = updated;
    setFormData({
      ...formData,
      dashboardDescription: localState,
    });
  };

  const handleAfterLoginTextchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const localContent = formData.dashboardDescription;
    localContent.isSame = event.target.checked;
    if (event.target.checked) {
      localContent.afterLogin = cloneDeep(localContent.beforeLogin);
    }
    setFormData({
      ...formData,
      dashboardDescription: localContent,
    });
  };

  const handleAfterLoginNoteTextchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const localContent = formData.note;
    localContent.isSame = event.target.checked;
    if (event.target.checked) {
      localContent.afterLogin = cloneDeep(localContent.beforeLogin);
    }
    setFormData({
      ...formData,
      note: localContent,
    });
  };

  const addNewDesc = () => {
    const localDescriptionState = formData.dashboardDescription;
    localDescriptionState.beforeLogin.description.push("");
    localDescriptionState.afterLogin.description.push("");
    setFormData({
      ...formData,
      dashboardDescription: localDescriptionState,
    });
  };

  //section 2 handler methods
  const handleAfterLoginScorechange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const localContent = formData.scoreSection;
    localContent.isSame = event.target.checked;
    if (event.target.checked) {
      localContent.afterLogin = cloneDeep(localContent.beforeLogin);
    }
    setFormData({
      ...formData,
      scoreSection: localContent,
    });
  };

  const handleScoreTitleonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.scoreSection);
    localState[forWhat].title = event.target.value;
    setFormData({
      ...formData,
      scoreSection: localState,
    });
  };

  const handleScoreDesconChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.scoreSection);
    localState[forWhat].subtitle[index] = event.target.value;
    setFormData({
      ...formData,
      scoreSection: localState,
    });
  };

  const handleScoreHelperonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.scoreSection);
    localState[forWhat].description[index] = event.target.value;
    setFormData({
      ...formData,
      scoreSection: localState,
    });
  };

  const handleScorePointsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.scoreSection);
    localState[forWhat].points[index] = event.target.value;
    setFormData({
      ...formData,
      scoreSection: localState,
    });
  };

  const handleScoreScaleonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.scoreSection);
    localState[forWhat].scoreScale[index].title = event.target.value;
    setFormData({
      ...formData,
      scoreSection: localState,
    });
  };

  const handleDeleteScoreDesc = (index: number, forWhat: "beforeLogin" | "afterLogin") => {
    const localState = cloneDeep(formData.scoreSection);
    const updated = localState[forWhat].subtitle.filter((v, i) => i !== index);
    localState[forWhat].subtitle = updated;
    setFormData({
      ...formData,
      scoreSection: localState,
    });
  };

  const handleDeleteScorePoints = (index: number, forWhat: "beforeLogin" | "afterLogin") => {
    const localState = cloneDeep(formData.scoreSection);
    const updated = localState[forWhat].points.filter((v, i) => i !== index);
    localState[forWhat].points = updated;
    setFormData({
      ...formData,
      scoreSection: localState,
    });
  };

  const addNewScoreDesc = () => {
    const localDescriptionState = formData.scoreSection;
    localDescriptionState.beforeLogin.subtitle.push("");
    localDescriptionState.afterLogin.subtitle.push("");
    setFormData({
      ...formData,
      scoreSection: localDescriptionState,
    });
  };

  const addNewScorePoints = () => {
    const localDescriptionState = formData.scoreSection;
    localDescriptionState.beforeLogin.points.push("");
    localDescriptionState.afterLogin.points.push("");
    setFormData({
      ...formData,
      scoreSection: localDescriptionState,
    });
  };

  const handleDeleteScoreHelperTextDesc = (
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.scoreSection);
    const updated = localState[forWhat].description.filter((v, i) => i !== index);
    localState[forWhat].description = updated;
    setFormData({
      ...formData,
      scoreSection: localState,
    });
  };

  const addNewScoreHelperDesc = () => {
    const localDescriptionState = formData.scoreSection;
    localDescriptionState.beforeLogin.description.push("");
    localDescriptionState.afterLogin.description.push("");
    setFormData({
      ...formData,
      scoreSection: localDescriptionState,
    });
  };

  //section 3  handler methods
  const handleTitleonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.note);
    localState[forWhat].title = event.target.value;
    setFormData({
      ...formData,
      note: localState,
    });
  };

  const handleNoteDesconChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.note);
    localState[forWhat].description[index] = event.target.value;
    setFormData({
      ...formData,
      note: localState,
    });
  };

  const handleNotePeerGrponChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.note);
    localState[forWhat].horizontalList[index] = event.target.value;
    setFormData({
      ...formData,
      note: localState,
    });
  };

  const handleNoteHelperTextconChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    forWhat: "beforeLogin" | "afterLogin",
  ) => {
    const localState = cloneDeep(formData.note);
    localState[forWhat].helper[index] = event.target.value;
    setFormData({
      ...formData,
      note: localState,
    });
  };

  const handleDeleteNoteDesc = (index: number, forWhat: "beforeLogin" | "afterLogin") => {
    const localState = cloneDeep(formData.note);
    const updated = localState[forWhat].description.filter((v, i) => i !== index);
    localState[forWhat].description = updated;
    setFormData({
      ...formData,
      note: localState,
    });
  };

  const handleDeleteNotePeerGrp = (index: number, forWhat: "beforeLogin" | "afterLogin") => {
    const localState = cloneDeep(formData.note);
    const updated = localState[forWhat].horizontalList.filter((v, i) => i !== index);
    localState[forWhat].horizontalList = updated;
    setFormData({
      ...formData,
      note: localState,
    });
  };

  const handleDeleteNoteHelperTextDesc = (index: number, forWhat: "beforeLogin" | "afterLogin") => {
    const localState = cloneDeep(formData.note);
    const updated = localState[forWhat].helper.filter((v, i) => i !== index);
    localState[forWhat].helper = updated;
    setFormData({
      ...formData,
      note: localState,
    });
  };

  const addNewNoteDesc = () => {
    const localDescriptionState = formData.note;
    localDescriptionState.beforeLogin.description.push("");
    localDescriptionState.afterLogin.description.push("");
    setFormData({
      ...formData,
      note: localDescriptionState,
    });
  };

  const addNewNotePeerGrp = () => {
    const localDescriptionState = formData.note;
    localDescriptionState.beforeLogin.horizontalList.push("");
    localDescriptionState.afterLogin.horizontalList.push("");
    setFormData({
      ...formData,
      note: localDescriptionState,
    });
  };

  const addNewNoteHelperDesc = () => {
    const localDescriptionState = formData.note;
    localDescriptionState.beforeLogin.helper.push("");
    localDescriptionState.afterLogin.helper.push("");
    setFormData({
      ...formData,
      note: localDescriptionState,
    });
  };

  const getInputHeader = (title: string, required: boolean, value: any) => {
    return (
      <Box
        sx={classes.label}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        borderColor="red"
      >
        <Box style={{ display: "flex" }}>
          {isTruthy(title) && (
            <>
              <Typography sx={classes.labelText}>{title}</Typography>
              {required && <Typography sx={classes.star}>*</Typography>}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getSection1 = () => {
    return (
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {getInputHeader("Description", true, "")}
          <Stack direction="row" justifyContent="space-between">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.dashboardDescription?.isSame}
                  onChange={(event) => handleAfterLoginTextchange(event)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Same for After Login"
            />
          </Stack>
        </Grid>
        {!formData.dashboardDescription?.isSame && (
          <Box ml={1}>
            <Typography sx={classes.authenticationText}>Before Login</Typography>
          </Box>
        )}
        {formData?.dashboardDescription?.beforeLogin?.description.map((text, index) => {
          return (
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <CustomInput
                placeHolder="Description"
                type="text"
                name="description"
                id="description"
                value={text}
                onChange={(event: any) => handleDesconChange(event, index, "beforeLogin")}
                onKeyPress={handleKeypress}
                InputProps={{
                  endAdornment: index > 0 && (
                    <InputAdornment position="end">
                      <DeleteOutlineIcon
                        sx={{ cursor: "pointer", marginLeft: "8px" }}
                        onClick={(event: any) => handleDeleteDesc(index, "beforeLogin")}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewDesc()}>
          + Add Description
        </Box>
        {!formData?.dashboardDescription.isSame && (
          <>
            <Box ml={1} mt={1}>
              <Typography sx={classes.authenticationText}>After Login</Typography>
            </Box>
            {formData?.dashboardDescription.afterLogin?.description.map((text, index) => {
              return (
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <CustomInput
                    placeHolder="Description"
                    type="text"
                    name="description"
                    id="description"
                    value={text}
                    onChange={(event: any) => handleDesconChange(event, index, "afterLogin")}
                    onKeyPress={handleKeypress}
                    InputProps={{
                      endAdornment: index > 0 && (
                        <InputAdornment position="end">
                          <DeleteOutlineIcon
                            sx={{ cursor: "pointer", marginLeft: "8px" }}
                            onClick={(event: any) => handleDeleteDesc(index, "afterLogin")}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              );
            })}
            <Box sx={classes.addLinks} onClick={() => addNewDesc()}>
              + Add Description
            </Box>
          </>
        )}
      </Grid>
    );
  };

  const getSection2 = () => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.scoreSection?.isSame}
                  onChange={(event) => handleAfterLoginScorechange(event)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Same for After Login"
            />
          </Stack>
        </Grid>
        {!formData.scoreSection?.isSame && (
          <Typography sx={classes.authenticationText}>Before Login</Typography>
        )}
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Title", true, "")}
          <CustomInput
            placeHolder="Title"
            type="text"
            name="title"
            id="title"
            value={formData.scoreSection?.beforeLogin?.title}
            onChange={(event: any) => handleScoreTitleonChange(event, "beforeLogin")}
            onKeyPress={handleKeypress}
          />
        </Grid>
        {getInputHeader("Subtitle", true, "")}
        {formData?.scoreSection?.beforeLogin?.subtitle.map((text, index) => {
          return (
            <>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <CustomInput
                  placeHolder="Subtitle"
                  type="text"
                  name="subtitle"
                  id="subtitle"
                  value={text}
                  onChange={(event: any) => handleScoreDesconChange(event, index, "beforeLogin")}
                  onKeyPress={handleKeypress}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteOutlineIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={(event: any) => handleDeleteScoreDesc(index, "beforeLogin")}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewScoreDesc()}>
          + Add Subtitle
        </Box>
        {getInputHeader("Bullets", true, "")}
        {formData?.scoreSection?.beforeLogin?.points.map((text, index) => {
          return (
            <>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <CustomInput
                  placeHolder="Text"
                  type="text"
                  name="points"
                  id="points"
                  value={text}
                  onChange={(event: any) => handleScorePointsChange(event, index, "beforeLogin")}
                  onKeyPress={handleKeypress}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteOutlineIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={(event: any) => handleDeleteScorePoints(index, "beforeLogin")}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewScorePoints()}>
          + Add Points
        </Box>
        {getInputHeader("Score Ratio", true, "")}
        {formData?.scoreSection?.beforeLogin.scoreScale.map((score, index) => {
          return (
            <>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <CustomInput
                  placeHolder="Text"
                  type="text"
                  name="title"
                  id="title"
                  value={score.title}
                  onChange={(event: any) => handleScoreScaleonChange(event, index, "beforeLogin")}
                  onKeyPress={handleKeypress}
                />
              </Grid>
            </>
          );
        })}
        {getInputHeader("Description", true, "")}
        {formData?.scoreSection?.beforeLogin?.description.map((text, index) => {
          return (
            <>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <CustomInput
                  placeHolder="Text"
                  type="text"
                  name="description"
                  id="description"
                  value={text}
                  onChange={(event: any) => handleScoreHelperonChange(event, index, "beforeLogin")}
                  onKeyPress={handleKeypress}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteOutlineIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={(event: any) =>
                            handleDeleteScoreHelperTextDesc(index, "beforeLogin")
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewScoreHelperDesc()}>
          + Add Helper Text
        </Box>
        {!formData?.scoreSection.isSame && (
          <>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              {getInputHeader("Title", true, "")}
              <CustomInput
                placeHolder="Title"
                type="text"
                name="title"
                id="title"
                value={formData.scoreSection?.afterLogin?.title}
                onChange={(event: any) => handleScoreTitleonChange(event, "afterLogin")}
                onKeyPress={handleKeypress}
              />
            </Grid>
            {getInputHeader("Subtitle", true, "")}
            {formData?.scoreSection?.afterLogin?.subtitle.map((text, index) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <CustomInput
                      placeHolder="Subtitle"
                      type="text"
                      name="subtitle"
                      id="subtitle"
                      value={text}
                      onChange={(event: any) => handleScoreDesconChange(event, index, "afterLogin")}
                      onKeyPress={handleKeypress}
                      InputProps={{
                        endAdornment: index > 0 && (
                          <InputAdornment position="end">
                            <DeleteOutlineIcon
                              sx={{ cursor: "pointer", marginLeft: "8px" }}
                              onClick={(event: any) => handleDeleteScoreDesc(index, "afterLogin")}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              );
            })}
            <Box sx={classes.addLinks} onClick={() => addNewScoreDesc()}>
              + Add Subtitle
            </Box>
            {getInputHeader("Bullets", true, "")}
            {formData?.scoreSection?.afterLogin?.points.map((text, index) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <CustomInput
                      placeHolder="Text"
                      type="text"
                      name="points"
                      id="points"
                      value={text}
                      onChange={(event: any) => handleScorePointsChange(event, index, "afterLogin")}
                      onKeyPress={handleKeypress}
                      InputProps={{
                        endAdornment: index > 0 && (
                          <InputAdornment position="end">
                            <DeleteOutlineIcon
                              sx={{ cursor: "pointer", marginLeft: "8px" }}
                              onClick={(event: any) => handleDeleteScorePoints(index, "afterLogin")}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              );
            })}
            <Box sx={classes.addLinks} onClick={() => addNewScorePoints()}>
              + Add Points
            </Box>
            {getInputHeader("Score Ratio", true, "")}
            {formData?.scoreSection?.afterLogin.scoreScale.map((score, index) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <CustomInput
                      placeHolder="Text"
                      type="text"
                      name="title"
                      id="title"
                      value={score.title}
                      onChange={(event: any) =>
                        handleScoreScaleonChange(event, index, "afterLogin")
                      }
                      onKeyPress={handleKeypress}
                    />
                  </Grid>
                </>
              );
            })}
            {getInputHeader("Description", true, "")}
            {formData?.scoreSection?.afterLogin?.description.map((text, index) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <CustomInput
                      placeHolder="Text"
                      type="text"
                      name="description"
                      id="description"
                      value={text}
                      onChange={(event: any) =>
                        handleScoreHelperonChange(event, index, "afterLogin")
                      }
                      onKeyPress={handleKeypress}
                      InputProps={{
                        endAdornment: index > 0 && (
                          <InputAdornment position="end">
                            <DeleteOutlineIcon
                              sx={{ cursor: "pointer", marginLeft: "8px" }}
                              onClick={(event: any) =>
                                handleDeleteScoreHelperTextDesc(index, "afterLogin")
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              );
            })}
            <Box sx={classes.addLinks} onClick={() => addNewScoreHelperDesc()}>
              + Add Helper Text
            </Box>
          </>
        )}
      </Grid>
    );
  };

  const getSection3 = () => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.note?.isSame}
                  onChange={(event) => handleAfterLoginNoteTextchange(event)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Same for After Login"
            />
          </Stack>
        </Grid>
        {!formData.note?.isSame && (
          <Typography sx={classes.authenticationText}>Before Login</Typography>
        )}
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Title", true, "")}
          <CustomInput
            placeHolder="Title"
            type="text"
            name="title"
            id="title"
            value={formData.note?.beforeLogin?.title}
            onChange={(event: any) => handleTitleonChange(event, "beforeLogin")}
            onKeyPress={handleKeypress}
          />
        </Grid>
        {getInputHeader("Description", true, "")}
        {formData?.note?.beforeLogin?.description.map((text, index) => {
          return (
            <>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <CustomInput
                  placeHolder="Description"
                  type="text"
                  name="description"
                  id="description"
                  value={text}
                  onChange={(event: any) => handleNoteDesconChange(event, index, "beforeLogin")}
                  onKeyPress={handleKeypress}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteOutlineIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={(event: any) => handleDeleteNoteDesc(index, "beforeLogin")}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewNoteDesc()}>
          + Add Description
        </Box>
        {getInputHeader("Peer Groups", true, "")}
        {formData?.note?.beforeLogin?.horizontalList.map((text, index) => {
          return (
            <>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <CustomInput
                  placeHolder="Peer Group"
                  type="text"
                  name="horizontalList"
                  id="horizontalList"
                  value={text}
                  onChange={(event: any) => handleNotePeerGrponChange(event, index, "beforeLogin")}
                  onKeyPress={handleKeypress}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteOutlineIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={(event: any) => handleDeleteNotePeerGrp(index, "beforeLogin")}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewNotePeerGrp()}>
          + Add Peer Group
        </Box>
        {getInputHeader("Helper Text", true, "")}
        {formData?.note?.beforeLogin?.helper.map((text, index) => {
          return (
            <>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <CustomInput
                  placeHolder="Helper Text"
                  type="text"
                  name="helper"
                  id="helper"
                  value={text}
                  onChange={(event: any) =>
                    handleNoteHelperTextconChange(event, index, "beforeLogin")
                  }
                  onKeyPress={handleKeypress}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteOutlineIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={(event: any) =>
                            handleDeleteNoteHelperTextDesc(index, "beforeLogin")
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewNoteHelperDesc()}>
          + Add Helper Text
        </Box>
        {!formData?.note.isSame && (
          <>
            <Typography sx={classes.authenticationText}>After Login</Typography>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              {getInputHeader("Title", true, "")}
              <CustomInput
                placeHolder="Title"
                type="text"
                name="title"
                id="title"
                value={formData.note?.afterLogin?.title}
                onChange={(event: any) => handleTitleonChange(event, "afterLogin")}
                onKeyPress={handleKeypress}
              />
            </Grid>
            {getInputHeader("Description", true, "")}
            {formData?.note?.afterLogin?.description.map((text, index) => {
              return (
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <CustomInput
                    placeHolder="Description"
                    type="text"
                    name="description"
                    id="description"
                    value={text}
                    onChange={(event: any) => handleNoteDesconChange(event, index, "afterLogin")}
                    onKeyPress={handleKeypress}
                    InputProps={{
                      endAdornment: index > 0 && (
                        <InputAdornment position="end">
                          <DeleteOutlineIcon
                            sx={{ cursor: "pointer", marginLeft: "8px" }}
                            onClick={(event: any) => handleDeleteNoteDesc(index, "afterLogin")}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              );
            })}
            <Box sx={classes.addLinks} onClick={() => addNewNoteDesc()}>
              + Add Description
            </Box>
            {getInputHeader("Peer Groups", true, "")}
            {formData?.note?.afterLogin?.horizontalList.map((text, index) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <CustomInput
                      placeHolder="Peer Group"
                      type="text"
                      name="horizontalList"
                      id="horizontalList"
                      value={text}
                      onChange={(event: any) =>
                        handleNotePeerGrponChange(event, index, "afterLogin")
                      }
                      onKeyPress={handleKeypress}
                      InputProps={{
                        endAdornment: index > 0 && (
                          <InputAdornment position="end">
                            <DeleteOutlineIcon
                              sx={{ cursor: "pointer", marginLeft: "8px" }}
                              onClick={(event: any) => handleDeleteNotePeerGrp(index, "afterLogin")}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              );
            })}
            <Box sx={classes.addLinks} onClick={() => addNewNotePeerGrp()}>
              + Add Peer Group
            </Box>
            {getInputHeader("Helper Text", true, "")}
            {formData?.note?.afterLogin?.helper.map((text, index) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <CustomInput
                      placeHolder="Helper Text"
                      type="text"
                      name="helper"
                      id="helper"
                      value={text}
                      onChange={(event: any) =>
                        handleNoteHelperTextconChange(event, index, "afterLogin")
                      }
                      onKeyPress={handleKeypress}
                      InputProps={{
                        endAdornment: index > 0 && (
                          <InputAdornment position="end">
                            <DeleteOutlineIcon
                              sx={{ cursor: "pointer", marginLeft: "8px" }}
                              onClick={(event: any) =>
                                handleDeleteNoteHelperTextDesc(index, "afterLogin")
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              );
            })}
            <Box sx={classes.addLinks} onClick={() => addNewNoteHelperDesc()}>
              + Add Helper Text
            </Box>
          </>
        )}
      </Grid>
    );
  };

  const getPreviewScreen = () => {
    return (
      <Box>
        <PreviewSampleDashboard content={formData} templateId={props.templateId} />
      </Box>
    );
  };

  const sections = [
    { section: getSection1(), id: uuidv4(), name: "Dashboard Description", isDeletable: false },
    { section: getSection2(), id: uuidv4(), name: "Scoring System", isDeletable: false },
    { section: getSection3(), id: uuidv4(), name: "Note Section", isDeletable: false },
  ];

  return (
    <Box>
      <GlobalLayout
        section={sections}
        pageTitle={"Dashboard"}
        previewElement={getPreviewScreen()}
      />
      <CustomLoader isLoading={loading} />
    </Box>
  );
};

export default SampleDashboard;
