import {
  descriptionFont,
  getRelativeFontSize,
  mediumFontTitle,
  theme,
} from "../../../../../utils/styles";

const SectionModalStyles = {
  blackHeadings: {
    ...mediumFontTitle,
    fontSize: getRelativeFontSize(5),
    fontweight: "bold",
  },
  description: {
    ...descriptionFont,
    color: "#3E3E3F",
    fontSize: getRelativeFontSize(4),
    mb: 2,
  },
  addLinks: {
    ml: 1,
    display: "inline-block",
    ...theme.typography.h6,
    color: theme.palette.secondary.dark,
    fontSize: getRelativeFontSize(4),
    cursor: "pointer",
  },
  pageBox: {
    padding: 2,
    border: "1px solid #F2F2F2",
    boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.05)",
  },
  headerBox: {
    ...theme.typography.h2,
    backgroundColor: "#FFF3F2",
    color: "#F3776C",
    padding: 2,
  },
  dialogTitleWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "10px",
    textAlign: "center",
  },
  dialogFooter: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    marginBottom: "20px",
    gap: "10px",
    "& button": {
      width: "120px",
    },
  },
  label: {
    display: "flex",
    marginTop: "5px",
  },
  labelIcon: { color: "black" },
  labelText: {
    ...theme.typography.button,
    marginLeft: "6px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(),
    },
  },
  star: {
    color: "red",
    marginLeft: "4px",
    fontSize: getRelativeFontSize(2),
    ...theme.typography.button,
  },
  characterLimit: {
    ...theme.typography.subtitle1,
    fontSize: getRelativeFontSize(),
  },
  limitExceed: {
    color: "red",
  },
};

export default SectionModalStyles;
