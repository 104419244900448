import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomTable from "../../global/components/CustomTable/CustomTable";
import { Header, ParagraphData, Rows, Section, commonData } from "../../models/interface";
import PracticesDetailStyles from "./PracticesDetail.styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import strings from "../../global/constants/StringConstants";
import { imageRenderer, isVideo } from "../../utils/service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import parse from "html-react-parser";
import { isTruthy, markdownText, openErrorNotification } from "../../helpers/methods";
import { theme } from "../../utils/styles";
import { useEffect, useState } from "react";
import { practicesData } from "./PracticeDetailService";
import { useAppSelector } from "../../utils/hooks";
import { selectAuthenticated, selectLocale } from "../../redux/authSlice";
import notifiers from "../../global/constants/NotificationConstants";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import CustomDialog from "../../global/components/CustomDialog/CustomDialog";
import urls from "../../global/constants/UrlConstants";
import { cloneDeep } from "lodash";
import history from "../../utils/history";
import { staticContent } from "../../utils/locale";

interface CustomProps {
  content: any;
  templateId: string;
}

const PracticeDetail = (props: CustomProps) => {
  const classes = PracticesDetailStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const locale = useAppSelector(selectLocale);
  const [data, setData] = useState<any>({} as any);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPractice, setSelectedPractice] = useState({
    redirectUrl: "",
    title: "",
    isExternal: false,
  });
  const [openModal, setOpenModal] = useState<{ isOpen: boolean; link: string }>({
    isOpen: false,
    link: "",
  });
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const contentText = staticContent(locale, isAuthenticated);

  useEffect(() => {
    getPracticesRoutes();
  }, []);

  const getPracticesRoutes = async () => {
    try {
      setLoading(true);
      const response = await practicesData(locale);
      const goodPracticesPages = JSON.parse(response.data);
      const pageURL = window.location.pathname;
      let selectedTitle = "";
      let selectedPractice = {
        redirectUrl: "",
        title: "",
        isExternal: false,
      };
      let goodPracticeStructure: any = {};
      goodPracticesPages.practicesList.forEach((practice: any) => {
        goodPracticeStructure[practice.title] = practice.practices.map((prac: any) => {
          if (pageURL === prac.redirectUrl) {
            selectedTitle = practice.title;
            selectedPractice.redirectUrl = prac.redirectUrl;
            selectedPractice.title = prac.title;
            selectedPractice.isExternal = prac.isExternal;
          }
          return {
            redirectUrl: prac.redirectUrl,
            title: prac.title,
            isExternal: prac.isExternal,
          };
        });
      });
      setData(goodPracticeStructure);
      setSelectedTitle(selectedTitle);
      setSelectedPractice(selectedPractice);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModel = () => {
    setOpenModal({ isOpen: false, link: "" });
  };

  const getYoutubeThumbnail = (link: any) => {
    if (isTruthy(link)) {
      const id = link[1];
      return id;
    }
    return "";
  };

  const dialogBody = () => (
    <Box>
      <iframe
        width="100%"
        style={{ maxHeight: "900px" }}
        height={isDesktop ? "750px" : "auto"}
        src={`https://www.youtube.com/embed/${openModal.link}`}
        title="YouTube video player"
        allowFullScreen
      ></iframe>
    </Box>
  );

  const getCustomDialog = () => {
    return (
      <CustomDialog
        isDialogOpen={openModal.isOpen}
        closable
        closeButtonVisibility
        handleDialogClose={handleCloseModel}
        dialogBodyContent={dialogBody()}
        dialogHeaderContent={<></>}
        height="80%"
        width="70%"
      />
    );
  };

  const getCustomTable = (tableData: {
    title: string;
    header: Header[];
    rows: Rows[];
    width: any;
  }) => {
    return (
      <Box p={1}>
        <Typography sx={classes.blackHeadings} gutterBottom>
          {tableData?.title}
        </Typography>
        <Box sx={{ width: "100%", overflow: "auto" }}>
          <CustomTable
            headers={tableData?.header}
            rows={tableData?.rows}
            customWidth={tableData?.width}
          />
        </Box>
      </Box>
    );
  };

  const getText = (data: any) => {
    return (
      <Box p={1}>
        <Typography sx={classes.blackHeadings} gutterBottom>
          {data?.title}
        </Typography>
        {data?.description?.map((element: ParagraphData) => {
          return (
            <>
              {element.type === strings.Paragraph ? (
                <Typography variant="subtitle1" sx={classes.description} gutterBottom>
                  {parse(markdownText(element.content))}
                </Typography>
              ) : (
                <List dense component={"ul"} sx={{ display: "flex", alignItems: "flex-start" }}>
                  <FiberManualRecordIcon fontSize="small" sx={{ width: 10, paddingTop: "10px" }} />
                  <ListItem sx={{ m: 0, p: 0, pl: 1 }} key={element.content}>
                    <Typography variant="subtitle1" sx={classes.bullets}>
                      {parse(markdownText(element.content))}
                    </Typography>
                  </ListItem>
                </List>
              )}
            </>
          );
        })}
      </Box>
    );
  };

  const getImage = (data: any) => {
    return (
      <>
        {data?.image && (
          <img
            src={imageRenderer(props.templateId, data?.image)}
            width="100%"
            alt={data?.altText}
          />
        )}
      </>
    );
  };

  const getComponents = (content: any) => {
    switch (content.type) {
      case "table":
        return getCustomTable(content.metadata!);
      case "text":
        return getText(content.metadata!);
      case "image":
        return getImage(content.metadata!);
      default:
        return <></>;
    }
  };

  const getHeader = () => {
    const titles = Object.keys(data);
    let practices = [];
    if (data.hasOwnProperty(selectedTitle)) {
      practices = data[selectedTitle];
    }
    return (
      <Box sx={classes.headWrapper}>
        <Container maxWidth="xl" sx={classes.flexHead}>
          <Box
            sx={{ display: "flex", alignItems: "center", mb: { xs: 1, sm: 1 } }}
            onClick={() => history.push(urls.goodPracticesViewPath)}
          >
            <Tooltip title={contentText.general.goBack}>
              <ArrowBackIcon sx={{ display: "inline", mr: 1 }} />
            </Tooltip>
          </Box>
          <Stack direction={isDesktop ? "row" : "column"} spacing={1}>
            <Select
              value={selectedTitle}
              name="hyperlink"
              id="hyperlink"
              sx={classes.titleDropdown}
              onChange={(event) => {
                const title = event.target.value;
                setSelectedTitle(title);
                const practice = data[title];

                setSelectedPractice(practice[0]);
                if (practice[0].isExternal) {
                  const videoId = getYoutubeThumbnail(
                    practice[0].redirectUrl.match(strings.youtubeVideoIdRegex),
                  );
                  if (videoId) {
                    setOpenModal({ isOpen: true, link: videoId });
                  }
                } else {
                  history.push(practice[0].redirectUrl);
                }
              }}
            >
              {titles.map((title) => {
                return <MenuItem value={title}>{title}</MenuItem>;
              })}
            </Select>
            <Select
              value={JSON.stringify(selectedPractice)}
              name="hyperlink"
              id="hyperlink"
              sx={classes.practiceDropdown}
              onChange={(event) => {
                const practice = JSON.parse(event.target.value);
                setSelectedPractice(practice);
                if (practice.isExternal) {
                  const videoId = getYoutubeThumbnail(
                    practice.redirectUrl.match(strings.youtubeVideoIdRegex),
                  );

                  setOpenModal({ isOpen: true, link: videoId });
                } else {
                  history.push(practice.redirectUrl);
                }
              }}
            >
              {practices?.map((practice: any) => {
                return (
                  <MenuItem
                    value={JSON.stringify({
                      redirectUrl: practice.redirectUrl,
                      title: practice.title,
                      isExternal: practice.isExternal,
                    })}
                  >
                    {practice.title}
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>
        </Container>
      </Box>
    );
  };

  const getWhoWeAre = () => {
    return (
      <Box sx={{ width: "100%", p: 1 }}>
        <Typography sx={classes.blackHeadings} gutterBottom>
          {contentText.general.who}
        </Typography>
        {props.content?.banner?.whoWeAreDescription.map((desc: string) => {
          return <Typography sx={classes.description}>{parse(markdownText(desc))}</Typography>;
        })}
      </Box>
    );
  };

  const getBanner = () => {
    const imageExist = props.content?.banner?.image ? true : false;
    return (
      <Grid container display="flex" justifyContent="space-evenly" pl={1}>
        <Grid item xs={12} sm={12} md={12} lg={imageExist ? 8 : 12} xl={imageExist ? 8 : 12}>
          <Typography sx={classes.orangeHeadingText}>
            {parse(markdownText(props.content?.banner?.title))}
          </Typography>
          {props.content?.banner?.category && (
            <Typography sx={classes.blackHeadings}>
              {parse(markdownText(props.content?.banner?.category))}
            </Typography>
          )}
          {props.content?.banner?.quote && (
            <Typography sx={classes.quoteText}>{props.content?.banner?.quote}</Typography>
          )}
        </Grid>

        {props.content?.banner?.image && (
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Box>
              {props.content?.banner?.image && (
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Box>
                    {isVideo(props.content?.banner?.image) ? (
                      <video width="320" height="240" controls>
                        <source
                          src={imageRenderer(props.templateId, props.content?.banner?.image)}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={imageRenderer(props.templateId, props.content?.banner?.image)}
                        width="100%"
                        alt={props.content?.banner?.imageAlt}
                      />
                    )}
                  </Box>
                </Grid>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  const getRelatedMeasures = () => {
    if (!isTruthy(props.content.relatedMeasures)) {
      return null;
    }
    return (
      <Box mb={3}>
        <Typography sx={classes.blackHeadings}>{contentText.general.measures}</Typography>
        {props.content.relatedMeasures?.map((measures: commonData) => {
          return (
            <List
              component={"ul"}
              sx={{ display: "flex", "&.MuiList-root": { padding: 0 } }}
              onClick={() =>
                window.open(measures.redirectUrl, measures.isExternal ? "_blank" : "_self")
              }
            >
              <FiberManualRecordIcon
                fontSize="small"
                sx={{ width: 10, paddingTop: "10px" }}
                color="primary"
              />
              <ListItem sx={{ m: 0, p: 0, pl: 1 }}>
                <Typography variant="subtitle1" sx={[classes.bullets, classes.bulletsHover]}>
                  {measures.title}
                </Typography>
              </ListItem>
            </List>
          );
        })}
      </Box>
    );
  };

  const checkRedirection = (practice: commonData) => {
    if (practice.isExternal) {
      const videoId = getYoutubeThumbnail(practice.redirectUrl.match(strings.youtubeVideoIdRegex));
      setOpenModal({ isOpen: true, link: videoId });
    } else {
      history.push(practice.redirectUrl);
    }
  };

  const getRelatedPractices = () => {
    if (!isTruthy(props.content.relatedGoodPractices)) {
      return null;
    }
    return (
      <Box mb={3}>
        <Typography sx={classes.blackHeadings}>{contentText.general.practice}</Typography>
        {props.content.relatedGoodPractices?.map((practices: commonData) => {
          return (
            <List
              dense
              component={"ul"}
              sx={{ display: "flex", alignItems: "flex-start", "&.MuiList-root": { padding: 0 } }}
            >
              <FiberManualRecordIcon
                fontSize="small"
                sx={{ width: 10, paddingTop: "10px" }}
                color="primary"
              />
              <ListItem sx={{ m: 0, p: 0, pl: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={[classes.bullets, classes.bulletsHover]}
                  onClick={() => checkRedirection(practices)}
                >
                  {practices.title}
                </Typography>
              </ListItem>
            </List>
          );
        })}
      </Box>
    );
  };
  const getRelatedResources = () => {
    if (!isTruthy(props.content.resources)) {
      return null;
    }
    return (
      <Box>
        <Typography sx={classes.blackHeadings}>{contentText.general.resource}</Typography>
        {props.content.resources?.map((resource: commonData) => {
          return (
            <List
              dense
              component={"ul"}
              sx={{
                display: "flex",
                "&.MuiList-root": { padding: 0 },
                fontWeight: "bold",
                color: "#4E7AEC",
              }}
              onClick={() =>
                window.open(resource.redirectUrl, resource.isExternal ? "_blank" : "_self")
              }
            >
              <FiberManualRecordIcon
                fontSize="small"
                sx={{ width: 10, paddingTop: "10px" }}
                color="primary"
              />
              <ListItem sx={{ m: 0, p: 0, pl: 1 }}>
                <Typography variant="subtitle1" sx={[classes.bullets, classes.bulletsHover]}>
                  {resource.title}
                </Typography>
              </ListItem>
            </List>
          );
        })}
      </Box>
    );
  };

  const getDrawerData = () => {
    return (
      <Box p={2}>
        {getRelatedMeasures()}
        {getRelatedPractices()}
        {getRelatedResources()}
      </Box>
    );
  };

  const getCustomGrid = (column: number, type: string) => {
    if (column === 1) {
      return 12;
    } else if (column === 2 && type === "image") {
      return 6;
    } else {
      return 6;
    }
  };

  return (
    <Box>
      {getHeader()}
      <Grid container display="flex" flexDirection={isDesktop ? "row" : "column-reverse"}>
        <Grid item lg={2} xl={2} md={4} sx={{ backgroundColor: "#FFF3F2" }}>
          {getDrawerData()}
        </Grid>
        <Grid item lg={10} xl={10} md={8} p={2} sx={{ width: "100%" }}>
          {getBanner()}
          {getWhoWeAre()}
          {props.content?.sections.map((section: Section, index: number) => {
            const isImageSection = section.content.findIndex((content) => content.type === "image");
            if (isImageSection > -1) {
              let x = section.content;
              if (isImageSection === 1) {
                const local = cloneDeep(section.content);
                const pop = local.pop();
                local.unshift(pop!);
                x = local;
              }
              return x.map((content, index: number) => {
                if (content.type === "image") {
                  return content?.metadata?.image ? (
                    <Box
                      sx={{
                        ...(isDesktop
                          ? {
                              float: isImageSection === 1 ? "right" : "left",
                              padding: isImageSection === 1 ? "0 0 32px 32px" : "32px 32px 0 0 ",
                            }
                          : { display: "flex" }),
                      }}
                    >
                      <img
                        src={imageRenderer(props.templateId, content?.metadata?.image)}
                        width="100%"
                        alt={content?.metadata?.altText}
                      />
                    </Box>
                  ) : null;
                }
                return getComponents(content);
              });
            }
            return (
              <Grid container sx={{ width: "100%" }}>
                {section.content.map((content) => {
                  const breakpoint = getCustomGrid(section.columns, content.type);
                  return (
                    <Grid item xs={12} sm={12} md={12} xl={breakpoint} lg={breakpoint}>
                      {getComponents(content)}
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <CustomLoader isLoading={loading} />
      {getCustomDialog()}
    </Box>
  );
};

export default PracticeDetail;
