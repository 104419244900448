import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../../../assets/loader.gif";
import { Typography } from "@mui/material";
import { useAppSelector } from "../../../utils/hooks";
import { selectLocale } from "../../../redux/authSlice";
import { theme } from "../../../utils/styles";
interface CustomLoaderProps {
  isLoading?: boolean;
}
const CustomLoaderWithText = (props: CustomLoaderProps) => {
  const locale = useAppSelector(selectLocale);
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 101,
        backgroundColor: "rgb(255 243 243 / 80%) !important",
        display: "flex",
        flexDirection: "column",
      }}
      open={props.isLoading!}
    >
      {/* <CircularProgress color="inherit" /> */}
      <img src={Loader} width="75px" />
      <Typography sx={{ color: "#42406F", ...theme.typography.h5 }}>
        {locale.toLowerCase() === "en"
          ? "Processing, please wait…"
          : "Veuillez patientez pendant que nous traitons les données…."}
      </Typography>
    </Backdrop>
  );
};

export default CustomLoaderWithText;
