import React from "react";
import CustomDialog from "../../../../global/components/CustomDialog/CustomDialog";
import { Box, Stack, Typography } from "@mui/material";
import AppButton from "../../../../global/components/AppButton/AppButton";
import { theme } from "../../../../utils/styles";

interface CustomProps {
  isDialogOpen: boolean;
  handleDialogClose: Function;
  save: Function;
}

const ConfirmSaveModal = (props: CustomProps) => {
  const getDialogTitle = () => {
    return (
      <Box>
        <Typography sx={{ ...theme.typography.button }}>Unsaved Changes</Typography>
      </Box>
    );
  };

  const getDialogBody = () => {
    return (
      <Box>
        <Typography sx={{ ...theme.typography.body1, mb: 1 }}>
          If you leave page, any changes you have made will be lost.
        </Typography>
        <Stack gap={1} direction={"row"} justifyContent="center">
          <AppButton label={"Save"} onClick={props.save} />
          <AppButton label={"Discard"} onClick={props.handleDialogClose} />
        </Stack>
      </Box>
    );
  };

  return (
    <CustomDialog
      isDialogOpen={props.isDialogOpen}
      handleDialogClose={props.handleDialogClose}
      dialogTitleContent={getDialogTitle()}
      dialogBodyContent={getDialogBody()}
    />
  );
};

export default ConfirmSaveModal;
