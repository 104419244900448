import { getRelativeFontSize, mediumFont, theme } from "../../../utils/styles";

const customInputStyles = {
  textField: {
    width: "100%",
    borderRadius: "12px",
    "& .MuiInputBase-input": {
      position: "relative",
      padding: "10px 10px",
      "&::placeholder": {
        ...theme.typography.h6,
        fontSize: getRelativeFontSize(2),
        color: "#7C7C7C",
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        borderColor: "#4B0150",
      },
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
      color: "#757575",
      opacity: 1,
    },
  },
  nameField: {
    ...theme.typography.body1,
    color: "#212121",
    "& .MuiFormLabel-asterisk": {
      color: "red",
    },
  },
  fullWidth: {
    width: "100%",
  },
} as const;

export default customInputStyles;
