import urls from "../global/constants/UrlConstants";
import { getNoAuthCallParams, makeCall } from "../utils/service";

export async function getPageDataById(pageId: string) {
  try {
    const body = {};
    const url = urls.getPageDetailsById + "/" + pageId;
    const callParams = getNoAuthCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function retrieveImage(templateId: String, fileName: string) {
  try {
    const url = urls.getImage + "/" + templateId + "/" + fileName;
    const callParams = getNoAuthCallParams("GET");
    const response = await fetch(url, callParams);
    return await response.blob();
  } catch (error: any) {
    throw error;
  }
}
