import { mediumFontTitle, theme, getRelativeFontSize, descriptionFont } from "../../utils/styles";

const PracticesDetailStyles = {
  orangeHeadingText: {
    ...mediumFontTitle,
    color: theme.palette.secondary.main,
    fontSize: getRelativeFontSize(10),
  },
  dropDown: {
    height: "50px",
    width: "100%",
    borderRadius: "12px",
    marginTop: "6px",
  },
  blackHeadings: {
    ...mediumFontTitle,
    fontSize: getRelativeFontSize(4),
    fontweight: "bold",
  },
  description: {
    textAlign: "justify",
    fontSize: getRelativeFontSize(4),
    mb: 1,
  },
  quoteText: {
    color: "#423F6F",
    ...theme.typography.h6,
    mb: 1,
    pb: 1,
  },
  bullets: {
    ...descriptionFont,
    color: "#3E3E3F",
    fontSize: getRelativeFontSize(4),
  },
  bulletsHover: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  whiteText: {
    fontSize: getRelativeFontSize(),
  },
  countBox: {
    border: "1.5px solid #FFFFFF",
    borderRadius: "5px",
    p: 1,
    width: "100px",
    textAlign: "center",
  },
  headWrapper: {
    background: theme.palette.primary.main,
    py: 3,
  },
  flexHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    flexWrap: "wrap",
  },
  practiceDropdown: {
    ...theme.typography.body1,
    backgroundColor: "rgba(248, 248, 255, 0.18)",
    border: "1px solid rgba(66, 64, 111, 0.30)",
    borderRadius: "10px",
    color: "#FFF",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    height: "50px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "350px!important",
    },
  },
  titleDropdown: {
    ...theme.typography.body1,
    backgroundColor: "transparent",
    border: "3px solid #FCFCFF4D",
    borderRadius: "10px",
    color: "#FFF",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    height: "50px",
    [theme.breakpoints.down("md")]: {
      width: "350px!important",
    },
  },
};

export default PracticesDetailStyles;
