import { FormControl, FormLabel, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import AppTextFieldStyles from "./AppTextField.styles";

const classes = AppTextFieldStyles;

const AppTextField = ({
  label,
  fieldLabel,
  placeholder,
  fullWidth,
  size = "small",
  required = false,
  onKeyPress,
  onChange,
  fieldName,
  fieldId,
  error,
  type,
}: {
  label?: string;
  fieldLabel?: string;
  placeholder?: string;
  fullWidth?: boolean;
  size?: "small" | "medium";
  required?: boolean;
  onKeyPress: any;
  onChange?: any;
  fieldName: string;
  fieldId: string;
  error?: any;
  type?: any;
}) => {
  return (
    <FormControl>
      {fieldLabel ? (
        <Stack direction="row">
          <FormLabel sx={{ fontSize: 16, fontWeight: 600, color: "#3E3E3F" }}>
            {fieldLabel}
          </FormLabel>
          {required && <Typography sx={{ color: "#d32f2f", ml: 0.5 }}>*</Typography>}
        </Stack>
      ) : null}
      <TextField
        sx={classes.textFieldStyles}
        inputProps={{
          sx: classes.inputPropsStyles,
        }}
        required={required}
        fullWidth={fullWidth}
        size={size}
        label={label}
        placeholder={placeholder}
        onKeyDown={onKeyPress}
        onChange={onChange}
        name={fieldName}
        id={fieldId}
        type={type}
        {...(error && { error: true, helperText: error })}
      />
    </FormControl>
  );
};

export default AppTextField;
