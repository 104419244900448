import { useEffect, useState } from "react";
import PageInfo from "./components/PageInfo";
import PageDataSetup from "./components/PageDataSetup";
import { addPage, updatePage } from "../../AdminService";
import {
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../../helpers/methods";
import { useLocation } from "react-router-dom";
import { getPageDataById } from "../../../PageRenderService";
import notifiers from "../../../../global/constants/NotificationConstants";
import urls from "../../../../global/constants/UrlConstants";
import { Box } from "@mui/material";
import CustomLoader from "../../../../global/components/CustomLoader/CustomLoader";
import history from "../../../../utils/history";

const AddPage = () => {
  const urlParams = useLocation().search;
  const pageId = new URLSearchParams(urlParams).get("id");
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [pageData, setPageData] = useState({
    id: "",
    locale: "",
    templateId: "",
    title: "",
    route: "",
    data: "",
    active: false,
  });
  const [currentPageData, setCurrentPageData] = useState({
    id: "",
    locale: "",
    templateId: "",
    title: "",
    route: "",
    data: "",
    active: false,
  });
  const [showError, setShowError] = useState(false);
  const [blockTheNavigation, setBlockTheNavigation] = useState(true);

  useEffect(() => {
    if (isTruthy(pageId)) {
      fetchPageData();
    }
  }, [pageId]);

  const fetchPageData = async () => {
    try {
      setLoading(true);
      const data = await getPageDataById(pageId!);
      setPageData({
        id: pageId!,
        templateId: data.templateId,
        data: JSON.parse(data.data),
        route: data.route,
        title: data.title,
        locale: data.locale,
        active: data.active,
      });
      setCurrentPageData({
        id: pageId!,
        templateId: data.templateId,
        data: JSON.parse(data.data),
        route: data.route,
        title: data.title,
        locale: data.locale,
        active: data.active,
      });
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const validateFormData = () => {
    if (
      !(
        isTruthy(pageData.title) &&
        isTruthy(pageData.locale) &&
        isTruthy(pageData.route) &&
        isTruthy(pageData.templateId)
      )
    ) {
      setShowError(true);
      return false;
    }
    return true;
  };

  const handleNext = async () => {
    if (validateFormData()) {
      setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setPageData(currentPageData);
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleAddEditPage = async () => {
    try {
      setLoading(true);
      if (pageData.id) {
        await updatePage({
          id: pageData.id,
          locale: pageData.locale,
          templateId: pageData.templateId,
          title: pageData.title,
          route: pageData.route,
          data: JSON.stringify(pageData.data),
          active: pageData.active,
        });
        openSuccessNotification("Page edited successfully");
      } else {
        await addPage({
          id: pageData.id,
          locale: pageData.locale,
          templateId: pageData.templateId,
          title: pageData.title,
          route: pageData.route,
          data: JSON.stringify(pageData.data),
          active: pageData.active,
        });
        openSuccessNotification("Page added successfully");
      }
      history.push(urls.adminPath);
    } catch (exception: any) {
      openErrorNotification(
        isTruthy(exception.errorMessage) ? exception.errorMessage : exception.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const updatePageContent = (pageContent: any) => {
    setPageData({
      ...pageData,
      data: pageContent,
    });
  };

  const getAddEditPage = () => {
    switch (activeStep) {
      case 0: {
        return (
          <PageInfo
            editMode={isTruthy(pageId)}
            handleNext={handleNext}
            pageData={pageData}
            setPageData={setPageData}
            currentRouteAndLocale={currentPageData}
            showError={showError}
          />
        );
      }
      case 1: {
        return (
          <PageDataSetup
            templateId={pageData.templateId}
            content={pageData.data}
            handleAddEditPage={handleAddEditPage}
            handlePrevious={handleBack}
            updatePageContent={updatePageContent}
            locale={pageData.locale}
            pageTitle={pageData.title}
          />
        );
      }
      default: {
        return (
          <PageInfo
            editMode={isTruthy(pageId)}
            handleNext={handleNext}
            pageData={pageData}
            setPageData={setPageData}
            currentRouteAndLocale={currentPageData}
            showError={showError}
          />
        );
      }
    }
  };

  return (
    <Box>
      {getAddEditPage()}
      <CustomLoader isLoading={isLoading} />
      {/* <RouterPrompt
        blockTheNavigation={blockTheNavigation}
        setBlockTheNavigation={setBlockTheNavigation}
      /> */}
    </Box>
  );
};

export default AddPage;
