import { descriptionFont, getRelativeFontSize, mediumFontTitle, theme } from "../../utils/styles";

const SampleDashboardStyles = {
  headWrapper: {
    background: theme.palette.primary.main,
    py: 3,
    mb: 4,
    position: "fixed",
    width: "100%",
    zIndex: 99,
  },
  orangeHeadingText: {
    ...mediumFontTitle,
    alignSelf: "center",
    color: theme.palette.secondary.main,
    fontSize: getRelativeFontSize(5),
    fontWeight: "bolder",
    p: 2,
  },
  flexHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    flexWrap: "wrap",
  },
  pageBodyWrapper: {
    py: 3,
  },
  pageBody: {
    display: "flex",
    flexDirection: "column",

    mt: 4,
  },
  button: {
    border: "1px solid #FFFFFF",
    backgroundColor: "transparent",
    color: "white",
    borderShadow: "none",
    borderRadius: "60px",
    fontSize: getRelativeFontSize(2),
  },
  addpeergrp: {
    backgroundColor: "rgba(255, 255, 255, 0.18)",
    border: "1px solid rgba(66, 64, 111, 0.3)",
    borderRadius: "60px",
    color: "white",
    borderShadow: "none",
    fontSize: getRelativeFontSize(2),
  },
  btnwithnoradius: {
    border: "1px solid #FFFFFF",
    backgroundColor: "transparent",
    color: "white",
    borderShadow: "none",
    fontSize: getRelativeFontSize(2),
  },
  btnfilledwithnoradius: {
    border: "1px solid #FFFFFF",
    backgroundColor: "rgba(255, 255, 255, 0.18)",
    color: "white",
    borderShadow: "none",
    fontSize: getRelativeFontSize(2),
  },
  tableRowBox: {
    background: "#FFFFFF",
    border: "1px solid #C8C8C8",
    borderRadius: "10px",
    p: 2,
    mt: 1,
  },
  cancelBtn: {
    border: "1px solid #FFFFFF",
    backgroundColor: "rgba(255, 255, 255, 0.18)",
    color: theme.palette.primary.main,
    borderShadow: "none",
    fontSize: getRelativeFontSize(2),
  },
  countBox: {
    border: "1.5px solid #FFFFFF",
    borderRadius: "5px",
    p: 1,
    width: "100px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  bodyContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    fontSize: getRelativeFontSize(),
    gap: 2,
  },
  whiteText: {
    width: "300px",
    fontSize: getRelativeFontSize(),
  },
  bullets: {
    ...descriptionFont,
    color: "#3E3E3F",
    fontSize: getRelativeFontSize(3),
  },
  borderBox: {
    background: "#FFFFFF",
    border: "0.3px solid #F3776C",
    boxShadow: "0px 3px 17px rgba(0, 0, 0, 0.06)",
    borderRadius: "13px",
    padding: 2,
  },
  locationBox: {
    background: "#E7E7FF",
    boxShadow: "0px 3px 17px rgba(0, 0, 0, 0.06)",
    borderRadius: "13px",
    padding: 2,
  },
  radioBox: {
    background: "#FFFFFF",
    border: "1px solid #E7E7E7",
    borderRadius: "10px",
    width: "300px",
  },
  blueText: {
    color: theme.palette.primary.main,
    ...theme.typography.body1,
    fontSize: getRelativeFontSize(),
    fontWeight: "bold",
    my: 1,
  },
  blueTextHeading: {
    color: theme.palette.primary.dark,
    ...theme.typography.button,
    fontSize: getRelativeFontSize(2),
    mb: 1,
    fontWeight: "bold",
  },
  peerGroupDropdown: {
    ...theme.typography.body1,
    backgroundColor: "#FFF",
    border: "1px solid rgba(66, 64, 111, 0.30)",
    borderRadius: "10px",
    color: "#423F6F",
    "& .MuiSvgIcon-root": {
      color: "#423F6F",
    },
    height: "45px",
  },
  heading: {
    ...theme.typography.h5,
    fontWeight: "bold",
  },
  hightlightedText: {
    ...theme.typography.body1,
    fontWeight: "bold",
    pr: 4,
  },
  pageBtn: {
    display: "flex",
    marginTop: "8px",
    justifyContent: "end",
    "& .Mui-selected": {
      color: "#fff",
      backgroundColor: `${theme.palette.primary.main} ! important`,
    },
  },
};

export default SampleDashboardStyles;
