import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { theme } from "../../utils/styles";
import AppButton from "../../global/components/AppButton/AppButton";
import GoodPracticesStyles from "./Goodpractices.styles";
import { CustomSection } from "../../global/components/CustomSection/CustomSection";
import { GoodPracticesDataType, PracticesData } from "../../models/interface";
import { Link } from "react-router-dom";
import bannerImage from "../../assets/images/Moredetails/good_practices.png";
import { markdownText } from "../../helpers/methods";
import parse from "html-react-parser";
import { staticContent } from "../../utils/locale";
import { useAppSelector } from "../../utils/hooks";
import { selectAuthenticated, selectLocale } from "../../redux/authSlice";

interface CustomProps {
  content: GoodPracticesDataType;
  templateId: string;
}

const Goodpractices = (props: CustomProps) => {
  const classes = GoodPracticesStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const locale = useAppSelector(selectLocale);
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const contentText = staticContent(locale, isAuthenticated);

  const getBanner = () => {
    return (
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={classes.bannerBg}
      >
        <Grid item xs={12} lg={6} md={12} sm={12} xl={6}>
          <Container maxWidth="xs">
            <Typography variant="h4" sx={classes.orangeHeadingText}>
              {parse(markdownText(props.content?.banner.title))}
            </Typography>
            <Typography sx={classes.blackHeadings} gutterBottom>
              {parse(markdownText(props.content?.banner.subtitle))}
            </Typography>

            {props.content?.banner.description.map((text) => {
              const hyphenText = text.replace(/-/g, "‑");
              return (
                <Typography variant="subtitle1" sx={classes.description}>
                  {parse(markdownText(hyphenText))}
                </Typography>
              );
            })}
          </Container>
        </Grid>
        <Grid item xs={12} lg={6} md={12} sm={12} xl={6}>
          <Box>
            <img
              src={bannerImage}
              alt={props.content?.banner?.altTextForImage}
              width={isDesktop ? "auto" : "90%"}
              height={"auto"}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const getTopics = () => {
    return (
      <Box>
        <CustomSection data={props.content?.practicesList} templateId={props.templateId} />
      </Box>
    );
  };

  return (
    <Box>
      {getBanner()}
      {!isDesktop && (
        <Box textAlign="center">
          <Typography sx={classes.orangeHeadingText}>
            {contentText.goodPractices.focusAreaText}
          </Typography>
        </Box>
      )}
      {getTopics()}
    </Box>
  );
};

export default Goodpractices;
