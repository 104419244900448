import { Box, Stack } from "@mui/material";
import Home from "../../../templates/Home/Home";
import AppButton from "../../../../../global/components/AppButton/AppButton";
import { getAllRoutes } from "../../../AdminService";
import { useEffect, useState } from "react";
import CustomLoader from "../../../../../global/components/CustomLoader/CustomLoader";
import notifiers from "../../../../../global/constants/NotificationConstants";
import { isTruthy, openErrorNotification } from "../../../../../helpers/methods";
import GoodPractices from "../../../templates/GoodPractices/GoodPractices";
import AboutPractices from "../../../templates/AboutPractices/AboutPractices";
import ContactPage from "../../../templates/Contact/Contact";
import Benchmarking from "../../../templates/Benchmarking/Benchmarking";
import Login from "../../../templates/Login/Login";
import SampleDashboard from "../../../templates/SampleDashboard/SampleDashboard";
import { useTitle } from "../../../../../utils/useTitle";

interface CustomProps {
  content: any;
  templateId: string;
  handlePrevious: Function;
  handleAddEditPage: Function;
  updatePageContent: Function;
  locale: string;
  pageTitle: string;
}

const PageDataSetup = (props: CustomProps) => {
  useTitle(props.pageTitle);
  const [routes, setRoutes] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getListOfRoutes(props.locale);
  }, []);

  const getListOfRoutes = async (locale: string) => {
    try {
      setLoading(true);
      const routes = await getAllRoutes(locale);
      setRoutes(routes);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const getPageComponent = () => {
    switch (props.templateId) {
      case "HOME":
        return (
          <Home
            content={props.content}
            updatePageContent={props.updatePageContent}
            templateId={props.templateId}
            routesList={routes}
          />
        );

      case "GOOD_PRACTICES":
        return (
          <GoodPractices
            content={props.content}
            updatePageContent={props.updatePageContent}
            templateId={props.templateId}
            routesList={routes}
          />
        );

      case "PRACTICE_DETAILS":
        return (
          <AboutPractices
            content={props.content}
            updatePageContent={props.updatePageContent}
            templateId={props.templateId}
            routesList={routes}
          />
        );

      case "CONTACT":
        return (
          <ContactPage
            content={props.content}
            updatePageContent={props.updatePageContent}
            templateId={props.templateId}
            routesList={routes}
          />
        );

      case "LOGIN":
        return (
          <Login
            content={props.content}
            updatePageContent={props.updatePageContent}
            templateId={props.templateId}
          />
        );

      case "SAMPLE_DASHBOARD":
        return (
          <SampleDashboard
            content={props.content}
            updatePageContent={props.updatePageContent}
            templateId={props.templateId}
          />
        );

      case "BENCHMARKING":
        return (
          <Benchmarking
            content={props.content}
            updatePageContent={props.updatePageContent}
            templateId={props.templateId}
          />
        );

      default:
        return (
          <Home
            content={props.content}
            updatePageContent={props.updatePageContent}
            templateId={props.templateId}
            routesList={routes}
          />
        );
    }
  };

  return (
    <Box>
      {getPageComponent()}
      <Stack direction="row" spacing={2} m={4}>
        <AppButton label="Back" onClick={props.handlePrevious} />
        <AppButton label={"Save"} onClick={() => props.handleAddEditPage()} />
      </Stack>
      <CustomLoader isLoading={isLoading} />
    </Box>
  );
};

export default PageDataSetup;
