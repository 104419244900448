import urls from "../../global/constants/UrlConstants";
import { getCallParams, getNoAuthCallParams, makeCall } from "../../utils/service";

export async function peerGroupCount(selectedString: string) {
  try {
    const body = { defItems: selectedString };
    const url = urls.getPeerGroupCount;
    const callParams = await getCallParams("PUT", body);
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function addPeerGroup(
  addInfo: { type: string; name: string; defItems: string },
  locale: string,
) {
  try {
    const body = addInfo;
    const url = `${urls.addPeeGroup}/lang/${locale}`;
    const callParams = await getCallParams("POST", body);
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function updatePeerGroup(
  addInfo: {
    type: string;
    name: string;
    defItems: string;
    id: string;
  },
  locale: string,
) {
  try {
    const body = addInfo;
    const url = `${urls.updatePeeGroup}/lang/${locale}`;
    const callParams = await getCallParams("PUT", body);
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function AllPeerGroups(pageNumber: number) {
  try {
    const url = urls.getAllPeerGroups + "/" + pageNumber;
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function AllPeerGroupsCount() {
  try {
    const url = urls.getPeerGroupsTotalCount;
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function deleteGroup(id: number) {
  try {
    const url = urls.deletePeerGroup + "/" + id;
    const callParams = await getCallParams("DELETE", {});
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function dashboardMeasures(locale: string, peerGroupdId: number) {
  try {
    const url = urls.DASHBOARD_MEASURES + "/" + locale.toUpperCase() + "/peerGroup/" + peerGroupdId;
    const callParams = await getNoAuthCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function dashboardMeasuresAfterLogin(locale: string, peerGroupdId: number) {
  try {
    const url =
      urls.AUTH_DASHBOARD_MEASURES + "/" + locale.toUpperCase() + "/peerGroup/" + peerGroupdId;
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}
