import { getRelativeFontSize, theme } from "../../../../utils/styles";

const sampleDashboardStyles = {
  label: {
    display: "flex",
    marginTop: "5px",
  },
  labelIcon: { color: "black" },
  labelText: {
    ...theme.typography.button,
    marginLeft: "6px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(),
    },
  },
  star: {
    color: "red",
    marginLeft: "4px",
    fontSize: getRelativeFontSize(2),
    ...theme.typography.button,
  },
  addLinks: {
    mt: 1,
    ml: 1,
    display: "flex",
    width: "100%",
    ...theme.typography.h6,
    color: theme.palette.secondary.dark,
    fontSize: getRelativeFontSize(),
    cursor: "pointer",
  },
  authenticationText: {
    ...theme.typography.button,
    marginLeft: "6px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(2),
    },
    color: theme.palette.primary.main,
  },
} as const;

export default sampleDashboardStyles;
