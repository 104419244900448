import { CSSProperties } from "@mui/material/styles/createMixins";
import {
  centerItemFlex,
  descriptionFont,
  getRelativeFontSize,
  mediumFontTitle,
  regularFont,
  theme,
} from "../../utils/styles";
import bg from "../../assets/images/Moredetails/bg.png";

const MeasuresStyles = {
  orangeHeadingText: {
    ...mediumFontTitle,
    color: theme.palette.secondary.main,
    fontSize: getRelativeFontSize(20),
    fontWeight: "bold",
    lineHeight: "65px",
  },
  blackHeadings: {
    ...theme.typography.h5,
    fontSize: getRelativeFontSize(4),
    fontWeight: 700,
    color: "#3E3E3F",
    mb: 1,
  },
  bullets: {
    ...descriptionFont,
    color: "#3E3E3F",
    fontSize: getRelativeFontSize(2),
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  bulletsWithoutHover: {
    ...descriptionFont,
    color: "#3E3E3F",
    fontSize: getRelativeFontSize(2),
  },
  description: {
    textAlign: "justify",
    fontSize: getRelativeFontSize(4),
    color: "#3E3E3F",
  },
  notScoreDescription: {
    fontSize: getRelativeFontSize(4),
    mb: 2,
    color: theme.palette.primary.main,
    ...mediumFontTitle,
  },
  bannerBg: {
    background: "url(" + bg + ")",
  },
  minMaxBox: {
    border: "1px solid #42406F4D",
    borderRadius: "12px",
    width: "350px",
    padding: "0 10px",
    boxShadow: "0px 10px 50px 0px #0000000D",
    mb: 2,
  },
  detailText: {
    fontSize: getRelativeFontSize(),
    color: "#3E3E3F",
    ...theme.typography.h6,
  },
  btnwithnoradius: {
    border: " 1px solid #42406F",
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    borderShadow: "none",
    fontSize: getRelativeFontSize(2),
  },
  whiteText: {
    fontSize: getRelativeFontSize(),
  },
  dropDown: {
    height: "50px",
    width: "100%",
    borderRadius: "12px",
    marginTop: "6px",
  },
  categoryDropdown: {
    ...theme.typography.body1,
    border: "1px solid rgba(252, 252, 255, 0.30)",
    borderRadius: "10px",
    color: "#FFF",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    height: "50px",
    mr: 1,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "350px!important",
      mb: 1,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  titleDropdown: {
    ...theme.typography.body1,
    backgroundColor: "rgba(248, 248, 255, 0.18)",
    border: "1px solid rgba(66, 64, 111, 0.30)",
    borderRadius: "10px",
    color: "#FFF",
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    height: "50px",
    width: "100%",
    "&:hover": {
      border: "1px solid rgba(66, 64, 111, 0.30) !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "350px!important",
      mb: 1,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  peerGroupDropdown: {
    ...theme.typography.body1,
    backgroundColor: "#FFF",
    border: "1px solid rgba(66, 64, 111, 0.30)",
    borderRadius: "10px",
    color: "#423F6F",
    "& .MuiSvgIcon-root": {
      color: "#423F6F",
    },
    height: "50px",
    [theme.breakpoints.down("md")]: {
      width: "200px!important",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  countBox: {
    border: "1.5px solid #FFFFFF",
    borderRadius: "5px",
    p: 1,
    width: "100px",
    textAlign: "center",
  },
  disclaimerText: {
    ...centerItemFlex,
    ...theme.typography.body2,
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: getRelativeFontSize(2),
    pt: 1,
  },
  headWrapper: {
    background: theme.palette.primary.main,
    py: 3,
  },
  flexHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  centeredDropdown: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
};

export default MeasuresStyles;
