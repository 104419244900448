import { CSSProperties } from "react";
import { regularFont, theme } from "../../../utils/styles";
import bannerbg from "../../../assets/images/Moredetails/bg.png";

const HomeStyles = {
  header: {
    background: "url(" + bannerbg + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    minHeight: "75vh",
    position: "relative",
    padding: `0 0 ${theme.spacing(14)} 0`,
    [theme.breakpoints.down("lg")]: {
      padding: `0 0 ${theme.spacing(10)} 0`,
    },
  },
  headWrapper: {
    maxWidth: "1440px",
    // margin: "0 auto",
  },

  cardLayout: {
    padding: `${theme.spacing(2)} 0`,
  },
  cardsWrapper: {
    background: "transparent",
    width: "100%",
    margin: `0 auto`,
  },
  cards: {
    margin: `0 auto`,
    paddingBottom: "40px",
    // transform: `translateY(-45%)`,
    [theme.breakpoints.down("lg")]: {
      flexWrap: "wrap",
    },
  },
  card: {
    ...regularFont,
    // marginTop: `-200px`,
    zIndex: 1001,
    transform: `translateY(-20%)`,
    [theme.breakpoints.down("lg")]: {
      transform: `translateY(0%)`,
    },
    background: theme.palette.primary.main,
    px: 2,
    py: 2,
    color: "#fff",
    borderRadius: "40px",
    textAlgin: "center",
    maxWidth: "30%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
    },
    justifyContent: "space-between",
  },
  cardIconBg: {
    padding: "20px",
    background: "rgba(255, 255, 255, .4)",
    borderRadius: "12px",
    width: "30%",
    marginBottom: `12px`,
    maxHeight: "90px",
    maxWidth: "90px",
  },
  aboutLayout: {
    paddingTop: `${theme.spacing(5)}`,
    position: "relative",
  },
  imageGrid: {
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      bottom: -15,
    },
  },
  hide: {
    display: "none",
  },
  visible: {
    display: "block",
  },
  carouselImage: {
    maxWidth: "100%",
    height: "auto",
    objectFit: "cover",
    // borderRadius: "0 0 0 200px",
  } as CSSProperties,
};

export default HomeStyles;
