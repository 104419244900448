import { Grid, Box, Typography, MenuItem, Select } from "@mui/material";
import CustomInput from "../../../../../../global/components/CustomInput/CustomInput";
import { Header, PracticesInternalPageData, Table } from "../../../../../../models/interface";
import AboutPracticesStyles from "../../AboutPractices.styles";
import { isTruthy, openErrorNotification } from "../../../../../../helpers/methods";
import { DeleteForeverOutlined } from "@mui/icons-material";
import strings from "../../../../../../global/constants/StringConstants";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import notifiers from "../../../../../../global/constants/NotificationConstants";

interface CustomProps {
  data: PracticesInternalPageData;
  setData: Function;
  sectionIndex: number;
  contentIndex: number;
}

const TableComponent = (props: CustomProps) => {
  const classes = AboutPracticesStyles;
  const table: Table =
    props.data.sections[props.sectionIndex]?.content[props.contentIndex]?.metadata;
  const [formData, setFormData] = useState({
    title: table?.title ?? "",
    width: table?.width ?? "",
    header: table?.header ?? [],
    rows: table?.rows ?? [],
  });

  const getInputHeader = (title: string, required: boolean, value: any) => {
    return (
      <Box
        sx={classes.label}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        borderColor="red"
      >
        <Box style={{ display: "flex" }}>
          {isTruthy(title) && (
            <>
              <Typography sx={classes.labelText}>{title}</Typography>
              {required && <Typography sx={classes.star}>*</Typography>}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const handleSectionOnChange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      title: event.target.value,
    });
    props.setData(
      {
        ...formData,
        title: event.target.value,
      },
      props.sectionIndex,
      props.contentIndex,
    );
  };

  const handleTableWidthOnChange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      width: event.target.value,
    });
    props.setData(
      {
        ...formData,
        width: event.target.value,
      },
      props.sectionIndex,
      props.contentIndex,
    );
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleRowsOnChange = (
    event: React.ChangeEvent<any>,
    rowIndex: number,
    headerName: string,
  ) => {
    const updatedSection = formData?.rows;
    updatedSection[rowIndex][headerName] = event.target.value;
    setFormData({
      ...formData,
      rows: updatedSection,
    });
    props.setData(
      {
        ...formData,
        rows: updatedSection,
      },
      props.sectionIndex,
      props.contentIndex,
    );
  };

  const handleColumnsOnChange = (event: React.ChangeEvent<any>, headerIndex: number) => {
    const updated = formData.header;
    table!.header[headerIndex]![event.target.name as "name" | "align"] = event.target.value;
    setFormData({
      ...formData,
      header: updated,
    });
    props.setData(
      {
        ...formData,
        header: updated,
      },
      props.sectionIndex,
      props.contentIndex,
    );
  };

  const deleteColumn = (headerIndex: number) => {
    const header = formData.header;
    const rows = formData.rows;
    const field: string = header[headerIndex].field;
    header.splice(headerIndex, 1);
    const updatedRows = rows.map((row) => {
      delete row[field];
      return row;
    });
    const updatedSection = formData;
    updatedSection.header = header;
    updatedSection.rows = updatedRows;
    setFormData(updatedSection);
    props.setData(updatedSection, props.sectionIndex, props.contentIndex);
  };

  const addNewColumn = () => {
    const updated = formData.header;
    const newColumnKey = uuidv4();
    updated.push({
      name: "",
      field: newColumnKey,
      align: "left",
    });
    const updatedRows = formData.rows;
    updatedRows.map((row) => {
      row[newColumnKey] = "";
    });
    setFormData({
      ...formData,
      header: updated,
      rows: updatedRows,
    });
    props.setData(
      {
        ...formData,
        header: updated,
        rows: updatedRows,
      },
      props.sectionIndex,
      props.contentIndex,
    );
  };

  const addNewRow = () => {
    const rows = formData.rows;
    const header = formData.header;
    let newRow: any = {};
    header.forEach((header) => (newRow[header.field] = ""));
    rows.push(newRow);
    setFormData({
      ...formData,
      rows: rows,
    });
    props.setData(
      {
        ...formData,
        rows: rows,
      },
      props.sectionIndex,
      props.contentIndex,
    );
  };

  const deleteRow = (index: number) => {
    try {
      const local = formData.rows;
      const updated = local.filter((v, i) => i !== index);
      setFormData({
        ...formData,
        rows: updated,
      });
      props.setData(
        {
          ...formData,
          rows: updated,
        },
        props.sectionIndex,
        props.contentIndex,
      );
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const getHeaderNames = (rowIndex: number) => {
    return formData!.header[rowIndex].name;
  };

  const getAlignmentDropDown = (value: string, headerIndex: number) => {
    return (
      <Select
        value={value}
        name="align"
        id="align"
        onChange={(event: any) => handleColumnsOnChange(event, headerIndex)}
        sx={classes.dropDown}
      >
        {strings.alignments.map((item: string) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
        {getInputHeader("Table Title", true, formData?.title)}
        <CustomInput
          placeHolder="Title"
          type="text"
          name="title"
          id="title"
          value={formData?.title}
          onChange={(event: any) => handleSectionOnChange(event)}
          onKeyPress={handleKeypress}
        />
        {getInputHeader("Width (will be considered in %)", true, formData?.title)}
        <CustomInput
          placeHolder="width"
          type="text"
          name="width"
          id="width"
          value={formData?.width}
          onChange={(event: any) => handleTableWidthOnChange(event)}
          onKeyPress={handleKeypress}
        />

        <Box>
          <Typography sx={classes.labelText}>Columns</Typography>
          {formData?.header?.map((column: Header, headerIndex: number) => {
            return (
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12} sm={12} md={12} xl={5} lg={5}>
                  {getInputHeader("Name", true, column.name)}
                  <CustomInput
                    placeHolder="Title"
                    type="text"
                    name="name"
                    id="name"
                    value={column.name}
                    onChange={(event: any) => handleColumnsOnChange(event, headerIndex)}
                    onKeyPress={handleKeypress}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={5} lg={5}>
                  {getInputHeader("Alignment", true, column.align)}
                  {getAlignmentDropDown(column.align, headerIndex)}
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={2} lg={2}>
                  {headerIndex > 0 && (
                    <Box sx={{ cursor: "pointer" }}>
                      <DeleteForeverOutlined onClick={() => deleteColumn(headerIndex)} />
                    </Box>
                  )}
                </Grid>
              </Grid>
            );
          })}
          <Box sx={classes.addLinks} onClick={() => addNewColumn()}>
            + Add Column
          </Box>
        </Box>
        <Box>
          <Typography sx={classes.labelText}>Rows</Typography>

          {formData?.rows.map((data, rowIndex) => {
            return (
              <Grid
                container
                alignItems="center"
                sx={{ borderBottom: "1px solid #E7E7F9", pb: 2 }}
                mb={2}
              >
                {Object.keys(data).map((x, rowDataIndex) => {
                  return (
                    <>
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                        <Typography sx={classes.labelText}>
                          {getHeaderNames(rowDataIndex)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <CustomInput
                          placeHolder="value"
                          type="text"
                          name="name"
                          id="name"
                          value={data[x]}
                          onChange={(event: any) => handleRowsOnChange(event, rowIndex, x)}
                          onKeyPress={handleKeypress}
                        />
                      </Grid>
                    </>
                  );
                })}
                {rowIndex > 0 && (
                  <Box sx={{ cursor: "pointer" }}>
                    <DeleteForeverOutlined onClick={() => deleteRow(rowIndex)} />
                  </Box>
                )}
              </Grid>
            );
          })}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography sx={classes.addLinks} onClick={() => addNewRow()}>
              + Add Row
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TableComponent;
