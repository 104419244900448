import { Grid, InputAdornment, Typography } from "@mui/material";
import { Stack, Box } from "@mui/system";
import { classes } from "istanbul-lib-coverage";
import React, { useState } from "react";
import strings from "../../../../../../global/constants/StringConstants";
import SectionModalStyles from "../SectionModal.styles";
import { isTruthy, openErrorNotification } from "../../../../../../helpers/methods";
import CustomInput from "../../../../../../global/components/CustomInput/CustomInput";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Data, PracticesInternalPageData } from "../../../../../../models/interface";
import notifiers from "../../../../../../global/constants/NotificationConstants";

interface CustomProps {
  data: PracticesInternalPageData;
  setData: Function;
  sectionIndex: number;
  contentIndex: number;
}

const TextComponent = (props: CustomProps) => {
  const classes = SectionModalStyles;
  const text: Data = props.data.sections[props.sectionIndex]?.content[props.contentIndex]?.metadata;
  const [formData, setFormData] = useState({
    title: text?.title ?? "",
    description: text?.description ?? [],
  });

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleOnChange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      title: event.target.value,
    });
    props.setData(
      {
        ...formData,
        title: event.target.value,
      },
      props.sectionIndex,
      props.contentIndex,
    );
  };

  const handleChangeDescription = (event: React.ChangeEvent<any>, index: number, type: string) => {
    const localDescriptionState = formData.description;
    localDescriptionState[index].type = type;
    localDescriptionState[index].content = event.target.value;
    setFormData({
      ...formData,
      description: localDescriptionState,
    });
    props.setData(
      {
        ...formData,
        description: localDescriptionState,
      },
      props.sectionIndex,
      props.contentIndex,
    );
  };

  const addNewPara = (type: string) => {
    const localDescriptionState = formData.description;
    localDescriptionState.push({ type: type, content: "" });
    setFormData({
      ...formData,
      description: localDescriptionState,
    });
    props.setData(
      {
        ...formData,
        description: localDescriptionState,
      },
      props.sectionIndex,
      props.contentIndex,
    );
  };

  const handleDeletePara = async (index: number) => {
    try {
      const local = formData.description;
      const updated = local.filter((v, i) => i !== index);
      setFormData({
        ...formData,
        description: updated,
      });
      props.setData(
        {
          ...formData,
          description: updated,
        },
        props.sectionIndex,
        props.contentIndex,
      );
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const getInputHeader = (title: string, required: boolean, value: any) => {
    return (
      <Box
        sx={classes.label}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        borderColor="red"
      >
        <Box style={{ display: "flex" }}>
          {isTruthy(title) && (
            <>
              <Typography sx={classes.labelText}>{title}</Typography>
              {required && <Typography sx={classes.star}>*</Typography>}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getInputs = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Title", true, formData.title)}
          <CustomInput
            placeHolder="Title"
            type="text"
            name="title"
            id="title"
            value={formData.title}
            onChange={handleOnChange}
            onKeyPress={handleKeypress}
            // error={!isTruthy(formData.about.title) && "Title cannot be empty"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {formData.description.map((ele, index: number) => {
            return (
              <>
                {getInputHeader(index > 0 ? "" : "Description", true, ele.content)}{" "}
                <CustomInput
                  placeHolder="Description"
                  type="text"
                  name="content"
                  id="content"
                  value={ele.content}
                  onChange={(event: any) => handleChangeDescription(event, index, ele.type)}
                  onKeyPress={handleKeypress}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteForeverIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={() => handleDeletePara(index)}
                        />
                      </InputAdornment>
                    ),
                  }}
                  // error={!isTruthy(ele) && "Atleast 3 description lines required"}
                />
              </>
            );
          })}
          <Box mt={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box sx={classes.addLinks} onClick={() => addNewPara(strings.Paragraph)}>
                + Add Description
              </Box>
              <Box sx={classes.addLinks} onClick={() => addNewPara(strings.bulledtedPara)}>
                + Add Bullets
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return <Box>{getInputs()}</Box>;
};

export default TextComponent;
