import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MeasuresInternalPageData } from "../../models/interface";
import MeasuresStyles from "./Measures.styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import urls from "../../global/constants/UrlConstants";
import { selectAuthenticated, selectLocale, selectRole } from "../../redux/authSlice";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import AppButton from "../../global/components/AppButton/AppButton";
import {
  alphabeticalSort,
  isTruthy,
  markdownText,
  openErrorNotification,
} from "../../helpers/methods";
import parse from "html-react-parser";
import ScoreGraph from "./components/ScoreGraph";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import notifiers from "../../global/constants/NotificationConstants";
import {
  retrieveAfterLoginMeasureDetails,
  retrieveAfterLoginPeerGroups,
  retrieveCategories,
  retrieveMeasureDetails,
  retrieveMeasures,
  retrievePeerGroups,
} from "./MeasureService";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import { theme } from "../../utils/styles";
import { savePeerGroup, selectedPeerGroup } from "../../redux/persistSlice";
import strings from "../../global/constants/StringConstants";
import CustomDialog from "../../global/components/CustomDialog/CustomDialog";
import history from "../../utils/history";
import { useLocation } from "react-router-dom";
import { staticContent } from "../../utils/locale";
import { dashboardMeasuresStylesData } from "../MockData";

interface CustomProps {
  content: MeasuresInternalPageData;
}

const Measures = (props: CustomProps) => {
  const classes = MeasuresStyles;
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const locale = useAppSelector(selectLocale).toUpperCase();
  const authenticated = useAppSelector(selectAuthenticated);
  const contentText = staticContent(locale.toLowerCase(), authenticated);
  const peerGroupPersisted = useAppSelector(selectedPeerGroup);
  const userRole = useAppSelector(selectRole);
  const isAuthenticated = authenticated && userRole === "coop";
  const urlParams = useLocation().search;
  const category = new URLSearchParams(urlParams).get("category");
  const measureId = new URLSearchParams(urlParams).get("measureId");
  const peerGroupId = new URLSearchParams(urlParams).get("peerGroupId");
  const [categories, setCategories] = useState([]);
  const [measures, setMeasures] = useState<string[]>([]);
  const [peerGroups, setPeerGroups] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<{ id: string; name: string }>({
    id: "",
    name: "",
  });
  const [selectedMeasure, setSelectedMeasure] = useState<{ id: string; name: string }>({
    id: "",
    name: "",
  });
  const [peerGroupSelected, setPeerGroupSelected] = useState<{ id: number; name: string }>(
    peerGroupPersisted,
  );
  const [pageData, setPageData] = useState({} as any);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [initLoaded, setInitLoaded] = useState(false);
  const [openModal, setOpenModal] = useState<{ isOpen: boolean; link: string }>({
    isOpen: false,
    link: "",
  });

  useEffect(() => {
    pageInit();
  }, []);

  useEffect(() => {
    if (initLoaded && isTruthy(selectedCategory.id)) {
      fetchMeasures(selectedCategory!);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (
      initLoaded &&
      isTruthy(selectedCategory.id) &&
      isTruthy(selectedMeasure.id) &&
      isTruthy(peerGroupSelected.id)
    ) {
      fetchPageData(selectedMeasure, peerGroupSelected);
    } else {
      // navigate(-1);
    }
  }, [selectedMeasure, peerGroupSelected]);

  const fetchPageData = async (
    selectedMeasure: { id: string; name: string },
    selectedPeerGroupId: { id: number; name: string },
  ) => {
    try {
      setLoading(true);
      const data = isAuthenticated
        ? await retrieveAfterLoginMeasureDetails(
            locale,
            selectedMeasure.id,
            selectedPeerGroupId.id,
            selectedCategory.name,
          )
        : await retrieveMeasureDetails(
            locale,
            selectedMeasure.id,
            selectedPeerGroupId.id,
            selectedCategory.name,
          );
      const parsedData = JSON.parse(data?.content ?? "");
      const parsed = {
        graphTitle: parsedData.graphTitle ?? "",
        graphDescription: parsedData.graphDescription ?? [{ title: "", description: [""] }],
        notApplicableDescription: parsedData.notApplicableDescription ?? [""],
      };
      setPageData({ ...data, content: parsed ?? "" });
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const addDisplayTitleAndSort = (cat: any) => {
    const localeMeasures = dashboardMeasuresStylesData[locale.toLowerCase()];
    const sortedCat = cat.map((category: any) => {
      const key = category.name.replace(/ /g, "-").toLowerCase();
      const localeData = localeMeasures[key];
      return {
        ...category,
        displayTitle: localeData.displayTitle,
      };
    });
    sortedCat.sort((a: any, b: any) => alphabeticalSort(a, b, "displayTitle"));
    return sortedCat;
  };

  const pageInit = async () => {
    try {
      setLoading(true);
      const [cat, groups, pageData] = await Promise.all([
        retrieveCategories(locale),
        isAuthenticated ? retrieveAfterLoginPeerGroups() : retrievePeerGroups(),
        isAuthenticated
          ? retrieveAfterLoginMeasureDetails(locale, measureId!, parseInt(peerGroupId!), category!)
          : retrieveMeasureDetails(locale, measureId!, parseInt(peerGroupId!), category!),
      ]);
      const sortedCat = addDisplayTitleAndSort(cat);
      const categoryIndex = sortedCat.findIndex(
        (c: { id: string; name: string; displayTitle: string }) => c.name === decodeURI(category!),
      );
      const parsedData = JSON.parse(pageData?.content ?? "");
      const parsed = {
        graphTitle: parsedData.graphTitle ?? "",
        graphDescription: parsedData.graphDescription ?? [{ title: "", description: [""] }],
        notApplicableDescription: parsedData.notApplicableDescription ?? [""],
      };
      setCategories(sortedCat);
      setSelectedCategory(sortedCat[categoryIndex]);

      const apiMeasure = await retrieveMeasures(sortedCat[categoryIndex].id, locale);
      apiMeasure.sort((a: any, b: any) => alphabeticalSort(a, b, "name"));
      setMeasures(apiMeasure);
      const measureIndex = apiMeasure.findIndex(
        (mes: any) => mes.id === parseInt(measureId ?? "0"),
      );
      setSelectedMeasure({
        id: apiMeasure[measureIndex]?.id,
        name: apiMeasure[measureIndex]?.name,
      });
      setPeerGroups(groups);
      const selectedGroup = groups.findIndex(
        (group: any) => group.id === parseInt(peerGroupId ?? "0"),
      );
      setPeerGroupSelected({ id: groups[selectedGroup].id, name: groups[selectedGroup].name });
      dispatch(savePeerGroup({ id: groups[selectedGroup].id, name: groups[selectedGroup].name }));
      setPageData({ ...pageData, content: parsed ?? "" });
      setInitLoaded(true);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchMeasures = async (selectedCategory: { id: string; name: string }) => {
    try {
      setLoading(true);
      const measure = await retrieveMeasures(selectedCategory.id, locale);
      measure.sort((a: any, b: any) => alphabeticalSort(a, b, "name"));
      setMeasures(measure);
      if (isTruthy(measure)) {
        setSelectedMeasure({
          id: measure[0].id,
          name: measure[0].name,
        });
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const getCategoryDropDown = () => {
    return (
      <Select
        label={null}
        aria-label="Without label"
        value={JSON.stringify(selectedCategory)}
        name="hyperlink"
        id="hyperlink"
        sx={classes.categoryDropdown}
        onChange={(event: any) => {
          const cat = JSON.parse(event.target.value);
          history.push(
            `${urls.measuresViewPath}?category=${cat.name}&measureId=${selectedMeasure.id}&peerGroupId=${peerGroupSelected.id}`,
          );
          setSelectedCategory(JSON.parse(event.target.value));
        }}
        MenuProps={{
          disableScrollLock: true,
        }}
        displayEmpty
        // inputProps={{ "aria-label": "Without label" }}
      >
        {categories.map((category: { id: string; name: string; displayTitle: string }) => {
          return <MenuItem value={JSON.stringify(category)}>{category.displayTitle}</MenuItem>;
        })}
      </Select>
    );
  };

  const getMeasuresDropDown = () => {
    return (
      <Select
        value={JSON.stringify(selectedMeasure)}
        name="hyperlink"
        id="hyperlink"
        sx={classes.titleDropdown}
        onChange={(event: any) => {
          const mes = JSON.parse(event.target.value);
          history.push(
            `${urls.measuresViewPath}?category=${selectedCategory.name}&measureId=${mes.id}&peerGroupId=${peerGroupSelected.id}`,
          );
          setSelectedMeasure(JSON.parse(event.target.value));
        }}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {measures.map((measure: any) => (
          <MenuItem value={JSON.stringify({ id: measure.id, name: measure.name })}>
            {measure.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const getPeerGroupsDropDown = () => {
    return (
      <Select
        value={JSON.stringify(peerGroupSelected)}
        name="hyperlink"
        id="hyperlink"
        sx={classes.peerGroupDropdown}
        onChange={(event: any) => {
          const selected = JSON.parse(event.target.value);
          setPeerGroupSelected(selected);
          dispatch(savePeerGroup(selected));
          history.push(
            `${urls.measuresViewPath}?category=${selectedCategory.name}&measureId=${selectedMeasure.id}&peerGroupId=${selected.id}`,
          );
        }}
        MenuProps={{
          disableScrollLock: true,
        }}
      >
        {peerGroups.map((group: any, index: number) => (
          <MenuItem value={JSON.stringify({ id: group.id, name: group.name })}>
            {locale.toLowerCase() === "en"
              ? group.name
              : contentText.sampleDashboard.comparison.peerGroups[index] ?? group.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const getYoutubeThumbnail = (link: any) => {
    if (isTruthy(link)) {
      const id = link[1];
      return id;
    }
    return "";
  };

  const checkRedirection = (practice: any) => {
    if (practice.isExternal) {
      const videoId = getYoutubeThumbnail(practice.link.match(strings.youtubeVideoIdRegex));
      setOpenModal({ isOpen: true, link: videoId });
    } else {
      history.push(practice.link);
    }
  };

  const getRelatedPractices = () => {
    return (
      <Box>
        <Typography sx={classes.blackHeadings} mb={3}>
          {locale.toLowerCase() === "en" ? "Related Good Practices" : "Bonnes pratiques associées"}
        </Typography>
        {pageData?.goodPractices?.map((practice: any) => {
          return (
            <List
              dense
              component={"ul"}
              sx={{ display: "flex", alignItems: "flex-start", "&.MuiList-root": { padding: 0 } }}
            >
              <FiberManualRecordIcon
                fontSize="small"
                sx={{ width: 10, paddingTop: "10px" }}
                color="primary"
              />

              <ListItem sx={{ m: 0, p: 0, pl: 1 }}>
                {isTruthy(practice.link) ? (
                  <Typography
                    variant="subtitle1"
                    sx={classes.bullets}
                    onClick={() => checkRedirection(practice)}
                  >
                    {practice.label}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" sx={classes.bulletsWithoutHover}>
                    {practice.label}
                  </Typography>
                )}
              </ListItem>
            </List>
          );
        })}
      </Box>
    );
  };

  const getDrawerData = () => {
    return <Box p={2}>{getRelatedPractices()}</Box>;
  };

  const getHeader = () => {
    return (
      <Box sx={classes.headWrapper}>
        <Container maxWidth="xl" sx={classes.flexHead}>
          <Box sx={classes.centeredDropdown}>
            <Tooltip title={contentText.general.goBack}>
              <ArrowBackIcon sx={{ display: "inline", mr: 1 }} onClick={() => history.goBack()} />
            </Tooltip>
            {getCategoryDropDown()}
            {getMeasuresDropDown()}
          </Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{contentText.sampleDashboard.comparison.comparedToText}</Typography>{" "}
            <Box>{getPeerGroupsDropDown()}</Box>
          </Stack>
        </Container>
      </Box>
    );
  };

  const getBanner = () => {
    const city = pageData?.coopCity?.match(strings.vowelRegex);
    return (
      <Grid container p={1} display="flex" justifyContent="space-evenly">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography sx={classes.blackHeadings}>
            {locale.toLowerCase() === "en"
              ? parse(
                  markdownText(
                    `Benchmark Report for ${
                      authenticated ? pageData?.coopName ?? "XYZ Co-op" : "XYZ Co-op"
                    }` ?? "",
                  ),
                )
              : parse(
                  markdownText(
                    `Rapport d'étalonnage pour une coopérative située à ${
                      authenticated ? pageData?.coopName ?? "Coopérative XYZ" : "Coopérative XYZ"
                    }` ?? "",
                  ),
                )}
          </Typography>
          <Typography sx={classes.description}>
            {locale.toLowerCase() === "en"
              ? parse(
                  markdownText(
                    `The benchmark report for this measure lets you compare your co-op to other co-ops in predefined or custom peer groups. These results are for
                ${city ? "an" : "a"} ${pageData?.coopCity ?? ""} co-op with ${
                      pageData?.units ?? ""
                    } suburban units. ${
                      !isAuthenticated
                        ? "[Log in](/login) now to see the actual results for your own co-op."
                        : ""
                    }`,
                  ),
                )
              : parse(
                  markdownText(
                    `Le rapport d'étalonnage pour cette mesure vous permet de comparer votre coopérative à d'autres coopératives d'habitation faisant partie de groupes de pairs prédéfinis ou personnalisés. Les résultats qui suivent sont ceux d’une coopérative d’habitation d’${
                      city ? "une" : "un"
                    } ${pageData?.coopCity ?? ""} dotée de ${
                      pageData?.units ?? ""
                    } logements en banlieue. ${
                      !isAuthenticated
                        ? "[Ouvrez une session](/login) pour voir les résultats de votre coopérative d'habitation."
                        : ""
                    }`,
                  ),
                )}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const formateData = (data: any) => {
    return data.map((values: any) => {
      return {
        year: values.year ?? 0,
        value: values.value ?? 0,
        displayValue: values.displayValue ?? "0",
        score: values.score ?? 0,
        peerCount: values.peerCount ?? 0,
        median: values.median ?? 0,
        displayMedian: values.displayMedian ?? "0",
      };
    });
  };

  const getGraph = () => {
    return (
      <Box>
        <Typography sx={classes.blackHeadings}>{pageData?.content?.graphTitle ?? ""}</Typography>
        <Box
          sx={{
            height: "auto",
            width: "auto",
            border: "1px solid #E6E6E6",
            borderRadius: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2" sx={classes.disclaimerText}>
            {contentText.general.graphText}
          </Typography>
          <ScoreGraph
            data={formateData(pageData?.values ?? [])}
            min={pageData?.min ?? 0}
            max={pageData?.max ?? 0}
            isAuthenticated={isAuthenticated}
            locale={locale}
            subTitle={pageData.subTitle}
          />
        </Box>
      </Box>
    );
  };

  const getGraphSection = () => {
    return (
      <Grid container p={1} display="flex" spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          {pageData?.content?.graphDescription?.map((data: any, index: number) => {
            return (
              <Box>
                <Typography sx={classes.blackHeadings}>
                  {parse(markdownText(data?.title ?? ""))}
                </Typography>
                {index === 0 &&
                  pageData?.scoreNotAvailable &&
                  pageData?.content?.notApplicableDescription.map((text: string) => {
                    return (
                      <Typography sx={classes.notScoreDescription} gutterBottom>
                        {parse(markdownText(text) ?? "")}
                      </Typography>
                    );
                  })}
                {data?.description?.map((desc: string) => {
                  return (
                    <Typography sx={classes.description} gutterBottom>
                      {parse(markdownText(desc ?? ""))}
                    </Typography>
                  );
                })}
              </Box>
            );
          })}

          {!isAuthenticated && (
            <AppButton
              label={locale.toLowerCase() === "en" ? "Log In" : "Ouvrir une session"}
              withRadius
              styles={classes.btnwithnoradius}
              onClick={() => history.push(urls.loginViewPath)}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          {!pageData.scoreNotAvailable && getGraph()}
        </Grid>
      </Grid>
    );
  };

  const dialogBody = () => (
    <Box>
      <iframe
        width={isDesktop ? 560 : "100%"}
        height="315"
        src={`https://www.youtube.com/embed/${openModal.link}`}
        title="YouTube video player"
        style={{ maxHeight: "315", maxWidth: 560 }}
        allowFullScreen
      ></iframe>
    </Box>
  );

  const handleCloseModel = () => {
    setOpenModal({ isOpen: false, link: "" });
  };

  const getCustomDialog = () => {
    return (
      <CustomDialog
        isDialogOpen={openModal.isOpen}
        closable
        closeButtonVisibility
        handleDialogClose={handleCloseModel}
        dialogBodyContent={dialogBody()}
        dialogHeaderContent={<></>}
      />
    );
  };

  return (
    <Box>
      {getHeader()}
      <Grid
        container
        display="flex"
        flexDirection={isDesktop ? "row" : "column-reverse"}
        sx={{ height: isDesktop ? "100vh" : "auto" }}
      >
        <Grid item lg={2} xl={2} md={4} sx={{ backgroundColor: "#FFF3F2" }}>
          {getDrawerData()}
        </Grid>
        <Grid item lg={10} xl={10} md={8} p={2}>
          {getBanner()}
          {getGraphSection()}
        </Grid>
      </Grid>
      <CustomLoader isLoading={isLoading} />
      {getCustomDialog()}
    </Box>
  );
};

export default Measures;
