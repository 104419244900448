import { Box, Container, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useAppSelector } from "../../../../utils/hooks";
import { selectAuthenticated, selectRole } from "../../../../redux/authSlice";
import Login from "../../../Login/Login";
import CustomLoader from "../../../../global/components/CustomLoader/CustomLoader";
import AdminDashboardStyles from "./Dashboard.styles";
import PagesList from "./components/PagesList";
import MeasuresList from "./components/MeasuresList";

const AdminDashboard = () => {
  const classes = AdminDashboardStyles;
  const [isLoading, setLoading] = useState<boolean>(false);
  const authenticated = useAppSelector(selectAuthenticated);
  const userRole = useAppSelector(selectRole);
  const isAuthenticated = authenticated && userRole === "cms";
  const [loadedList, setLoadedList] = useState<string>("Pages");

  const getHeader = () => {
    return (
      <Container maxWidth="xl">
        <Stack direction="row" spacing={2} alignItems="center">
          <Box onClick={() => setLoadedList("Pages")}>
            <Typography sx={[classes.pagesHeading, loadedList === "Pages" && classes.activeList]}>
              Pages List
            </Typography>
          </Box>
          <Box onClick={() => setLoadedList("Measures")}>
            <Typography
              sx={[classes.pagesHeading, loadedList === "Measures" && classes.activeList]}
            >
              Measures List
            </Typography>
          </Box>
        </Stack>
      </Container>
    );
  };

  const getComponent = () => {
    switch (loadedList) {
      case "Pages":
        return <PagesList />;
      case "Measures":
        return <MeasuresList />;
      default:
        return <PagesList />;
    }
  };

  if (!isAuthenticated) {
    return <Login adminLogin />;
  }

  return (
    <Box sx={classes.header}>
      <Box mb={2}>{getHeader()}</Box>
      {getComponent()}
      <CustomLoader isLoading={isLoading} />
    </Box>
  );
};

export default AdminDashboard;
