import { MeasuresInternalPageData } from "../../../../models/interface";

export const InputFields = (prevState?: MeasuresInternalPageData) => {
  return {
    goodPractices: prevState?.goodPractices ?? [{ label: "", link: "", isExternal: false }],
    content: prevState?.content ?? {
      graphTitle: "",
      graphDescription: [{ title: "", description: [""] }],
      notApplicableDescription: [""],
    },
    name: prevState?.name ?? "",
    category: prevState?.category ?? "",
    rowId: prevState?.rowId ?? "",
    id: prevState?.id ?? "",
  } as MeasuresInternalPageData;
};
