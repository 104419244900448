import React, { useState } from "react";
import { Box, Container, Tooltip, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoginFields, loginPageContentData } from "../../../../models/interface";
import { descriptionFont, theme } from "../../../../utils/styles";
import AppButton from "../../../../global/components/AppButton/AppButton";
import AppTextField from "../../../../global/components/AppTextField/AppTextField";
import strings from "../../../../global/constants/StringConstants";
import urls from "../../../../global/constants/UrlConstants";
import { isTruthy, markdownText } from "../../../../helpers/methods";
import { selectLocale } from "../../../../redux/authSlice";
import { useAppSelector } from "../../../../utils/hooks";
import { loginForm } from "../../../Login/LoginTypesAndValidation";
import LoginStyles from "../../../Login/Login.styles";
import HomeRunLogo from "../../../../assets/images/logos/logo-light-en.svg";
import HomeRunLogoFr from "../../../../assets/images/logos/logo-light-fr.svg";
import parse from "html-react-parser";
import history from "../../../../utils/history";

const classes = LoginStyles;

interface CustomProps {
  content: loginPageContentData;
  templateId: string;
}

const LoginPreview = (props: CustomProps) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [formFields, setFormFields] = useState<LoginFields>(loginForm());
  const locale = useAppSelector(selectLocale);

  const handleOnChangeInputField = (event: React.ChangeEvent<any>) => {
    setFormFields({
      ...formFields,
      [event.target.name]: {
        ...formFields[event.target.name],
        value: event.target.value,
      },
    });
  };

  const getLoginCard = () => {
    return (
      <Box sx={classes.backgroundBox}>
        <Container maxWidth="xl">
          <Box sx={classes.wrapper}>
            <Box
              sx={{
                my: 2,
                position: "relative",
                zIndex: 2,
              }}
            >
              <Box sx={classes.loginCard}>
                <Tooltip title="Go Back" onClick={() => history.goBack()}>
                  <ArrowBackIcon sx={{ display: "inline" }} />
                </Tooltip>
                <Box>
                  <Typography sx={classes.title} variant="h5" fontWeight={700} display="inline">
                    <Typography
                      sx={classes.title}
                      variant="h5"
                      fontWeight={700}
                      color="secondary"
                      display="inline"
                    >
                      {props.content.welcomeText.orangeText}
                    </Typography>{" "}
                    {props.content.welcomeText.blackText}
                  </Typography>
                  <Typography fontSize={18} fontWeight={500} variant="subtitle1">
                    {props.content.subTitle}
                  </Typography>
                </Box>
                <Typography sx={classes.subHeading} variant="h5" fontWeight={700}>
                  Log In
                </Typography>
                <Box display="flex" gap={2} flexDirection={"column"}>
                  <AppTextField
                    fieldLabel={`Username`}
                    placeholder="Your Username"
                    onKeyPress={() => {}}
                    onChange={handleOnChangeInputField}
                    fieldId="email"
                    fieldName="email"
                    required
                    fullWidth
                    error={!isTruthy(formFields.email.value) && formFields.email.error}
                  />
                  <AppTextField
                    fieldLabel={`Password`}
                    placeholder="Your Password"
                    onKeyPress={() => {}}
                    onChange={handleOnChangeInputField}
                    fieldId="password"
                    fieldName="password"
                    type="password"
                    required
                    fullWidth
                    error={!isTruthy(formFields.password.value) && formFields.password.error}
                  />
                  <Box>
                    <Typography
                      my={1}
                      fontSize={16}
                      sx={{ ...descriptionFont }}
                      variant="subtitle1"
                    >
                      {parse(markdownText(props.content.description))}
                    </Typography>
                  </Box>
                </Box>
                <AppButton
                  label={"Log In"}
                  withRadius
                  styles={{
                    alignSelf: "center",
                    fontSize: 14,
                    paddingBottom: `8px`,
                    paddingTop: `8px`,
                    fontWeight: 400,
                    width: "auto",
                  }}
                  onClick={() => {}}
                />
              </Box>
              <Typography sx={{ mt: 4, textAlign: "center", fontSize: 14 }}>
                © 2023 HomeRun. All Rights Reserved
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    );
  };

  return (
    <>
      <Box sx={classes.header}>
        <Container maxWidth="xl">
          <img
            src={locale === strings.EN ? HomeRunLogo : HomeRunLogoFr}
            alt="Homerun"
            height="80px"
            style={{ cursor: "pointer" }}
            onClick={() => history.push(urls.homeViewPath)}
          />
        </Container>
      </Box>
      {getLoginCard()}
    </>
  );
};

export default LoginPreview;
