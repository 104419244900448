import { GoodPracticesDataType } from "../../../../models/interface";

export const InputFields = (prevState?: GoodPracticesDataType) => {
  return {
    banner: {
      title: prevState?.banner?.title ?? "",
      subtitle: prevState?.banner?.subtitle ?? "",
      altTextForImage: prevState?.banner?.altTextForImage ?? "",
      description: prevState?.banner?.description ?? [""],
      button: {
        label: prevState?.banner?.button.label ?? "",
        hyperlink: prevState?.banner?.button.hyperlink ?? "",
        isExternal: prevState?.banner?.button.hyperlink ?? false,
      },
    },
    practicesList: prevState?.practicesList ?? [
      {
        practiceIcon: "",
        practiceIconAltText: "",
        title: "",
        practices: [
          {
            redirectUrl: "",
            icon: "",
            altText: "",
            title: "",
            isExternal: false,
          },
        ],
      },
    ],
  } as GoodPracticesDataType;
};
