import { CSSProperties } from "@mui/material/styles/createMixins";
import { descriptionFont, getRelativeFontSize, mediumFontTitle, theme } from "../../utils/styles";
import bg from "../../assets/images/contact_banner.png";
import { getBodyHeight } from "../../helpers/methods";

const ContactStyles = {
  orangeHeadingText: {
    ...mediumFontTitle,
    color: theme.palette.secondary.main,
    fontSize: getRelativeFontSize(30),
    fontWeight: "bold",
    lineHeight: "50px",
  },
  blackHeadings: {
    ...mediumFontTitle,
    fontSize: getRelativeFontSize(4),
    fontweight: "bold",
  },
  description: {
    ...theme.typography.body1,
    color: "#212121",
    fontSize: getRelativeFontSize(2),
  },
  bannerBg: {
    background: "url(" + bg + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
  },
  contactFormBox: {
    padding: 2,
    borderRadius: "12px",
    border: "1px solid #8D8D8D",
    background: "#FFF",
  },
  btnwithnoradius: {
    border: " 1px solid #42406F",
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    borderShadow: "none",
    fontSize: getRelativeFontSize(2),
  },
  orangeBox: {
    padding: "0px 16px",
    marginTop: 4,
    marginBottom: 4,
    borderRadius: "12px",
    background: "#F3776C",
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      right: -250,
      top: 70,
      width: "350px",
      marginTop: 0,
    },
  },
  bullets: {
    ...descriptionFont,
    color: "#FFFFFF",
    fontSize: getRelativeFontSize(4),
  },
  inputField: {
    "&::placeholder": {
      color: "red",
    },
  },
};

export default ContactStyles;
