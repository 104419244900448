import { selectLocale } from "../../redux/authSlice";
import { useAppSelector } from "../../utils/hooks";
import { store } from "../../utils/store";
import { StringConstants } from "./StringConstants";

class NotificationConstants extends StringConstants {
  GENERIC_ERROR =
    store.getState().auth.locale === "en" ? "An error has occurred" : "Une erreur s'est produite";
  LOGGEDOUT =
    store.getState().auth.locale === "en"
      ? "Please login to continue."
      : "Veuillez ouvrir une session pour continuer";
}

let notifiers = new NotificationConstants();
export default notifiers;
