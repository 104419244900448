import urls from "../../global/constants/UrlConstants";
import { Page } from "../../models/interface";
import {
  getCallParams,
  getFileCallParams,
  getNoAuthCallParams,
  makeCall,
} from "../../utils/service";

export async function updatePage(pageData: Page) {
  try {
    const body = pageData;
    const callParams = await getCallParams("PUT", body);
    const response: any = await makeCall(urls.updatePage, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function addPage(pageData: Page) {
  try {
    const body = pageData;
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(urls.addPageAPI, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function totalPagesCount() {
  try {
    const url = urls.ADMIN_DYNAMIC_ROUTES_PAGE_COUNT;
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function searchPageCount(title: string) {
  try {
    const url = urls.ADMIN_DYNAMIC_ROUTES_PAGE_SEARCH_COUNT + "/" + title + "/count";
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function searchMeasureCount(title: string) {
  try {
    const url = urls.searchMeasureCount + "/" + title;
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const addImage = async (formData: any, templateId: string) => {
  try {
    const callParams = await getFileCallParams(formData);
    const response: any = await makeCall(urls.addImage + "/" + templateId, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const deleteImage = async (templateId: string, fileName: string) => {
  try {
    const url = urls.deleteImage + "/" + templateId + "/" + fileName;
    const callParams = await getCallParams("DELETE", {});
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const deletePage = async (pageId: string) => {
  try {
    const url = urls.deletePage + "/" + pageId;
    const callParams = await getCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const validateRoutes = async (route: String, locale: string) => {
  try {
    const body = { locale, route };
    const callParams = await getCallParams("POST", body);
    const response: any = await makeCall(urls.validateRoutes, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getAllRoutes = async (locale: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.listOfRoutes + "/" + locale, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getAllMeasureRoutes = async (page: number) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.measureList + "/" + page, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getMeasure = async (id: string, locale: string) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.getMeasureDetails + "/" + id + "/locale/" + locale,
      callParams,
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const getMeasureCount = async () => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(urls.measureCount, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const saveMeasureByLocale = async (data: any, locale: string) => {
  try {
    const body = data;
    const callParams = await getCallParams("PUT", body);
    const response: any = await makeCall(urls.updateMeasure + "/" + locale, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updateMeasureStatus = async (id: number, status: boolean) => {
  try {
    const callParams = await getCallParams("PUT");
    const response: any = await makeCall(
      urls.updateMeasurePageStatus + "/" + id + "/status/" + status,
      callParams,
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const search = async (title: string, page: number) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.searchPage + "/" + title + "/page/" + page,
      callParams,
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const searchMeasureByTitle = async (title: string, page: number) => {
  try {
    const callParams = await getCallParams("GET");
    const response: any = await makeCall(
      urls.searchMeasurePage + "/" + title + "/page/" + page,
      callParams,
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const updatePageStatus = async (status: boolean, pageId: string) => {
  try {
    const callParams = await getCallParams("PUT", {});
    const response: any = await makeCall(
      urls.updatePageStatus + "/" + status + "?pageId=" + pageId,
      callParams,
    );
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const retrieveMeasureRoutesForRedirection = async (locale: string) => {
  try {
    const url = urls.getMeasuresRoutes + "/" + locale;
    const callParams = getNoAuthCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
};
