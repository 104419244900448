import { useState, useEffect } from "react";
import light from "./../assets/icons/logo-light.png";
import dark from "./../assets/icons/logo-dark.png";

const FaviconUpdater = () => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (event: MediaQueryList) => {
      setTheme(event.matches ? "dark" : "light");
    };

    // Set the initial theme based on the browser's preference
    handleChange(mediaQuery);

    // Listen for changes in the theme preference
    mediaQuery.addEventListener("change", () => handleChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      mediaQuery.removeEventListener("change", () => handleChange);
    };
  }, []);

  useEffect(() => {
    // Update the favicon based on the current theme
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    favicon!.href = theme === "light" ? dark : light;
  }, [theme]);

  return null;
};

export default FaviconUpdater;
