import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  StepConnector,
  Typography,
  stepConnectorClasses,
  styled,
  useMediaQuery,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { theme } from "../../../utils/styles";
import { staticContent } from "../../../utils/locale";
import { selectAuthenticated, selectLocale, selectRole } from "../../../redux/authSlice";
import { useAppSelector } from "../../../utils/hooks";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#13B9A5",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#13B9A5",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

interface CustomProps {
  data: any;
  activeStep: number;
}

const BenchmarkingSteps = (props: CustomProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const locale = useAppSelector(selectLocale);
  const userRole = useAppSelector(selectRole);
  const authenticated = useAppSelector(selectAuthenticated);
  const isAuthenticated = authenticated && userRole === "coop";
  const content = props.data;

  return (
    <Stepper activeStep={props.activeStep} alternativeLabel connector={<QontoConnector />}>
      {content?.map((step: any, index: number) => {
        const StepIcon = () => {
          return (
            <CircleIcon style={{ color: index <= props.activeStep ? "#13B9A5" : "#FFCACA" }} />
          );
        };
        return (
          <Step key={step.title}>
            <StepLabel StepIconComponent={StepIcon}>
              <Typography
                sx={{
                  // transform: isMobile ? "rotate(-90deg)" : "rotate(0)",
                  fontSize: isMobile ? 10 : 20,
                }}
              >
                {step.title}
              </Typography>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default BenchmarkingSteps;
