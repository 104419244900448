import { Box } from "@mui/material";
import Goodpractices from "./Goodpractices/Goodpractices";
import Home from "./Template/Home/Home";
import { useState, useEffect } from "react";
import { isTruthy, openErrorNotification } from "../helpers/methods";
import { getPageDataById } from "./PageRenderService";
import notifiers from "../global/constants/NotificationConstants";
import CustomLoader from "../global/components/CustomLoader/CustomLoader";
import PracticeDetail from "./PracticesDetail/PracticeDetail";
import Contact from "./Contact/Contact";
import Benchmarking from "./Benchmarking/Benchmarking";
import SampleDashboard from "./SampleDashboard/SampleDashboard";
import { useTitle } from "../utils/useTitle";
import Layout from "./Shared/Layout/Layout";

interface CustomProps {
  pageId: string;
}

const PageRender = (props: CustomProps) => {
  const [pageData, setPageData] = useState({} as any);
  const [isLoading, setLoading] = useState<boolean>(false);
  useTitle(pageData.title);

  useEffect(() => {
    fetchPageData();
  }, [props.pageId]);

  const fetchPageData = async () => {
    try {
      setLoading(true);
      const pageData = await getPageDataById(props.pageId);
      setPageData({
        pageId: props.pageId,
        templateId: pageData.templateId,
        content: JSON.parse(pageData.data),
        title: pageData.title,
      });
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const getTemplateComponent = (templateId: string) => {
    switch (templateId) {
      case "HOME":
        return <Home content={pageData.content} templateId={templateId} />;
      case "GOOD_PRACTICES":
        return <Goodpractices content={pageData.content} templateId={templateId} />;
      case "PRACTICE_DETAILS":
        return <PracticeDetail content={pageData.content} templateId={templateId} />;
      case "CONTACT":
        return <Contact content={pageData.content} />;
      case "BENCHMARKING":
        return <Benchmarking content={pageData.content} templateId={templateId} />;
      case "SAMPLE_DASHBOARD":
        return <SampleDashboard content={pageData.content} templateId={templateId} />;
      default:
        return <Home content={pageData.content} templateId={templateId} />;
    }
  };

  const getPageWithLayout = (templateId: string) => {
    return <Layout>{getTemplateComponent(templateId)}</Layout>;
  };

  const getPage = () => {
    if (!isTruthy(pageData)) {
      return (
        <Box>
          <CustomLoader isLoading={isLoading} />
        </Box>
      );
    }
    return getPageWithLayout(pageData.templateId);
  };

  return getPage();
};

export default PageRender;
