import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  MenuItem,
  Select,
  Stack,
  FormHelperText,
} from "@mui/material";
import addPageStyles from "../AddPage.styles";
import homess from "../../../../../assets/images/screens/home.png";
import goodpracticesss from "../../../../../assets/images/screens/goodpractices.png";
import detailsss from "../../../../../assets/images/screens/details.png";
import aboutpracticesss from "../../../../../assets/images/screens/practicedetails.png";
import benchmarkingss from "../../../../../assets/images/screens/benchmarking.png";
import contact from "../../../../../assets/images/screens/contact-us.png";
import login from "../../../../../assets/images/screens/login.png";
import sampleDashboard from "../../../../../assets/images/screens/sample-dashboard.png";
import { theme } from "../../../../../utils/styles";
import homeStyles from "../../../templates/Home/Home.styles";
import CustomInput from "../../../../../global/components/CustomInput/CustomInput";
import AppButton from "../../../../../global/components/AppButton/AppButton";
import { validateRoutes } from "../../../AdminService";
import { compareStrings, isTruthy, openErrorNotification } from "../../../../../helpers/methods";
import history from "../../../../../utils/history";

interface CustomProps {
  handleNext: Function;
  pageData: any;
  setPageData: any;
  editMode: boolean;
  currentRouteAndLocale: any;
  showError: boolean;
}

const templates = [
  { title: "HOME", screen: homess, displayText: "Home" },
  { title: "GOOD_PRACTICES", screen: goodpracticesss, displayText: "Good Practices" },
  { title: "PRACTICE_DETAILS", screen: aboutpracticesss, displayText: "About Practices" },
  { title: "BENCHMARKING", screen: benchmarkingss, displayText: "Benchmarking" },
  { title: "CONTACT", screen: contact, displayText: "Contact" },
  { title: "LOGIN", screen: login, displayText: "Login" },
  { title: "SAMPLE_DASHBOARD", screen: sampleDashboard, displayText: "Sample Dashboard" },
];

const PageInfo = (props: CustomProps) => {
  const classes = addPageStyles;
  const homeClasses = homeStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const isRouteAndLocaleModified = () => {
    const currentRoute = props.currentRouteAndLocale.route;
    const currentLocale = props.currentRouteAndLocale.locale;
    let areFieldDirty = false;
    if (
      !(
        compareStrings(currentLocale, props.pageData.locale) &&
        compareStrings(currentRoute, props.pageData.route)
      )
    ) {
      areFieldDirty = true;
    }
    return areFieldDirty;
  };

  const handleNext = async () => {
    try {
      if (isRouteAndLocaleModified()) {
        await checkAvailableRoutes();
      }
      props.handleNext();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : error.GENERIC_ERROR,
      );
    }
  };

  const checkAvailableRoutes = async () => {
    try {
      const response = await validateRoutes(props.pageData.route, props.pageData.locale);
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    props.setPageData({
      ...props.pageData,
      [event.target.name]: event.target.value,
    });
  };
  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const getLocalDropDown = () => {
    return (
      <Select
        value={props.pageData.locale}
        onChange={(event: any) =>
          props.setPageData({
            ...props.pageData,
            locale: event.target.value,
          })
        }
        sx={classes.dropDown}
      >
        <MenuItem value={"en"}>English</MenuItem>
        <MenuItem value={"fr"}>French</MenuItem>
      </Select>
    );
  };

  const getInputFields = () => {
    return (
      <Box sx={{ width: 500 }}>
        <Typography gutterBottom sx={classes.mediumFonts}>
          {props.editMode ? "Edit" : "Add"} Page Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <Box sx={homeClasses.label} borderColor="red">
              <Typography sx={homeClasses.labelText}>Title </Typography>
              <Typography sx={homeClasses.star}>*</Typography>
            </Box>
            <CustomInput
              placeHolder="Title"
              type="text"
              name="title"
              id="title"
              value={props.pageData.title}
              onChange={handleChange}
              onKeyPress={handleKeypress}
            />
            {props.showError && !isTruthy(props.pageData.title) && (
              <FormHelperText error>Title cannot be empty</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
            <Box sx={homeClasses.label} borderColor="red">
              <Typography sx={homeClasses.labelText}>Route</Typography>
              <Typography sx={homeClasses.star}>*</Typography>
            </Box>
            <CustomInput
              placeHolder="/link"
              type="text"
              name="route"
              id="route"
              value={props.pageData.route}
              onChange={handleChange}
              onKeyPress={handleKeypress}
            />
            {props.showError && !isTruthy(props.pageData.route) && (
              <FormHelperText error>Route cannot be empty</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={6} xl={6} lg={6}>
            <Box sx={homeClasses.label}>
              <Typography sx={homeClasses.labelText}>Locale</Typography>
              <Typography sx={homeClasses.star}>*</Typography>
            </Box>
            {getLocalDropDown()}
            {props.showError && !isTruthy(props.pageData.locale) && (
              <FormHelperText error>Please select the locale from the dropdown</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };
  return (
    <Box m={4}>
      {getInputFields()}
      <Typography gutterBottom sx={classes.mediumFonts} mt={2}>
        Choose a template.
      </Typography>
      {props.showError && !isTruthy(props.pageData.templateId) && (
        <FormHelperText error>Please select the template</FormHelperText>
      )}
      <Grid container spacing={2} alignItems="center" mt={2} mb={2}>
        {templates.map((template) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <Box
                sx={[
                  classes.imageBox,
                  props.pageData.templateId === template.title && classes.selectedTemplate,
                ]}
                onClick={() => props.setPageData({ ...props.pageData, templateId: template.title })}
              >
                <img
                  src={template.screen}
                  height={isDesktop ? 250 : "auto"}
                  width={"100%"}
                  style={{
                    borderRadius: "10px 10px 0 0",
                  }}
                />
                <Typography sx={classes.regularFonts}>{template.displayText}</Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Stack direction="row" spacing={1}>
        <AppButton label={"Back"} onClick={() => history.goBack()} />
        <AppButton label={"Next"} onClick={() => handleNext()} />
      </Stack>
    </Box>
  );
};

export default PageInfo;
