import { CSSProperties } from "@mui/material/styles/createMixins";
import { getRelativeFontSize, mediumFontTitle, theme } from "../../utils/styles";
import bg from "../../assets/images/Moredetails/bg.png";

const GoodPracticesStyles = {
  orangeHeadingText: {
    ...mediumFontTitle,
    color: theme.palette.secondary.main,
    fontSize: getRelativeFontSize(22),
    lineHeight: "65px",
  },
  blackHeadings: {
    ...mediumFontTitle,
    fontSize: getRelativeFontSize(4),
    fontweight: "bold",
  },
  description: {
    textAlign: "justify",
    fontSize: getRelativeFontSize(4),
    mb: 2,
    letterSpacing: "0.8px",
  },
  bannerBg: {
    [theme.breakpoints.down("md")]: {
      p: 2,
    },
    mt: 2,
    [theme.breakpoints.up("md")]: {
      background: "url(" + bg + ")",
    },
  },
};

export default GoodPracticesStyles;
