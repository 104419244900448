import strings from "../global/constants/StringConstants";

const getYear = () => {
  return new Date().getFullYear();
};

export const staticContent = (locale: string, isAuthenticated: boolean) => {
  if (locale === strings.EN) {
    return {
      general: {
        goBack: "Go Back",
        peerGroupAdded: "New peer group created",
        email: "Please enter email id",
        password: "Please enter password",
        graphText: "*Hover over the bars below to see your scores!",
        who: "Who We Are",
        measures: "Related Measures",
        practice: "Related Good Practices",
        resource: "Resources",
      },
      footer: {
        copyright: `Copyright © 2012-${getYear()} The Agency for Co-operative Housing`,
        agencyLink: "https://www.agency.coop/",
        linkName: "www.agency.coop",
      },
      home: {
        learnMoreText: "Learn more.",
      },
      navbar: {
        home: "Home",
        features: "Features",
        pricing: "Pricing",
        contact_us: "Contact Us",
        benchmark: "Benchmark",
        sign_in: "Sign In",
        login: "Log In",
        logout: "Logout",
        sign_up_free: "Sign up Free!",
        benchmarking: "Benchmarking",
        good_practices: "Good Practices",
        sample_dashboard: "Sample Dashboard",
        dashboard: "Dashboard",
      },
      benchmarking: [
        {
          title: "How it Works",
          description: [
            "Through benchmarking, you can track your progress year over year, compare expenses against your peers, and establish a reputation for strong performance that attracts new applicants and reduces turnover.",
            "Click the arrow to learn how you can benchmark your co-op using HomeRun.",
          ],
        },
        {
          title: "Sample Dashboard",
          description: ["Look at the sample dashboard to get an idea of what to expect."],
        },
        {
          title: "Log In",
          description: ["Sign in to benchmark your co-op against others in your peer group."],
        },
        {
          title: "Check Your Results",
          description: [
            "Explore your co-op's scores on each benchmarked measure. Look for home runs and areas for improvement. ",
          ],
        },
        {
          title: "Choose Peer Groups",
          description: [
            "Create comparison peer groups based on factors like co-op size, building type, management type, province, or city.",
          ],
        },
        {
          title: "Save Peer Groups",
          description: [
            "Customize the names of your chosen peer groups. They will be available on your dashboard when you log in again. You can create as many groups as you want.",
          ],
        },
        {
          title: "Explore Results",
          description: [
            "Dive deeper into each measure for more detailed results. Discover relevant resources and success stories on each measure's page.",
            isAuthenticated
              ? "Visit your [Dashboard](/sample-dashboard) to begin."
              : "Ready to get started? [Log in](/login) to begin.",
          ],
        },
      ],
      sampleDashboard: {
        heading: "Sample Benchmarking Dashboard",
        disclaimer:
          "This dashboard looks at how well your co‑op is doing, and where it could get better results. The dashboard shows your co‑op’s performance compared with its peers on a variety of measures in different categories",
        scoringSystemHeading: "Scoring System",
        scoringSystemSubTitle:
          "Your co-op’s performance is measured on a scale of one to four houses:",
        scoringSystemdesc: [
          "Four houses put the co-op in the top 15% of its peer group",
          "Three and two houses represent middle-of-the-pack or average performance",
          "One house puts the co-op in the lowest 15%",
        ],
        scoreSystemRating: [
          {
            title: "In the top 15%",
            score: 4,
          },
          {
            title: "Above the median",
            score: 3,
          },
          {
            title: "Below the median",
            score: 2,
          },
          {
            title: "In the bottom 15%",
            score: 1,
          },
        ],
        measuringCriteria:
          "For most measures, the co-op’s performance is compared to the peer group you have selected.",
        comparison: {
          heading: "A Note about Comparisons",
          description: [
            "Comparing one co-op with another can be helpful but may not always show the whole picture. You will need to keep any specific circumstances and context in mind when assessing benchmark performance.",
            "To get the most out of the benchmarking process, think about the “quick pick” peer group you want to be compared to. Your options are:",
          ],
          comparedToText: "Compared to:",
          peerGroups: ["All", "Province", "City"],
          summary:
            "If you decide that you would like to compare all the measures to one of these groups, click on the dropdown on the top right corner. This option will also work if you select a custom peer group.",
        },
      },
      contact: {
        heading: "Contact Us!",
        feedbackQuestions: [
          "Does your co‑op have any good practices to share?",
          "Was an idea from another co‑op helpful?",
          "Have you gained new insights into co‑op operations as a result of benchmarking your co‑op against others?",
        ],
        formPlaceholders: {
          name: "Your Name",
          email: "Your Email Address",
          coop: "Your Housing Co-op (If Applicable)",
          province: "Your Province",
          message: "How can we help?",
        },
      },
      goodPractices: {
        focusAreaText: "Focus Areas",
      },
      peerGroup: {
        header: {
          heading: "Peer Group Definitions",
          newPeergrp: "Define a New Peer Group",
          cancel: "Cancel",
        },
        table: {
          heading: "Your Custom Peer Groups",
          headers: {
            name: "Peer Group Name",
            char: "Peer Group Characteristics",
            updated: "Last Updated",
            action: "Action",
          },
        },
        predefined: {
          heading: "Predefined Peer Groups",
          values: {
            all: "All Agency Co-ops",
            province: "Co-ops in your Province",
            city: "Co-ops in your City",
          },
        },
        filters: {
          units: "Any number of units",
          type: "Any building type",
          mgmt: "Any type of management",
          location: "Anywhere",
        },
      },
      peerGroupForm: {
        header: {
          heading: "Define a Custom Peer Group",
          desc: `Number of co-ops in custom peer group (This will change as you make selections)`,
        },
        coopSize: {
          size: {
            heading: "Co-op Size",
            values: ["35 units or less", "36 to 80 units", "More than 80 units"],
          },
          buildingType: {
            heading: "Building Type",
            values: [
              "Walk-up apartment building",
              "Elevator apartment building",
              "Townhouses",
              "Single family homes, Duplexes or Triplexes",
            ],
          },
          mgmtType: {
            heading: "Management Type",
            values: [
              "Management company",
              "Paid staff",
              "Paid bookkeeper only",
              "Volunteers only",
              "Other",
            ],
          },
        },
        location: {
          heading: "Location",
          province: {
            heading: "Province",
            values: ["B.C.", "Alberta", "Ontario", "P.E.I."],
            validationText:
              "You've chosen to select location by province, so you must select at least one entry under province.",
          },
          city: {
            heading: "City",
            values: [
              {
                province: "British Columbia",
                city: [
                  { label: "Burnaby", value: "Burnaby" },
                  { label: "Richmond", value: "Richmond" },
                  { label: "Vancouver", value: "Vancouver" },
                  { label: "Victoria", value: "Victoria" },
                ],
              },
              {
                province: "Alberta",
                city: [
                  { label: "Calgary", value: "Calgary" },
                  { label: "Edmonton", value: "Edmonton" },
                ],
              },
              {
                province: "Ontario",
                city: [
                  { label: "London", value: "London" },
                  { label: "Ottawa", value: "Ottawa" },
                  { label: "Toronto", value: "Toronto" },
                ],
              },
            ],
            validationText:
              "You’ve chosen to select location by city, so you must select at least one entry under city.",
          },
        },
      },
      addPeerGroup: {
        heading: "Give your custom peer group a name, and click to save and view your results.",
        description: [
          "The defined peer group will be saved.",
          "Later, you can define and name other types of peer groups to compare your co-op to.",
        ],
      },
    };
  } else {
    return {
      general: {
        email: "Veuillez saisir votre adresse courriel",
        password: "Veuillez saisir votre mot de passe",
        goBack: "Page précédente",
        peerGroupAdded: "Nouveau groupe de pairs créé",
        graphText: "*Placez votre curseur sur les barres ci-dessous pour voir vos résultats!",
        who: "Qui sommes-nous?",
        measures: "Indicateurs connexes",
        practice: "Pratiques exemplaires connexes",
        resource: "Ressources",
      },
      footer: {
        copyright: `Droits d'auteur © 2012-${getYear()} l'Agence des coopératives d'habitation`,
        agencyLink: "https://www.agence.coop/",
        linkName: "www.agence.coop",
      },
      home: {
        learnMoreText: "Apprenez-en davantage.",
      },
      navbar: {
        home: "Accueil",
        features: "Fonctionnalités",
        pricing: "Tarification",
        contact_us: "Pour nous joindre",
        benchmark: "Comparaison",
        sign_in: "Se connecter",
        sign_up_free: "Inscription gratuite!",
        benchmarking: "Étalonnage",
        good_practices: "Bonnes pratiques ",
        sample_dashboard: "Exemple de tableau de bord",
        dashboard: "Tableau de bord",
        login: "Ouvrir une session",
        logout: "Fermer la session",
      },
      benchmarking: [
        {
          title: "Comment ça fonctionne",
          description: [
            "Grâce à l'analyse comparative, vous pouvez suivre vos progrès d'une année sur l'autre, comparer vos dépenses à celles de vos pairs et établir une réputation de performance solide qui attire de nouveaux candidats et réduit le roulement.",
            "Cliquez sur la flèche pour savoir comment vous pouvez comparer votre coopérative à l'aide de HomeRun.",
          ],
        },
        {
          title: "Exemple de tableau de bord",
          description: [
            "Grâce à l'analyse comparative, vous pouvez suivre vos progrès d'une année sur l'autre, comparer vos dépenses à celles de vos pairs et établir une réputation de performance solide qui attire de nouveaux candidats et réduit le roulement.",
            "Cliquez sur la flèche pour savoir comment vous pouvez comparer votre coopérative à l'aide de HomeRun.",
          ],
        },
        {
          title: "Connexion",
          description: [
            "Connectez-vous pour comparer votre coopérative à d'autres membres de votre groupe de pairs.",
          ],
        },
        {
          title: "Vérifiez vos résultats",
          description: [
            "Explorez les scores de votre coopérative pour chaque mesure de référence. Recherchez les coups de circuit et les points à améliorer.",
          ],
        },
        {
          title: "Choisissez des groupes de pairs",
          description: [
            "Créez des groupes de pairs de comparaison en fonction de facteurs tels que la taille de la coopérative, le type de bâtiment, le type de gestion, la province ou la ville.",
          ],
        },
        {
          title: "Enregistrer les groupes de pairs",
          description: [
            "Personnalisez les noms des groupes de pairs que vous avez choisis. Ils seront disponibles sur votre tableau de bord lorsque vous vous reconnecterez. Vous pouvez créer autant de groupes que vous le souhaitez.",
          ],
        },
        {
          title: "Explorer les résultats",
          description: [
            "Plongez plus profondément dans chaque mesure pour des résultats plus détaillés. Découvrez les ressources pertinentes et les exemples de réussite sur la page de chaque mesure.",
            "Prêt à commencer? [Connectez-vous](/login) pour commencer.",
          ],
        },
      ],
      sampleDashboard: {
        heading: "Exemple de tableau de bord d'étalonnage",
        disclaimer:
          "Ce tableau de bord examine dans quelle mesure notre exemple de coopérative se porte bien et où elle pourrait obtenir de meilleurs résultats. Ces repères sont pour une coopérative ontarienne de 100 unités de banlieue, comparativement à des groupes de pairs choisis par l'Agence.",
        scoringSystemHeading: "Système de pointage",
        scoringSystemSubTitle:
          "Your co-op's performance is measured on a scale of one to four houses:",
        scoringSystemdesc: [
          "Un pointage de quatre maisons signifie que la coopérative se situe dans les 15 % des meilleurs parmi ses pairs.",
          "Deux ou trois maisons correspondent à un rendement moyen, soit légèrement supérieur, ou légèrement inférieur à la médiane.",
          "Un pointage d'une seule maison place la coopérative parmi les 15 % les moins performantes.",
        ],
        scoreSystemRating: [
          {
            title: "dans les 15 % supérieurs",
            score: 4,
          },
          {
            title: "Au-dessus de la médiane",
            score: 3,
          },
          {
            title: "Sous la médiane",
            score: 2,
          },
          {
            title: "dans les 15 % inférieurs",
            score: 1,
          },
        ],
        measuringCriteria:
          "Pour la plupart des indicateurs, le rendement de la coopérative est comparé à celui des coopératives du groupe de pairs indiqué.",
        comparison: {
          heading: "Mise en garde au sujet des comparaisons",
          description: [
            "Malgré son utilité, la comparaison avec d'autres coopératives ne dit pas tout. Vous devez faire appel à votre jugement et à vos propres connaissances de la situation de votre coopérative pour bien évaluer le rendement comparé.",
            "Pour tirer le maximum du processus d'étalonnage, pensez au groupe de pairs auquel vous souhaitez comparer votre coopérative. Les options de groupe de pairs sont les suivantes :",
          ],
          comparedToText: "Comparaison avec :",
          peerGroups: ["Tout", "Province", "Ville"],
          summary:
            "Si vous souhaitez comparer votre coopérative à l'un de ces groupes pour tous les indicateurs, cliquez sur « Appliquer le changement à tous les indicateurs » juste au dessous. Cette option fonctionnera également si vous sélectionnez un groupe de pairs personnalisé.",
        },
      },
      contact: {
        heading: "Merci de communiquer avec nous!",
        feedbackQuestions: [
          "Votre coopérative a-t-elle des pratiques exemplaires qu’elle aimerait faire connaître?",
          "Une idée d’une autre coopérative vous a-t-elle été utile?",
          "Avez-vous découvert de nouveaux points de vue sur l’exploitation de la coopérative après avoir comparé vos résultats à ceux des autres?",
        ],
        formPlaceholders: {
          name: "Votre nom",
          email: "Votre adresse de courriel",
          coop: "Votre coopérative d'habitation (s'il y a lieu)",
          province: "Votre province",
          message: "Comment pouvons-nous vous aider?",
        },
      },
      goodPractices: {
        focusAreaText: "Domaines d’intervention",
      },
      peerGroup: {
        header: {
          heading: "Définitions des groupes de pairs",
          newPeergrp: "Définir un nouveau groupe de pairs",
          cancel: "Annuler",
        },
        table: {
          heading: "Vos groupes de pairs personnalisés",
          headers: {
            name: "Nom du groupe de pairs",
            char: "Caractéristiques du groupe de pairs",
            updated: "Dernière mise à jour",
            action: "Action",
          },
        },
        predefined: {
          heading: "Groupes de pairs prédéfinis",
          values: {
            all: "Toutes les coopératives de l’Agence",
            province: "Coopératives dans votre province",
            city: "Coopératives dans votre ville",
          },
        },
        filters: {
          units: "Tout nombre d’unités",
          type: "Tout type de bâtiment",
          mgmt: "Tout type de gestion ",
          location: "Tout lieu",
        },
      },
      peerGroupForm: {
        header: {
          heading: "Définir un groupe de pairs personnalisé",
          desc: "Nombre de coopératives appartenant au groupe de pairs personnalisé (ce nombre changera à mesure que vous faites des sélections)",
        },
        coopSize: {
          size: {
            heading: "Taille de la coopérative",
            values: ["35 unités ou moins", "De 36 à 80 unités", "Plus de 80 unités"],
          },
          buildingType: {
            heading: "Type de bâtiment",
            values: [
              "Immeuble d’appartements sans ascenseur",
              "Immeuble d’appartements avec ascenseur",
              "Maisons en rangée",
              "Maisons unifamiliales, duplex et triplex",
            ],
          },
          mgmtType: {
            heading: "Type de gestion",
            values: [
              "Société de gestion",
              "Personnel rémunéré",
              "Aide-comptable rémunéré seulement",
              "Bénévoles seulement",
              "Autre",
            ],
          },
        },
        location: {
          heading: "Lieu",
          province: {
            heading: "Province",
            values: ["C.-B.", "Alberta", "Ontario", "I.-P.-É."],
            validationText:
              "Comme vous avez opté pour sélectionner le lieu selon la province, vous devez sélectionner au moins une option dans la liste des provinces.",
          },
          city: {
            heading: "Ville",
            values: [
              {
                province: "Colombie-Britannique",
                city: [
                  { label: "Burnaby", value: "Burnaby" },
                  { label: "Richmond", value: "Richmond" },
                  { label: "Vancouver", value: "Vancouver" },
                  { label: "Victoria", value: "Victoria" },
                ],
              },
              {
                province: "Alberta",
                city: [
                  { label: "Calgary", value: "Calgary" },
                  { label: "Edmonton", value: "Edmonton" },
                ],
              },
              {
                province: "Ontario",
                city: [
                  { label: "London", value: "London" },
                  { label: "Ottawa", value: "Ottawa" },
                  { label: "Toronto", value: "Toronto" },
                ],
              },
            ],
            validationText:
              "Comme vous avez opté pour sélectionner le lieu selon la ville, vous devez sélectionner au moins une option dans la liste des ville.",
          },
        },
      },
      addPeerGroup: {
        heading:
          "Donnez un nom à votre groupe de pairs, et cliquez pour enregistrer et afficher vos résultats.",
        description: [
          "Le groupe de pairs défini sera enregistré.",
          "Vous pourrez plus tard définir et nommer d’autres types de groupes de pairs, et y comparer votre coopérative.",
        ],
      },
    };
  }
};
