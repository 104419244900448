import { getRelativeFontSize, lightGreyBg, theme } from "../../../utils/styles";

const AppTextFieldStyles = {
  textFieldStyles: {
    borderRadius: "6px",
    fontSize: 14,
    border: "none",
    color: "#3E3E3F",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      "&.Mui-focused fieldset": {
        borderColor: "#4B0150",
      },
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
      color: "#757575",
      opacity: 1,
    },
  },
  inputPropsStyles: {
    fontSize: getRelativeFontSize(-2),
    outline: "none",
    border: "none",
    fontFamily: theme.typography.button,
  },
  input: {
    padding: 0,
  },
};

export default AppTextFieldStyles;
