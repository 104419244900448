import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalLayout from "../../Pages/Global/GlobalLayout";
import CustomInput from "../../../../global/components/CustomInput/CustomInput";
import { contactInputFields } from "./ContactTypesAndValidation";
import homeStyles from "./Contact.styles";
import PreviewHomeComponent from "../../../Template/Home/Home";
import strings from "../../../../global/constants/StringConstants";
import { addImage, deleteImage, getAllRoutes } from "../../AdminService";
import { retrieveImage } from "../../../PageRenderService";
import { imageRenderer } from "../../../../utils/service";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { isTruthy, openErrorNotification } from "../../../../helpers/methods";
import notifiers from "../../../../global/constants/NotificationConstants";
import { v4 as uuidv4 } from "uuid";
import CustomLoader from "../../../../global/components/CustomLoader/CustomLoader";
import Contact from "../../../Contact/Contact";

interface CustomProps {
  content: any;
  updatePageContent: Function;
  templateId: string;
  routesList: string[];
}

const ContactPage = (props: CustomProps) => {
  const classes = homeStyles;
  const [formData, setFormData] = useState(contactInputFields(props.content));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.updatePageContent(formData);
  }, [formData]);

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleOnchange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeDescription = (event: React.ChangeEvent<any>, index: number) => {
    const localInformatoryText = formData.informatoryText;
    localInformatoryText[index] = event.target.value;
    setFormData({
      ...formData,
      informatoryText: localInformatoryText,
    });
  };

  const addNewRow = () => {
    const localDescriptionState = formData.informatoryText;
    localDescriptionState.push("");
    setFormData({
      ...formData,
      informatoryText: localDescriptionState,
    });
  };

  const handleDeleteInformatoryText = async (index: number) => {
    const local = formData.informatoryText;
    const updated = local.filter((v, i) => i !== index);
    setFormData({
      ...formData,
      informatoryText: updated,
    });
  };

  const getSection1 = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Title", true, formData.title)}
          <CustomInput
            placeHolder="Title"
            type="text"
            name="title"
            id="title"
            value={formData.title}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
            // error={!isTruthy(formData.banner.title) && "Title cannot be empty"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {formData.informatoryText.map((ele, index: number) => {
            return (
              <>
                {getInputHeader(index > 0 ? "" : "Description", true, ele)}
                <CustomInput
                  placeHolder="Enter text"
                  type="text"
                  name="informatoryText"
                  id="informatoryText"
                  value={ele}
                  onChange={(event: any) => handleChangeDescription(event, index)}
                  onKeyPress={handleKeypress}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteForeverIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={() => handleDeleteInformatoryText(index)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            );
          })}
          <Box sx={classes.addLinks} onClick={() => addNewRow()}>
            + Add
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Alternative Text for Graphic", true, formData.title)}
          <CustomInput
            placeHolder="Text"
            type="text"
            name="altText"
            id="altText"
            value={formData.altText}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
            // error={!isTruthy(formData.banner.title) && "Title cannot be empty"}
          />
        </Grid>
      </Grid>
    );
  };

  const getInputHeader = (title: string, required: boolean, value: any) => {
    return (
      <Box
        sx={classes.label}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        borderColor="red"
      >
        <Box style={{ display: "flex" }}>
          {isTruthy(title) && (
            <>
              <Typography sx={classes.labelText}>{title}</Typography>
              {required && <Typography sx={classes.star}>*</Typography>}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getPreviewScreen = () => {
    return (
      <Box>
        <Contact content={formData} />
      </Box>
    );
  };

  const sections = [{ section: getSection1(), id: uuidv4(), name: "Contact", isDeletable: false }];

  return (
    <Box>
      <GlobalLayout section={sections} pageTitle={"Home"} previewElement={getPreviewScreen()} />
      <CustomLoader isLoading={loading} />
    </Box>
  );
};

export default ContactPage;
