import { Box } from "@mui/system";
import { PracticesInternalPageData } from "../../../../../../models/interface";
import { Grid, Typography } from "@mui/material";
import { classes } from "istanbul-lib-coverage";
import strings from "../../../../../../global/constants/StringConstants";
import SectionModalStyles from "../SectionModal.styles";
import CustomInput from "../../../../../../global/components/CustomInput/CustomInput";
import { isTruthy, openErrorNotification } from "../../../../../../helpers/methods";
import notifiers from "../../../../../../global/constants/NotificationConstants";
import { addImage } from "../../../../AdminService";
import { useState } from "react";
import { imageRenderer } from "../../../../../../utils/service";

interface CustomProps {
  data: PracticesInternalPageData;
  setData: Function;
  sectionIndex: number;
  contentIndex: number;
  templateId: string;
}

const ImageComponent = (props: CustomProps) => {
  const classes = SectionModalStyles;
  const image = props.data.sections[props.sectionIndex]?.content[props.contentIndex]?.metadata;
  const [formData, setFormData] = useState({
    image: image?.image ?? "",
    altText: image?.altText ?? "",
  });

  const handleOnDropZoneFile = async (e: any) => {
    try {
      const data = new FormData();
      const file = e.target.files[0];
      data.append("file", file);
      const response = await addImage(data, props.templateId);
      setFormData({ ...formData, image: response.filename });
      props.setData(
        {
          image: response.filename,
          altText: formData.altText,
        },
        props.sectionIndex,
        props.contentIndex,
      );
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    }
  };

  const handleOnChange = (event: React.ChangeEvent<any>) => {
    setFormData({ ...formData, altText: event.target.value });
    props.setData(
      {
        ...formData,
        altText: event.target.value,
      },
      props.sectionIndex,
      props.contentIndex,
    );
  };

  const getInput = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} xl={2} lg={2} justifyContent="center">
          <Box sx={classes.label}>
            <Typography sx={classes.labelText}> Preview</Typography>
          </Box>
          {isTruthy(formData.image) && (
            <img
              src={imageRenderer(props.templateId, formData.image)}
              width={50}
              height={50}
              alt={formData.altText}
              style={{ marginTop: "5px" }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
          <Box sx={classes.label}>
            <Typography sx={classes.labelText}>Image</Typography>
          </Box>
          <CustomInput
            type="file"
            name="file"
            value={""}
            onChange={(e: any) => handleOnDropZoneFile(e)}
            InputProps={{
              accept: strings.acceptedFiles,
              dataMaxSize: 3000000,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
          <Box sx={classes.label}>
            <Typography sx={classes.labelText}>Image Alternative Text</Typography>
          </Box>
          <CustomInput
            type="text"
            name="altText"
            id="altText"
            placeHolder="Text"
            value={formData.altText}
            onChange={handleOnChange}
          />
        </Grid>
      </Grid>
    );
  };
  return <Box>{getInput()}</Box>;
};

export default ImageComponent;
