import { primaryColor } from "../../utils/styles";

const PageNotFoundStyles = {
  mainContainer: {
    backgroundColor: primaryColor,
    width: "100vw",
    height: "100vh",
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
} as const;

export default PageNotFoundStyles;
