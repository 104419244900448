import urls from "../global/constants/UrlConstants";
import { store } from "./store";
import { RESET_STORE } from "../redux/authSlice";
import strings from "../global/constants/StringConstants";
import notifiers from "../global/constants/NotificationConstants";
import { isTruthy } from "../helpers/methods";
import history from "./history";

// Checks if the error code is 401 or 403 -> Logout the user
export const checkStatus = async (error: any) => {
  if (error.status === 403 || error.status === 401) {
    store.dispatch({ type: RESET_STORE });
    history.push(urls.homeViewPath);
    return true;
  }
  return false;
};

// Authenticated Call Headers
export const getCallParams = async (methodType: string, body?: any) => {
  const accessToken = "Bearer " + store.getState().auth.accessToken;
  return {
    method: methodType,
    headers: await getHeaderObject(accessToken, strings.applicationJSON),
    body: JSON.stringify(body),
  };
};

// Unauthenticated Call Headers
export const getNoAuthCallParams = (methodType: string, body?: any) => {
  return {
    method: methodType,
    headers: strings.applicationJSON,
    body: JSON.stringify(body),
  };
};

export const getFileCallParams = async (body: any) => {
  const accessToken = "Bearer " + store.getState().auth.accessToken;
  return {
    method: "POST",
    headers: await getHeaderObject(accessToken, strings.multipartForm),
    body: body,
  };
};

export const getNoAuthFileCallParams = (body: any) => {
  return {
    method: "POST",
    headers: strings.multipartForm,
    body: body,
  };
};

export async function getHeaderObject(accessToken: string, contentType: any) {
  try {
    if (accessToken) {
      return {
        ...contentType,
        Authorization: accessToken,
      };
    }
    window.history.pushState(null, "", urls.homeViewPath);
    return null;
  } catch (error: any) {
    throw error;
  }
}

// Triggers the api call
// If the api call takes more time than timeout, then it will timeout the call.
export const makeCall = async (callName: string, callParams: any) => {
  try {
    let call = fetch(callName, callParams);
    let response: any;
    if (urls.BYPASS_TIMEOUT.some((url) => callName.includes(url))) {
      response = await Promise.race([call]).catch((error) => {
        throw error;
      });
    } else {
      let timeout = getTimeoutPromise();
      response = await Promise.race([timeout, call]).catch((error) => {
        throw error;
      });
    }

    const json = await response.json();
    if (response && response.ok) {
      return json;
    }
    throw json;
  } catch (error: any) {
    if (await checkStatus(error)) {
      throw { error: true, errorMessage: notifiers.LOGGEDOUT };
    }
    throw error;
  }
};

export const getTimeoutPromise = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () =>
        reject({
          error: true,
          message:
            store.getState().auth.locale === "en"
              ? "Something went wrong. Please reload the page."
              : "Un problème est survenu. Veuillez réessayer plus tard.",
        }),
      20000, // 20 Seconds
    );
  });
};

export async function getAccessToken(methodType: string, body?: any, token?: string) {
  const accessToken = "Bearer " + token;
  return {
    method: methodType,
    headers: await getHeaderObject(accessToken, strings.applicationJSON),
    body: JSON.stringify(body),
  };
}

export async function getDynamicRoutesData() {
  try {
    const locale = store.getState().auth.locale;
    const url = urls.DYNAMIC_ROUTES + "/" + locale;
    const callParams = getNoAuthCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getDynamicRoutesDataForAdmin(page: number) {
  try {
    const url = urls.ADMIN_DYNAMIC_ROUTES + "/" + page;
    const callParams = getNoAuthCallParams("GET");
    const response = await makeCall(url, callParams);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export const imageRenderer = (templateId: string, fileName: string) => {
  if (isTruthy(fileName)) {
    return urls.getImage + "/" + templateId + "/" + fileName;
  }
  return "";
};

export const isVideo = (filename: string) => {
  const extension = filename.split(".")[1];
  return extension === "mp4";
};
