import { CSSProperties } from "@mui/material/styles/createMixins";
import { theme } from "../../utils/styles";
import bg from "../../assets/images/contact_banner.png";

const LoginStyles = {
  header: {
    py: { xs: 2, md: 3 },
    px: 1,
    background: theme.palette.primary.main,
    position: "sticky",
    top: 0,
    zIndex: 4,
    height: "15vh",
  },
  wrapper: {
    background: bg,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { xs: "center", lg: "flex-start" },
  },
  sidePattern: {
    position: "absolute",
    left: "-10%",
    top: "50%",
    transform: "translateY(-50%)",
    width: "150px",
    overflow: "hidden",
    objectFit: "cover",
    zIndex: -1,
  } as CSSProperties,
  cardWrapper: {
    width: { xs: "100%", md: "50%", lg: "35%" },
    my: 2,
    position: "relative",
    zIndex: 2,
  },
  loginCard: {
    background: "#fff",
    p: { xs: 2, md: 4 },
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "start",
    flexDirection: "column",
    gap: 2,
    [theme.breakpoints.up("md")]: {
      borderRadius: "12px",
      boxShadow: "0 0 20px #ccc",
    },
  },
  title: {
    fontSize: 30,
  },
  subHeading: {
    fontSize: 26,
  },
  backgroundBox: {
    background: bg,
  },
};

export default LoginStyles;
