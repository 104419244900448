const appDrawerStyles = {
  drawer: {
    display: "flex",
    flexGrow: 1,
    width: "100vw",
    backgroundColor: "#F5F5F5 ",
  },
  Supportdrawer: {
    display: "flex",
    flexGrow: 1,
    width: "30vw",
    backgroundColor: "#F5F5F5 ",
  },
} as const;

export default appDrawerStyles;
