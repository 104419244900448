import { InputAdornment, Pagination, Switch, Tooltip, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { classes } from "istanbul-lib-coverage";
import React, { ChangeEvent, useEffect, useState } from "react";
import strings from "../../../../../global/constants/StringConstants";
import {
  debounceEventHandler,
  isTruthy,
  openErrorNotification,
} from "../../../../../helpers/methods";
import CustomInput from "../../../../../global/components/CustomInput/CustomInput";
import AdminDashboardStyles from "../Dashboard.styles";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import urls from "../../../../../global/constants/UrlConstants";
import edit from "../../../../../assets/icons/edit.png";
import notifiers from "../../../../../global/constants/NotificationConstants";
import {
  getAllMeasureRoutes,
  getMeasureCount,
  searchMeasureByTitle,
  searchMeasureCount,
  updateMeasureStatus,
  updatePageStatus,
} from "../../../AdminService";
import CustomLoader from "../../../../../global/components/CustomLoader/CustomLoader";
import history from "../../../../../utils/history";

const MeasuresList = () => {
  const classes = AdminDashboardStyles;
  const [searchPage, setSearchPage] = useState<string>("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [measureRoutes, setMeasureRoutes] = useState<
    {
      rowId: number;
      nameE: string;
      categoryE: string;
      nameF: string;
      categoryF: string;
      active: boolean;
      id: number;
    }[]
  >([]);

  useEffect(() => {
    if (isTruthy(searchPage.trim())) {
      searchPageByTitle();
    } else {
      getMeasures();
      getMeasureTotalCount();
    }
  }, [page, searchPage]);

  const getMeasures = async () => {
    try {
      setLoading(true);
      const response = await getAllMeasureRoutes(page);
      setMeasureRoutes(response);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const searchPageByTitle = async () => {
    try {
      setLoading(true);
      const [searchResults, searchCount] = await Promise.all([
        searchMeasureByTitle(searchPage, page),
        searchMeasureCount(searchPage),
      ]);
      setMeasureRoutes(searchResults);
      setCount(searchCount.value);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const getMeasureTotalCount = async () => {
    try {
      setLoading(true);
      const response = await getMeasureCount();
      setCount(response.value);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const setPageStatus = async (status: boolean, id: number) => {
    try {
      setLoading(true);
      const response = await updateMeasureStatus(id, status);
      await getMeasures();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSearchOnChange = (SearchEvent: ChangeEvent<HTMLInputElement>) => {
    setSearchPage(SearchEvent.target.value.trim());
    setPage(1);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setPageStatus(event.target.checked, id);
  };

  const getPagination = () => {
    return (
      <Pagination
        page={page}
        count={Math.ceil(count / 10)}
        shape="rounded"
        size="medium"
        sx={classes.pageBtn}
        onChange={handlePageChange}
        showLastButton
        showFirstButton
      />
    );
  };

  const getHeaders = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        mt={2}
        sx={classes.headerBox}
      >
        <Typography>Page Title</Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography>Status</Typography>
          <Typography>Edit </Typography>
        </Stack>
      </Stack>
    );
  };

  const getMeasuresList = () => {
    return (
      <Stack direction="column" spacing={2}>
        {measureRoutes.map((page: any) => {
          return (
            <Box sx={classes.pageBox}>
              <Stack direction="row" justifyContent="space-between" alignContent="center">
                <Stack direction="column">
                  <Typography>{page.nameE}</Typography>
                  <Typography variant="body1" fontSize={14}>
                    {page.nameF}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Tooltip title={page.active ? "Deactivate" : "Activate"} placement="top">
                    <Switch
                      checked={page.active}
                      onChange={(event) => handleChange(event, page.id)}
                    />
                  </Tooltip>
                  <img
                    src={edit}
                    height={40}
                    width={40}
                    onClick={() => history.push(urls.editMeasuresPage + "?id=" + page.id)}
                  />
                </Stack>
              </Stack>
            </Box>
          );
        })}
      </Stack>
    );
  };
  const getPage = () => {
    return (
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
          <CustomInput
            placeHolder="Search text"
            id="searchText"
            sx={classes.searchInput}
            onChange={debounceEventHandler(handleSearchOnChange, strings.SEARCH_TIME_OUT)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {getPagination()}
        </Stack>
        {getHeaders()}
        {getMeasuresList()}
      </Container>
    );
  };
  return (
    <Box>
      {getPage()}
      <CustomLoader isLoading={loading} />
    </Box>
  );
};

export default MeasuresList;
