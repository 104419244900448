import { Box, Typography } from "@mui/material";
import PageNotFoundStyles from "./PageNotFound.styles";
import NotFoundImage from "../../assets/images/fourOhFourImage.svg";
import AppButton from "../../global/components/AppButton/AppButton";
import history from "../../utils/history";

const PageNotFound = () => {
  const classes = PageNotFoundStyles;

  const getBody = () => {
    return (
      <Box sx={{ ...classes.centered, height: "100vh" }}>
        <Box sx={{ ...classes.centered, flexDirection: "column", gap: 2 }}>
          <Typography variant="h4" textAlign="center" fontWeight={600}>
            Page Not Found
          </Typography>
          <img style={{ maxWidth: "100%" }} src={NotFoundImage} alt="" />
          <AppButton
            label={<Typography fontWeight={700}>Visit Home</Typography>}
            withRadius
            styles={{ textTransform: "capitalize" }}
            onClick={() => history.push("/")}
          />
        </Box>
      </Box>
    );
  };

  return <>{getBody()}</>;
};

export default PageNotFound;
