import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../utils/store";

export interface PersistState {
  peerGroup: {
    id: number;
    name: string;
  };
}

const initialState: PersistState = {
  peerGroup: {
    id: 1,
    name: "All",
  },
};

export const persistSlice = createSlice({
  name: "persist",
  initialState,
  reducers: {
    savePeerGroup: (state, action: PayloadAction<{ id: number; name: string }>) => {
      state.peerGroup = action.payload;
    },
  },
});

export const { savePeerGroup } = persistSlice.actions;

export const selectedPeerGroup = (state: RootState) => state.persist.peerGroup;

export default persistSlice.reducer;
