import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { descriptionFont, getRelativeFontSize, theme } from "../../../utils/styles";

interface CustomProps {
  headers: any[];
  rows: any[];
  customWidth?: any;
}
const CustomTable = (props: CustomProps) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const getHeaders = () => {
    return (
      <TableHead>
        <TableRow style={{ borderRadius: "10px" }}>
          {props.headers?.map((column, index) => (
            <TableCell
              align={column["align"]}
              key={index}
              sx={{
                backgroundColor: "#FFF3F2",
                color: "#F3776C !important",

                border: "none",
                ...theme.typography.h4,
                fontSize: getRelativeFontSize(6),
              }}
            >
              {column.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  const getRowData = (row: any) => {
    return props?.headers?.map((column, index) => (
      <>
        <TableCell align={column["align"]} key={index}>
          {row[column["field"]]}
        </TableCell>
      </>
    ));
  };

  const getTable = () => {
    return (
      <Box pb={4}>
        <Table
          sx={{
            borderCollapse: "separate",
            borderSpacing: "0 5px",
            width: isDesktop ? (props.customWidth ? `${props.customWidth}%` : "100%") : "100%",
          }}
        >
          {getHeaders()}
          {props.rows?.map((data) => {
            return (
              <TableRow sx={{ border: "1px solid #C8C8C8", borderRadius: "10px" }}>
                {getRowData(data)}
              </TableRow>
            );
          })}
        </Table>
      </Box>
    );
  };

  return <Box sx={{ ...descriptionFont }}>{getTable()}</Box>;
};

export default CustomTable;
