import { PracticesInternalPageData } from "../../../../models/interface";

export const InputFields = (prevState?: PracticesInternalPageData) => {
  return {
    relatedMeasures: prevState?.relatedMeasures ?? [
      { title: "", redirectUrl: "", isExternal: false },
    ],
    relatedGoodPractices: prevState?.relatedGoodPractices ?? [
      { title: "", redirectUrl: "", isExternal: false },
    ],
    resources: prevState?.resources ?? [{ title: "", redirectUrl: "", isExternal: false }],
    banner: {
      title: prevState?.banner?.title ?? "",
      category: prevState?.banner?.category ?? "",
      quote: prevState?.banner?.quote ?? "",
      whoWeAreDescription: prevState?.banner?.whoWeAreDescription ?? [""],
      image: prevState?.banner?.image ?? "",
      imageAlt: prevState?.banner?.imageAlt ?? "",
    },
    sections: prevState?.sections ?? [],
  } as PracticesInternalPageData;
};
