import _ from "lodash";
import strings from "../global/constants/StringConstants";
import { globalEmitter } from "../utils/emitter";
import { store } from "../utils/store";

const methodsContext = this;

export const isTruthy = (value: any, shouldCheckByType: boolean = true) => {
  const validatedByType = shouldCheckByType ? customValidatorByType(value) : true;

  if (value !== null && value !== undefined && validatedByType) {
    return true;
  }
  return false;
};

const customValidatorByType = (value: any) => {
  if (value !== undefined && value !== null) {
    const type = typeof value;
    switch (type) {
      case "string":
        return value.trim() !== "";
      case "object":
        if (Array.isArray(value)) {
          return value.length > 0;
        } else {
          return Object.keys(value).length > 0;
        }
      default:
        return true;
    }
  }
};

export const compareStrings = (string1: string, string2: string) => {
  if (!(isTruthy(string1) || isTruthy(string2))) {
    return true;
  } else {
    if (string1 && string2) {
      if (string1.toLowerCase() === string2.toLowerCase()) {
        return true;
      }
    }
  }
  return false;
};

export const compareObjects = (object1: any, object2: any) => {
  if (!(isTruthy(object1) || isTruthy(object2))) {
    return true;
  } else {
    return _.isEqual(object1, object2);
  }
  return false;
};

export const openInfoNotification = (message: any, title: string = "Info") => {
  globalEmitter.emit(strings.notification, {
    type: strings.info,
    message: message,
    title: title,
  });
};

export const openSuccessNotification = (message: any, title: string = "Success") => {
  globalEmitter.emit(strings.notification, {
    type: strings.success,
    message: message,
    title: title,
  });
};

export const openWarningNotification = (message: any, title: string = "Warning") => {
  globalEmitter.emit(strings.notification, {
    type: strings.warning,
    message: message,
    title: title,
  });
};

export const openErrorNotification = (message: any, title: string = "Error") => {
  globalEmitter.emit(strings.notification, {
    type: strings.error,
    message: message,
    title: title,
  });
};

export function debounce(func: Function, wait: number) {
  let timeout: any;
  return function (...args: any) {
    const context = methodsContext;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export function debounceEventHandler(func: Function, wait: number) {
  const debounced = debounce(func, wait);
  return function (event: any) {
    event.persist();
    return debounced(event);
  };
}

export const getFormattedNumbers = (value: string) => {
  const matches = value.match(/\d+/g);
  let number = "";
  if (matches !== null) {
    matches.forEach((match) => {
      number = number + match;
    });
  }
  if (number.length === 10) {
    value = number.replace(/^(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return { number: number, maskedNumber: value };
};

export const checkForChanges = (defaultVal: any, newVal: any) => {
  let isDisabled = true;
  for (const defaultValKey in defaultVal) {
    for (const newValKey in newVal) {
      if (newValKey === defaultValKey) {
        const newValue = newVal[newValKey]?.value,
          defaultValue = defaultVal[defaultValKey]?.value;
        if (newValue !== defaultValue) {
          isDisabled = false;
        }
      }
    }
  }

  return isDisabled;
};

export const markdownText = (markdownText: string) => {
  const regex = /(\*\*|__)(.*?)\1|\[(.*?)\]\((.*?)\)/g;
  return markdownText.replaceAll(regex, (match, p1, p2, p3, p4) => {
    const isExternal = p4.match(/[a-zA-Z0-9]*:\/\/[^\s]*/g) != null;
    if (p1 === "**") {
      return `<strong>${p2}</strong>`;
    }
    if (p1 === "__") {
      return `<em>${p2}</em>`;
    }
    if (p3 && p4) {
      return `<a href="${p4}" target=${
        isExternal ? "_blank" : "_self"
      } style="textDecoration:underline">${p3}</a>`;
    }
    return match; // Return the original match if no formatting matches
  });
};

export const getBodyHeight = () => {
  const header = document.getElementById("Header");
  const footer = document.getElementById("Footer");
  return (header?.offsetHeight ?? 0) + (footer?.offsetHeight ?? 0);
};

export const reorder = (
  list: { id: string; text: string }[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getContentBasedOnAuthState = (
  isAuthenticated: boolean,
  dataSource: any,
  key: string,
) => {
  let whatToReturn = "beforeLogin";
  if (dataSource.isSame && isAuthenticated) {
    whatToReturn = "afterLogin";
  }
  if (dataSource.isSame && !isAuthenticated) {
    whatToReturn = "beforeLogin";
  }
  if (!dataSource.isSame && isAuthenticated) {
    whatToReturn = "afterLogin";
  }
  if (!dataSource.isSame && !isAuthenticated) {
    whatToReturn = "beforeLogin";
  }
  return dataSource[whatToReturn][key];
};

export const alphabeticalSort = (objectA: any, objectB: any, key: string) => {
  const propertyA = objectA[key].toUpperCase(); // ignore upper and lowercase
  const propertyB = objectB[key].toUpperCase(); // ignore upper and lowercase

  if (propertyA < propertyB) {
    return -1;
  }
  if (propertyA > propertyB) {
    return 1;
  }

  // names must be equal
  return 0;
};
