import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import GlobalLayout from "../../Pages/Global/GlobalLayout";
import PracticeDetail from "../../../PracticesDetail/PracticeDetail";
import { useEffect, useState } from "react";
import { InputFields } from "./AboutPracticesTypesAndValidations";
import AboutPracticesStyles from "./AboutPractices.styles";
import { isTruthy, openErrorNotification } from "../../../../helpers/methods";
import CustomInput from "../../../../global/components/CustomInput/CustomInput";
import strings from "../../../../global/constants/StringConstants";
import { v4 as uuidv4 } from "uuid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { addImage, deleteImage, retrieveMeasureRoutesForRedirection } from "../../AdminService";
import notifiers from "../../../../global/constants/NotificationConstants";
import { imageRenderer } from "../../../../utils/service";
import SectionModal from "./components/SectionModal";
import AppButton from "../../../../global/components/AppButton/AppButton";
import { Measures, Section, SectionMetaDataType } from "../../../../models/interface";
import { cloneDeep } from "lodash";
import { getJSXAndFormFields } from "./components/helper";
import TextComponent from "./components/TextComponent/TextComponent";
import TableComponent from "./components/TableComponent/TableComponent";
import ImageComponent from "./components/ImageComponent/ImageComponent";
import { useAppSelector } from "../../../../utils/hooks";
import { selectLocale } from "../../../../redux/authSlice";

interface CustomProps {
  content: any;
  updatePageContent: Function;
  templateId: string;
  routesList: string[];
}

const AboutPractices = (props: CustomProps) => {
  const classes = AboutPracticesStyles;
  const [openModal, setOpenModal] = useState<{
    mode: string;
    visible: boolean;
    section: { id: string; name: string; columns: number; content: { type: string }[] };
  }>({
    mode: "add",
    visible: false,
    section: {
      id: "",
      name: "",
      columns: 1,
      content: [],
    },
  });
  const [formData, setFormData] = useState(InputFields(props.content));
  const [loading, setLoading] = useState(false);
  const locale = useAppSelector(selectLocale);
  const [measureLinks, setMeasureLinks] = useState<Measures[]>([]);

  const updateIndividualSection = (data: any, sectionIndex: number, contentIndex: number) => {
    const local = cloneDeep(formData);
    local.sections[sectionIndex].content[contentIndex].metadata = data;
    setFormData(local);
  };

  const handleReOrder = (newOrder: any) => {
    const local = cloneDeep(formData.sections);
    const newSections: Section[] = [];
    newOrder.forEach((section: any) => {
      const indexToPush = local.findIndex((sec: any) => sec.id === section.id);
      if (indexToPush > -1) {
        newSections.push(local[indexToPush]);
      }
    });
    setFormData({
      ...formData,
      sections: newSections,
    });
  };

  useEffect(() => {
    measureRoutes();
  }, []);

  useEffect(() => {
    props.updatePageContent(formData);
  }, [formData]);

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const measureRoutes = async () => {
    try {
      const routes = await retrieveMeasureRoutesForRedirection(locale);
      setMeasureLinks(routes);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const addSection = (sectionMetaData: {
    columns: number;
    id: string;
    name: string;
    content: { type: string }[];
  }) => {
    setOpenModal({
      visible: false,
      mode: "add",
      section: {
        id: "",
        name: "",
        columns: 1,
        content: [],
      },
    });
    let fieldsToSetInFormData = {
      id: uuidv4(),
      columns: sectionMetaData.columns,
      content: [] as any,
      name: sectionMetaData.name,
    };
    sectionMetaData.content.forEach((eachContent, index) => {
      const { formFields } = getJSXAndFormFields(eachContent.type);
      fieldsToSetInFormData.content.push(formFields);
    });
    const localFormData = cloneDeep(formData);
    localFormData.sections.push(fieldsToSetInFormData);
    setFormData(localFormData);
  };

  const editSection = (sectionMetaData: {
    id: string;
    name: string;
    columns: number;
    content: any;
  }) => {
    setOpenModal({
      visible: false,
      mode: "add",
      section: {
        id: "",
        name: "",
        columns: 1,
        content: [],
      },
    });
    const localFormData = cloneDeep(formData);
    localFormData.sections.forEach((section) => {
      if (section.id === sectionMetaData.id) {
        section.name = sectionMetaData.name;
        section.columns = sectionMetaData.columns;
        section.content = sectionMetaData.content;
      }
    });
    setFormData(localFormData);
  };

  const handleOnchange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleTitleChangeForRelatedMeasures = (event: React.ChangeEvent<any>, index: number) => {
    const updated = formData.relatedMeasures;
    updated[index].title = event.target.value;
    setFormData({
      ...formData,
      relatedMeasures: updated,
    });
  };

  const handleTitleChangeForRelatedGoodPractices = (
    event: React.ChangeEvent<any>,
    index: number,
  ) => {
    const updated = formData.relatedGoodPractices;
    updated[index].title = event.target.value;
    setFormData({
      ...formData,
      relatedGoodPractices: updated,
    });
  };

  const handleTitleChangeForResources = (event: React.ChangeEvent<any>, index: number) => {
    const updated = formData.resources;
    updated[index].title = event.target.value;
    setFormData({
      ...formData,
      resources: updated,
    });
  };

  const handleChangeDescription = (event: React.ChangeEvent<any>, index: number) => {
    const localDescriptionState = formData.banner.whoWeAreDescription;
    localDescriptionState[index] = event.target.value;
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        whoWeAreDescription: localDescriptionState,
      },
    });
  };

  const handleDeleteDesc = async (text: string) => {
    const local = formData.banner.whoWeAreDescription;
    const updated = local.filter((v, i) => v !== text);
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        whoWeAreDescription: updated,
      },
    });
  };

  const addNewDesc = () => {
    const localDescriptionState = formData.banner.whoWeAreDescription;
    localDescriptionState.push("");
    setFormData({
      ...formData,
      banner: {
        ...formData.banner,
        whoWeAreDescription: localDescriptionState,
      },
    });
  };

  const handleDelete = async (templateId: string, image: string) => {
    try {
      const deletedImage = await deleteImage(templateId, image);
      setFormData({
        ...formData,
        banner: {
          ...formData.banner,
          image: "",
        },
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const handleSectionsDelete = (id: string, isCustom: boolean, key: string) => {
    if (!isCustom) {
      setFormData({
        ...formData,
        [key]: [],
      });
      return;
    }
    const local = formData.sections;
    const updated = local.filter((v, i) => v.id !== id);
    setFormData({
      ...formData,
      sections: updated,
    });
  };

  const handleLinkSelectionForMeasures = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updated: any = formData.relatedMeasures;
    updated[index].redirectUrl = event.target.value;
    setFormData({
      ...formData,
      relatedMeasures: updated,
    });
  };

  const generateMeasuresLink = (value: any, index: number) => {
    const updated: any = formData.relatedMeasures;
    updated[index].redirectUrl = value;
    setFormData({
      ...formData,
      relatedMeasures: updated,
    });
  };

  const handleLinkSelectionForPractices = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updated: any = formData.relatedGoodPractices;
    updated[index].redirectUrl = event.target.value;
    setFormData({
      ...formData,
      relatedGoodPractices: updated,
    });
  };

  const handleLinkSelectionForResources = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updated: any = formData.resources;
    updated[index].redirectUrl = event.target.value;
    setFormData({
      ...formData,
      resources: updated,
    });
  };

  const handleOnDropZoneFile = async (e: any) => {
    try {
      const data = new FormData();
      const file = e.target.files[0];
      data.append("file", file);
      const response = await addImage(data, props.templateId);
      setFormData({
        ...formData,
        banner: {
          ...formData.banner,
          image: response.filename,
        },
      });
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    }
  };

  const handleReadMoreLinksChangeForMeasures = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updated: any = formData.relatedMeasures;
    updated[index].redirectUrl = "";
    updated[index].isExternal = event.target.checked;
    setFormData({
      ...formData,
      relatedMeasures: updated,
    });
  };

  const handleReadMoreLinksChangeForPractices = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updated: any = formData.relatedGoodPractices;
    updated[index].redirectUrl = "";
    updated[index].isExternal = event.target.checked;
    setFormData({
      ...formData,
      relatedGoodPractices: updated,
    });
  };

  const handleReadMoreLinksChangeForResources = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updated: any = formData.resources;
    updated[index].redirectUrl = "";
    updated[index].isExternal = event.target.checked;
    setFormData({
      ...formData,
      resources: updated,
    });
  };

  const addNewRelatedMeasures = () => {
    const newSection = formData.relatedMeasures;
    newSection.push({
      redirectUrl: "",
      title: "",
      isExternal: false,
    });
    setFormData({
      ...formData,
      relatedMeasures: newSection,
    });
  };

  const addNewRelatedPractices = () => {
    const newSection = formData.relatedGoodPractices;
    newSection.push({
      redirectUrl: "",
      title: "",
      isExternal: false,
    });
    setFormData({
      ...formData,
      relatedGoodPractices: newSection,
    });
  };

  const addNewResources = () => {
    const newSection = formData.resources;
    newSection.push({
      redirectUrl: "",
      title: "",
      isExternal: false,
    });
    setFormData({
      ...formData,
      resources: newSection,
    });
  };

  const deleteMeasure = (index: number) => {
    try {
      const local = formData.relatedMeasures;
      const updated = local.filter((v, i) => i !== index);
      setFormData({
        ...formData,
        relatedMeasures: updated,
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const deletePractice = (index: number) => {
    try {
      const local = formData.relatedGoodPractices;
      const updated = local.filter((v, i) => i !== index);
      setFormData({
        ...formData,
        relatedGoodPractices: updated,
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const deleteResources = (index: number) => {
    try {
      const local = formData.resources;
      const updated = local.filter((v, i) => i !== index);
      setFormData({
        ...formData,
        resources: updated,
      });
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    }
  };

  const getMeasuresLinksDropdown = (value: string, index: number, isExternal: boolean) => {
    return (
      <Select
        value={value}
        name="hyperlink"
        id="hyperlink"
        onChange={(event: any) => generateMeasuresLink(event.target.value, index)}
        sx={classes.dropDown}
      >
        {measureLinks.map((item) => {
          return (
            <MenuItem
              value={`/measures?category=${item.category}&measureId=${item.measureId}&peerGroupId=${item.peerGroupId}`}
            >
              {item.measure}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const getInternalLinksDropdownForPractices = (value: string, index: number) => {
    return (
      <Select
        value={value}
        name="hyperlink"
        id="hyperlink"
        onChange={(event: any) => handleLinkSelectionForPractices(event, index)}
        sx={classes.dropDown}
      >
        {props.routesList.map((item) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    );
  };

  const getInternalLinksDropdownForResources = (value: string, index: number) => {
    return (
      <Select
        value={value}
        name="hyperlink"
        id="hyperlink"
        onChange={(event: any) => handleLinkSelectionForResources(event, index)}
        sx={classes.dropDown}
      >
        {props.routesList.map((item) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    );
  };

  const getInputHeader = (title: string, required: boolean, value: any) => {
    return (
      <Box
        sx={classes.label}
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        borderColor="red"
      >
        <Box style={{ display: "flex" }}>
          {isTruthy(title) && (
            <>
              <Typography sx={classes.labelText}>{title}</Typography>
              {required && <Typography sx={classes.star}>*</Typography>}
            </>
          )}
        </Box>
      </Box>
    );
  };

  const getSection1 = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Title", true, formData.banner.title)}
          <CustomInput
            placeHolder="Title"
            type="text"
            name="title"
            id="title"
            value={formData.banner.title}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
            key="myinput"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Category", true, formData.banner.category)}
          <CustomInput
            placeHolder="Category"
            type="text"
            name="category"
            id="category"
            value={formData.banner.category}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Quote", true, formData.banner.quote!)}
          <CustomInput
            placeHolder="Quote"
            type="text"
            name="quote"
            id="quote"
            value={formData.banner.quote!}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {formData.banner.whoWeAreDescription.map((ele: string, index: number) => {
            return (
              <>
                {getInputHeader(index > 0 ? "" : "Description", true, ele)}
                <CustomInput
                  placeHolder="Description"
                  type="text"
                  name="description"
                  id="description"
                  value={ele}
                  onChange={(event: any) => handleChangeDescription(event, index)}
                  onKeyPress={handleKeypress}
                  // error={!isTruthy(ele) && "Atleast 3 description lines required"}
                  InputProps={{
                    endAdornment: index > 0 && (
                      <InputAdornment position="end">
                        <DeleteOutlineIcon
                          sx={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={() => handleDeleteDesc(ele)}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </>
            );
          })}
          <Box sx={classes.addLinks} onClick={() => addNewDesc()}>
            + Add
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} xl={6} lg={6}>
          <Box sx={classes.label}>
            <Typography sx={classes.labelText}>Image</Typography>
          </Box>
          <CustomInput
            type="file"
            name="file"
            value={""}
            onChange={handleOnDropZoneFile}
            InputProps={{
              accept: strings.acceptedFiles,
              dataMaxSize: 3000000,
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} xl={6} lg={6}>
          <Box sx={classes.label}>
            <Typography sx={classes.labelText}>Image List</Typography>
          </Box>
          {formData.banner?.image! && (
            <Stack direction="row" spacing={2} alignItems="center">
              <img
                src={imageRenderer(props.templateId, formData.banner?.image!)}
                width={50}
                height={50}
              />
              <DeleteOutlineIcon
                sx={{ cursor: "pointer" }}
                onClick={() => handleDelete(props.templateId, formData.banner?.image!)}
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          {getInputHeader("Alternate Text", true, formData.banner.imageAlt ?? "")}
          <CustomInput
            placeHolder="Text"
            type="text"
            name="imageAlt"
            id="imageAlt"
            value={formData.banner.imageAlt!}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
          />
        </Grid>
      </Grid>
    );
  };

  const getRelatedMeasures = () => {
    return (
      <Grid container spacing={1}>
        {formData.relatedMeasures?.map((measure, index) => {
          return (
            <>
              {index > 0 && (
                <Box
                  onClick={() => deleteMeasure(index)}
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DeleteOutlineIcon />
                </Box>
              )}
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                {getInputHeader("Title", true, measure.title)}
                <CustomInput
                  placeHolder="Title"
                  type="text"
                  name="title"
                  id="title"
                  value={measure.title}
                  onChange={(event: any) => handleTitleChangeForRelatedMeasures(event, index)}
                  onKeyPress={handleKeypress}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                sx={{ borderBottom: "1px solid #E7E7F9", padding: "10px 0" }}
              >
                <Stack direction="row" justifyContent="space-between">
                  {getInputHeader("Link", true, measure.redirectUrl)}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={measure.isExternal}
                        onChange={(event) => handleReadMoreLinksChangeForMeasures(event, index)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="External Link"
                  />
                </Stack>
                {measure.isExternal ? (
                  <CustomInput
                    placeHolder="/link"
                    type="text"
                    name="hyperlink"
                    id="hyperlink"
                    value={measure.redirectUrl}
                    onChange={(event: any) => handleLinkSelectionForMeasures(event, index)}
                    onKeyPress={handleKeypress}
                    // error={!isTruthy(card.readMoreLink) && "Add text to button"}
                  />
                ) : (
                  getMeasuresLinksDropdown(measure.redirectUrl, index, measure.isExternal)
                )}
              </Grid>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewRelatedMeasures()}>
          + Add
        </Box>
      </Grid>
    );
  };

  const getRelatedGoodPractices = () => {
    return (
      <Grid container spacing={1}>
        {formData.relatedGoodPractices?.map((practice, index) => {
          return (
            <>
              {index > 0 && (
                <Box
                  onClick={() => deletePractice(index)}
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DeleteOutlineIcon />
                </Box>
              )}
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                {getInputHeader("Title", true, practice.title)}
                <CustomInput
                  placeHolder="Title"
                  type="text"
                  name="title"
                  id="title"
                  value={practice.title}
                  onChange={(event: any) => handleTitleChangeForRelatedGoodPractices(event, index)}
                  onKeyPress={handleKeypress}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                sx={{ borderBottom: "1px solid #E7E7F9", padding: "10px 0" }}
              >
                <Stack direction="row" justifyContent="space-between">
                  {getInputHeader("Practices Link", true, practice.redirectUrl)}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={practice.isExternal}
                        onChange={(event) => handleReadMoreLinksChangeForPractices(event, index)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="External Link"
                  />
                </Stack>
                {practice.isExternal ? (
                  <CustomInput
                    placeHolder="/link"
                    type="text"
                    name="hyperlink"
                    id="hyperlink"
                    value={practice.redirectUrl}
                    onChange={(event: any) => handleLinkSelectionForPractices(event, index)}
                    onKeyPress={handleKeypress}
                    // error={!isTruthy(card.readMoreLink) && "Add text to button"}
                  />
                ) : (
                  getInternalLinksDropdownForPractices(practice.redirectUrl, index)
                )}
              </Grid>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewRelatedPractices()}>
          + Add
        </Box>
      </Grid>
    );
  };

  const getResources = () => {
    return (
      <Grid container spacing={1}>
        {formData.resources?.map((resource, index) => {
          return (
            <>
              {index > 0 && (
                <Box
                  onClick={() => deleteResources(index)}
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <DeleteOutlineIcon />
                </Box>
              )}
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                {getInputHeader("Title", true, resource.title)}
                <CustomInput
                  placeHolder="Title"
                  type="text"
                  name="title"
                  id="title"
                  value={resource.title}
                  onChange={(event: any) => handleTitleChangeForResources(event, index)}
                  onKeyPress={handleKeypress}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                sx={{ borderBottom: "1px solid #E7E7F9", padding: "10px 0" }}
              >
                <Stack direction="row" justifyContent="space-between">
                  {getInputHeader("Practices Link", true, resource.redirectUrl)}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={resource.isExternal}
                        onChange={(event) => handleReadMoreLinksChangeForResources(event, index)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="External Link"
                  />
                </Stack>
                {resource.isExternal ? (
                  <CustomInput
                    placeHolder="/link"
                    type="text"
                    name="hyperlink"
                    id="hyperlink"
                    value={resource.redirectUrl}
                    onChange={(event: any) => handleLinkSelectionForResources(event, index)}
                    onKeyPress={handleKeypress}
                    // error={!isTruthy(card.readMoreLink) && "Add text to button"}
                  />
                ) : (
                  getInternalLinksDropdownForResources(resource.redirectUrl, index)
                )}
              </Grid>
            </>
          );
        })}
        <Box sx={classes.addLinks} onClick={() => addNewResources()}>
          + Add
        </Box>
      </Grid>
    );
  };

  const getPreviewScreen = () => {
    return (
      <Box>
        <PracticeDetail content={formData} templateId={props.templateId} />
      </Box>
    );
  };

  const customDialog = () => {
    return (
      <SectionModal
        setOpenModal={setOpenModal}
        openModal={openModal.visible}
        handleAddSection={addSection}
        editMode={openModal.mode === "edit"}
        handleEditSection={editSection}
        section={openModal.section}
      />
    );
  };

  const getSectionsBasedOnType = (type: string, sectionIndex: number, contentIndex: number) => {
    switch (type) {
      case "text":
        return (
          <TextComponent
            data={formData}
            setData={updateIndividualSection}
            sectionIndex={sectionIndex}
            contentIndex={contentIndex}
          />
        );

      case "table": {
        return (
          <TableComponent
            data={formData}
            setData={updateIndividualSection}
            sectionIndex={sectionIndex}
            contentIndex={contentIndex}
          />
        );
      }
      case "image": {
        return (
          <ImageComponent
            data={formData}
            setData={updateIndividualSection}
            sectionIndex={sectionIndex}
            contentIndex={contentIndex}
            templateId={props.templateId}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const getSections = () => {
    return formData.sections.map((section, sectionIndex: number) => {
      const sec = section.content.map((content, contentIndex: number) => {
        return getSectionsBasedOnType(content.type, sectionIndex, contentIndex);
      });
      const name = section.name ?? "";
      return {
        isDeletable: true,
        isEditable: true,
        id: section.id,
        name: name,
        section: sec,
        custom: true,
        key: name.replaceAll(" ", ""),
      };
    });
  };

  const handleEditSection = (section: any) => {
    const foundSection = formData.sections.find((sec) => sec.id === section.id);
    setOpenModal({
      mode: "edit",
      visible: true,
      section: foundSection!,
    });
  };

  const sectionData = [
    {
      section: [getSection1()],
      id: uuidv4(),
      name: "Banner",
      isDeletable: false,
      isEditable: false,
      custom: false,
      key: "banner",
    },
    {
      section: [getRelatedMeasures()],
      id: uuidv4(),
      name: "Related Measures",
      isDeletable: true,
      isEditable: false,
      custom: false,
      key: "relatedMeasures",
    },
    {
      section: [getRelatedGoodPractices()],
      id: uuidv4(),
      name: "Related Good Practices",
      isDeletable: true,
      isEditable: false,
      custom: false,
      key: "relatedGoodPractices",
    },
    {
      section: [getResources()],
      id: uuidv4(),
      name: "Resources",
      isDeletable: true,
      isEditable: false,
      custom: false,
      key: "resources",
    },
    ...getSections(),
  ];

  return (
    <Box>
      <GlobalLayout
        section={sectionData}
        pageTitle={"About Practices"}
        previewElement={getPreviewScreen()}
        handleDelete={handleSectionsDelete}
        customComponent={
          <AppButton
            label={"Add section"}
            onClick={() =>
              setOpenModal({
                mode: "add",
                visible: true,
                section: {
                  id: "",
                  name: "",
                  columns: 1,
                  content: [],
                },
              })
            }
            styles={{ px: 4, mt: 4 }}
          />
        }
        handleReOrder={handleReOrder}
        handleEditSection={handleEditSection}
      />
      {customDialog()}
    </Box>
  );
};

export default AboutPractices;
