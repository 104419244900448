import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import HomeRunLogo from "../../../assets/images/logos/logo_dark_en.svg";
import AgencyLogo from "../../../assets/images/logos/agency_en.svg";
import HomeRunLogoFr from "../../../assets/images/logos/logo_dark_fr.svg";
import AgencyLogoFr from "../../../assets/images/logos/agency_fr.svg";
import appHeaderStyles from "./AppHeader.styles";
import AppDrawer from "../AppDrawer/AppDrawer";
import { useMediaQuery } from "@mui/material";
import { theme } from "../../../utils/styles";
import urls from "../../../global/constants/UrlConstants";
import AppButton from "../../../global/components/AppButton/AppButton";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import {
  logoutAction,
  selectAuthenticated,
  selectLocale,
  selectRole,
  selectedCoopName,
  updateLocale,
} from "../../../redux/authSlice";
import strings from "../../../global/constants/StringConstants";
import { staticContent } from "../../../utils/locale";
import { useLocation } from "react-router-dom";
import history from "../../../utils/history";
import { savePeerGroup } from "../../../redux/persistSlice";

interface CustomProps {
  toggleDrawer: Function;
}

const AppHeader = (props: CustomProps) => {
  const classes = appHeaderStyles;
  const locale = useAppSelector(selectLocale);
  const location = useLocation();
  const coopName = useAppSelector(selectedCoopName);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [menuMobileVisible, setMenuMobileVisible] = useState<boolean>(false);
  const authenticated = useAppSelector(selectAuthenticated);
  const userRole = useAppSelector(selectRole);
  const isAuthenticated = authenticated && userRole === "coop";
  const content = staticContent(locale, isAuthenticated);

  const dispatch = useAppDispatch();

  const handleMenuMobileVisibility = (event: any) => {
    setMenuMobileVisible(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    dispatch(savePeerGroup({ id: 1, name: "All" }));
    history.push(urls.homeViewPath);
  };

  const changeLocale = () => {
    const changeTo = locale === strings.EN ? strings.FR : strings.EN;
    dispatch(updateLocale(changeTo));
    // @ts-ignore
    window.location.reload();
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setMenuMobileVisible(false);
  };

  const getMobileMenuDrawer = () => {
    return (
      <Drawer
        open={menuMobileVisible}
        onClose={toggleDrawer}
        sx={{
          paper: classes.menuMobile,
        }}
      >
        <AppDrawer
          setMenuMobileVisible={setMenuMobileVisible}
          listItems={getNavMenu()}
          // subListItems={getNavButton()}
        />
      </Drawer>
    );
  };

  const isActiveTab = (pathName: string) => {
    const pathNameURL = location.pathname;
    if (pathNameURL === pathName || pathNameURL === pathName + "/") {
      return true;
    }
    return false;
  };

  const getNavMenu = () => {
    return (
      <List sx={classes.navMenuMain}>
        <ListItem
          sx={[classes.navBtn, isActiveTab(urls.benchMarkingViewPath) && classes.selected]}
          onClick={() => {
            setMenuMobileVisible(false);
            history.push(urls.benchMarkingViewPath);
          }}
        >
          <Typography variant="subtitle1" fontWeight={700}>
            {content.navbar.benchmarking}
          </Typography>
        </ListItem>
        <ListItem
          sx={[classes.navBtn, isActiveTab(urls.goodPracticesViewPath) && classes.selected]}
          onClick={() => {
            setMenuMobileVisible(false);
            history.push(urls.goodPracticesViewPath);
          }}
        >
          <Typography variant="subtitle1" fontWeight={700}>
            {content.navbar.good_practices}
          </Typography>
        </ListItem>
        <ListItem
          sx={[classes.navBtn, isActiveTab(urls.sampleDashboardViewPath) && classes.selected]}
          onClick={() => {
            setMenuMobileVisible(false);
            history.push(urls.sampleDashboardViewPath);
          }}
        >
          <Typography variant="subtitle1" fontWeight={700}>
            {isAuthenticated ? content.navbar.dashboard : content.navbar.sample_dashboard}
          </Typography>
        </ListItem>
        <ListItem
          sx={[classes.navBtn, isActiveTab(urls.contactUsViewPath) && classes.selected]}
          onClick={() => {
            setMenuMobileVisible(false);
            history.push(urls.contactUsViewPath);
          }}
        >
          <Typography variant="subtitle1" fontWeight={700}>
            {content.navbar.contact_us}
          </Typography>
        </ListItem>
        <ListItem sx={classes.navBtn} onClick={() => changeLocale()}>
          <Typography variant="subtitle1" fontWeight={700}>
            {locale === strings.EN ? "Français" : "English"}
          </Typography>
        </ListItem>
        {!isDesktop &&
          (isAuthenticated ? (
            <AppButton
              label={<Typography fontWeight={700}>{content.navbar.logout}</Typography>}
              withRadius
              variant="outlined"
              onClick={() => handleLogout()}
            />
          ) : (
            <AppButton
              label={<Typography fontWeight={700}>{content.navbar.login}</Typography>}
              withRadius
              variant="outlined"
              onClick={() => history.push(urls.loginViewPath)}
            />
          ))}
      </List>
    );
  };

  const getLogoAndHeight = () => {
    const isHomePage = location.pathname === "/";
    switch (locale) {
      case strings.EN: {
        return {
          logo: isHomePage ? AgencyLogo : HomeRunLogo,
          height: isDesktop ? "60px" : "40px",
        };
      }
      case strings.FR: {
        return {
          logo: isHomePage ? AgencyLogoFr : HomeRunLogoFr,
          height: isDesktop ? "60px" : "40px",
        };
      }
      default: {
        return {
          logo: isHomePage ? AgencyLogo : HomeRunLogo,
          height: isDesktop ? "60px" : "40px",
        };
      }
    }
  };

  const getAppHeader = () => {
    const { logo, height } = getLogoAndHeight();
    return (
      <>
        <AppBar position="sticky" sx={classes.appBar} id={"Header"}>
          <Toolbar sx={classes.menuToolbar}>
            <Container maxWidth="xl">
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems="center"
                sx={{ width: "auto", maxWidth: "100%" }}
              >
                <img
                  src={logo}
                  alt="HomeRun"
                  style={{
                    height: height,
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => history.push(urls.homeViewPath)}
                />
                <Box sx={{ display: { xs: "none", md: "flex" } }}>{getNavMenu()}</Box>
                {isDesktop &&
                  (isAuthenticated ? (
                    <AppButton
                      label={<Typography fontWeight={700}>{content.navbar.logout}</Typography>}
                      withRadius
                      variant="outlined"
                      styles={{ textTransform: "capitalize" }}
                      onClick={() => handleLogout()}
                    />
                  ) : (
                    <AppButton
                      label={<Typography fontWeight={700}>{content.navbar.login}</Typography>}
                      withRadius
                      variant="outlined"
                      onClick={() => history.push(urls.loginViewPath)}
                    />
                  ))}
                {!isDesktop && (
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="open drawer"
                    onClick={handleMenuMobileVisibility}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </Stack>
            </Container>
          </Toolbar>
        </AppBar>
        {getMobileMenuDrawer()}
      </>
    );
  };

  return getAppHeader();
};

export default AppHeader;
