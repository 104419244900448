import { InputAdornment, Pagination, Switch, Tooltip, Typography } from "@mui/material";
import { Container, Stack, Box } from "@mui/system";
import moment from "moment";
import AppButton from "../../../../../global/components/AppButton/AppButton";
import strings from "../../../../../global/constants/StringConstants";
import urls from "../../../../../global/constants/UrlConstants";
import {
  debounceEventHandler,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../../../../helpers/methods";
import AdminDashboardStyles from "../Dashboard.styles";
import edit from "../../../../../assets/icons/edit.png";
import deleteImg from "../../../../../assets/icons/delete.png";
import { ChangeEvent, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CustomInput from "../../../../../global/components/CustomInput/CustomInput";
import DeleteModal from "../../Global/DeleteModal/DeleteModal";
import { useAppSelector } from "../../../../../utils/hooks";
import { selectAuthenticated, selectRole } from "../../../../../redux/authSlice";
import {
  deletePage,
  search,
  searchPageCount,
  totalPagesCount,
  updatePageStatus,
} from "../../../AdminService";
import notifiers from "../../../../../global/constants/NotificationConstants";
import { getDynamicRoutesDataForAdmin } from "../../../../../utils/service";
import CustomLoader from "../../../../../global/components/CustomLoader/CustomLoader";
import history from "../../../../../utils/history";

const PagesList = () => {
  const classes = AdminDashboardStyles;
  const [pageId, setPageId] = useState<string>("");
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState<string>("");
  const [dynamicRoutes, setDynamicRoutes] = useState<
    {
      route: string;
      title: string;
      id: string;
      locale: string;
      active: boolean;
      updatedOn: any;
      createdOn: any;
    }[]
  >([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const authenticated = useAppSelector(selectAuthenticated);
  const userRole = useAppSelector(selectRole);
  const isAuthenticated = authenticated && userRole === "cms";
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      getPageCount();
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (isTruthy(searchPage.trim())) {
        searchPageByTitle();
      } else {
        fetchDynamicRoutes();
      }
    }
  }, [searchPage, page]);

  const getPageCount = async () => {
    try {
      setLoading(true);
      const totalCount = await totalPagesCount();
      setCount(totalCount.value);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const searchPageByTitle = async () => {
    try {
      setLoading(true);
      const [searchResults, searchCount] = await Promise.all([
        search(searchPage, page),
        searchPageCount(searchPage),
      ]);
      setDynamicRoutes(searchResults);
      setCount(searchCount.value);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchDynamicRoutes = async () => {
    try {
      setLoading(true);
      const [pages, count] = await Promise.all([
        getDynamicRoutesDataForAdmin(page),
        totalPagesCount(),
      ]);
      setDynamicRoutes(pages);
      setCount(count.value);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, pageId: string) => {
    setPageStatus(event.target.checked, pageId);
  };

  const handleSearchOnChange = (SearchEvent: ChangeEvent<HTMLInputElement>) => {
    setSearchPage(SearchEvent.target.value.trim());
    setPage(1);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      setOpenModal(false);
      const response = await deletePage(pageId);
      await fetchDynamicRoutes();
      openSuccessNotification("Page deleted successfully.");
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteRequestBtn = (pageId: string) => {
    setPageId(pageId);
    setOpenModal(true);
  };

  const setPageStatus = async (status: boolean, pageId: string) => {
    try {
      setLoading(true);
      const response = await updatePageStatus(status, pageId);
      await fetchDynamicRoutes();
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const getPagination = () => {
    return (
      <Pagination
        page={page}
        count={Math.ceil(count / 10)}
        shape="rounded"
        size="medium"
        sx={classes.pageBtn}
        onChange={handlePageChange}
        showLastButton
        showFirstButton
      />
    );
  };

  const customDialog = () => {
    return (
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        handleConfirmDelete={handleDelete}
        heading="Delete Page"
        description="Are you sure you want to delete the page?"
      />
    );
  };

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
        <Stack direction="row" alignItems="end" spacing={2}>
          <CustomInput
            placeHolder="Search text"
            id="searchText"
            sx={classes.searchInput}
            onChange={debounceEventHandler(handleSearchOnChange, strings.SEARCH_TIME_OUT)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <AppButton withRadius label={"+ Add"} onClick={() => history.push(urls.addPage)} />
        </Stack>
        {getPagination()}
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        mt={2}
        sx={classes.headerBox}
      >
        <Typography>Page Title</Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography>Language</Typography>
          <Typography>Edit </Typography>
          <Typography>Delete </Typography>
          <Typography>Status</Typography>
          <Typography>Created On</Typography>
          <Typography>Updated On</Typography>
        </Stack>
      </Stack>

      <Stack direction="column" spacing={2}>
        {dynamicRoutes.map((page) => {
          return (
            <Box sx={classes.pageBox}>
              <Stack direction="row" justifyContent="space-between" alignContent="center">
                <Typography>{page.title}</Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography>{page.locale === strings.EN ? "English" : "Français"}</Typography>
                  <img
                    src={edit}
                    height={40}
                    width={40}
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push(urls.addPage + "?id=" + page.id)}
                  />
                  <Box onClick={() => deleteRequestBtn(page.id)} sx={{ cursor: "pointer" }}>
                    <img src={deleteImg} height={40} width={40} />
                  </Box>
                  <Tooltip title={page.active ? "Deactivate" : "Activate"} placement="top">
                    <Switch
                      checked={page.active}
                      onChange={(event) => handleChange(event, page.id)}
                    />
                  </Tooltip>
                  <Typography>{moment(page.createdOn).format("DD MMM, YYYY")}</Typography>
                  <Typography>{moment(page.updatedOn).format("DD MMM, YYYY")}</Typography>
                </Stack>
              </Stack>
            </Box>
          );
        })}
      </Stack>
      {customDialog()}
      <CustomLoader isLoading={isLoading} />
    </Container>
  );
};

export default PagesList;
