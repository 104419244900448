import { Box, Grid, Typography, Container, Button, useMediaQuery, Stack } from "@mui/material";
import { theme } from "../../../utils/styles";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import HomeStyles from "./Home.styles";
import AppButton from "../../../global/components/AppButton/AppButton";
import about from "../../../assets/images/home/about.png";
import ideaIcon from "../../../assets/images/idea.svg";
import layoutIcon from "../../../assets/images/layout.svg";
import statsIcon from "../../../assets/images/stats.svg";
import { Button as ButtonType, CardsData, homePageContentData } from "../../../models/interface";
import { imageRenderer } from "../../../utils/service";
import { selectAuthenticated, selectLocale, selectRole } from "../../../redux/authSlice";
import { useAppSelector } from "../../../utils/hooks";
import parse from "html-react-parser";
import { isTruthy, markdownText } from "../../../helpers/methods";
import CustomDialog from "../../../global/components/CustomDialog/CustomDialog";
import { useEffect, useState } from "react";
import strings from "../../../global/constants/StringConstants";
import { staticContent } from "../../../utils/locale";
import history from "../../../utils/history";

const classes = HomeStyles;

const cards = [ideaIcon, statsIcon, layoutIcon];

interface CustomProps {
  content: homePageContentData;
  templateId: string;
}

const Home = (props: CustomProps) => {
  const locale = useAppSelector(selectLocale);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const authenticated = useAppSelector(selectAuthenticated);
  const userRole = useAppSelector(selectRole);
  const isAuthenticated = authenticated && userRole === "coop";
  const [openModal, setOpenModal] = useState<{ isOpen: boolean; id: string }>({
    isOpen: false,
    id: "",
  });
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const contentText = staticContent(locale, isAuthenticated);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImageIndex((prevIndex) =>
        prevIndex === props.content.banner.images.length - 1 ? 0 : prevIndex + 1,
      );
    }, props.content.banner.carousel_metadata.interval * 1000);

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [props.content.banner.carousel_metadata.interval]);

  const handleCloseModel = () => {
    setOpenModal({ isOpen: false, id: "" });
  };

  const dialogBody = () => (
    <Box>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${openModal.id}`}
        title="YouTube video player"
        allowFullScreen
      ></iframe>
    </Box>
  );

  const getCustomDialog = () => {
    return (
      <CustomDialog
        isDialogOpen={openModal.isOpen}
        closable
        closeButtonVisibility
        handleDialogClose={handleCloseModel}
        dialogHeaderContent={<></>}
        dialogBodyContent={dialogBody()}
      />
    );
  };

  const getYoutubeThumbnail = (link: any) => {
    if (isTruthy(link)) {
      const id = link[1];
      return id;
    }
    return "";
  };

  const checkRedirection = (item: ButtonType) => {
    if (item.isExternal) {
      const videoId = getYoutubeThumbnail(item.hyperlink.match(strings.youtubeVideoIdRegex));
      setOpenModal({ isOpen: true, id: videoId });
    } else {
      history.push(item.hyperlink);
    }
  };

  const getBanner = () => {
    return (
      <Box sx={classes.header} display="flex" justifyContent="flex-start" alignItems="center">
        <Container maxWidth="xl">
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: "center", md: "flex-start" }}
              gap={2}
              sx={{
                position: "relative",
                maxWidth: { xs: "100%", md: "35%", lg: "35%" },
                mx: { xs: 2, md: 0 },
              }}
            >
              <Box sx={{ position: "relative", mt: { xs: 8, lg: 4 } }}>
                <Typography
                  variant="h4"
                  sx={{
                    zIndex: 1,
                    position: "relative",
                    textAlign: { xs: "center", md: "left" },
                  }}
                  color="secondary"
                >
                  {parse(markdownText(props.content?.banner.title))}
                </Typography>
              </Box>
              {props.content?.banner.description.map((data, index: number) => {
                const text = data.replace(/-/g, "‑");
                return (
                  <Typography
                    variant="subtitle1"
                    className="hero-subtitle"
                    sx={{ textAlign: { xs: "center", md: "left" } }}
                    key={index}
                  >
                    {parse(markdownText(text))}
                  </Typography>
                );
              })}
              {!isAuthenticated && (
                <AppButton
                  label={
                    <Typography variant="subtitle1" fontWeight={700}>
                      {props.content?.banner.button.label}
                    </Typography>
                  }
                  onClick={() => history.push(props.content?.banner.button.hyperlink)}
                  withRadius
                  variant="contained"
                  styles={{
                    fontSize: 18,
                    padding: `10px 55px`,
                    "&:hover": { backgroundColor: theme.palette.primary.main },
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Box
              display="flex"
              alignItems="end"
              justifyContent="flex-start"
              gap={2}
              sx={{
                position: { xs: "static", md: "absolute" },
                maxWidth: isMobileScreen ? "100%" : "57%",
                marginTop: isMobileScreen ? "30px" : undefined,
                top: 0,
                right: 0,
                maxHeight: isMobileScreen ? "100%" : "85%",
                borderRadius: { xs: "none", md: "0 0 0 200px" },
                overflow: "hidden",
              }}
            >
              <Box display="flex" justifyContent="end" alignItems="end">
                {props.content.banner.images.map((image, index) => (
                  <Box
                    sx={[
                      {
                        opacity: 0,
                        animation: `fade-in-out ${props.content.banner.carousel_metadata.interval}s ease-in-out forwards`,
                      },
                      activeImageIndex === index ? classes.visible : classes.hide,
                    ]}
                  >
                    <img
                      key={index}
                      src={imageRenderer(props.templateId, image.title)}
                      alt={image.altText ?? ""}
                      style={{
                        width: "100%",
                        height: isDesktop ? "500px" : "auto",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>
    );
  };

  const getCards = () => {
    return (
      <Box sx={classes.cardLayout}>
        <Box sx={classes.cardsWrapper} className="cardsWrapper">
          <Container maxWidth="lg">
            <Box display="flex" justifyContent="center" gap={2} sx={classes.cards}>
              {props.content?.cards.map((card: CardsData, index) => {
                const text = card.description.replace(/-/g, "‑");
                return (
                  <Stack
                    key={index}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={classes.card}
                  >
                    <img
                      style={classes.cardIconBg}
                      src={imageRenderer(props.templateId, card.icon)}
                      alt={card.altText}
                    />
                    <Typography variant="h5" textAlign="center">
                      {card.title}
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center">
                      {text}{" "}
                    </Typography>

                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {/* <Stack direction="column" justifyContent="flex-end" alignItems="center"> */}
                      <Typography
                        style={{ fontWeight: "bold", color: "#8684B1", cursor: "pointer" }}
                        onClick={() =>
                          window.open(
                            card.readMoreLink.hyperlink,
                            card.readMoreLink.isExternal ? "_blank" : "_self",
                          )
                        }
                      >
                        {contentText.home.learnMoreText}
                      </Typography>
                      {/* </Stack> */}
                    </Box>
                  </Stack>
                );
              })}
            </Box>
          </Container>
        </Box>
      </Box>
    );
  };

  const getAboutUs = () => {
    return (
      <Box sx={classes.aboutLayout}>
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid
              sx={classes.imageGrid}
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              item
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={about}
                height={isMobileScreen ? "300px" : "100%"}
                width="100%"
                alt={props.content?.about?.altTextForImage}
              />
            </Grid>
            <Grid display="flex" xs={12} sm={12} md={12} lg={6} xl={6} item>
              <Box
                display="flex"
                flexDirection="column"
                alignItems={isDesktop ? "flex-start" : "center"}
                justifyContent="center"
                sx={{ py: 1 }}
              >
                <Typography variant="h4" sx={{ fontSize: 40 }}>
                  {props.content?.about.title}
                </Typography>

                <Box>
                  {props.content?.about.description.map((data, index: number) => {
                    return (
                      <Typography variant="subtitle1" gutterBottom mt={1} key={index}>
                        {parse(markdownText(data))}
                      </Typography>
                    );
                  })}
                </Box>

                {isTruthy(props.content?.about.button.label) && (
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    size="large"
                    sx={{
                      mt: 2,
                      px: 4,
                      position: "relative",
                      minHeight: "30px",
                      borderRadius: "100px",
                    }}
                    type={"button"}
                    onClick={() => checkRedirection(props.content?.about.button)}
                  >
                    <Typography variant="button" style={{ marginRight: "20px", fontSize: 18 }}>
                      {props.content?.about.button.label}
                    </Typography>
                    <PlayCircleIcon
                      style={{
                        position: "absolute",
                        right: "10px",
                        transform: `scale(2.4)`,
                      }}
                    />
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  return (
    <Box>
      {getBanner()}
      {getCards()}
      {getAboutUs()}
      {getCustomDialog()}
    </Box>
  );
};

export default Home;
