import { Router, Route, useLocation, Switch } from "react-router-dom";
import urls from "./global/constants/UrlConstants";
import withClearCache from "./ClearCache";
import Box from "@mui/material/Box";
import PageNotFound from "./screens/PageNotFound/PageNotFound";
import Notifications from "./utils/notifications";
import strings from "./global/constants/StringConstants";
import Layout from "./screens/Shared/Layout/Layout";
import Login from "./screens/Login/Login";
import AdminPanel from "./screens/Admin/Pages/Dashboard/Dashboard";
import AdminPanelLayout from "./screens/Shared/AdminPanelLayout/AdminPanelLayout";
import admin_home from "./screens/Admin/templates/Home/Home";
import { useEffect, useLayoutEffect, useState } from "react";
import PageRender from "./screens/PageRender";
import { getDynamicRoutesData } from "./utils/service";
import { isTruthy, openErrorNotification } from "./helpers/methods";
import notifiers from "./global/constants/NotificationConstants";
import AddPage from "./screens/Admin/Pages/AddPage/AddPage";
import CustomLoader from "./global/components/CustomLoader/CustomLoader";
import { useAppSelector } from "./utils/hooks";
import { selectAuthenticated, selectRole } from "./redux/authSlice";
import PracticeDetail from "./screens/PracticesDetail/PracticeDetail";
import ListOfPeerGroups from "./screens/SampleDashboard/components/ListOfPeerGroups";
import AddPeerGroup from "./screens/SampleDashboard/components/AddPeerGroup";
import Measures from "./screens/MeasuresInternalPage/Measures";
import FaviconUpdater from "./utils/FaviconUpdater";
import history from "./utils/history";
import MeasuresEditForm from "./screens/Admin/Pages/EditPage/MeasuresEditForm";

const App = () => {
  return <ClearCacheComponent />;
};

function UserRoute({
  component: Component,
  path,
  componentName,
  authenticated,
  privateRoute,
}: {
  component: any;
  path: any;
  componentName: string;
  authenticated: boolean;
  privateRoute: boolean;
}) {
  const location = useLocation();
  if (privateRoute && !authenticated) {
    history.push(urls.loginViewPath);
    return <Login adminLogin={false} />;
  }
  return (
    <>
      {location.pathname !== urls.loginViewPath ? (
        <Layout>
          <Component />
        </Layout>
      ) : (
        <Component />
      )}
    </>
  );
}

function AdminRoute({
  component: Component,
  path,
  componentName,
  authenticated,
  privateRoute,
}: {
  component: any;
  path: any;
  componentName: any;
  authenticated: boolean;
  privateRoute: boolean;
}) {
  const location = useLocation();
  if (privateRoute && !authenticated) {
    history.push(urls.adminPath);
    return <AdminPanel />;
  }
  if (
    authenticated &&
    (location.pathname === urls.adminPath || location.pathname === urls.adminPath + "/")
  ) {
    return (
      <AdminPanelLayout>
        <Component />
      </AdminPanelLayout>
    );
  } else {
    return <Component />;
  }
}

function CMSRoute({
  route,
  title,
  pageId,
  component: Component,
}: {
  route: string;
  title: string;
  pageId: string;
  component: any;
}) {
  return <Component pageId={pageId} />;
}

const MainApp = () => {
  const authenticated = useAppSelector(selectAuthenticated);
  const userRole = useAppSelector(selectRole);
  const [dynamicRoutes, setDynamicRoutes] = useState<
    { route: string; title: string; id: string }[]
  >([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchDynamicRoutes();
  }, []);

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  const fetchDynamicRoutes = async () => {
    try {
      setLoading(true);
      const pages = await getDynamicRoutesData();
      setDynamicRoutes(pages);
    } catch (error: any) {
      openErrorNotification(isTruthy(error.message) ? error.message : notifiers.GENERIC_ERROR);
    } finally {
      setLoading(false);
    }
  };

  const getDynamicRoutes = () => {
    return dynamicRoutes.map((info, index: number) => {
      return (
        <Route
          exact
          key={index}
          path={info.route}
          component={(props: any) => (
            <CMSRoute
              route={info.route}
              title={info.title}
              pageId={info.id}
              component={PageRender}
              {...props}
            />
          )}
        />
      );
    });
  };

  return (
    <Box>
      {isLoading ? (
        <></>
      ) : (
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            {/* Dynamic Routes */}
            {getDynamicRoutes()}

            <Route
              exact
              path={urls.loginViewPath}
              component={(props: any) => <Login adminLogin={false} {...props} />}
            />
            {/* <Route
              path={urls.benchMarkingViewPath}
              element={
                <UserRoute
                  componentName={strings.BENCHMARKING}
                  path={urls.benchMarkingViewPath}
                  component={Benchmarking}
                  authenticated={authenticated && userRole === "coop"}
                  privateRoute={false}
                />
              }
            /> */}
            {/* <Route
              path={urls.sampleDashboardViewPath}
              element={
                <UserRoute
                  componentName={strings.SAMPLE_DASHBOARD}
                  path={urls.sampleDashboardViewPath}
                  component={SampleDashboard}
                  authenticated={authenticated && userRole === "coop"}
                  privateRoute={false}
                />
              }
            /> */}
            <Route
              exact
              path={urls.listofpeergrpsViewPath}
              component={(props: any) => (
                <UserRoute
                  componentName={strings.LIST_OF_PEER_GROUPS}
                  path={urls.listofpeergrpsViewPath}
                  component={ListOfPeerGroups}
                  authenticated={authenticated && userRole === "coop"}
                  privateRoute={true}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path={urls.addPeerGroupsViewPath}
              component={(props: any) => (
                <UserRoute
                  componentName={strings.ADD_PEER_GROUP}
                  path={urls.addPeerGroupsViewPath}
                  component={AddPeerGroup}
                  authenticated={authenticated && userRole === "coop"}
                  privateRoute={true}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path={urls.editPeerGroupsViewPath}
              component={(props: any) => (
                <UserRoute
                  componentName={strings.EDIT_PEER_GROUP}
                  path={urls.editPeerGroupsViewPath}
                  component={AddPeerGroup}
                  authenticated={authenticated && userRole === "coop"}
                  privateRoute={true}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path={urls.measuresViewPath}
              component={(props: any) => (
                <UserRoute
                  componentName={strings.SAMPLE_DASHBOARD}
                  path={urls.measuresViewPath}
                  component={Measures}
                  authenticated={authenticated && userRole === "coop"}
                  privateRoute={false}
                  {...props}
                />
              )}
            />

            {/* Admin Routes */}
            <Route
              exact
              path={urls.adminPath}
              // component={AdminPanel}
              component={(props: any) => (
                <AdminRoute
                  componentName={strings.ADMIN_PANEL}
                  path={urls.adminPath}
                  component={AdminPanel}
                  authenticated={authenticated && userRole === "cms"}
                  privateRoute={false}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path={urls.addPage}
              component={(props: any) => (
                <AdminRoute
                  componentName={strings.ADMIN_ADD_PAGE}
                  path={urls.addPage}
                  component={AddPage}
                  authenticated={authenticated && userRole === "cms"}
                  privateRoute={true}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path={urls.editMeasuresPage}
              component={(props: any) => (
                <AdminRoute
                  componentName={strings.EDIT_MEASURES_PAGE}
                  path={urls.editMeasuresPage}
                  component={MeasuresEditForm}
                  authenticated={authenticated && userRole === "cms"}
                  privateRoute={true}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path={urls.ADMIN_HOME}
              component={(props: any) => (
                <AdminRoute
                  componentName={strings.ADMIN_PANEL_HOME}
                  path={urls.ADMIN_HOME}
                  component={admin_home}
                  authenticated={authenticated && userRole === "cms"}
                  privateRoute={true}
                  {...props}
                />
              )}
            />
            <Route path={"*"} component={(props: any) => <PageNotFound />} />
          </Switch>
        </Router>
      )}
      <Notifications />
      <CustomLoader isLoading={isLoading} />
      <FaviconUpdater />
    </Box>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

export default App;
