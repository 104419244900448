import { Box } from "@mui/material";
import DeleteModalStyles from "./DeleteModal.styles";
import CustomDialog from "../../../../../global/components/CustomDialog/CustomDialog";
import AppButton from "../../../../../global/components/AppButton/AppButton";
import deleteImage from "../../../../../assets/images/delete.png";
import { useAppSelector } from "../../../../../utils/hooks";
import { selectLocale } from "../../../../../redux/authSlice";

interface customProps {
  setOpenModal: Function;
  openModal: boolean;
  handleConfirmDelete: Function;
  heading: string;
  description: string;
}

const DeleteModal = (props: customProps) => {
  const classes = DeleteModalStyles;
  const locale = useAppSelector(selectLocale);

  const handleCloseModel = () => {
    props.setOpenModal(false);
  };

  const dialogTitleContent = () => {
    return (
      <>
        <Box sx={classes.dialogTitleWrapper}>
          <Box sx={classes.blackHeadings}>{props.heading}</Box>
        </Box>
      </>
    );
  };

  const dialogBody = () => <Box sx={classes.description}>{props.description}</Box>;

  const dialogContent = () => {
    return (
      <>
        <Box sx={classes.dialogFooter}>
          <AppButton
            label={locale === "en" ? "Cancel" : "Annuler"}
            onClick={() => handleCloseModel()}
          />
          <AppButton
            label={locale === "en" ? "Delete" : "Supprimer"}
            onClick={() => props.handleConfirmDelete()}
          />
        </Box>
      </>
    );
  };

  const dialogHeaderContent = () => {
    return (
      <Box display={"flex"}>
        <img src={deleteImage} />
      </Box>
    );
  };

  return (
    <CustomDialog
      dialogHeaderContent={dialogHeaderContent()}
      isDialogOpen={props.openModal}
      closable
      closeButtonVisibility
      handleDialogClose={handleCloseModel}
      dialogTitleContent={dialogTitleContent()}
      dialogBodyContent={dialogBody()}
      dialogFooterContent={dialogContent()}
      width="450px"
    />
  );
};

export default DeleteModal;
