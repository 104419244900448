import { getRelativeFontSize, theme } from "../../../../utils/styles";

const homeStyles = {
  label: {
    display: "flex",
    marginTop: "5px",
  },
  labelIcon: { color: "black" },
  labelText: {
    ...theme.typography.button,
    marginLeft: "6px",
    [theme.breakpoints.down("lg")]: {
      fontSize: getRelativeFontSize(),
    },
  },
  star: {
    color: "red",
    marginLeft: "4px",
    fontSize: getRelativeFontSize(2),
    ...theme.typography.button,
  },
  addLinks: {
    mt: 1,
    display: "inline-block",
    ...theme.typography.h6,
    color: theme.palette.secondary.dark,
    fontSize: getRelativeFontSize(),
    cursor: "pointer",
  },
  dropDown: {
    height: "50px",
    width: "100%",
    borderRadius: "12px",
    marginTop: "6px",
  },
  characterLimit: {
    ...theme.typography.subtitle1,
    fontSize: getRelativeFontSize(),
  },
  limitExceed: {
    color: "red",
  },
} as const;

export default homeStyles;
