import {
  Box,
  FormHelperText,
  Grid,
  List,
  ListItem,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import contactImage from "../../assets/images/contact.png";
import React, { useEffect, useRef, useState } from "react";
import ContactStyles from "./Contact.styles";
import { Container, Stack } from "@mui/system";
import CustomInput from "../../global/components/CustomInput/CustomInput";
import { getRelativeFontSize, regularFont, theme } from "../../utils/styles";
import AppButton from "../../global/components/AppButton/AppButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { staticContent } from "../../utils/locale";
import { selectAuthenticated, selectLocale, selectRole } from "../../redux/authSlice";
import { useAppSelector } from "../../utils/hooks";
import notifiers from "../../global/constants/NotificationConstants";
import {
  getBodyHeight,
  isTruthy,
  openErrorNotification,
  openSuccessNotification,
} from "../../helpers/methods";
import urls from "../../global/constants/UrlConstants";
import strings from "../../global/constants/StringConstants";
import CustomLoader from "../../global/components/CustomLoader/CustomLoader";
import { contact } from "./ContactService";
import { ContactPageContentData } from "../../models/interface";

interface CustomProps {
  content: ContactPageContentData;
}

const Contact = (props: CustomProps) => {
  const classes = ContactStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const locale = useAppSelector(selectLocale);
  const userRole = useAppSelector(selectRole);
  const authenticated = useAppSelector(selectAuthenticated);
  const isAuthenticated = authenticated && userRole === "coop";
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    name: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    coop: {
      value: "",
      error: "",
    },
    province: {
      value: "",
      error: "",
    },
    comment: {
      value: "",
      error: "",
    },
  });
  const contentText = staticContent(locale, isAuthenticated);

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleOnchange = (event: React.ChangeEvent<any>) => {
    setFormData({
      ...formData,
      [event.target.name]: {
        value: event.target.value,
        error: "",
      },
    });
  };

  const validateData = () => {
    let errors = formData;
    let isValid = true;
    const email = formData.email.value;
    const name = formData.name.value;
    const message = formData.comment.value;
    if (!email && !name && !message) {
      errors.name.error = "Please enter name.";
      errors.email.error = "Please enter email.";
      errors.comment.error = "Please enter message.";
      isValid = false;
    } else if (!name) {
      errors.name.error = "Please enter name.";
      isValid = false;
    } else if (!email) {
      errors.email.error = "Please enter email.";
      isValid = false;
    } else if (!message) {
      errors.comment.error = "Please enter message.";
      isValid = false;
    } else if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)) {
      errors.email.error = "Please enter valid email.";
      isValid = false;
    }
    setFormData({ ...errors });
    return isValid;
  };

  const submitData = async () => {
    try {
      setIsLoading(true);
      if (validateData()) {
        const body = {
          name: formData.name.value,
          emailId: formData.email.value,
          comment: formData.comment.value,
          province: formData.province.value,
          coop: formData.coop.value ?? "",
        };
        await contact(body, locale);
        setFormData({
          name: {
            value: "",
            error: "",
          },
          coop: {
            value: "",
            error: "",
          },
          province: {
            value: "",
            error: "",
          },
          email: {
            value: "",
            error: "",
          },
          comment: {
            value: "",
            error: "",
          },
        });
        openSuccessNotification(
          locale.toLowerCase() === "en"
            ? "Your request has been submitted!"
            : "Votre demande a été soumise!",
        );
      }
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getContactForm = () => {
    return (
      <Box sx={classes.contactFormBox}>
        <Typography sx={classes.orangeHeadingText}>{props.content.title}</Typography>
        <Box sx={{ width: isDesktop ? "400px" : "100%" }}>
          <CustomInput
            placeHolder={contentText.contact.formPlaceholders.name}
            type="text"
            name="name"
            id="name"
            value={formData.name.value}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
            error={isTruthy(formData.name.error) && formData.name.error}
            disabled={isLoading}
            sx={{ width: "100%" }}
          />
          <CustomInput
            placeHolder={contentText.contact.formPlaceholders.email}
            type="text"
            name="email"
            id="email"
            value={formData.email.value}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
            error={isTruthy(formData.email.error) && formData.email.error}
            disabled={isLoading}
          />
          <CustomInput
            placeHolder={contentText.contact.formPlaceholders.coop}
            type="text"
            name="coop"
            id="coop"
            value={formData.coop.value}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
            error={isTruthy(formData.coop.error) && formData.coop.error}
            disabled={isLoading}
          />
          <CustomInput
            placeHolder={contentText.contact.formPlaceholders.province}
            type="text"
            name="province"
            id="province"
            value={formData.province.value}
            onChange={handleOnchange}
            onKeyPress={handleKeypress}
            error={isTruthy(formData.province.error) && formData.province.error}
            disabled={isLoading}
          />
          <TextareaAutosize
            name="comment"
            id="comment"
            maxRows={7}
            minRows={4}
            maxLength={1000}
            value={formData.comment.value}
            placeholder={contentText.contact.formPlaceholders.message}
            onChange={handleOnchange}
            style={{
              ...theme.typography.h6,
              color: "#7C7C7C",
              height: "10vh",
              width: isDesktop ? "400px" : "100%",
              marginTop: 10,
              fontSize: getRelativeFontSize(2),
              padding: 10,
              borderRadius: "10px",
              borderColor: isTruthy(formData.comment.error) ? "#d32f2f" : "rgba(0, 0, 0, 0.23)",
            }}
            disabled={isLoading}
          />
          {isTruthy(formData.comment.error) && (
            <FormHelperText sx={{ color: "#d32f2f", ml: "14px" }}>
              {formData.comment.error}
            </FormHelperText>
          )}
          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            <AppButton
              label={locale === "en" ? "Submit" : "Soumettre"}
              withRadius
              onClick={() => submitData()}
            />
          </Stack>
        </Box>
      </Box>
    );
  };

  const orangeInfoBox = () => {
    return (
      <Box sx={classes.orangeBox}>
        <List component={"ul"} sx={{ display: "flex", alignItems: "flex-start" }}>
          <Stack direction="column">
            {props.content.informatoryText.map((text) => {
              return (
                <Box display="flex">
                  <FiberManualRecordIcon
                    fontSize="small"
                    sx={{ width: 10, color: "white", marginTop: "14px" }}
                  />
                  <ListItem>
                    <Typography variant="subtitle1" sx={classes.bullets}>
                      {text}
                    </Typography>
                  </ListItem>
                </Box>
              );
            })}
          </Stack>
        </List>
      </Box>
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={[classes.bannerBg, { height: { lg: `calc(100vh - ${getBodyHeight()}px)` } }]}
    >
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Container
          maxWidth="sm"
          sx={{
            position: "relative",
            flexDirection: isDesktop ? "row" : "column-reverse",
            display: isDesktop ? "block" : "flex",
          }}
        >
          {getContactForm()}
          {orangeInfoBox()}
        </Container>
      </Grid>
      {isDesktop && (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <img
            src={contactImage}
            width={isDesktop ? "auto" : "100%"}
            alt={props.content.altText ?? ""}
          />
        </Grid>
      )}
      <CustomLoader isLoading={isLoading} />
    </Grid>
  );
};

export default Contact;
