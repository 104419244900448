import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import urls from "../../../global/constants/UrlConstants";
import { Link } from "react-router-dom";
import SampleDashboardStyles from "../SampleDashboard.styles";
import { theme } from "../../../utils/styles";
import { isTruthy } from "../../../helpers/methods";
import { staticContent } from "../../../utils/locale";
import { useAppSelector } from "../../../utils/hooks";
import {
  selectAuthenticated,
  selectLocale,
  selectRole,
  selectedCoopName,
} from "../../../redux/authSlice";
import history from "../../../utils/history";

interface CustomProps {
  data: {
    icon: string;
    title: string;
    displayTitle: string;
    borderColor: string;
    backgroundColor: string;
    measure: {
      title: string;
      score: number;
      measureId: string;
      peerGroupId: string;
      scoreNotAvailable: boolean;
    }[];
  }[];
}

const CustomTabs = (props: CustomProps) => {
  const classes = SampleDashboardStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [value, setValue] = React.useState(0);
  const additionalBoxes = 4 - (props.data[value]?.measure?.length % 4);
  const requiredBoxes = additionalBoxes % 4;
  const numberOfRows = props.data[value]?.measure?.length / 4;
  const authenticated = useAppSelector(selectAuthenticated);
  const coopName = useAppSelector(selectedCoopName);
  const locale = useAppSelector(selectLocale);
  const userRole = useAppSelector(selectRole);
  const isAuthenticated = authenticated && userRole === "coop";
  const content = staticContent(locale, isAuthenticated);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getHouseIcon = (fill?: boolean) => {
    return (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 19"
        fill={fill ? "#F3776C" : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9211 1.08264L10.9211 1.08266L19.305 7.60358C19.3051 7.60361 19.3051 7.60364 19.3051 7.60366C19.6811 7.89637 19.4746 8.49999 18.9971 8.49999H18.0001H17.5001V8.99999V17C17.5001 17.3978 17.3421 17.7793 17.0608 18.0606C16.7795 18.342 16.3979 18.5 16.0001 18.5H12.5001V13C12.5001 12.3369 12.2367 11.7011 11.7679 11.2322C11.299 10.7634 10.6631 10.5 10.0001 10.5C9.33706 10.5 8.70118 10.7634 8.23234 11.2322C7.7635 11.7011 7.5001 12.3369 7.5001 13V18.5H4.0001C3.60228 18.5 3.22075 18.342 2.93944 18.0606C2.65814 17.7793 2.5001 17.3978 2.5001 17V8.99999V8.49999H2.0001H1.0031C0.527211 8.49999 0.317726 7.89758 0.695498 7.60234C0.695586 7.60227 0.695675 7.6022 0.695763 7.60213L9.07905 1.08269L9.0791 1.08264C9.34242 0.877811 9.6665 0.766602 10.0001 0.766602C10.3337 0.766602 10.6578 0.877811 10.9211 1.08264Z"
          stroke="#F3776C"
        />
      </svg>
    );
  };

  const showHousesWithFill = (numberOfFilledHouses: number, numberOfHousesToShow: number) => {
    return (
      <Stack direction="row" spacing={0.5}>
        {Array(numberOfHousesToShow)
          .fill(1)
          .map((score, index: number) => getHouseIcon(index + 1 <= numberOfFilledHouses))}
      </Stack>
    );
  };

  return (
    <>
      <Tabs
        onChange={handleChange}
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          "&.MuiTabs-root": {
            "& .MuiTabs-flexContainer": {
              display: "flex",
              justifyContent: "space-between",
            },
          },
        }}
        TabIndicatorProps={{
          sx: {
            background: props.data[value].borderColor,
            height: 3,
          },
        }}
      >
        {props.data?.map((measure, index) => {
          return (
            <Tab
              sx={{
                width: isDesktop ? "20%" : "30%",
                "&:hover": {
                  backgroundColor: measure.backgroundColor,
                },
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              }}
              icon={<img src={measure.icon} width="120px" height="120px" />}
              label={measure.displayTitle}
              value={index}
            />
          );
        })}
      </Tabs>
      <Box>
        <Grid container sx={{ border: "1px solid #D9D9D9" }} justifyContent="center">
          {props.data[value]?.measure?.length > 0 ? (
            props.data[value]?.measure?.map((item) => {
              const measureLink = `${urls.measuresViewPath}?category=${props.data[value].title}&measureId=${item.measureId}&peerGroupId=${item.peerGroupId}`;
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  px={2}
                  pt={1}
                  width="100%"
                  sx={{ borderRight: "1px solid #D9D9D9" }}
                >
                  {item.scoreNotAvailable ? (
                    <Typography sx={{ color: "grey", lineHeight: 0.8 }}>N/A</Typography>
                  ) : (
                    showHousesWithFill(item.score, 4)
                  )}
                  {isTruthy(item.score) ? (
                    <Typography
                      variant="subtitle2"
                      sx={[classes.blueText, { cursor: "pointer" }]}
                      gutterBottom
                      onClick={() => history.push(measureLink)}
                    >
                      {item.title}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2" sx={classes.blueText} gutterBottom>
                      {item.title}
                    </Typography>
                  )}
                </Grid>
              );
            })
          ) : (
            <Typography sx={classes.orangeHeadingText}>
              {locale === "en"
                ? "Stay tuned for new measures."
                : "Restez à l'affût pour de nouvelles mesure."}
            </Typography>
          )}
          {isDesktop &&
            requiredBoxes > 0 &&
            Array(requiredBoxes)
              .fill(1)
              .map((item) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    p={2}
                    width="100%"
                    sx={{ borderRight: "1px solid #D9D9D9" }}
                  ></Grid>
                );
              })}
        </Grid>
      </Box>
    </>
  );
};

export default CustomTabs;
