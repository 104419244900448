import { SampleDashboardType } from "../../../../models/interface";

export const InputFields = (prevState?: SampleDashboardType) => {
  return {
    dashboardDescription: {
      beforeLogin: {
        description: prevState?.dashboardDescription?.beforeLogin.description ?? [""],
      },
      afterLogin: { description: prevState?.dashboardDescription?.afterLogin.description ?? [""] },
      isSame: prevState?.dashboardDescription?.isSame ?? true,
    },
    scoreSection: {
      beforeLogin: prevState?.scoreSection?.beforeLogin ?? {
        title: "",
        subtitle: [""],
        points: [""],
        scoreScale: [
          {
            title: "",
            score: 4,
          },
          {
            title: "",
            score: 3,
          },
          {
            title: "",
            score: 2,
          },
          {
            title: "",
            score: 1,
          },
        ],
        description: [""],
      },
      afterLogin: prevState?.scoreSection?.afterLogin ?? {
        title: "",
        subtitle: [""],
        points: [""],
        scoreScale: [
          {
            title: "",
            score: 4,
          },
          {
            title: "",
            score: 3,
          },
          {
            title: "",
            score: 2,
          },
          {
            title: "",
            score: 1,
          },
        ],
        description: [""],
      },
      isSame: prevState?.scoreSection?.isSame ?? true,
    },
    note: {
      beforeLogin: prevState?.note?.beforeLogin ?? {
        title: "",
        description: [""],
        horizontalList: [""],
        helper: [""],
      },
      afterLogin: prevState?.note?.afterLogin ?? {
        title: "",
        description: [""],
        horizontalList: [""],
        helper: [""],
      },
      isSame: prevState?.note?.isSame ?? true,
    },
  } as SampleDashboardType;
};
