import {
  Box,
  Container,
  Typography,
  Stack,
  Tooltip,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  List,
  ListItem,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import SampleDashboardStyles from "../SampleDashboard.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { City, PeerGroupData, Province } from "./AddPeerGroupFormData";
import { CustomCheckbox } from "../../../utils/styles";
import location from "../../../assets/images/Dashboard/location.png";
import { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomInput from "../../../global/components/CustomInput/CustomInput";
import AppButton from "../../../global/components/AppButton/AppButton";
import { cloneDeep } from "lodash";
import { addPeerGroup, peerGroupCount, updatePeerGroup } from "../SampleDashboardService";
import { isTruthy, openErrorNotification, openSuccessNotification } from "../../../helpers/methods";
import notifiers from "../../../global/constants/NotificationConstants";
import urls from "../../../global/constants/UrlConstants";
import { initAddEditPeerGroup } from "./PeerGroupHelpers";
import history from "../../../utils/history";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { selectAuthenticated, selectLocale } from "../../../redux/authSlice";
import { staticContent } from "../../../utils/locale";
import { savePeerGroup } from "../../../redux/persistSlice";
import CustomLoaderWithText from "../../../global/components/CustomLoaderWithText/CustomLoaderWithText";
import CustomLoader from "../../../global/components/CustomLoader/CustomLoader";

const AddPeerGroup = () => {
  const classes = SampleDashboardStyles;
  const editState: any = useLocation().state;
  const initStates = initAddEditPeerGroup(editState);
  const [selectedLocation, setSelectedLocation] = useState(initStates.selectedLocation);
  const [upsertPeerGroupLoader, setUpsertPeerGroupLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selections, setSelections] = useState<{ [key: string]: Set<string> }>(
    initStates.selections,
  );
  const [locations, setLocations] = useState<{ [key: string]: Set<string> }>(initStates.locations);
  const [peerCount, setPeerGroupCount] = useState("");
  const [peerGroupName, setPeerGroupName] = useState(initStates.name);
  const urlParams = useLocation().search;
  const peergroupId = new URLSearchParams(urlParams).get("id");
  const locale = useAppSelector(selectLocale);
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const contentText = staticContent(locale, isAuthenticated);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getLiveCount();
  }, [selections, locations]);

  const getLiveCount = async () => {
    try {
      setLoading(true);
      const defItems = getDefItems();
      const count = await peerGroupCount(defItems.join("&"));
      setPeerGroupCount(count.value);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleValidation = () => {
    let isValid = false;
    if (selections["units"].size > 0) {
      isValid = true;
    }
    return isValid;
  };

  const saveGroup = async () => {
    try {
      setUpsertPeerGroupLoader(true);
      if (isTruthy(peerGroupName)) {
        const defItems = getDefItems();
        const isLocationSelected = defItems.some((def) => def.includes("location"));
        if (!isLocationSelected) {
          setUpsertPeerGroupLoader(false);
          return openErrorNotification(
            locale.toLowerCase() === "en"
              ? "Please select the location"
              : "Veuillez sélectionner l’emplacement",
          );
        }
        const payload = {
          type: "User",
          name: peerGroupName,
          defItems: defItems.join("&"),
        };
        if (!isTruthy(editState)) {
          const response = await addPeerGroup(payload, locale);
          setUpsertPeerGroupLoader(false);
          openSuccessNotification(
            contentText.general.peerGroupAdded,
            // locale.toLowerCase() === "en" ? "Success" : "Création réussie",
          );
          dispatch(savePeerGroup({ id: response?.id ?? 1, name: response?.name ?? "All" }));
        } else {
          await updatePeerGroup({ ...payload, id: peergroupId ?? "" }, locale);
          setUpsertPeerGroupLoader(false);
          openSuccessNotification(
            locale.toLowerCase() === "en" ? "Peer group updated" : "Groupe de pairs mis à jour",
          );
          dispatch(
            savePeerGroup({ id: parseInt(peergroupId!) ?? 1, name: peerGroupName ?? "All" }),
          );
        }
        history.push(urls.dashboardViewPath);
      } else {
        setUpsertPeerGroupLoader(false);
        openErrorNotification(
          locale.toLowerCase() === "en"
            ? "Peer Group Name is Mandatory"
            : "Le nom du groupe de pairs est requis.",
        );
      }
    } catch (error: any) {
      setUpsertPeerGroupLoader(false);
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLocation((event.target as HTMLInputElement).value);
    setLocations({
      locationcity: new Set(),
      locationprovince: new Set(),
    });
  };

  const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPeerGroupName(event.target.value);
  };

  const getDefItems = () => {
    let defItems: string[] = [];
    Object.keys(selections).forEach((selection) => {
      let item: string[] = [];
      // if (selections[selection].size > 0) {
      selections[selection].forEach((val) => {
        item.push(selection + ":" + val);
      });
      defItems.push(selection + "=" + item.join(","));
      // }
    });
    let locItem: string[] = [];
    if (isTruthy(selectedLocation) && locations[selectedLocation].size > 0) {
      locations[selectedLocation].forEach((val) => locItem.push(selectedLocation + ":" + val));
      const locationString =
        "location=" +
        selectedLocation +
        "&" +
        selectedLocation.replace("location", "") +
        "=" +
        locItem.join(",");
      defItems.push(locationString);
    }
    return defItems;
  };

  const getHeader = () => {
    return (
      <Box sx={classes.headWrapper}>
        <Container maxWidth="xl" sx={classes.flexHead}>
          <Box
            sx={{ display: "flex", alignItems: "center" }}
            onClick={() => history.push(urls.listofpeergrpsViewPath)}
          >
            <Tooltip title={contentText.general.goBack}>
              <ArrowBackIcon sx={{ display: "inline", mr: 1 }} />
            </Tooltip>
            <Typography variant="h5">{contentText.peerGroupForm.header.heading}</Typography>
          </Box>
          <Stack direction="row" spacing={1}>
            <Box sx={classes.countBox}>{peerCount}</Box>
            <Typography sx={classes.whiteText}>{contentText.peerGroupForm.header.desc}</Typography>
          </Stack>
        </Container>
      </Box>
    );
  };

  const handleCheckboxClick = (event: any, stateType: string, mappedValue: string) => {
    const localState = cloneDeep(selections);
    if (localState[stateType].has(mappedValue)) {
      localState[stateType].delete(mappedValue);
    } else {
      localState[stateType].add(mappedValue);
    }
    setSelections(localState);
  };

  const handleLocationsCheckboxClick = (event: any, stateType: string, mappedValue: string) => {
    const localState = cloneDeep(locations);
    if (localState[stateType].has(mappedValue)) {
      localState[stateType].delete(mappedValue);
    } else {
      localState[stateType].add(mappedValue);
    }
    setLocations(localState);
  };

  const getProvinceLocationCheckBox = (
    values: { label: string; value: string }[],
    stateType: string,
  ) => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{
          flexWrap: "wrap",
        }}
      >
        {values.map((value) => {
          return (
            <Grid item lg={6} xl={6} sx={{ marginTop: "6px" }} display="flex" alignItems="center">
              <CustomCheckbox
                checked={locations[stateType].has(value.value)}
                onChange={(event) => handleLocationsCheckboxClick(event, stateType, value.value)}
                disabled={selectedLocation === "locationcity"}
              />
              <Typography>{value.label}</Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const getCityLocationCheckboxes = (
    data: { province: string; city: { label: string; value: string }[] }[],
    stateType: string,
  ) => {
    return (
      <Stack
        direction="column"
        spacing={1}
        alignItems="flex-start"
        sx={{
          flexWrap: "wrap",
        }}
      >
        {data.map((value) => {
          return (
            <Stack direction="column" mt={1}>
              <Typography sx={classes.heading}>{value.province}</Typography>
              <Stack
                direction="row"
                sx={{
                  flexWrap: "wrap",
                }}
              >
                {value.city.map((name) => {
                  return (
                    <Box display="flex" alignItems="center">
                      <CustomCheckbox
                        checked={locations[stateType].has(name.value)}
                        onChange={(event) =>
                          handleLocationsCheckboxClick(event, stateType, name.value)
                        }
                        disabled={selectedLocation === "locationprovince"}
                      />{" "}
                      <Typography>{name.label}</Typography>
                    </Box>
                  );
                })}
              </Stack>
            </Stack>
          );
        })}
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            flexWrap: "wrap",
          }}
        >
          <CustomCheckbox
            checked={locations[stateType].has("Other")}
            onChange={(event) => handleLocationsCheckboxClick(event, stateType, "Other")}
            disabled={selectedLocation === "locationprovince"}
          />
          <Typography>
            {locale === "en"
              ? "Co-ops not in these cities"
              : "Coopératives non situées dans ces villes"}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const getLocationBox = () => {
    return (
      <Box sx={classes.locationBox}>
        <Stack direction="row" spacing={1} alignItems="center">
          <img src={location} />
          <Typography sx={classes.heading}>Location</Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            flexWrap: "wrap",
          }}
          mx={1}
        >
          <Box mt={2}>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={selectedLocation}
              onChange={handleChange}
            >
              <Grid container spacing={6}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={4}
                  xl={4}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <FormControlLabel
                    value="locationprovince"
                    control={<Radio />}
                    label="Province"
                    sx={classes.radioBox}
                  />
                  {getProvinceLocationCheckBox(Province().values, Province().stateType)}
                  {selectedLocation === "locationprovince" &&
                    locations[selectedLocation].size < 1 && (
                      <Typography sx={classes.whiteText}>{Province().validationText}</Typography>
                    )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={8}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <FormControlLabel
                    value="locationcity"
                    control={<Radio />}
                    label={locale === "en" ? "City" : "Ville"}
                    sx={classes.radioBox}
                  />
                  {getCityLocationCheckboxes(City().values, City().stateType)}
                  {selectedLocation === "locationcity" && locations[selectedLocation].size < 1 && (
                    <Typography sx={classes.whiteText}>{City().validationText}</Typography>
                  )}
                </Grid>
              </Grid>
            </RadioGroup>
          </Box>
        </Stack>
      </Box>
    );
  };

  const getBody = () => {
    return (
      <Container maxWidth="xl" sx={{ pt: "150px" }}>
        <Stack direction="column" spacing={2}>
          {PeerGroupData().map((element) => {
            return (
              <Box sx={classes.borderBox}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <img src={element.icon} />
                  <Typography sx={classes.heading}>{element.title}</Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="flex-start"
                  sx={{
                    flexWrap: "wrap",
                  }}
                >
                  {element.values.map((value) => {
                    return (
                      <Box display="flex" alignItems="center">
                        <CustomCheckbox
                          checked={selections[element.stateType].has(value.value)}
                          onChange={(event) =>
                            handleCheckboxClick(event, element.stateType, value.value)
                          }
                        />{" "}
                        <Typography>{value.label}</Typography>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            );
          })}
          {getLocationBox()}
        </Stack>
      </Container>
    );
  };

  const getNameInputField = () => {
    return (
      <CustomInput
        placeHolder={locale === "en" ? "Name" : "Nom"}
        type="text"
        name="name"
        id="name"
        value={peerGroupName}
        onChange={handleGroupNameChange}
        sx={{ width: "100%" }}
      />
    );
  };

  const getNameSection = () => {
    return (
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <Box>
          <Typography sx={classes.heading}>{contentText.addPeerGroup.heading}</Typography>
          <List dense component={"ul"} sx={{ display: "flex", flexDirection: "column" }}>
            {contentText.addPeerGroup.description.map((text) => {
              return (
                <Box sx={{ display: "flex" }}>
                  <FiberManualRecordIcon
                    fontSize="small"
                    sx={{ paddingTop: "10px" }}
                    color="primary"
                  />
                  <ListItem sx={{ m: 0, p: 0, pl: 1 }}>
                    <Typography variant="subtitle1" sx={classes.bullets}>
                      {text}
                    </Typography>
                  </ListItem>
                </Box>
              );
            })}
          </List>
          {getNameInputField()}
        </Box>
      </Container>
    );
  };

  const getButtons = () => {
    return (
      <Stack direction="row" alignItems="center" justifyContent="center" m={2} spacing={1}>
        <AppButton
          withRadius
          label={locale === "en" ? "Save & View Results" : "Enregistrer et afficher les résultats"}
          onClick={saveGroup}
        />
        <AppButton
          withRadius
          label={locale === "en" ? "Cancel" : "Annuler"}
          styles={classes.cancelBtn}
          onClick={() => history.push(urls.dashboardViewPath)}
        />
      </Stack>
    );
  };

  return (
    <>
      {getHeader()}
      {getBody()}
      {getNameSection()}
      {getButtons()}
      <CustomLoaderWithText isLoading={upsertPeerGroupLoader} />
      <CustomLoader isLoading={loading} />
    </>
  );
};

export default AddPeerGroup;
