import { centerItemFlex, getRelativeFontSize, regularFont, theme } from "../../../utils/styles";

const appFooterStyles = {
  wrapper: {
    background: theme.palette.primary.main,
    color: "#fff",
    padding: 2,
  },
  menuItems: {
    padding: 5,
    backgroundColor: "#F5F5F5",
    alignItems: "center",
  },
  footerTypo: {
    ...centerItemFlex,
    ...regularFont,
    fontSize: getRelativeFontSize(4),
    marginTop: 3,
    color: "#666666",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  description: {
    ...regularFont,
    color: "#666666",
    fontSize: getRelativeFontSize(4),
  },
  socialMediaBoxes: {
    ...centerItemFlex,
    backgroundColor: "#5B5882",
    color: "#FFF",
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    p: 2,
  },
} as const;

export default appFooterStyles;
