import {
  Box,
  Container,
  Typography,
  Stack,
  Button,
  Grid,
  Pagination,
  useMediaQuery,
} from "@mui/material";
import urls from "../../../global/constants/UrlConstants";
import SampleDashboardStyles from "../SampleDashboard.styles";
import { getRelativeFontSize, theme } from "../../../utils/styles";
import { AllPeerGroups, AllPeerGroupsCount, deleteGroup } from "../SampleDashboardService";
import { isTruthy, openErrorNotification, openSuccessNotification } from "../../../helpers/methods";
import notifiers from "../../../global/constants/NotificationConstants";
import { useEffect, useState } from "react";
import { PeerGroupData } from "../../../models/interface";
import edit from "../../../assets/icons/edit.png";
import deleteImg from "../../../assets/icons/delete.png";
import DeleteModal from "../../Admin/Pages/Global/DeleteModal/DeleteModal";
import CustomLoader from "../../../global/components/CustomLoader/CustomLoader";
import moment from "moment";
import {
  City,
  PeerGroupData as PeerGroupStaticData,
  Province,
  predefinedData,
} from "./AddPeerGroupFormData";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import history from "../../../utils/history";
import { useAppSelector } from "../../../utils/hooks";
import { selectAuthenticated, selectLocale } from "../../../redux/authSlice";
import { staticContent } from "../../../utils/locale";

const ListOfPeerGroups = () => {
  const limit = 10;
  const classes = SampleDashboardStyles;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isLoading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<PeerGroupData[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const locale = useAppSelector(selectLocale);
  const isAuthenticated = useAppSelector(selectAuthenticated);
  const contentText = staticContent(locale, isAuthenticated);

  useEffect(() => {
    getListOfPeerGroupsCount();
  }, []);

  useEffect(() => {
    getListOfPeerGroups();
  }, [page]);

  const getListOfPeerGroups = async (newPage: number = page) => {
    try {
      setLoading(true);
      const data = await AllPeerGroups(newPage);
      setGroups(data);
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const getListOfPeerGroupsCount = async () => {
    try {
      setLoading(true);
      const totalCount = await AllPeerGroupsCount();
      setCount(totalCount.value);
      return totalCount.value;
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteGroup(groupId);
      const count = await getListOfPeerGroupsCount();
      const noGroupsOnPage = Math.ceil(parseInt(count) / limit);
      const newPage = page <= noGroupsOnPage ? page : page - 1;
      await getListOfPeerGroups(newPage);
      setOpenModal(false);
      openSuccessNotification(
        locale.toLowerCase() === "en" ? "Peer group deleted" : "Groupe de pairs supprimé",
      );
    } catch (error: any) {
      openErrorNotification(
        isTruthy(error.errorMessage) ? error.errorMessage : notifiers.GENERIC_ERROR,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const deleteRequestBtn = (groupId: number) => {
    setGroupId(groupId);
    setOpenModal(true);
  };

  const getPeerGroupCharacteristics = (inputString: string) => {
    const selections = {
      units: new Set(),
      type: new Set(),
      mgmt: new Set(),
    } as { [key: string]: Set<string> };
    const locations = {
      locationcity: new Set(),
      locationprovince: new Set(),
    } as { [key: string]: Set<string> };

    const AndSeparatedStrings = inputString.split("&");
    let selectedLocation = "";
    AndSeparatedStrings.forEach((separatedString) => {
      const [key, values] = separatedString.split("=");
      if (selections.hasOwnProperty(key)) {
        const vals = values.split(",");
        vals.forEach((value) => {
          selections[key].add(value.replace(key + ":", ""));
        });
      }
      if (key === "location") {
        selectedLocation = values;
      }
      if (key === "city" || key === "province") {
        const vals = values.split(",");
        vals.forEach((value) => {
          const newKey = "location" + key;
          locations[newKey].add(value.replace(newKey + ":", ""));
        });
      }
    });
    return {
      selections,
      locations,
      selectedLocation,
    };
  };

  const getHeader = () => {
    return (
      <Box sx={classes.headWrapper}>
        <Container maxWidth="xl" sx={classes.flexHead}>
          <Typography variant="h5" gutterBottom>
            {locale === "en" ? "Peer Group Definitions" : "Définitions des groupes de pairs"}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button
              sx={classes.btnfilledwithnoradius}
              onClick={() => history.push(urls.addPeerGroupsViewPath)}
            >
              {locale === "en" ? "Define a New Peer Group" : "Définir un nouveau groupe de pairs"}
            </Button>
            <Button
              sx={classes.btnwithnoradius}
              onClick={() => history.push(urls.sampleDashboardViewPath)}
            >
              {locale === "en" ? "Cancel" : "Annuler"}
            </Button>
          </Stack>
        </Container>
      </Box>
    );
  };

  const getInfoHeader = () => {
    return (
      <Grid
        container
        spacing={1}
        sx={{
          backgroundColor: "#FFF3F2",
          color: "#F3776C !important",
          p: 2,
          border: "none",
          ...theme.typography.h4,
          fontSize: getRelativeFontSize(6),
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <Typography>{contentText.peerGroup.table.headers.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <Typography>{contentText.peerGroup.table.headers.char}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
          <Typography>{contentText.peerGroup.table.headers.updated}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
          <Typography>{contentText.peerGroup.table.headers.action}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getPredefinedInfoHeader = () => {
    return (
      <Grid
        container
        spacing={1}
        sx={{
          backgroundColor: "#FFF3F2",
          color: "#F3776C !important",
          p: 2,
          border: "none",
          ...theme.typography.h4,
          fontSize: getRelativeFontSize(6),
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <Typography>{contentText.peerGroup.table.headers.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Typography>{contentText.peerGroup.table.headers.char}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
          <Typography>{contentText.peerGroup.table.headers.updated}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getGroupCharacteristics = (defItems: any, char: any) => {
    const stateType = char.stateType;
    if (defItems.selections[stateType].size === char.values.length) {
      if (stateType === "units") {
        return contentText.peerGroup.filters.units;
      }
      if (stateType === "type") {
        return contentText.peerGroup.filters.type;
      }
      if (stateType === "mgmt") {
        return contentText.peerGroup.filters.mgmt;
      }
    }
    if (stateType === "units") {
      const max = Array.from(defItems.selections[stateType]).sort();
      const matched = char.values.findIndex((a: any) => a.value === max[max.length - 1]);
      if (matched > -1) {
        return char.values[matched].label;
      }
      return "";
    }
    let matched: string[] = [];
    Array.from(defItems.selections[stateType]).map((x) => {
      const item = char.values.findIndex((a: any) => a.value === x);
      if (item > -1) {
        matched.push(char.values[item].label);
      }
    });
    return matched.join(", ");
  };

  const getLocationCharacteristics = (defItems: any, selectedLocation: any) => {
    if (selectedLocation === "locationprovince") {
      if (defItems.locations["locationprovince"].size !== Province().values.length) {
        return "In " + Array.from(defItems.locations["locationprovince"]).join(", ");
      }
    } else {
      const allCities = City().values.flatMap((entry) => entry.city);
      if (defItems.locations["locationcity"].size !== allCities.length) {
        return "In " + Array.from(defItems.locations["locationcity"]).join(", ");
      }
    }
    return contentText.peerGroup.filters.location;
  };

  const getInfo = () => {
    return groups.map((element) => {
      const defItems = getPeerGroupCharacteristics(element.defItems);
      return (
        <Grid container sx={classes.tableRowBox} spacing={1} alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Typography>{element.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            {PeerGroupStaticData().map((char: any, index: number) => {
              const characteristics = getGroupCharacteristics(defItems, char);
              return (
                <>
                  <Stack direction={"row"}>
                    <Typography>
                      {char.title}:{" "}
                      <Typography component={"span"} sx={classes.hightlightedText}>
                        {characteristics}
                      </Typography>
                    </Typography>
                  </Stack>
                </>
              );
            })}
            <Stack direction={"row"} spacing={1}>
              <Typography>{locale.toLowerCase() === "en" ? "Location:" : "Lieu:"}</Typography>{" "}
              <Typography sx={classes.hightlightedText}>
                {getLocationCharacteristics(defItems, defItems.selectedLocation)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
            <Typography>{moment(element.updatedOn).format("DD MMMM YYYY")}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <img
                src={edit}
                height={40}
                width={40}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  history.push({
                    pathname: `${urls.editPeerGroupsViewPath}`,
                    search: `?id=${element.id}`,
                    state: {
                      selections: defItems.selections,
                      locations: defItems.locations,
                      selectedLocation: defItems.selectedLocation,
                      name: element.name,
                    },
                  })
                }
              />
              <Box onClick={() => deleteRequestBtn(element.id)} sx={{ cursor: "pointer" }}>
                <img src={deleteImg} height={40} width={40} />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      );
    });
  };

  const getPredefinedInfo = () => {
    return predefinedData().map((data) => {
      return (
        <Grid container sx={classes.tableRowBox} spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Typography>{data.heading}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            {data.characteristics.map((char: any) => {
              return (
                <>
                  <Stack direction={"row"} spacing={1}>
                    <Typography>{char.label}:</Typography>{" "}
                    <Typography sx={classes.hightlightedText}>{char.value}</Typography>
                  </Stack>
                </>
              );
            })}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
            <Typography>{locale === "en" ? "Predefined" : "Prédéfinis"}</Typography>
          </Grid>
          {isTruthy(data.comment) && (
            <Typography sx={classes.bullets}>
              <FiberManualRecordIcon
                fontSize="small"
                sx={{ width: 10, paddingTop: "8px", mr: 1 }}
              />
              {data.comment}
            </Typography>
          )}
        </Grid>
      );
    });
  };

  const customDialog = () => {
    return (
      <DeleteModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        handleConfirmDelete={handleDelete}
        heading={
          locale.toLowerCase() === "en" ? "Delete peer group" : "Supprimer le groupe de pairs"
        }
        description={
          locale.toLowerCase() === "en"
            ? "Are you sure want to delete this peer group?"
            : "Êtes-vous sûr(e) de vouloir supprimer ce groupe de pairs?"
        }
      />
    );
  };

  const getList = () => {
    return (
      <Box>
        <Typography sx={classes.heading} gutterBottom>
          {locale === "en" ? "Your Custom Peer Groups" : "Vos groupes de pairs personnalisés"}
        </Typography>
        {getInfoHeader()}
        {getInfo()}
        {count > 10 && (
          <Pagination
            page={page}
            count={Math.ceil(count / limit)}
            shape="rounded"
            size="medium"
            sx={classes.pageBtn}
            onChange={(event: any, page: any) => {
              handleChangePage(event, page);
            }}
            showLastButton
            showFirstButton
          />
        )}
      </Box>
    );
  };

  const getPredefinedGroupData = () => {
    return (
      <Box mt={2}>
        <Typography sx={classes.heading} gutterBottom>
          {contentText.peerGroup.predefined.heading}
        </Typography>
        {getPredefinedInfoHeader()}
        {getPredefinedInfo()}
      </Box>
    );
  };
  return (
    <Box>
      {getHeader()}
      <Container maxWidth="xl" sx={{ pt: isDesktop ? "100px" : "150px" }}>
        <Box m={2}>
          {getList()}
          {getPredefinedGroupData()}
        </Box>
      </Container>
      {customDialog()}
      <CustomLoader isLoading={isLoading} />
    </Box>
  );
};

export default ListOfPeerGroups;
